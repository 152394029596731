<template>
	<div>
		<div>
			<JobsTreeTeamItem
				v-for="(teamItem, teamIndex) in departmentTeams"
				:key="`team_${teamIndex}`"
				:team="teamItem"
				:jobs="jobs"
				@newchild="handleNewChild"
				:organizationName="organizationName"
				:departmentName="departmentName"
				:onArchiveJob="onArchiveJob"
				:unfold="unfold"
			></JobsTreeTeamItem>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import JobsTreeTeamItem from '../jobsTreeTeamItem/JobsTreeTeamItem';

export default {
	name: 'JobsTreeDepartments',
	components: {
		JobsTreeTeamItem,
	},
	computed: {
		...mapState({
			teams: state => state.teams.items,
		}),
		departmentTeams() {
			const { departmentId, teams } = this;
			return teams.filter(item => item.department_id === departmentId);
		},
	},
	props: {
		departmentId: {
			type: Number,
			required: true,
		},
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		organizationName: {
			type: String,
			required: true,
		},
		departmentName: {
			type: String,
			required: true,
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
		unfold: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			childrenCount: 0,
		};
	},
	methods: {
		handleNewChild() {
			this.childrenCount += 1;
			this.$emit('newchild');
		},
	},
	watch: {
		jobs: {
			handler: function () {
				this.childrenCount = 0;
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
