<template>
	<v-dialog
		max-width="400"
		:value="isVisible"
		@click:outside="handleClickOutside"
		@keydown.esc="handleClickOutside"
		persistent
	>
		<v-card>
			<v-card-text style="padding: 0">
				<ValidationObserver
					ref="current_user_props_form"
					tag="form"
					@submit="handleSubmit"
					id="current_user_props_form"
				>
					<v-container>
						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-text-field
										v-model="form.name"
										:label="`${$t('label.full-name')}*`"
										flat
										hide-details
										outlined
										:error-messages="errors"
										name="name"
										type="name"
										dense
									></v-text-field>
								</ValidationProvider>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="handleSubmit">
					{{ $t('button.update') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import api from '@/api';

export default {
	name: 'CurrentUserPropDialog',
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			isVisible: false,
			form: {
				name: '',
			},
		};
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.current_user_props_form.validate();

			if (isValid) {
				const { form } = this;
				api.authUser.put(form).then(() => {
					this.$store.commit('currentUser/setProps', form);
				});
			}
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
};
</script>
