<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex>
				<v-card tile elevation="0">
					<v-divider></v-divider>
					<div>
						<v-tabs v-model="tab" slider-size="3" slider-color="primary">
							<v-tab>
								{{ $t('tab.account-settings') }}
							</v-tab>
							<v-tab v-if="provider === 'microsoft.com'">
								{{ $t('tab.group-mail') }}
							</v-tab>
						</v-tabs>
					</div>
					<v-divider></v-divider>
					<v-tabs-items v-model="tab">
						<v-tab-item>
							<AccountSettings></AccountSettings>
						</v-tab-item>
						<v-tab-item>
							<GroupMail></GroupMail>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
				<router-view></router-view>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import AccountSettings from './accountSettings/AccountSettings';
import GroupMail from './groupMail/GroupMail';

export default {
	name: 'Users',
	components: {
		AccountSettings,
		GroupMail,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			isDark: state => state.currentUser.isDark,
			provider: state => state.currentUser.provider,
		}),
	},
	data() {
		return {
			tab: 0,
		};
	},
};
</script>
