<template>
	<SettingsItemLayout>
		<template v-slot:bar>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						icon
						:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
						@click="handleRefresh"
					>
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</template>
		<template v-slot:navigation>
			<v-list dense>
				<v-list-item
					@click="handleOpenOfferTemplate({ id: null })"
					:class="offerTemplate.id === null ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-icon>add</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t('button.new-offer-template')
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					@click="handleOpenOfferTemplate({ id: item.id })"
					v-for="(item, index) in offerTemplates"
					:key="index"
					:class="offerTemplate.id === item.id ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>folder_open</v-icon>
						</v-badge>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<template>
			<v-card v-if="showTemplateDetails" tile elevation="0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-toolbar-title>
						{{ offerTemplate.label }}
					</v-toolbar-title>

					<v-spacer></v-spacer>

					<v-btn
						@click="() => handleSubmit({ close: false, refresh: true })"
						color="primary"
					>
						{{ $t('button.save-changes') }}
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<ValidationObserver
						ref="email_template_form"
						tag="form"
						@submit="handleSubmit"
						id="email_template_form"
					>
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}:
											{{
												offerTemplate.active
													? $t('label.active')
													: $t('label.inactive')
											}}
										</legend>
										<v-switch
											v-model="offerTemplate.active"
											color="success"
											inset
											dense
											hide-details
											class="v-input--template-switch"
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="offerTemplate.label"
											:label="$t('label.label')"
											outlined
											hide-details
											dense
											:error-messages="errors"
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.offer-template') }}
										</legend>
										<RichEditor
											v-model="offerTemplate.offer_template"
											refName="offerTemplate"
										></RichEditor>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>{{ $t('label.email') }}</legend>

										<v-container>
											<v-row align="center">
												<v-col sm="4" cols="12">
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-select
															v-model="offerTemplate.sent_from"
															:label="$t('label.from')"
															outlined
															:items="emails"
															hide-details
															dense
															:error-messages="errors"
														></v-select>
													</ValidationProvider>
												</v-col>

												<v-col sm="4" cols="12">
													<v-autocomplete
														v-model="offerTemplate.cc"
														:items="
															users.map(item => ({
																value: item.email,
															}))
														"
														item-text="value"
														item-value="value"
														hide-no-data
														hide-details
														outlined
														:label="$t('label.cc-uppercase')"
														multiple
														chips
														deletable-chips
														class="v-select-talentics-chips"
														dense
													></v-autocomplete>
												</v-col>

												<v-col sm="4" cols="12">
													<v-autocomplete
														v-model="offerTemplate.bcc"
														:items="
															users.map(item => ({
																value: item.email,
															}))
														"
														item-text="value"
														item-value="value"
														hide-no-data
														hide-details
														outlined
														:label="$t('label.bcc-uppercase')"
														multiple
														chips
														deletable-chips
														class="v-select-talentics-chips"
														dense
													></v-autocomplete>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-text-field
															v-model="offerTemplate.email_subject"
															outlined
															:label="$t('label.subject')"
															hide-details
															dense
															:error-messages="errors"
														></v-text-field>
													</ValidationProvider>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<fieldset
														class="talentics-fieldset talentics-fieldset-paddings"
													>
														<legend>
															{{ $t('label.body') }}
														</legend>
														<RichEditor
															v-model="offerTemplate.email_body"
															refName="emailBody"
														></RichEditor>
													</fieldset>
												</v-col>
											</v-row>

											<v-row>
												<v-col>
													<Attachments
														:attachments="offerTemplate.attachment_files"
														:onNewAttachmentAdded="handleNewAttachmentAdded"
														:onAttachmentDeleteClick="
															handleAttachmentDeleteClick
														"
													></Attachments>
												</v-col>
											</v-row>
										</v-container>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
			</v-card>
		</template>
	</SettingsItemLayout>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import Attachments from '@/components/shared/attachments/Attachments';
import SettingsItemLayout from '../settingsItemLayout/SettingsItemLayout';

export default {
	name: 'OfferTemplates',
	components: {
		ValidationObserver,
		ValidationProvider,
		RichEditor,
		Attachments,
		SettingsItemLayout,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			offerTemplates: state => state.offerTemplates.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			emails: [
				{
					value: 'noreply@talentics.app',
					text: 'noreply@talentics.app',
				},
			],
			offerTemplate: {
				id: null,
				sent_from: 'noreply@talentics.app',
				label: '',
				template: '',
				active: true,
				cc: [],
				bcc: [],
				attachments: [],
				attachment_files: [],
			},
			showTemplateDetails: false,
		};
	},
	methods: {
		handleClose() {
			this.showTemplateDetails = false;
		},
		async handleSubmit({ close = false, refresh = false }) {
			const isValid = await this.$refs.email_template_form.validate();
			if (isValid) {
				const { offerTemplate } = this;
				const { id } = offerTemplate;
				if (id) {
					this.$store
						.dispatch('offerTemplates/put', {
							items: [
								{
									...offerTemplate,
								},
							],
						})
						.then(() => {
							if (close) {
								this.showTemplateDetails = false;
							}
							if (refresh) {
								this.handleOpenOfferTemplate({ id });
							}
						});
				} else {
					this.$store
						.dispatch('offerTemplates/post', {
							items: [
								{
									...offerTemplate,
								},
							],
						})
						.then(response => {
							if (close) {
								this.showTemplateDetails = false;
							}
							if (refresh) {
								const { items } = response.data;
								if (items.length > 0) {
									const { id } = items[0];
									this.handleOpenOfferTemplate({ id });
								}
							}
							this.$store.dispatch('offerTemplates/get', {
								updateStore: true,
								refresh: true,
							});
						});
				}
			}
		},
		handleOpenOfferTemplate({ id }) {
			if (id) {
				this.$store
					.dispatch('offerTemplates/get', { id, refresh: true })
					.then(response => {
						const { items } = response.data;
						if (items.length > 0) {
							this.offerTemplate = { ...items[0] };
							this.showTemplateDetails = true;
						}
					});
			} else {
				this.offerTemplate = {
					id: null,
					sent_from: 'noreply@talentics.app',
					label: '',
					email_subject: '',
					email_body: '',
					offer_body: '',
					active: true,
					cc: [],
					bcc: [],
					attachments: [],
					attachment_files: [],
				};
				this.showTemplateDetails = true;
			}
		},
		handleRefresh() {
			this.$store.dispatch('offerTemplates/get', {
				updateStore: true,
				refresh: true,
			});
			this.handleOpenOfferTemplate({ id: null });
		},
		handleNewAttachmentAdded({ newFiles = [] }) {
			this.offerTemplate = {
				...this.offerTemplate,
				attachments: [
					...(this.offerTemplate.attachments
						? this.offerTemplate.attachments
						: []),
					...newFiles.map(({ id }) => id),
				],
				attachment_files: [
					...(this.offerTemplate.attachment_files
						? this.offerTemplate.attachment_files
						: []),
					...newFiles,
				],
			};
		},
		handleAttachmentDeleteClick({ index }) {
			this.offerTemplate = {
				...this.offerTemplate,
				attachments: this.offerTemplate.attachments.filter(
					(item, itemIndex) => index !== itemIndex,
				),
			};
			this.handleSubmit({ refresh: true });
		},
	},
	created() {
		this.$store.dispatch('offerTemplates/get', {
			updateStore: true,
			refresh: true,
		});
	},
	mounted() {
		this.handleOpenOfferTemplate({ id: null });
	},
};
</script>
