<template>
	<ValidationProvider :rules="rules" v-slot="{ errors }">
		<v-text-field
			v-model="itemModel"
			:label="label"
			:hint="description"
			:counter="maxLength"
			:maxlength="maxLength"
			:type="inputType"
			:min="min"
			:max="max"
			outlined
			dense
			:error-messages="errors"
			hide-details
			@input="newVal => handleChange({ newVal })"
			@blur="onBlur"
			:readonly="readOnly"
			:clearable="clearable"
		></v-text-field>
	</ValidationProvider>
</template>

<script>
import { dynamicFormMixin } from '@/mixins/dynamicFormMixin';
import { ValidationProvider } from 'vee-validate';

export default {
	name: 'TextField',
	mixins: [dynamicFormMixin],
	components: {
		ValidationProvider,
	},
	props: {
		value: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		maxLength: {
			type: Number,
			required: false,
		},
		inputType: {
			type: String,
			required: false,
			default() {
				return 'text';
			},
		},
		min: {
			type: Number,
			required: false,
		},
		max: {
			type: Number,
			required: false,
		},
		rules: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		debounce: {
			type: Number,
			required: false,
			default() {
				return 0;
			},
		},
		clearable: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		onBlur: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			timer: null,
			itemModel: null,
		};
	},
	methods: {
		handleChange({ newVal }) {
			const { timer, debounce } = this;
			if (debounce) {
				if (timer) {
					clearTimeout(timer);
				}
				this.timer = setTimeout(
					function () {
						this.$emit('input', newVal);
					}.bind(this),
					debounce,
				);
			} else {
				this.$emit('input', newVal);
			}
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				this.itemModel = newVal;
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
