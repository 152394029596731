<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="75vw"
			@click:outside="() => onClose({ needsRefresh: false })"
			@keydown.esc="() => onClose({ needsRefresh: false })"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat>
						<v-toolbar-title>
							{{ $t('text.view-form') }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<Avatar :userId="form.created_by"></Avatar>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 1rem">
					<DynamicForm
						:jsonSchema="form.json_schema"
						:contextSchema="form.context_schema"
						read-only
					></DynamicForm>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="() => onClose()">{{ $t('button.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import DynamicForm from '@/components/shared/dynamicForm/DynamicForm';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'ViewFormDialog',
	components: {
		DynamicForm,
		Avatar,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		formId: {
			type: [Number, String],
			required: true,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			isVisible: false,
			form: {
				json_schema: [],
				context_schema: {},
			},
		};
	},
	methods: {
		async handleGetForm({ id }) {
			this.$store
				.dispatch('forms/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.form = items[0];
					}
				});
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
	watch: {
		formId: {
			handler: function (newVal) {
				this.handleGetForm({ id: newVal });
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
