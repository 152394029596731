const state = {
	title: '',
	routeFirstLevel: '',
	routeSecondLevel: '',
	lastUpdatedAt: 0,
	drawer: true,
	drawerCalendar: false,
};

const getters = {};

const actions = {};

const mutations = {
	setRouteFirstLevel: (state, { routeName }) => {
		const now = new Date();
		state.routeFirstLevel = routeName;
		state.lastUpdatedAt = now.getTime();
	},
	setRouteSecondLevel: (state, { routeName }) => {
		const now = new Date();
		state.routeSecondLevel = routeName;
		state.lastUpdatedAt = now.getTime();
	},
	setTitle: (state, { title }) => {
		const now = new Date();
		state.title = title;
		state.lastUpdatedAt = now.getTime();
	},
	setDrawer: (state, { drawer }) => {
		const now = new Date();
		state.drawer = drawer;
		state.updated = now.getTime();
	},
	setDrawerCalendar: (state, { drawerCalendar }) => {
		const now = new Date();
		state.drawerCalendar = drawerCalendar;
		state.updated = now.getTime();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
