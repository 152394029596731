<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="true"
			max-width="1000px"
			@click:outside="handleClose"
			@keydown.esc="handleClose"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>{{ $t('text.merge-candidates') }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="pa-0">
					<v-container>
						<v-row>
							<v-col cols="12" md="5">
								<v-card>
									<v-list tile dense>
										<v-list-item-group>
											<template v-for="(item, index) in candidatesToMerge">
												<v-list-item :key="index">
													<v-list-item-content>
														<v-list-item-title
															v-text="`${item.text} (id: ${item.value})`"
														></v-list-item-title>
													</v-list-item-content>
													<v-list-item-action>
														<v-btn
															icon
															x-small
															@click="
																() =>
																	handleRemoveCandidate({
																		index,
																	})
															"
														>
															<v-icon>close</v-icon>
														</v-btn>
													</v-list-item-action>
												</v-list-item>
												<v-divider :key="`divider_${index}`"></v-divider>
											</template>
										</v-list-item-group>

										<v-list-item>
											<v-list-item-content>
												<div style="margin-top: 0.25rem">
													<Autocomplete
														:label="$t('label.search-candidates')"
														:filled="true"
														:dense="true"
														:flat="true"
														:solo="true"
														:solo-inverted="false"
														:hide-details="true"
														:items="autocompleteItems"
														:onKeyUp="handleAutocompleteInput"
														prepend-inner-icon="search"
														@change="handleAutocompleteChange"
														:outlined="true"
													></Autocomplete>
												</div>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card>
							</v-col>

							<v-col cols="12" md="2" class="text-center" align-self="center">
								<p>{{ $t('text.merge-into') }}</p>
								<v-icon size="32">forward</v-icon>
							</v-col>

							<v-col cols="12" md="5">
								<v-card>
									<v-list tile dense>
										<v-list-item-group>
											<template v-for="(item, index) in candidatesToMergeInto">
												<v-list-item :key="index">
													<v-list-item-content>
														<v-list-item-title
															v-text="`${item.text} (id: ${item.value})`"
														></v-list-item-title>
													</v-list-item-content>
													<v-list-item-action>
														<v-btn
															icon
															x-small
															@click="
																() =>
																	handleMergeIntoRemoveCandidate({
																		index,
																	})
															"
														>
															<v-icon>close</v-icon>
														</v-btn>
													</v-list-item-action>
												</v-list-item>
												<v-divider :key="`divider_${index}`"></v-divider>
											</template>
										</v-list-item-group>

										<v-list-item>
											<v-list-item-content>
												<div style="margin-top: 0.25rem">
													<Autocomplete
														:label="$t('label.search-candidates')"
														:filled="true"
														:dense="true"
														:flat="true"
														:solo="true"
														:solo-inverted="false"
														:hide-details="true"
														:items="autocompleteMergeIntoItems"
														:onKeyUp="handleMergeIntoAutocompleteInput"
														prepend-inner-icon="search"
														@change="handleMergeIntoAutocompleteChange"
														:outlined="true"
													></Autocomplete>
												</div>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card>
							</v-col>
						</v-row>
						<v-row v-if="submitEnabled">
							<v-col>
								<v-alert
									border="left"
									colored-border
									type="warning"
									elevation="2"
								>
									<div
										v-html="
											$t('warnings.merge-candidates', {
												candidates: candidatesToMerge
													.map(item => `${item.text} (${item.value})`)
													.join(', '),
												candidateMergeInto: candidatesToMergeInto
													.map(item => `${item.text} (${item.value})`)
													.join(', '),
											})
										"
									></div>
								</v-alert>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="handleClose">{{ $t('button.close') }}</v-btn>
					<v-btn
						color="primary"
						:disabled="!submitEnabled"
						@click="handleSubmit"
						>{{ $t('button.merge-candidates') }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Autocomplete from '@/components/shared/autocomplete/Autocomplete';
import api from '@/api';

export default {
	name: 'MergeCandidates',
	components: {
		Autocomplete,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
		submitEnabled() {
			const { candidatesToMerge, candidatesToMergeInto } = this;
			return candidatesToMerge.length > 0 && candidatesToMergeInto.length > 0;
		},
	},
	props: {
		onClose: {
			type: Function,
			required: true,
		},
		candidates: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		mergeInto: {
			type: Number,
			required: false,
			default() {
				return null;
			},
		},
	},
	data() {
		return {
			autocompleteItems: [],
			autocompleteMergeIntoItems: [],
			candidatesToMerge: [],
			candidatesToMergeInto: [],
		};
	},
	methods: {
		handleClose() {
			this.onClose({ needsRefresh: false });
		},
		handleAutocompleteInput({ newVal }) {
			if (newVal && newVal.length > 2) {
				api.search.get({ q: newVal }).then(response => {
					const { candidates } = response.data;
					const autocompleteItems = [
						...(candidates.length > 0 ? [{ header: 'Candidates' }] : []),
						...candidates.map(item => ({
							text: item.name,
							value: item.id,
							group: 'candidates',
						})),
					];
					this.autocompleteItems = autocompleteItems;
				});
			} else {
				this.autocompleteItems = [];
			}
		},
		handleAutocompleteChange(newVal) {
			if (newVal) {
				this.candidatesToMerge = [...this.candidatesToMerge, newVal];
			}
		},
		handleRemoveCandidate({ index }) {
			this.candidatesToMerge = this.candidatesToMerge.filter(
				(item, itemIndex) => itemIndex !== index,
			);
		},
		handleMergeIntoAutocompleteInput({ newVal }) {
			if (newVal && newVal.length > 2) {
				api.search.get({ q: newVal }).then(response => {
					const { candidates } = response.data;
					const autocompleteItems = [
						...(candidates.length > 0 ? [{ header: 'Candidates' }] : []),
						...candidates.map(item => ({
							text: item.name,
							value: item.id,
							group: 'candidates',
						})),
					];
					this.autocompleteMergeIntoItems = autocompleteItems;
				});
			} else {
				this.autocompleteMergeIntoItems = [];
			}
		},
		handleMergeIntoAutocompleteChange(newVal) {
			if (newVal) {
				this.candidatesToMergeInto = [newVal];
			}
		},
		handleMergeIntoRemoveCandidate({ index }) {
			this.candidatesToMergeInto = this.candidatesToMergeInto.filter(
				(item, itemIndex) => itemIndex !== index,
			);
		},
		async handleLoadCandidatesToMerge() {
			this.candidatesToMerge = await Promise.all(
				this.candidates.map(async id => {
					const candidateResponse = await api.candidates.get({ id });
					if (
						candidateResponse &&
						candidateResponse.data &&
						candidateResponse.data.items &&
						candidateResponse.data.items.length > 0
					) {
						const { id, name } = candidateResponse.data.items[0];
						return {
							text: name,
							value: id,
						};
					}
					return null;
				}),
			);
		},
		async handleLoadCandidatesToMergeInto() {
			const candidatesToMergeIntoResponse = await api.candidates.get({
				id: this.mergeInto,
			});
			if (
				candidatesToMergeIntoResponse &&
				candidatesToMergeIntoResponse.data &&
				candidatesToMergeIntoResponse.data.items &&
				candidatesToMergeIntoResponse.data.items.length > 0
			) {
				const { id, name } = candidatesToMergeIntoResponse.data.items[0];
				this.candidatesToMergeInto = [
					{
						text: name,
						value: id,
					},
				];
			} else {
				this.candidatesToMergeInto = [];
			}
		},
		async handleSubmit() {
			alert('Merge candidates functionality will be released soon!');
		},
	},
	mounted() {
		this.handleLoadCandidatesToMerge();
		this.handleLoadCandidatesToMergeInto();
	},
};
</script>
