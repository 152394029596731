<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" outlined text class="icons-with-menu-button">
				<span v-on="on" class="icons-with-menu-icon-container">
					<v-icon v-if="paneModel === 'nosplit'">list</v-icon>
					<v-icon v-else-if="paneModel === 'right'">vertical_split</v-icon>
					<v-icon>arrow_drop_down</v-icon>
				</span>
			</v-btn>
		</template>
		<v-list>
			<v-list-item @click="() => handleChangePaneMode({ paneMode: 'nosplit' })">
				<v-list-item-icon><v-icon>list</v-icon></v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('text.no-split') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="() => handleChangePaneMode({ paneMode: 'right' })">
				<v-list-item-icon><v-icon>vertical_split</v-icon></v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{
						$t('text.right-of-candidates-list')
					}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
	name: 'PanePicker',
	computed: {
		...mapState({
			paneMode: state => state.currentUser.paneMode,
		}),
	},
	data() {
		return {
			paneModel: 'nosplit',
		};
	},
	methods: {
		handleChangePaneMode({ paneMode }) {
			api.authUser.put({ pane_mode: paneMode }).then(() => {
				this.$store.commit('currentUser/setPaneMode', { paneMode });
			});
		},
	},
	watch: {
		paneMode: {
			handler(newValue) {
				this.paneModel = newValue ? newValue : 'nosplit';
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.v-btn {
	&.icons-with-menu-button {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
.icons-with-menu-icon-container {
	margin-left: -0.5rem;
}
</style>
