<template>
	<div>
		<fieldset
			:class="`talentics-fieldset talentics-fieldset-paddings ${
				readOnly ? 'talentics-fieldset-disabled' : ''
			}`"
		>
			<legend>{{ $t('text.attachments') }}</legend>
			<v-list v-if="attachments.length > 0" dense>
				<template v-for="(item, index) in attachments">
					<v-list-item :key="`attachment_${index}`">
						<v-list-item-avatar>
							<v-icon>attachment</v-icon>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title
								v-html="item.original_file_name"
							></v-list-item-title>
							<v-list-item-subtitle
								>{{ item.content_type }} -
								{{ item.size }} bytes</v-list-item-subtitle
							>
						</v-list-item-content>

						<v-list-item-action v-if="!readOnly">
							<v-menu bottom left>
								<template v-slot:activator="{ on }">
									<v-btn icon small v-on="on">
										<v-icon>more_vert</v-icon>
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item
										@click="
											() =>
												handleDownloadFile({
													id: item.id,
												})
										"
									>
										<v-list-item-icon>
											<v-icon>download</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$t('button.download')
										}}</v-list-item-title>
									</v-list-item>
									<v-list-item
										@click="
											() =>
												handleDeleteFile({
													fileIndex: index,
												})
										"
									>
										<v-list-item-icon>
											<v-icon>delete</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$t('button.delete')
										}}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-list-item-action>
					</v-list-item>

					<v-divider
						:key="index"
						v-if="index < attachments.length - 1"
					></v-divider>
				</template>
			</v-list>

			<div v-if="!readOnly">
				<Dropzone
					id="attachmentsDropzone"
					ref="attachmentsDropzoneContainer"
					:options="{
						maxFilesize: 20,
						maxFiles: 1,
					}"
					:initialFiles="[]"
					:onSuccess="(file, response) => handleDropzoneSuccess({ response })"
					:onRemove="file => handleDropzoneRemove({ file })"
					use-custom-slot
					customClassNames="dropzone-button"
				>
					<div class="dropzone-custom-content">
						<v-btn @click="handleClickAttachFiles">
							<v-icon left>attach_email</v-icon>
							{{ $t('button.attach-files') }}
						</v-btn>
					</div>
				</Dropzone>
			</div>

			<div v-if="readOnly && attachments.length === 0">-</div>
		</fieldset>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Dropzone from '@/components/shared/dropzone/Dropzone';
import api from '@/api';

export default {
	name: 'Attachments',
	components: {
		Dropzone,
	},
	props: {
		attachments: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onNewAttachmentAdded: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onAttachmentDeleteClick: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		readOnly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			isDialogVisible: false,
		};
	},
	methods: {
		handleDeleteFile({ fileIndex }) {
			this.onAttachmentDeleteClick({ index: fileIndex });
		},
		async handleDownloadFile({ id }) {
			const base64Response = await api.files.base64.get({ id });
			const { base64, original_file_name, content_type } = base64Response.data;
			const base64Fetch = await fetch(`data:${content_type};base64,${base64}`);
			const blob = await base64Fetch.blob();
			const FileSaver = require('file-saver');
			FileSaver.saveAs(blob, original_file_name);
		},
		handleClickAttachFiles() {
			this.isDialogVisible = true;
		},
		handleDropzoneSuccess({ response }) {
			this.onNewAttachmentAdded({ newFiles: response.items });
			this.$refs.attachmentsDropzoneContainer.$refs.attachmentsDropzone.removeAllFiles();
		},
		handleDropzoneRemove() {
			return false;
		},
	},
};
</script>
