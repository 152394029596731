<template>
	<SettingsItemLayout>
		<template v-slot:bar>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						icon
						:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
						@click="handleRefresh"
					>
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</template>
		<template v-slot:navigation>
			<v-list dense>
				<v-list-item
					@click="handleOpenApprovalTemplate({ id: null })"
					:class="approvalTemplate.id === null ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-icon>add</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t('button.new-approval-template')
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					@click="handleOpenApprovalTemplate({ id: item.id })"
					v-for="(item, index) in approvalTemplates"
					:key="index"
					:class="approvalTemplate.id === item.id ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>folder_open</v-icon>
						</v-badge>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<template>
			<v-card tile elevation="0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-toolbar-title>
						{{ approvalTemplate.label }}
					</v-toolbar-title>

					<v-spacer></v-spacer>

					<v-btn @click="handleSubmit" color="primary" :disabled="previewMode">
						{{ $t('button.save') }} &amp; {{ $t('button.close') }}
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<ValidationObserver
						ref="dynamic_form_build"
						tag="form"
						@submit="handleSubmit"
						id="dynamic_form_build"
					>
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}:
											{{
												approvalTemplate.active
													? $t('label.active')
													: $t('label.inactive')
											}}
										</legend>
										<v-switch
											v-model="approvalTemplate.active"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="approvalTemplate.label"
											:label="$t('label.label')"
											outlined
											hide-details
											:error-messages="errors"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('text.checkboxes') }}
										</legend>
										<v-container>
											<v-row>
												<v-col>
													<Draggable
														v-model="approvalTemplate.json_schema"
														@start="drag = true"
														@end="drag = false"
													>
														<div
															v-for="(
																item, index
															) in approvalTemplate.json_schema"
															:key="`checkbox_${index}`"
														>
															<v-row>
																<v-col cols="auto" align-self="center">
																	<span class="all-scroll">
																		<v-icon>drag_indicator</v-icon>
																	</span>
																</v-col>
																<v-col align-self="center">
																	<div style="margin-bottom: 0.5rem">
																		<v-text-field
																			v-model="item.label"
																			outlined
																			hide-details
																			dense
																			:placeholder="$t('label.checkbox-label')"
																		></v-text-field>
																	</div>
																	<RichEditor
																		v-model="item.html"
																		refName="`checkbox_${index}`"
																	></RichEditor>
																</v-col>
																<v-col
																	class="disp-flex flex-end"
																	cols="auto"
																	align-self="center"
																>
																	<v-btn
																		text
																		icon
																		@click="
																			() =>
																				handleDeleteCheckbox({
																					index,
																				})
																		"
																	>
																		<v-icon>delete</v-icon>
																	</v-btn>
																</v-col>
															</v-row>
														</div>
													</Draggable>
												</v-col>
											</v-row>
											<v-row style="margin-top: 0.5rem">
												<v-col>
													<v-btn @click="handleAddNewCheckbox">
														{{ $t('button.add') }}
													</v-btn>
												</v-col>
											</v-row>
										</v-container>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('text.request-email') }}
										</legend>
										<v-container>
											<v-row>
												<v-col>
													<ValidationProvider
														rules="required"
														v-slot="{ errors }"
													>
														<v-text-field
															v-model="approvalTemplate.subject"
															:label="$t('label.subject')"
															outlined
															hide-details
															:error-messages="errors"
															dense
														></v-text-field>
													</ValidationProvider>
												</v-col>
											</v-row>
											<v-row>
												<v-col>
													<RichEditor
														v-model="approvalTemplate.body"
														refName="body"
													></RichEditor>
												</v-col>
											</v-row>
										</v-container>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
			</v-card>
		</template>
	</SettingsItemLayout>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Draggable from 'vuedraggable';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import SettingsItemLayout from '../settingsItemLayout/SettingsItemLayout';

export default {
	name: 'ApprovalTemplates',
	components: {
		ValidationObserver,
		ValidationProvider,
		Draggable,
		RichEditor,
		SettingsItemLayout,
	},
	computed: {
		...mapState({
			approvalTemplates: state => state.approvalTemplates.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			showTemplateDetails: false,
			previewMode: false,
			approvalTemplate: {
				id: null,
				label: '',
				subject: '',
				body: '',
				json_schema: [],
				active: true,
			},
			newFormItem: null,
			completedApprovalTemplate: {},
			viewMode: 'edit',
		};
	},
	methods: {
		handleClose() {
			this.viewMode = 'edit';
			this.showTemplateDetails = false;
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.dynamic_form_build.validate();
			if (isValid) {
				const { approvalTemplate } = this;
				const { id } = approvalTemplate;
				if (id) {
					this.$store.dispatch('approvalTemplates/put', {
						items: [
							{
								...approvalTemplate,
							},
						],
					});
				} else {
					this.$store
						.dispatch('approvalTemplates/post', {
							items: [
								{
									...approvalTemplate,
								},
							],
						})
						.then(response => {
							const { items } = response.data;
							if (items.length > 0) {
								const { id } = items[0];
								this.handleOpenApprovalTemplate({ id });
							}
						});
				}
			}
		},
		handleOpenApprovalTemplate({ id }) {
			if (id) {
				this.$store
					.dispatch('approvalTemplates/get', { id, refresh: true })
					.then(response => {
						const { items } = response.data;
						if (items.length > 0) {
							this.approvalTemplate = { ...items[0] };
							this.showTemplateDetails = true;
						}
					});
			} else {
				this.approvalTemplate = {
					id: null,
					label: '',
					subject: '',
					body: '',
					json_schema: [{ label: '', html: '' }],
					active: true,
				};
				this.showTemplateDetails = true;
			}
		},
		handleRefresh() {
			this.$store.dispatch('approvalTemplates/get', {
				updateStore: true,
				refresh: true,
			});
			this.handleOpenApprovalTemplate({ id: null });
		},
		handleAddNewCheckbox() {
			this.approvalTemplate = {
				...this.approvalTemplate,
				json_schema: [
					...this.approvalTemplate.json_schema,
					{ label: '', html: '' },
				],
			};
		},
		handleDeleteCheckbox({ index }) {
			this.approvalTemplate = {
				...this.approvalTemplate,
				json_schema: [
					...this.approvalTemplate.json_schema.filter(
						(item, itemIndex) => itemIndex !== index,
					),
				],
			};
		},
	},
	created() {
		this.$store.dispatch('approvalTemplates/get', {
			updateStore: true,
			refresh: true,
		});
		this.showTemplateDetails = false;
	},
	mounted() {
		this.handleOpenApprovalTemplate({ id: null });
	},
};
</script>

<style lang="scss">
.v-input--template-switch {
	&.v-input--selection-controls {
		margin-top: 0;
		margin-left: 4px;
	}
}
</style>
