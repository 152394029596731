<template>
	<div>
		<v-list>
			<template v-for="(item, index) in workTypes">
				<v-list-item
					:key="item.created"
					@click="
						() =>
							addOrEditItem({
								id: item.id,
								operation: 'edit',
							})
					"
				>
					<v-list-item-avatar>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>folder_open</v-icon>
						</v-badge>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title v-html="item.name"></v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-menu v-if="!item.addButton" bottom left>
							<template v-slot:activator="{ on }">
								<v-btn icon small v-on="on">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									@click="
										() =>
											addOrEditItem({
												id: item.id,
												operation: 'edit',
											})
									"
								>
									<v-list-item-title>{{
										$t('text.edit-work-type')
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-list-item-action>
				</v-list-item>

				<v-divider
					:key="index"
					v-if="index < workTypes.length - 1"
					inset
				></v-divider>
			</template>
		</v-list>
		<div style="padding: 1rem">
			<v-btn
				small
				@click="
					() =>
						addOrEditItem({
							operation: 'add',
						})
				"
			>
				<v-icon left>add</v-icon> {{ $t('button.add-work-type') }}
			</v-btn>
		</div>
		<WorkTypeAttrs
			v-if="isWorkTypeAttrsVisible"
			:method="workTypeActionMethod"
			:workType="workTypeToEdit"
			:onClose="handleWorkTypeAttrsClose"
		></WorkTypeAttrs>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import WorkTypeAttrs from './workTypeAttrs/WorkTypeAttrs';

export default {
	name: 'WorkTypes',
	components: {
		WorkTypeAttrs,
	},
	computed: {
		...mapState({
			workTypes: state => state.workTypes.items,
		}),
	},
	data() {
		return {
			isWorkTypeAttrsVisible: false,
			workTypeActionMethod: 'edit',
			workTypeToEdit: {},
		};
	},
	methods: {
		addOrEditItem({ id, operation }) {
			if (operation === 'edit') {
				this.workTypeActionMethod = 'edit';
				this.workTypeToEdit = this.workTypes.find(item => item.id === id);
			} else if (operation === 'add') {
				this.workTypeActionMethod = 'add';
				this.workTypeToEdit = {
					active: true,
					name: '',
				};
			}
			this.isWorkTypeAttrsVisible = true;
		},
		handleWorkTypeAttrsClose() {
			this.isWorkTypeAttrsVisible = false;
		},
	},
	created() {
		this.$store.dispatch('workTypes/get', { updateStore: true });
	},
};
</script>
