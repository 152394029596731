<template>
	<v-container>
		<v-row>
			<v-col>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-autocomplete
						v-model="positionDepartmentId"
						:label="`${$t('label.department')}*`"
						:items="departmentItems"
						item-text="name"
						item-value="id"
						hide-details
						dense
						outlined
						@change="
							newVal =>
								handleDepartmentChange({
									department_id: newVal,
								})
						"
						:error-messages="errors"
					></v-autocomplete>
				</ValidationProvider>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-autocomplete
						v-model="positionTeamId"
						:label="`${$t('label.team')}*`"
						:items="teamItems"
						item-text="name"
						item-value="id"
						hide-details
						dense
						outlined
						@change="newVal => handleTeamChange({ team_id: newVal })"
						:error-messages="errors"
					></v-autocomplete>
				</ValidationProvider>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-autocomplete
						v-model="positionPositionId"
						:label="`${$t('label.position')}*`"
						:items="positionItems"
						item-text="name"
						item-value="id"
						hide-details
						dense
						outlined
						@change="newVal => handlePositionChange({ position_id: newVal })"
						:error-messages="errors"
					></v-autocomplete>
				</ValidationProvider>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import api from '@/api';

export default {
	name: 'PositionPicker',
	components: {
		ValidationProvider,
	},
	props: {
		departmentId: {
			type: [Number, String],
			required: false,
		},
		teamId: {
			type: [Number, String],
			required: false,
		},
		positionId: {
			type: [Number, String],
			required: false,
		},
		positionTitle: {
			type: [String],
			required: false,
		},
	},
	data() {
		return {
			departmentItems: [],
			teamItems: [],
			positionItems: [],
			positionDepartmentId: null,
			positionTeamId: null,
			positionPositionId: null,
			positionPositionPresetId: null,
			positionName: null,
		};
	},
	methods: {
		getDepartments() {
			api.departments.get().then(response => {
				const { items } = response.data;
				this.departmentItems = items;
			});
		},
		getTeams({ department_id }) {
			api.teams.get({ department_id }).then(response => {
				const { items } = response.data;
				this.teamItems = items;
			});
		},
		getPredefinedPositions({ team_id }) {
			api.predefinedPositions.get({ team_id }).then(response => {
				const { items } = response.data;
				this.positionItems = items;
			});
		},
		handleDepartmentChange({ department_id }) {
			if (department_id) {
				this.getTeams({ department_id });
			} else {
				this.teamItems = [];
			}
			this.positionItems = [];
			this.positionTeamId = null;
			(this.positionPositionId = null),
				(this.positionPositionPresetId = null),
				(this.positionName = null);
			setTimeout(
				function () {
					this.handleEmitChange({ team_id_changed: true });
				}.bind(this),
				50,
			);
		},
		handleTeamChange({ team_id }) {
			if (team_id) {
				this.getPredefinedPositions({ team_id });
			} else {
				this.positionItems = [];
			}
			(this.positionPositionId = null),
				(this.positionPositionPresetId = null),
				(this.positionName = null);
			setTimeout(
				function () {
					this.handleEmitChange({ team_id_changed: true });
				}.bind(this),
				50,
			);
		},
		handlePositionChange({ position_id }) {
			if (position_id) {
				const { positionItems } = this;
				const position = positionItems.find(item => item.id === position_id);
				const { predefined_position_preset_id } = position;
				this.positionPositionPresetId = predefined_position_preset_id;
			}

			setTimeout(
				function () {
					this.handleEmitChange({ team_id_changed: false });
				}.bind(this),
				50,
			);
		},
		handlePositionNameInput() {
			setTimeout(
				function () {
					this.handleEmitChange({ team_id_changed: false });
				}.bind(this),
				50,
			);
		},
		handleEmitChange({ team_id_changed }) {
			const {
				positionDepartmentId,
				positionTeamId,
				positionPositionId,
				positionPositionPresetId,
				positionName,
				positionItems,
			} = this;

			const position = positionPositionId
				? positionItems.find(({ id }) => id === positionPositionId)
				: null;

			const newVal = {
				department_id: positionDepartmentId,
				team_id: positionTeamId,
				predefined_position_id: positionPositionId,
				predefined_position_preset_id: positionPositionPresetId,
				name: position ? position.name : positionName,
				team_id_changed,
			};

			this.$emit('change', newVal);
		},
	},
	mounted() {
		this.getDepartments();
	},
	watch: {
		departmentId: {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.positionDepartmentId = newVal;
					this.getTeams({ department_id: newVal });
				}
			},
			deep: true,
			immediate: true,
		},
		teamId: {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.positionTeamId = newVal;
					this.getPredefinedPositions({ team_id: newVal });
				}
			},
			deep: true,
			immediate: true,
		},
		positionId: {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.positionPositionId = newVal;
				}
			},
			deep: true,
			immediate: true,
		},
		positionTitle: {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.positionName = newVal;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
