<template>
	<div>
		<v-dialog
			:value="true"
			max-width="400"
			@click:outside="handleClose"
			@keydown.esc="handleClose"
		>
			<v-card>
				<ValidationObserver ref="form" tag="form" @submit="handleSubmit">
					<v-container>
						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-text-field
										:disabled="method === 'edit'"
										v-model="groupMailEditable.email_address"
										outlined
										:label="$t('label.email')"
										:error-messages="errors"
										hide-details
										dense
									></v-text-field>
								</ValidationProvider>
							</v-col>
						</v-row>
						<v-row v-if="method === 'edit'">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.status') }}:
										{{
											groupMailEditable.is_active
												? $t('label.active')
												: $t('label.inactive')
										}}
									</legend>
									<v-switch
										v-model="groupMailEditable.is_active"
										color="success"
										inset
										hide-details
										dense
										class="v-input--template-switch"
									></v-switch>
								</fieldset>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleClose">{{
						$t('button.close')
					}}</v-btn>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: 'GroupMailAttrs',
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	props: {
		method: {
			type: String,
			required: true,
		},
		groupMailData: {
			type: Object,
			required: false,
			default() {
				return {
					active: true,
					email_address: '',
				};
			},
		},
		onClose: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			groupMailEditable: {
				id: null,
				email_address: '',
				is_active: true,
			},
		};
	},
	methods: {
		handleClose() {
			this.onClose();
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form.validate();
			if (isValid) {
				const { groupMailEditable, method } = this;
				if (method === 'edit') {
					this.$store
						.dispatch('groupMail/put', {
							...groupMailEditable,
						})
						.then(() => {
							this.$store.dispatch('groupMail/get', {
								updateStore: true,
							});
							this.handleClose();
						});
				} else if (method === 'add') {
					this.$store
						.dispatch('groupMail/post', {
							emailAddress: groupMailEditable.email_address,
						})
						.then(() => {
							this.$store.dispatch('groupMail/get', {
								updateStore: true,
							});
							this.handleClose();
						});
				}
			}
		},
	},
	watch: {
		groupMailData: {
			handler(newVal) {
				this.groupMailEditable = Object.assign(this.groupMailEditable, newVal);
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
