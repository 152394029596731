<template>
	<div>
		<MaintenanceLayout v-if="isMaintenancePage">
			<MaintenanceView />
		</MaintenanceLayout>

		<AuthLayout v-else-if="isAuthRoute || currentUser === null">
			<v-card elevation="0">
				<v-card-text>
					<v-container>
						<v-row>
							<v-col class="text-center" style="font-size: 1.8rem">
								{{ $t('text.get-started-with') }}
								<img
									src="/talentics.png"
									style="max-width: 200px; margin-bottom: -8px"
							/></v-col>
						</v-row>
						<v-row style="margin-top: 5rem">
							<v-col>
								<div
									id="google-signin-button"
									class="google-signin-button"
								></div>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<div class="separator-container">
									<v-divider></v-divider>
									<div class="or-container">or</div>
								</div>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="text-center">
								<v-btn
									tile
									small
									dark
									@click="handleMicrosoftSignInClick"
									class="microsoft-signin-button"
								>
									<img src="/microsoft-icon.png" />
									Sign in with Microsoft
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<div v-if="isAuthRoute">
					<router-view></router-view>
				</div>
			</v-card>
		</AuthLayout>

		<DefaultLayout v-else>
			<router-view></router-view>
		</DefaultLayout>

		<CurrentUserPropDialog
			v-if="currentUser !== null && !isUserNameFilled && !isAuthRoute"
		></CurrentUserPropDialog>
		<v-snackbar
			v-model="snackbar.isVisible"
			:timeout="snackbar.timeout"
			left
			bottom
		>
			{{ snackbar.text }}
			<template v-slot:action="{ attrs }">
				<v-btn
					dark
					text
					icon
					v-bind="attrs"
					@click="snackbar.isVisible = false"
					style="margin-right: 0.5rem"
				>
					<v-icon>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
		<v-overlay :value="isLoading" :z-index="9999">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-snackbar
			:value="userRole === 900 || userRole === 999"
			:timeout="-1"
			color="pink"
			elevation="24"
			right
			bottom
		>
			<v-icon>support_agent</v-icon> Viewing as support agent
		</v-snackbar>
	</div>
</template>

<script>
import axios from 'axios';

import { mapState } from 'vuex';
import DefaultLayout from './components/layouts/defaultLayout/DefaultLayout';
import AuthLayout from './components/layouts/authLayout/AuthLayout';
import MaintenanceLayout from './components/layouts/maintenanceLayout/MaintenanceLayout';
import CurrentUserPropDialog from './components/shared/currentUserPropDialog/CurrentUserPropDialog';
import { auth } from './main';
import {
	getAuth,
	getIdToken,
	GoogleAuthProvider,
	isSignInWithEmailLink,
	signInWithEmailLink,
	OAuthProvider,
	signInWithCredential,
	signInWithPopup,
	onAuthStateChanged,
} from 'firebase/auth';
import MaintenanceView from './components/routes/maintenance/maintenanceView.vue';

export default {
	name: 'App',
	components: {
		DefaultLayout,
		AuthLayout,
		MaintenanceLayout,
		CurrentUserPropDialog,
		MaintenanceView,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
			currentLang: state => state.currentUser.lang,
			isLoading: state => state.apiTraffic.isLoading,
			isDark: state => state.currentUser.isDark,
		}),
		isUserNameFilled() {
			if (this.currentUser === null) {
				return true;
			}
			return Boolean(this.currentUser.name);
		},
	},
	data() {
		return {
			availableLangs: ['en', 'de', 'tr'],
			isMaintenancePage: false,
			isAuthRoute: false,
			snackbar: {
				isVisible: false,
				timeout: 3000,
				text: '',
			},
		};
	},
	methods: {
		parseJwt(token) {
			var base64Url = token.split('.')[1];
			var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			var jsonPayload = decodeURIComponent(
				window
					.atob(base64)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join(''),
			);

			return JSON.parse(jsonPayload);
		},
		onGoogleSignIn(user) {
			this.$store.commit('currentUser/setProvider', {
				provider: 'google.com',
			});
			this.$store.commit('currentUser/setGoogleUser', { user });

			const token = user.getAuthResponse(true);
			const { access_token } = token;
			const credential = GoogleAuthProvider.credential(null, access_token);

			signInWithCredential(auth, credential);
		},
		renderGoogleSignInButton() {
			if (window.gapi) {
				window.gapi.signin2.render('google-signin-button', {
					scope: 'email profile',
					response_type: 'id_token permission',
					onsuccess: this.onGoogleSignIn,
					theme: 'dark',
					longtitle: true,
				});
			} else {
				setTimeout(
					function () {
						this.renderGoogleSignInButton();
					}.bind(this),
					250,
				);
			}
		},
		setLang({ lang = 'en', redirect = true }) {
			const { availableLangs, currentUser } = this;
			const paramsLang = this.$route.params.lang;

			if (lang && availableLangs?.indexOf(lang) > -1) {
				this.$i18n.locale = lang;

				if (!redirect) {
					return false;
				}

				if (!paramsLang || availableLangs?.indexOf(paramsLang) === -1) {
					const { query } = this.$route;
					this.$router.push({
						path: `/${lang}`,
						query,
					});

					return false;
				}

				if (paramsLang !== lang && currentUser) {
					const { path } = this.$route;
					const newPath = path.replace(`/${paramsLang}`, `/${lang}`);
					const { query } = this.$route;
					this.$router.push({
						path: newPath,
						query,
					});

					return false;
				}
			} else {
				const { query } = this.$route;
				this.$router.push({
					path: `/${lang}`,
					query,
				});

				return false;
			}
		},
		async handleMicrosoftSignInClick() {
			this.$store.commit('currentUser/setProvider', {
				provider: 'microsoft.com',
			});
			const provider = new OAuthProvider('microsoft.com');
			await signInWithPopup(getAuth(), provider);
		},
		setUserProvider(user) {
			const [provider] = user.providerData;
			const { providerId } = provider;
			this.$store.commit('currentUser/setProvider', {
				provider: providerId,
			});
		},
	},
	created() {
		axios.defaults.baseURL = process.env.VUE_APP_HOST_API_KEY;

		axios.interceptors.request.use(
			config => {
				if (
					config &&
					// extract long-polling requests
					((config.url === '/v1/hire-app/user-approval-requests' &&
						config.method === 'get') ||
						// extract counter requests
						(config.url === '/v1/hire-app/applicants/counters' &&
							config.method === 'get') ||
						(config.url === '/v1/hire-app/applicants/counters/lead' &&
							config.method === 'get') ||
						(config.url === '/v1/hire-app/applicants/counters/interview' &&
							config.method === 'get') ||
						(config.url === '/v1/hire-app/applicants/counters/archive' &&
							config.method === 'get'))
				) {
					return config;
				}
				this.$store.commit('apiTraffic/increaseRequests');
				return config;
			},
			() => {
				this.$store.commit('apiTraffic/increaseRequests');
			},
		);

		axios.interceptors.response.use(
			response => {
				if (
					response &&
					response.config &&
					// extract long-polling request responses
					((response.config.url === '/v1/hire-app/user-approval-requests' &&
						response.config.method === 'get') ||
						// extract counter responses
						(response.config.url === '/v1/hire-app/applicants/counters' &&
							response.config.method === 'get') ||
						(response.config.url === '/v1/hire-app/applicants/counters/lead' &&
							response.config.method === 'get') ||
						(response.config.url ===
							'/v1/hire-app/applicants/counters/interview' &&
							response.config.method === 'get') ||
						(response.config.url ===
							'/v1/hire-app/applicants/counters/archive' &&
							response.config.method === 'get'))
				) {
					return response;
				}
				this.$store.commit('apiTraffic/increaseResponses');
				if (
					(response.config.method === 'post' ||
						response.config.method === 'put' ||
						response.config.method === 'delete') &&
					response.config.url !== '/v1/hire-app/auth-user' &&
					response.config.url !== '/v1/hire-app/mentions/mark-as-seen' &&
					response.config.url !== '/v1/hire-app/notifications/mark-as-seen'
				) {
					const getText = ({ method }) => {
						if (method === 'post') {
							return this.$t('snackbar-messages.successfully-created');
						} else if (method === 'put') {
							return this.$t('snackbar-messages.successfully-updated');
						} else if (method === 'delete') {
							return this.$t('snackbar-messages.successfully-deleted');
						}
						return '';
					};
					this.snackbar = {
						isVisible: true,
						timeout: 3000,
						text: getText({ method: response.config.method }),
					};
				}
				// eslint-disable-next-line no-debugger
				return response;
			},
			error => {
				// extract long-polling request responses
				if (
					!(
						(error &&
							error.config &&
							error.config.url === '/v1/hire-app/user-approval-requests' &&
							error.config.method === 'get') ||
						(error.config.url === '/v1/hire-app/applicants/counters' &&
							error.config.method === 'get') ||
						(error.config.url === '/v1/hire-app/applicants/counters/lead' &&
							error.config.method === 'get') ||
						(error.config.url ===
							'/v1/hire-app/applicants/counters/interview' &&
							error.config.method === 'get') ||
						(error.config.url === '/v1/hire-app/applicants/counters/archive' &&
							error.config.method === 'get')
					)
				) {
					this.$store.commit('apiTraffic/increaseResponses');
				}

				if (error && error.response) {
					if (
						error.response.data &&
						error.response.data.code === 'auth/id-token-expired'
					) {
						// REFRESH TOKEN
						const auth = getAuth();
						const { currentUser } = auth;
						return getIdToken(currentUser, true).then(idToken => {
							axios.defaults.headers.common['X-Auth-Token'] = idToken;
							error.config.headers['X-Auth-Token'] = idToken;
							return axios.request(error.config);
						});
					} else if (
						error.response.data &&
						error.response.data.data &&
						error.response.data.data.message
					) {
						this.snackbar = {
							isVisible: true,
							timeout: 3000,
							text: error.response.data.data.message,
						};
					} else if (error.response.data) {
						this.snackbar = {
							isVisible: true,
							timeout: 3000,
							text:
								typeof error.response.data === 'object' &&
								error.response.data.message
									? this.$t(`snackbar-messages.${error.response.data.message}`)
									: this.$t('snackbar-messages.an-error-occurred'),
						};
					}
					return Promise.reject(error.response);
				}
				return Promise.reject(error);
			},
		);
	},
	mounted() {
		this.renderGoogleSignInButton();

		if (isSignInWithEmailLink(auth, window.location.href)) {
			const email = window.localStorage.getItem('emailForSignIn');

			if (email) {
				signInWithEmailLink(email, window.location.href).then(() => {
					window.localStorage.removeItem('emailForSignIn');
				});
			}
		}

		onAuthStateChanged(auth, user => {
			if (user) {
				this.setUserProvider(user);
				user.getIdToken().then(idToken => {
					this.$store.dispatch('currentUser/authUser', { idToken }).then(() => {
						/**
						 * getIdToken() is called with true parameter to force
						 * refresh the current user's id token.
						 * This is required because auth-user endpoint
						 * creates custom claims for the id token received.
						 * This id token created with custom claims supersedes
						 * id token acquired above. When id token is refresh,
						 * however, it'll contain the necessary custom claims
						 * in it.
						 */
						user.getIdToken(true).then(refreshToken => {
							this.$store.commit('currentUser/setIdToken', { 
								id_token: refreshToken 
							});
							const decodedTokenData = this.parseJwt(refreshToken);
							this.$store.dispatch('currentUser/setRole', {
								role: decodedTokenData.role,
							});
							this.$store.dispatch('company/get', {});
							this.$store.dispatch('pipelineInterview/get', {
								updateStore: true,
								refresh: true,
							});
							this.$store.dispatch('pipelineArchive/get', {
								updateStore: true,
								refresh: true,
							});
							this.$store.dispatch('pipelineLead/get', {
								updateStore: true,
								refresh: true,
							});
							this.$store.dispatch('userApprovalRequests/get', {
								updateStore: true,
								refresh: true,
							});
						});
					});
				});
			} else {
				setTimeout(
					function () {
						this.renderGoogleSignInButton();
					}.bind(this),
					500,
				);
			}
		});
	},
	watch: {
		currentLang: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					const { fullPath } = this.$route;

					// TODO: Seperate this to a public layout
					const isMaintenancePage = fullPath?.includes('/maintenance');
					if (isMaintenancePage) {
						this.isMaintenancePage = isMaintenancePage;
					} else {
						// Prevent redirecting to main route if maintenance
						const isAuthRoute = fullPath?.indexOf('/auth/') > -1;
						this.isAuthRoute = isAuthRoute;
						this.setLang({ lang: newVal, redirect: !isAuthRoute });
					}
				}
			},
			deep: true,
			immediate: true,
		},
		isDark: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.$vuetify.theme.dark = newVal;
				}
			},
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
.page {
	max-width: 1185px;
	margin: 0 auto;
}
.theme--light.v-data-table.transparent-table {
	background: transparent;
}

.v-select {
	&.v-select--chips {
		&.v-select-talentics-chips {
			.v-select__selections {
				min-height: 42px !important;
			}
		}
	}
}

.google-signin-button {
	& > div {
		margin: 0 auto;
	}
}

.microsoft-signin-button {
	text-transform: none !important;
	height: 36px !important;
	position: relative;
	padding-left: 50px !important;

	& > .v-btn__content {
		position: static;

		img {
			position: absolute;
			top: 1px;
			left: 1px;
		}
	}
}

.separator-container {
	position: relative;
	max-width: 400px;
	margin: 1rem auto;
}

.or-container {
	position: absolute;
	width: 60px;
	height: 22px;
	text-align: center;
	left: 50%;
	top: -11px;
	margin-left: -30px;
	background: white;
}

fieldset.talentics-fieldset {
	border: 1px solid #9e9e9e;
	border-radius: 4px;
	margin-top: -10px;

	& > legend {
		color: rgba(0, 0, 0, 0.6);
		font-size: 12px;
		margin-left: 10px;
		padding-left: 4px;
		padding-right: 4px;
	}

	&.talentics-fieldset-paddings {
		padding: 0 0.5rem 0.5rem 0.5rem;

		& > legend {
			margin-left: 2px;
		}
	}

	&.talentics-fieldset-error {
		border: 2px solid #ff5252;

		& > legend {
			color: #ff5252;
		}
	}

	&.talentics-fieldset-disabled {
		border: 1px solid #bdbdbd;

		& > legend {
			color: #bdbdbd;
		}
	}

	& > * {
		border-radius: 4px;
	}
}

.theme--dark {
	fieldset.talentics-fieldset {
		legend {
			color: rgba(255, 255, 255, 0.7);
		}
	}
}
</style>
