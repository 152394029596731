var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.readonly)?_c('div',[_c('fieldset',{staticClass:"talentics-fieldset talentics-fieldset-paddings"},[_c('legend',[_vm._v(_vm._s(_vm.label))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.date ? _vm.date : '&nbsp;')}})])]):_c('div',[(_vm.type === 'button')?_c('v-btn',_vm._g({attrs:{"depressed":"","color":"transparent","tile":""}},on),[_vm._v(" "+_vm._s(_vm.formatDate({ timestamp: _vm.date }))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_drop_down")])],1):_c('v-text-field',_vm._g({attrs:{"value":_vm.date,"prepend-inner-icon":_vm.prependInnerIcon,"readonly":"","outlined":_vm.outlined,"background-color":`${_vm.isDark ? '' : '#ffffff'}`,"hide-details":"","no-title":"","error-messages":_vm.errors,"label":_vm.label,"dense":_vm.dense,"disabled":_vm.disabled}},on))],1)]}}])},[_c('div',[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"no-title":"","scrollable":"","first-day-of-week":1,"range":_vm.range},on:{"input":_vm.handleDatePickerMenuInput},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),(_vm.range)?_c('div',{staticStyle:{"margin-top":"-5px"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('fieldset',{staticClass:"talentics-fieldset talentics-fieldset-paddings"},[_c('legend',[_vm._v(_vm._s(_vm.$t('label.quick-selections')))]),_c('div',[_c('v-btn',{staticStyle:{"margin":"0.25rem"},attrs:{"x-small":""},on:{"click":function($event){return _vm.handleQuickSelection({
										preset: 'this-week',
									})}}},[_vm._v(_vm._s(_vm.$t('button.this-week')))]),_c('v-btn',{staticStyle:{"margin":"0.25rem"},attrs:{"x-small":""},on:{"click":function($event){return _vm.handleQuickSelection({
										preset: 'this-month',
									})}}},[_vm._v(_vm._s(_vm.$t('button.this-month')))]),_c('v-btn',{staticStyle:{"margin":"0.25rem"},attrs:{"x-small":""},on:{"click":function($event){return _vm.handleQuickSelection({
										preset: 'last-month',
									})}}},[_vm._v(_vm._s(_vm.$t('button.last-month')))]),_c('v-btn',{staticStyle:{"margin":"0.25rem"},attrs:{"x-small":""},on:{"click":function($event){return _vm.handleQuickSelection({
										preset: 'this-year',
									})}}},[_vm._v(_vm._s(_vm.$t('button.this-year')))])],1)])])],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }