<template>
	<div>
		<div
			small
			:color="team.active ? 'green' : 'grey'"
			:class="`${childrenCount > 0 ? 'd-block' : 'd-none'} sector-item-wrapper`"
		>
			<div class="sector-title" style="border-left: 40px solid transparent">
				<span style="color: grey">{{ organizationName }}</span> /
				<span style="color: grey">{{ departmentName }}</span> /
				{{ team.name }} ({{ childrenCount }})
				<v-btn icon @click="handleToggleCollapseClick">
					<v-icon v-if="collapsed">keyboard_arrow_down</v-icon>
					<v-icon v-else>keyboard_arrow_right</v-icon>
				</v-btn>
			</div>
			<div
				:style="`display: ${
					childrenCount > 0 && collapsed ? 'block' : 'none'
				};`"
			>
				<JobsTreeJobs
					:teamId="team.id"
					:jobs="jobs"
					@newchild="handleNewChild"
					:organizationName="organizationName"
					:departmentName="departmentName"
					:teamName="team.name"
					:onArchiveJob="onArchiveJob"
					:unfold="unfold"
				></JobsTreeJobs>
			</div>
		</div>
	</div>
</template>

<script>
import JobsTreeJobs from '../jobsTreeJobs/JobsTreeJobs';

export default {
	name: 'JobsTreeTeamItem',
	props: {
		team: {
			type: Object,
		},
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		organizationName: {
			type: String,
			required: true,
		},
		departmentName: {
			type: String,
			required: true,
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
		unfold: {
			type: Boolean,
			required: true,
		},
	},
	components: {
		JobsTreeJobs,
	},
	data() {
		return {
			childrenCount: 0,
			collapsed: true,
		};
	},
	methods: {
		handleNewChild() {
			this.childrenCount += 1;
			this.$emit('newchild');
		},
		handleToggleCollapseClick() {
			this.collapsed = !this.collapsed;
		},
	},
	watch: {
		jobs: {
			handler: function () {
				this.childrenCount = 0;
			},
			immediate: true,
			deep: true,
		},
		unfold: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.collapsed = newVal;
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
