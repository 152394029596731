<script>
import { Bar } from 'vue-chartjs';

export default {
	name: 'ChartStackedBar',
	extends: Bar,
	props: {
		chartdata: {
			type: Object,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
		onClick: {
			type: Function,
			default() {
				return false;
			},
		},
	},
	methods: {
		handleRenderChart(chartData) {
			this.renderChart(chartData, {
				responsive: true,
				maintainAspectRatio: false,
				onClick: this.handleClick,
				scales: {
					xAxes: [
						{
							stacked: true,
							categoryPercentage: 0.5,
							barPercentage: 1,
						},
					],
					yAxes: [
						{
							stacked: true,
						},
					],
				},
			});
		},
		handleClick(event) {
			const c = this._data._chart;
			const datapoints = c.getElementAtEvent(event);

			if (datapoints.length > 0) {
				const { _index, _datasetIndex } = datapoints[0];
				this.onClick({ index: _index, datasetIndex: _datasetIndex });
			}
		},
	},
	mounted() {
		this.handleRenderChart(this.chartdata);
	},
	watch: {
		chartdata: {
			handler: function (newVal) {
				this.handleRenderChart(newVal);
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>
