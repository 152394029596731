<script>
import { Bar } from 'vue-chartjs';

export default {
	name: 'ChartBar',
	extends: Bar,
	props: {
		chartdata: {
			type: Object,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
	},
	methods: {
		handleRenderChart(chartData) {
			this.renderChart(chartData, {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: false,
				},
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
							},
						},
					],
					xAxes: [
						{
							afterFit: scale => {
								scale.height = 80;
							},
						},
					],
				},
			});
		},
	},
	mounted() {
		this.handleRenderChart(this.chartdata);
	},
	watch: {
		chartdata: {
			handler: function (newVal) {
				this.handleRenderChart(newVal);
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>
