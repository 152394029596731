<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="680"
			persistent
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat>
						<v-toolbar-title>
							{{ candidate.name }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 1rem">
					<ValidationObserver
						ref="form_new_form"
						tag="form"
						@submit="handleSubmit"
						id="form_new_form"
					>
						<div style="margin-bottom: 1rem">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="form.application_id"
									:items="applicationsWithJobNames"
									:item-text="
										item =>
											`${item.job_name} (${handleFormatDate({
												timestamp: item.created,
											})})`
									"
									item-value="id"
									:label="$t('label.choose-job')"
									outlined
									hide-details
									dense
									:error-messages="errors"
									@change="
										newVal =>
											handleApplicationIdChange({
												applicationId: newVal,
											})
									"
								></v-select>
							</ValidationProvider>
						</div>
						<div>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="form.pipeline_status"
									:items="statuses"
									:item-text="
										item => {
											if (item.text) {
												return $t(`pipeline.steps.${item.text}`);
											}
											return item;
										}
									"
									item-value="value"
									:label="$t('label.status')"
									outlined
									hide-details
									dense
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</div>
						<div style="margin: 1rem 0">
							<v-divider></v-divider>
						</div>
						<DynamicForm
							:jsonSchema="form.json_schema"
							:contextSchema="form.context_schema"
							:onChange="handleDynamicFormChange"
						></DynamicForm>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="() => onClose({ needsRefresh: false })">{{
						$t('button.close')
					}}</v-btn>
					<v-btn
						v-if="form.status === 'draft'"
						@click="handleSaveAsDraft"
						color="secondary"
					>
						<v-icon left>note_alt</v-icon>
						{{ $t('button.save-as-draft') }}
					</v-btn>
					<v-btn
						v-if="form.status === 'draft'"
						@click="evt => handleSubmit(evt, true)"
						color="primary"
					>
						<v-icon left>task</v-icon>
						{{ $t('button.complete-and-share') }}
					</v-btn>
					<v-btn
						v-if="form.status === 'completed'"
						@click="evt => handleSubmit(evt, false)"
						color="primary"
					>
						<v-icon left>task</v-icon>
						{{ $t('button.save') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { format } from 'date-fns';
import DynamicForm from '@/components/shared/dynamicForm/DynamicForm';

export default {
	name: 'EditFormDialog',
	components: {
		ValidationObserver,
		ValidationProvider,
		DynamicForm,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			jobs: state => state.jobs.items,
			pipelineInterviewItems: state => state.pipelineInterview.items,
			pipelineLeadItems: state => state.pipelineLead.items,
			pipelineArchiveItems: state => state.pipelineArchive.items,
		}),
		applicationsWithJobNames() {
			const { applications } = this.candidate;
			const { jobs } = this;
			if (applications) {
				return applications.map(application => {
					const job = jobs.find(item => item.id === application.job_id);
					return {
						...application,
						job_name: job ? job.name : application.job_id,
					};
				});
			}
			return [];
		},
		statuses() {
			const {
				pipelineInterviewItems,
				pipelineLeadItems,
				pipelineArchiveItems,
			} = this;
			return [
				{ header: 'Lead' },
				...pipelineLeadItems.map(item => ({ text: item, value: item })),
				{ divider: true },
				{ header: 'Application' },
				{ text: 'new-applicant', value: 'new-applicant' },
				{ divider: true },
				{ header: 'Interview' },
				...pipelineInterviewItems.map(item => ({
					text: item,
					value: item,
				})),
				{ header: 'Archive' },
				...pipelineArchiveItems.map(item => ({
					text: item,
					value: item,
				})),
			];
		},
	},
	props: {
		formId: {
			type: [String, Number],
			required: true,
		},
		candidate: {
			type: Object,
			required: true,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			format,
			isVisible: false,
			form: {
				json_schema: [],
				context_schema: {},
				pipeline_status: null,
				job_id: null,
				application_id: null,
			},
		};
	},
	methods: {
		async handleGetForm({ id }) {
			this.$store
				.dispatch('forms/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.form = items[0];
					}
				});
		},
		async handleSaveAsDraft() {
			const { id, context_schema, pipeline_status, job_id, application_id } =
				this.form;
			this.$store
				.dispatch('forms/put', {
					items: [
						{
							id,
							context_schema,
							pipeline_status,
							job_id,
							application_id,
							status: 'draft',
						},
					],
				})
				.then(() => {
					this.$store.dispatch('drafts/get_forms', {});
					this.onClose({ needsRefresh: true });
				});
		},
		async handleSubmit(evt, complete_and_share) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_new_form.validate();
			if (isValid) {
				const { id, context_schema, pipeline_status, job_id, application_id } =
					this.form;
				this.$store
					.dispatch('forms/put', {
						items: [
							{
								id,
								context_schema,
								pipeline_status,
								pipeline_status_translation: this.$t(
									`pipeline.steps.${pipeline_status}`,
								),
								job_id,
								application_id,
								status: 'completed',
								complete_and_share,
							},
						],
					})
					.then(() => {
						this.$store.dispatch('drafts/get_forms', {});
						this.onClose({ needsRefresh: true });
					});
			}
		},
		handleDynamicFormChange({ newVal }) {
			this.form = {
				...this.form,
				context_schema: {
					...this.form.context_schema,
					...newVal,
				},
			};
		},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleFormatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleApplicationIdChange({ applicationId }) {
			if (applicationId) {
				const application = this.candidate.applications.find(
					item => item.id === applicationId,
				);
				const { job_id } = application;
				const getPipelineStatus = ({
					is_archived,
					archive_status,
					is_interview,
					interview_status,
					is_lead,
					lead_status,
				}) => {
					if (is_archived) {
						return archive_status ? archive_status : 'underqualified';
					} else if (is_interview) {
						return interview_status ? interview_status : 'phone';
					} else if (is_lead) {
						return lead_status ? lead_status : 'new-lead';
					}
					return 'new-applicant';
				};
				const pipeline_status = getPipelineStatus(application);
				this.form = {
					...this.form,
					pipeline_status,
					job_id,
				};
			}
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
	watch: {
		formId: {
			handler: function (newVal) {
				this.handleGetForm({ id: newVal });
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
