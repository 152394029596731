<template>
	<v-menu
		offset-y
		bottom
		left
		:max-height="500"
		:max-width="500"
		v-model="menu"
	>
		<template v-slot:activator="{ on }">
			<span v-on="on">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
							<v-badge
								color="pink"
								overlap
								bottom
								:content="userApprovalRequests.length"
								:value="userApprovalRequests.length"
							>
								<v-icon>check_circle</v-icon>
							</v-badge>
						</v-btn>
					</template>
					{{ !menu ? $t('tooltip.approval-requests') : '' }}
				</v-tooltip>
			</span>
		</template>

		<v-list three-line>
			<v-list-item-group v-if="userApprovalRequests.length > 0">
				<template v-for="(userApprovalRequest, index) in userApprovalRequests">
					<v-list-item :key="index" @click="handleClick(userApprovalRequest)">
						<v-list-item-avatar>
							<Avatar :userId="userApprovalRequest.requested_by"></Avatar>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title>{{
								$t(
									`text.${userApprovalRequest.requisition_type.replace(
										/_/g,
										'-',
									)}`,
								)
							}}</v-list-item-title>
							<v-list-item-subtitle>{{
								getJobName({
									jobId: userApprovalRequest.job_id,
								})
							}}</v-list-item-subtitle>
							<v-list-item-subtitle>{{
								formatDate({
									timestamp: userApprovalRequest.created,
								})
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider
						:key="`divider_${index}`"
						v-if="index < userApprovalRequests.length - 1"
					></v-divider>
				</template>
			</v-list-item-group>

			<v-list-item v-else disabled>
				<v-list-item-title>
					{{ $t('text.no-new-approval-requests') }}</v-list-item-title
				>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'UserApprovalRequests',
	components: {
		Avatar,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			currentLang: state => state.currentUser.lang,
			userApprovalRequests: state => state.userApprovalRequests.items,
			jobs: state => state.jobs.items,
		}),
	},
	data() {
		return {
			format,
			menu: false,
			userApprovalRequestInterval: null,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		getJobName({ jobId }) {
			const { jobs } = this;
			const job = jobs.find(item => item.id === jobId);
			return job ? job.name : jobId;
		},
		handleClick({ requisition_type, requisition_id, job_id }) {
			if (requisition_type === 'new_job') {
				this.$router.push({
					path: `/${this.currentLang}/jobs/edit/${job_id}/requisitions/${requisition_id}`,
				});
			}
		},
		handleGetUserApprovalRequests({ miliseconds }) {
			this.userApprovalRequestInterval = setInterval(
				function () {
					this.$store.dispatch('userApprovalRequests/get', {
						updateStore: true,
						refresh: true,
					});
				}.bind(this),
				miliseconds,
			);
		},
	},
	mounted() {
		this.handleGetUserApprovalRequests({ miliseconds: 600000 });
	},
	destroyed() {
		if (this.userApprovalRequestInterval) {
			clearInterval(this.userApprovalRequestInterval);
		}
	},
	watch: {
		menu: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal && newVal === true) {
					this.$store.dispatch('jobs/get', { updateStore: true });
				}
			},
			immediate: false,
			deep: true,
		},
	},
};
</script>
