<template>
	<v-menu
		offset-y
		bottom
		left
		:max-height="500"
		:max-width="500"
		v-model="menu"
	>
		<template v-slot:activator="{ on }">
			<span v-on="on">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
							<v-badge
								color="pink"
								overlap
								bottom
								:content="totalCount"
								:value="totalCount"
							>
								<v-icon>drive_file_rename_outline</v-icon>
							</v-badge>
						</v-btn>
					</template>
					{{ !menu ? $t('tooltip.my-drafts') : '' }}
				</v-tooltip>
			</span>
		</template>

		<v-list dense>
			<v-subheader>{{ $t('tab.feedbacks-to-complete') }}</v-subheader>
			<v-list-item-group v-if="incompleteFeedbacks.length > 0">
				<v-divider></v-divider>
				<template v-for="(feedback, index) in incompleteFeedbacks">
					<v-list-item
						:key="index"
						@click="handleIncompleteFeedbackClick(feedback)"
					>
						<v-list-item-avatar>
							<Avatar :userId="currentUser.id"></Avatar>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title style="white-space: normal"
								>{{ feedback.candidate_name }} -
								{{ getJobName({ jobId: feedback.job_id }) }} -
								{{
									$t(`pipeline.steps.${feedback.pipeline_status}`)
								}}</v-list-item-title
							>
							<v-list-item-subtitle
								v-html="formatDate({ timestamp: feedback.created })"
							></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider :key="`divider_feedback_${index}`"></v-divider>
				</template>
			</v-list-item-group>

			<v-list-item-group v-else>
				<v-divider></v-divider>
				<v-list-item disabled>
					<v-list-item-title>
						{{ $t('err.no-incomplete-feedbacks') }}</v-list-item-title
					>
				</v-list-item>
				<v-divider></v-divider>
			</v-list-item-group>

			<v-subheader>{{ $t('tab.forms-to-complete') }}</v-subheader>

			<v-list-item-group v-if="incompleteForms.length > 0">
				<v-divider></v-divider>
				<template v-for="(form, index) in incompleteForms">
					<v-list-item :key="index" @click="handleIncompleteFormClick(form)">
						<v-list-item-avatar>
							<Avatar :userId="currentUser.id"></Avatar>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title style="white-space: normal"
								>{{ form.candidate_name }} -
								{{ getJobName({ jobId: form.job_id }) }} -
								{{
									$t(`pipeline.steps.${form.pipeline_status}`)
								}}</v-list-item-title
							>
							<v-list-item-subtitle
								v-html="formatDate({ timestamp: form.created })"
							></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider :key="`divider_form_${index}`"></v-divider>
				</template>
			</v-list-item-group>

			<v-list-item-group v-else>
				<v-divider></v-divider>
				<v-list-item disabled>
					<v-list-item-title>
						{{ $t('err.no-incomplete-forms') }}</v-list-item-title
					>
				</v-list-item>
				<v-divider></v-divider>
			</v-list-item-group>
		</v-list>
	</v-menu>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'MyDrafts',
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			currentUser: state => state.currentUser.currentUser,
			jobs: state => state.jobs.items,
			incompleteFeedbacks: state => state.drafts.feedback_items,
			incompleteForms: state => state.drafts.form_items,
		}),
		totalCount() {
			const { incompleteFeedbacks, incompleteForms } = this;
			return incompleteFeedbacks.length + incompleteForms.length;
		},
	},
	components: {
		Avatar,
	},
	data() {
		return {
			format,
			menu: false,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleIncompleteFeedbackClick({ candidate_id }) {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${candidate_id}`,
				query: {
					...query,
					is_search: 1,
					tab: 'feedbacks',
				},
			});
		},
		handleIncompleteFormClick({ candidate_id }) {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${candidate_id}`,
				query: {
					...query,
					is_search: 1,
					tab: 'forms',
				},
			});
		},
		getJobName({ jobId }) {
			const { jobs } = this;
			const job = jobs.find(item => item.id === jobId);
			return job ? job.name : jobId;
		},
	},
	mounted() {
		this.$store.dispatch('drafts/get_feedbacks', {});
		this.$store.dispatch('drafts/get_forms', {});
	},
};
</script>
