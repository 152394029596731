import api from '@/api';

const state = {
	items: [],
	updated: 0,
};

const getters = {};

const actions = {
	get: (
		{ commit },
		{ refresh = false, updateStore = true, id, parseResume = false },
	) => {
		return new Promise((resolve, reject) => {
			const fileExists = state.items.find(item => item.id === id);
			if (!refresh && fileExists) {
				resolve('stateReady');
			} else {
				api.files
					.get({ id, parseResume })
					.then(response => {
						if (updateStore) {
							commit('addItem', { item: { ...response.data } });
						}
						resolve(response);
					})
					.catch(error => {
						reject(error);
					});
			}
		});
	},
	// put: ({ commit }, params) => {
	//   return new Promise((resolve, reject) => {
	//     api.departments
	//     .put(params)
	//     .then((response) => {
	//       const { items } = response.data;
	//       commit('updateItems', { items });
	//       resolve(response);
	//     })
	//     .catch((error) => {
	//       reject(error);
	//     });
	//   });
	// },
	// post: ({ commit }, params) => {
	//   return new Promise((resolve, reject) => {
	//     api.departments
	//     .post(params)
	//     .then((response) => {
	//       const { items } = response.data;
	//       commit('addItems', { items });
	//       resolve(response);
	//     })
	//     .catch((error) => {
	//       reject(error);
	//     });
	//   });
	// },
};

const mutations = {
	addItem: (state, { item }) => {
		const now = new Date();
		state.items = [...state.items, item];
		state.updated = now.getTime();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
