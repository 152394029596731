<template>
	<div style="max-width: 980px; padding-top: 1rem">
		<v-container>
			<v-row
				v-for="(formRuleset, index) in formRulesets"
				:key="`formRuleset_${index}`"
			>
				<v-col>
					<fieldset class="talentics-fieldset">
						<legend>
							{{ $t('label.rule-no', { index: index + 1 }) }}
						</legend>
						<v-container>
							<v-row>
								<v-col>
									<StagePicker
										:label="$t('label.prev-status')"
										v-model="formRuleset.prev_status"
										rules="required"
										disabled
									></StagePicker>
								</v-col>
								<v-col>
									<StagePicker
										:label="$t('label.next-status')"
										v-model="formRuleset.next_status"
										rules="required"
										disabled
									></StagePicker>
								</v-col>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											:label="$t('label.form-template')"
											v-model="formRuleset.form_id"
											:items="
												formTemplates.map(item => ({
													value: item.id,
													text: item.label,
												}))
											"
											hide-details
											outlined
											dense
											:error-messages="errors"
											disabled
										></v-select>
									</ValidationProvider>
								</v-col>
								<v-col cols="auto">
									<v-btn
										color="secondary"
										@click="
											handleDeleteItem({
												id: formRuleset.id,
											})
										"
									>
										{{ $t('button.delete') }}
									</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</fieldset>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<fieldset class="talentics-fieldset">
						<legend>{{ $t('label.add-new-rule') }}</legend>
						<ValidationObserver
							ref="form_rulesets_form"
							tag="form"
							@submit="handleSubmit"
							id="form_rulesets_form"
						>
							<v-container>
								<v-row>
									<v-col>
										<StagePicker
											:label="$t('label.prev-status')"
											v-model="form.prev_status"
											rules="required"
										></StagePicker>
									</v-col>
									<v-col>
										<StagePicker
											:label="$t('label.next-status')"
											v-model="form.next_status"
											rules="required"
										></StagePicker>
									</v-col>
									<v-col>
										<ValidationProvider rules="required" v-slot="{ errors }">
											<v-select
												:label="$t('label.form-template')"
												v-model="form.form_id"
												:items="
													formTemplates.map(item => ({
														value: item.id,
														text: item.label,
													}))
												"
												hide-details
												outlined
												dense
												:error-messages="errors"
											></v-select>
										</ValidationProvider>
									</v-col>
									<v-col cols="auto">
										<v-btn color="secondary" @click="handleSubmit">
											{{ $t('button.add') }}
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</ValidationObserver>
					</fieldset>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import StagePicker from '@/components/shared/stagePicker/StagePicker';

export default {
	name: 'FormRulesets',
	computed: {
		...mapState({
			formTemplates: state => state.formTemplates.items,
			formRulesets: state => state.formRulesets.items,
		}),
	},
	components: {
		StagePicker,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			form: {
				prev_status: null,
				next_status: null,
				form_id: null,
			},
		};
	},
	methods: {
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.form_rulesets_form.validate();

			if (isValid) {
				const { form } = this;

				this.$store
					.dispatch('formRulesets/post', {
						items: [{ ...form }],
					})
					.then(() => {
						this.$store.dispatch('formRulesets/get', {
							updateStore: true,
							refresh: true,
						});
					});
			}
		},
		handleDeleteItem({ id }) {
			this.$store
				.dispatch('formRulesets/put', {
					items: [{ is_deleted: true, id }],
				})
				.then(() => {
					this.$store.dispatch('formRulesets/get', {
						updateStore: true,
						refresh: true,
					});
				});
		},
	},
	created() {
		this.$store.dispatch('formTemplates/get', { updateStore: true });
		this.$store.dispatch('formRulesets/get', { updateStore: true });
	},
};
</script>
