var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","value":_vm.isVisible,"max-width":"680","persistent":""},on:{"click:outside":_vm.handleClickOutside,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleClickOutside.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":`${_vm.isDark ? '' : 'grey lighten-3'}`,"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.candidate.name)+" ")]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"padding":"1rem"}},[_c('ValidationObserver',{ref:"form_new_form",attrs:{"tag":"form","id":"form_new_form"},on:{"submit":_vm.handleSubmit}},[_c('div',{staticStyle:{"margin-bottom":"1rem"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.applicationsWithJobNames,"item-text":item =>
										`${item.job_name} (${_vm.handleFormatDate({
											timestamp: item.created,
										})})`,"item-value":"id","label":_vm.$t('label.choose-job'),"outlined":"","hide-details":"","dense":"","error-messages":errors},on:{"change":newVal =>
										_vm.handleApplicationIdChange({
											applicationId: newVal,
										})},model:{value:(_vm.form.application_id),callback:function ($$v) {_vm.$set(_vm.form, "application_id", $$v)},expression:"form.application_id"}})]}}])})],1),_c('div',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.statuses,"item-text":item => {
										if (item.text) {
											return _vm.$t(`pipeline.steps.${item.text}`);
										}
										return item;
									},"item-value":"value","label":_vm.$t('label.status'),"outlined":"","hide-details":"","dense":"","error-messages":errors},model:{value:(_vm.form.pipeline_status),callback:function ($$v) {_vm.$set(_vm.form, "pipeline_status", $$v)},expression:"form.pipeline_status"}})]}}])})],1),_c('div',{staticStyle:{"margin":"1rem 0"}},[_c('v-divider')],1),_c('DynamicForm',{attrs:{"jsonSchema":_vm.form.json_schema,"contextSchema":_vm.form.context_schema,"onChange":_vm.handleDynamicFormChange}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":() => _vm.onClose({ needsRefresh: false })}},[_vm._v(_vm._s(_vm.$t('button.close')))]),(_vm.form.status === 'draft')?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.handleSaveAsDraft}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("note_alt")]),_vm._v(" "+_vm._s(_vm.$t('button.save-as-draft'))+" ")],1):_vm._e(),(_vm.form.status === 'draft')?_c('v-btn',{attrs:{"color":"primary"},on:{"click":evt => _vm.handleSubmit(evt, true)}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("task")]),_vm._v(" "+_vm._s(_vm.$t('button.complete-and-share'))+" ")],1):_vm._e(),(_vm.form.status === 'completed')?_c('v-btn',{attrs:{"color":"primary"},on:{"click":evt => _vm.handleSubmit(evt, false)}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("task")]),_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }