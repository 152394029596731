<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex style="position: relative">
				<v-navigation-drawer
					:value="drawer"
					:clipped="$vuetify.breakpoint.lgAndUp"
					app
					:color="`${isDark ? '#121212' : 'white'}`"
				>
					<v-list dense>
						<v-list-item
							v-for="(item, index) in items.filter(
								item => userRole >= item.minRole,
							)"
							v-bind:key="index"
							:to="`/${currentLang}/${item.route}`"
						>
							<v-list-item-content>
								<v-list-item-title>{{
									$t(`sidenav-menu.${item.name}`)
								}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-navigation-drawer>

				<router-view></router-view>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			drawer: state => state.currentRoute.drawer,
			isDark: state => state.currentUser.isDark,
			userRole: state => state.currentUser.userRole,
		}),
	},
	data() {
		return {
			items: [
				{
					name: 'at-a-glance',
					route: 'reports/at-a-glance',
					minRole: 333,
				},
				{
					name: 'export',
					route: 'reports/export',
					minRole: 666,
				},
				{
					name: 'daily-snapshots',
					route: 'reports/daily-snapshots',
					minRole: 666,
				},
			],
		};
	},
	created() {
		this.$store.commit('currentRoute/setRouteFirstLevel', {
			routeName: 'reports',
		});
		this.$store.dispatch('organizations/get', { updateStore: true });
		this.$store.dispatch('departments/get', { updateStore: true });
		this.$store.dispatch('teams/get', { updateStore: true });
		this.$store.dispatch('users/get', { updateStore: true });
		this.$store.dispatch('jobs/get', { updateStore: true });
	},
};
</script>
