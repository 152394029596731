<template>
	<v-badge avatar overlap bordered :offset-x="12" :offset-y="36">
		<template v-slot:badge>
			<v-avatar :color="getScoreColor(score)">
				<v-icon v-if="score === 3 || score === 4" dark size="14">
					thumb_up
				</v-icon>
				<v-icon v-else dark> thumb_down </v-icon>
			</v-avatar>
		</template>
		<Avatar
			:userId="userId"
			:tooltip-prepend="`: ${$t(`text.${getScoreText(score)}`)}`"
		></Avatar>
	</v-badge>
</template>

<script>
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'CandidateFeedbackIcon',
	props: {
		score: {
			type: [String, Number],
			required: true,
		},
		userId: {
			type: [String, Number],
			required: true,
		},
	},
	components: {
		Avatar,
	},
	methods: {
		getScoreColor(score) {
			if (score === 1) {
				return '#b71c1c';
			} else if (score === 2) {
				return '#f44336';
			} else if (score === 3) {
				return '#66BB6A';
			}
			return '#2E7D32';
		},
		getScoreText(score) {
			if (score === 1) {
				return 'strong-no';
			} else if (score === 2) {
				return 'no';
			} else if (score === 3) {
				return 'yes';
			}
			return 'strong-yes';
		},
	},
};
</script>
