<template>
	<v-dialog
		:value="true"
		@click:outside="handleClickOutside"
		@keydown.esc="handleClickOutside"
		persistent
		scrollable
		transition="dialog-bottom-transition"
		max-width="600"
	>
		<v-card elevation="0">
			<v-card-title class="pa-0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-toolbar-title>{{ reportTemplate.label }}</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text style="padding: 0">
				<ValidationObserver
					ref="exportable_reports_form"
					tag="form"
					@submit="handleSubmit"
					id="exportable_reports_form"
				>
					<v-container>
						<v-row v-if="reportTemplate.is_date_picker_visible">
							<v-col>
								<DatePicker
									v-model="form.datePickerDate"
									no-title
									reactive
									dense
									range
									:label="$t('label.date-range')"
									:disabled="isGenerated"
								></DatePicker>
							</v-col>
						</v-row>
						<v-row v-if="reportTemplate.is_job_picker_visible">
							<v-col>
								<JobPicker
									v-model="form.job_id"
									:jobs="jobs"
									:label="`${$t('label.jobs')} ${
										reportTemplate.predefined_type ===
										'position-based-applications'
											? '*'
											: ''
									}`"
									hide-inactive-teams
									hide-inactive-departments
									hide-draft-jobs
									hide-pending-jobs
									dense
									:disabled="isGenerated"
									:rules="
										reportTemplate.predefined_type ===
										'position-based-applications'
											? 'required'
											: ''
									"
								></JobPicker>
							</v-col>
						</v-row>
						<v-row v-if="reportTemplate.is_owner_picker_visible">
							<v-col>
								<UserPicker
									v-model="form.hiring_manager"
									:users="users.filter(item => item.active)"
									:label="$t('label.hiring-manager')"
									multiple
									dense
									:disabled="isGenerated"
								></UserPicker>
							</v-col>
						</v-row>
						<v-row v-if="reportTemplate.is_interviewer_picker_visible">
							<v-col>
								<UserPicker
									v-model="form.interviewer"
									:users="users.filter(item => item.active)"
									:label="$t('label.interviewer')"
									multiple
									dense
									:disabled="isGenerated"
								></UserPicker>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
			</v-card-text>
			<v-divider></v-divider>

			<v-card-actions v-if="isGenerated">
				<v-spacer></v-spacer>
				<v-btn text @click="handleClose">
					{{ $t('button.close') }}
				</v-btn>
				<v-btn color="primary" :href="generatedUrl" _target="blank">
					<v-icon left>download</v-icon>
					{{ $t('button.download-xlsx') }}
				</v-btn>
			</v-card-actions>

			<v-card-actions v-else>
				<v-spacer></v-spacer>
				<v-btn text @click="handleClose">
					{{ $t('button.close') }}
				</v-btn>
				<v-btn color="primary" @click="handleSubmit">
					{{ $t('button.export') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import { ValidationObserver } from 'vee-validate';
import api from '@/api';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import JobPicker from '@/components/shared/jobPicker/JobPicker';
import UserPicker from '@/components/shared/userPicker/UserPicker';

export default {
	name: 'ExportReportDialog',
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			jobs: state => state.jobs.items,
			users: state => state.users.items,
			currentLang: state => state.currentUser.lang,
		}),
	},
	components: {
		ValidationObserver,
		DatePicker,
		JobPicker,
		UserPicker,
	},
	data() {
		const end_date = new Date();
		end_date.setDate(end_date.getDate() - 1);
		const start_date = new Date(end_date);
		start_date.setDate(start_date.getDate() - 30);
		const max_date_formatted = format(end_date, 'yyyy-MM-dd');
		const min_date_formatted = format(start_date, 'yyyy-MM-dd');
		const datePickerDate = [min_date_formatted, max_date_formatted];

		return {
			format,
			reportTemplate: {
				label: '',
				is_date_picker_visible: false,
				is_job_picker_visible: false,
				is_owner_picker_visible: false,
				is_interviewer_picker_visible: false,
				predefined_type: null,
			},
			form: {
				datePickerDate,
				job_id: null,
				hiring_manager: null,
				interviewer: null,
			},
			isGenerated: false,
			generatedUrl: null,
		};
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleClose() {
			this.$router.push({
				path: `/${this.currentLang}/reports/export`,
				query: {},
			});
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.exportable_reports_form.validate();

			if (isValid) {
				const { datePickerDate, job_id, hiring_manager } = this.form;
				const [min_date, max_date] = datePickerDate;
				const { predefined_type } = this.reportTemplate;

				api.exportableReports
					.post({ min_date, max_date, job_id, hiring_manager, predefined_type })
					.then(response => {
						const { url } = response.data;
						this.isGenerated = true;
						this.generatedUrl = url;
					});
			}
		},
	},
	mounted() {
		const { uuid } = this.$route.params;
		api.reportTemplates.get({ uuid }).then(response => {
			const { items } = response.data;
			if (items.length > 0) {
				const {
					label,
					is_date_picker_visible,
					is_job_picker_visible,
					is_owner_picker_visible,
					is_interviewer_picker_visible,
					predefined_type,
				} = items[0];

				this.reportTemplate = {
					...this.reportTemplate,
					label,
					is_date_picker_visible,
					is_job_picker_visible,
					is_owner_picker_visible,
					is_interviewer_picker_visible,
					predefined_type,
				};
			}
		});
	},
};
</script>
