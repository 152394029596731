<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex>
				<v-card tile elevation="0">
					<div style="padding: 0.5rem">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									icon
									:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
									@click="handleRefresh"
								>
									<v-icon>refresh</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.refresh') }}
						</v-tooltip>
					</div>
					<v-divider></v-divider>
					<div>
						<v-tabs v-model="tab" slider-size="3" slider-color="primary">
							<v-tab v-if="userRole >= 666">
								{{ $t('tab.application-form') }}
							</v-tab>
							<v-tab v-if="userRole >= 666">
								{{ $t('tab.confirmation-email') }}
							</v-tab>
							<v-tab v-if="userRole >= 666">
								{{ $t('tab.logos-style') }}
							</v-tab>
							<v-tab
								v-if="userRole >= 666 || currentUser.can_edit_career_pages"
							>
								{{ $t('tab.custom-blocks') }}
							</v-tab>
						</v-tabs>
					</div>
					<v-divider></v-divider>
					<v-tabs-items v-model="tab">
						<v-tab-item v-if="userRole >= 666">
							<ApplicationForm></ApplicationForm>
						</v-tab-item>
						<v-tab-item v-if="userRole >= 666">
							<ConfirmationEmail></ConfirmationEmail>
						</v-tab-item>
						<v-tab-item v-if="userRole >= 666">
							<Styling></Styling>
						</v-tab-item>
						<v-tab-item
							v-if="userRole >= 666 || currentUser.can_edit_career_pages"
						>
							<CustomBlocks></CustomBlocks>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
				<router-view></router-view>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import Styling from './styling/Styling';
import ApplicationForm from './applicationForm/ApplicationForm';
import ConfirmationEmail from './confirmationEmail/ConfirmationEmail';
import CustomBlocks from './customBlocks/CustomBlocks';

export default {
	name: 'JobSite',
	components: {
		Styling,
		ApplicationForm,
		ConfirmationEmail,
		CustomBlocks,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
		}),
	},
	data() {
		return {
			tab: 0,
		};
	},
	created() {
		this.$store.dispatch('company/get', {
			refresh: true,
			updateStore: true,
		});
	},
	methods: {
		handleRefresh() {
			this.$store.dispatch('company/get', {
				refresh: true,
				updateStore: true,
			});
		},
	},
};
</script>
