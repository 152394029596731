var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","value":true,"max-width":"1000px"},on:{"click:outside":_vm.handleClose,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleClose.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":`${_vm.isDark ? '' : 'grey lighten-3'}`,"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('text.merge-candidates')))]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',[_c('v-list',{attrs:{"tile":"","dense":""}},[_c('v-list-item-group',[_vm._l((_vm.candidatesToMerge),function(item,index){return [_c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(`${item.text} (id: ${item.value})`)}})],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":() =>
																_vm.handleRemoveCandidate({
																	index,
																})}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('v-divider',{key:`divider_${index}`})]})],2),_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticStyle:{"margin-top":"0.25rem"}},[_c('Autocomplete',{attrs:{"label":_vm.$t('label.search-candidates'),"filled":true,"dense":true,"flat":true,"solo":true,"solo-inverted":false,"hide-details":true,"items":_vm.autocompleteItems,"onKeyUp":_vm.handleAutocompleteInput,"prepend-inner-icon":"search","outlined":true},on:{"change":_vm.handleAutocompleteChange}})],1)])],1)],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2","align-self":"center"}},[_c('p',[_vm._v(_vm._s(_vm.$t('text.merge-into')))]),_c('v-icon',{attrs:{"size":"32"}},[_vm._v("forward")])],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',[_c('v-list',{attrs:{"tile":"","dense":""}},[_c('v-list-item-group',[_vm._l((_vm.candidatesToMergeInto),function(item,index){return [_c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(`${item.text} (id: ${item.value})`)}})],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":() =>
																_vm.handleMergeIntoRemoveCandidate({
																	index,
																})}},[_c('v-icon',[_vm._v("close")])],1)],1)],1),_c('v-divider',{key:`divider_${index}`})]})],2),_c('v-list-item',[_c('v-list-item-content',[_c('div',{staticStyle:{"margin-top":"0.25rem"}},[_c('Autocomplete',{attrs:{"label":_vm.$t('label.search-candidates'),"filled":true,"dense":true,"flat":true,"solo":true,"solo-inverted":false,"hide-details":true,"items":_vm.autocompleteMergeIntoItems,"onKeyUp":_vm.handleMergeIntoAutocompleteInput,"prepend-inner-icon":"search","outlined":true},on:{"change":_vm.handleMergeIntoAutocompleteChange}})],1)])],1)],1)],1)],1)],1),(_vm.submitEnabled)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"border":"left","colored-border":"","type":"warning","elevation":"2"}},[_c('div',{domProps:{"innerHTML":_vm._s(
										_vm.$t('warnings.merge-candidates', {
											candidates: _vm.candidatesToMerge
												.map(item => `${item.text} (${item.value})`)
												.join(', '),
											candidateMergeInto: _vm.candidatesToMergeInto
												.map(item => `${item.text} (${item.value})`)
												.join(', '),
										})
									)}})])],1)],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleClose}},[_vm._v(_vm._s(_vm.$t('button.close')))]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.submitEnabled},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('button.merge-candidates')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }