<template>
	<div>
		<v-btn color="primary" small @click="newAssessmentDialogVisible = true">
			<v-icon>add</v-icon>
			{{ $t('button.new_assessment') }}
		</v-btn>
		<div v-if="assessments.length > 0" style="margin-top: 1rem">
			<v-divider></v-divider>
			<div v-for="(assessment, index) in assessments" :key="index">
				<div>
					<v-container>
						<v-row>
							<v-col cols="2">
								{{ $t('label.status') }}
							</v-col>
							<v-col cols="7">
								<v-chip
									small
									:ripple="false"
									:color="assessment.is_completed ? 'success' : 'danger'"
									variant="outlined"
								>
									{{
										assessment.is_completed
											? $t('label.completed')
											: $t('label.not_completed')
									}}
								</v-chip>
							</v-col>
							<v-col cols="3" style="justify-content: flex-end">
								<div class="d-flex" style="justify-content: flex-end">
									<ScheduleEmailMenu
										v-if="
											!assessment.notification_emails ||
											(assessment.notification_emails &&
												assessment.notification_emails.length === 0)
										"
										:candidate="candidate"
										:onStart="handleScheduleNewEmail"
										buttonLabel="button.new-email"
										:assessment="assessment"
									/>
									<div v-else>
										<label class="mr-1"
											><strong>{{ $t('label.email_send_at') }}</strong></label
										>
										<span>{{
											formatDate({
												timestamp: assessment.notification_emails[0].send_at,
											})
										}}</span>
									</div>
								</div>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.assessment_url') }}
							</v-col>
							<v-col cols="10">
								<a :href="assessment.spica_link_url" target="_blank">
									{{ assessment.spica_link_url }}
								</a>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.job_name') }}
							</v-col>
							<v-col cols="10">
								{{ assessment.job_name }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.assessment-product') }}
							</v-col>
							<v-col cols="10">
								{{ assessment.spica_product_name }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.kolayik.first-name') }}
							</v-col>
							<v-col cols="10">
								{{ assessment.first_name }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.kolayik.last-name') }}
							</v-col>
							<v-col cols="10">
								{{ assessment.last_name }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.kolayik.email') }}
							</v-col>
							<v-col cols="10">
								{{ assessment.email }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.language') }}
							</v-col>
							<v-col cols="10">
								{{ assessment.language }}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.created_at') }}
							</v-col>
							<v-col cols="10">
								{{
									formatDate({
										timestamp: assessment.created_at,
									})
								}}
							</v-col>
						</v-row>
						<v-row v-if="assessment.is_completed">
							<v-col cols="2">
								{{ $t('label.completed_at') }}
							</v-col>
							<v-col cols="10">
								{{
									formatDate({
										timestamp: assessment.completed_at,
									})
								}}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.application_received_at') }}
							</v-col>
							<v-col cols="10">
								{{
									formatDate({
										timestamp: assessment.application_received_at,
									})
								}}
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="2">
								{{ $t('label.is_candidate_notified') }}
							</v-col>
							<v-col cols="10">
								{{
									assessment.notification_emails &&
									assessment.notification_emails.length > 0 &&
									assessment.notification_emails[0].is_sent
										? $t('label.yes')
										: $t('label.no')
								}}
							</v-col>
						</v-row>
						<v-row v-if="assessment.is_completed">
							<v-col cols="12">
								<div class="button-wrapper">
									<div v-if="hasMultipleReports[assessment.spica_product_id]">
										<v-btn
											v-for="report in hasMultipleReports[
												assessment.spica_product_id
											]"
											:key="report.id + report.reportName"
											small
											class="my-2 ml-auto mr-2"
											@click="
												() =>
													handleDownloadPDFClick(assessment, report.moduleId)
											"
										>
											{{ report.reportName }}
										</v-btn>
									</div>
									<v-btn
										small
										class="my-2 mr-2"
										@click="() => handleDownloadPDFClick(assessment)"
										>{{ $t('button.download-pdf') }}</v-btn
									>
									<v-btn
										small
										class="my-2"
										@click="() => handleSeeResultsClick(assessment)"
										>{{ $t('button.see-results') }}</v-btn
									>
								</div>
							</v-col>
						</v-row>
					</v-container>
				</div>
				<v-divider></v-divider>
			</div>
		</div>
		<div v-else style="margin-top: 1rem">
			{{ $t('err.no_assessments_found') }}
		</div>
		<NewAssessment
			v-if="newAssessmentDialogVisible"
			:dialog="newAssessmentDialogVisible"
			:onClose="handleNewAssessmentModalClose"
			:onSubmit="onGenerateNewAssessment"
			:candidate="candidate"
			:assessment="selectedAssessment"
		/>
		<ScheduleEmailDialog
			v-if="scheduleEmailDialogVisible"
			:email="email"
			:candidate="candidate"
			:onClose="handleScheduleEmailDialogClose"
			:assessment="selectedAssessment"
			:onScheduleEmailComplete="onScheduleCandidate"
		/>
		<AssessmentResults
			v-if="resultsModalVisible"
			:dialog="resultsModalVisible"
			:onClose="() => (resultsModalVisible = false)"
			:assessment="selectedAssessment"
		/>
		<AssessmentReport
			v-if="reportModalVisible"
			:dialog="reportModalVisible"
			:onClose="() => (reportModalVisible = false)"
			:assessment="selectedAssessment"
			:moduleId="selectedModuleId"
		/>
	</div>
</template>

<script>
import { format } from 'date-fns';
import NewAssessment from './NewAssessment';
import AssessmentResults from './AssessmentResults';
import AssessmentReport from './AssessmentReport';
import ScheduleEmailMenu from '../emails/scheduleEmailMenu/ScheduleEmailMenu';
import ScheduleEmailDialog from '../emails/scheduleEmailDialog/ScheduleEmailDialog';

export default {
	name: 'Assessments',
	components: {
		AssessmentReport,
		AssessmentResults,
		NewAssessment,
		ScheduleEmailMenu,
		ScheduleEmailDialog,
	},
	props: {
		candidate: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			format,
			createdSpicaURL: null,
			assessments: [],
			newAssessmentDialogVisible: false,
			scheduleEmailDialogVisible: false,
			reportModalVisible: false,
			email: null,
			selectedAssessment: null,
			selectedModuleId: null,
			resultsModalVisible: false,
			hasMultipleReports: {},
		};
	},
	computed: {
		assessmentsData: function () {
			return this.$store.state.assessments.items;
		},
		spicaProducts: function () {
			return this.$store.state.company.company.spica_products;
		},
	},
	methods: {
		handleDownloadPDFClick(assessment, moduleId) {
			this.reportModalVisible = true;
			this.selectedAssessment = assessment;

			this.selectedModuleId = moduleId ? moduleId : null;
		},
		handleSeeResultsClick(assessment) {
			this.resultsModalVisible = true;
			this.selectedAssessment = assessment;
		},
		handleScheduleEmailDialogClose() {
			this.scheduleEmailDialogVisible = false;
		},
		onScheduleCandidate() {
			this.scheduleEmailDialogVisible = false;

			this.getCandidateAssessments();
		},
		handleScheduleNewEmail({ email }, assessment) {
			this.scheduleEmailDialogVisible = true;
			this.email = email;
			this.selectedAssessment = assessment;
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		getCandidateAssessments() {
			const { candidateId } = this.$route.params;

			this.$store
				.dispatch('assessments/get', {
					candidate_id: candidateId,
					refresh: true,
					updateStore: true,
				})
				.then(response => {
					this.assessments = response.data.data;
				});
		},
		handleNewAssessmentModalClose() {
			this.newAssessmentDialogVisible = false;
		},
		onGenerateNewAssessment(newAssessment, isEmailSchedule) {
			const { candidateId } = this.$route.params;

			this.$store
				.dispatch('assessments/post', {
					candidate_id: candidateId,
					application_id: newAssessment.application_id,
					product_id: newAssessment.product,
					first_name: newAssessment.name,
					last_name: newAssessment.surname,
					email: newAssessment.email,
					language: newAssessment.language,
				})
				.then(res => {
					this.createdSpicaURL = res.data.url;
					this.getCandidateAssessments();

					if (!isEmailSchedule) {
						this.newAssessmentDialogVisible = false;
					}
				});
		},
	},
	watch: {
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.getCandidateAssessments();
				}
			},
			deep: true,
			immediate: true,
		},
		assessments: {
			handler: function (newVal, oldVal) {
				if (
					JSON.stringify(newVal) !== JSON.stringify(oldVal) &&
					this.createdSpicaURL
				) {
					this.assessments.find(assessment => {
						if (assessment.spica_link_url === this.createdSpicaURL) {
							this.selectedAssessment = assessment;
						}
					});
				}
			},
			deep: true,
			immediate: true,
		},
		assessmentsData: {
			handler: function (newVal) {
				this.assessments = newVal;
			},
			deep: true,
			immediate: true,
		},
		spicaProducts: {
			handler: function (newVal) {
				newVal?.forEach(product => {
					if (product.pdfRaporModul?.length) {
						product.pdfRaporModul.forEach(reportModule => {
							if (this.hasMultipleReports[product.productID] === undefined) {
								this.hasMultipleReports = {
									...this.hasMultipleReports,
									[product.productID]: [
										{
											id: product.productID,
											reportName: reportModule.name,
											moduleId: reportModule.id,
										},
									],
								};
							} else if (
								this.hasMultipleReports[product.productID].length === 1
							) {
								this.hasMultipleReports = {
									...this.hasMultipleReports,
									[product.productID]: [
										...this.hasMultipleReports[product.productID],
										{
											id: product.productID,
											reportName: reportModule.name,
											moduleId: reportModule.id,
										},
									],
								};
							}
						});
					}
				});
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style>
.button-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
</style>
