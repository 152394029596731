<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		offset-y
		:nudge-width="250"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" small>
				<v-icon left>{{ buttonIcon }}</v-icon>
				{{ $t(buttonLabel) }}
			</v-btn>
		</template>

		<v-card>
			<ValidationObserver
				ref="form_schedule_email"
				tag="form"
				@submit="handleStart"
				id="form_schedule_email"
			>
				<v-container fluid>
					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="email.id"
									:items="emailTemplates.filter(item => item.active)"
									:item-text="item => item.label + ` (${item.sent_from})`"
									item-value="id"
									:label="$t('label.template')"
									outlined
									hide-details
									dense
									@change="id => handleEmailTemplateChange({ id })"
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>
				</v-container>
			</ValidationObserver>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="menu = false">
					{{ $t('button.cancel') }}
				</v-btn>
				<v-btn color="primary" @click="handleStart">
					{{ $t('button.start') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: 'ScheduleEmailMenu',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		assessment: {
			type: Object,
			required: false,
		},
		onStart: {
			type: Function,
			required: true,
		},
		buttonIcon: {
			type: String,
			required: false,
			default() {
				return 'add';
			},
		},
		buttonLabel: {
			type: String,
			required: false,
			default() {
				return 'button.schedule-new-email';
			},
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	computed: {
		...mapState({
			emailTemplates: state => state.emailTemplates.items,
		}),
	},
	data() {
		return {
			email: {
				id: null,
				label: null,
				subject: null,
				template: null,
				sent_from: null,
				send_at: null,
				send_time: null,
				cc: [],
				bcc: [],
				email_template_id: null,
			},
			menu: false,
		};
	},
	methods: {
		initForm() {
			const activeEmailTemplates = this.emailTemplates.filter(
				item => item.active,
			);
			if (activeEmailTemplates.length > 0) {
				const { id } = activeEmailTemplates[0];
				this.handleEmailTemplateChange({ id });
			}
		},
		handleEmailTemplateChange({ id }) {
			this.$store
				.dispatch('emailTemplates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					const { candidate, applicationId } = this;
					if (items.length > 0) {
						this.email = {
							...items[0],
							candidate_id: candidate.id,
							application_id: applicationId,
							send_to: candidate.email[0],
							is_sent: false,
							is_cancelled: false,
							send_time: items[0].default_schedule,
							email_template_id: id,
						};
					}
				});
		},
		async handleStart(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_schedule_email.validate();
			if (isValid) {
				const { email } = this;
				this.onStart({ email }, this.assessment);
				this.menu = false;
			}
		},
	},
	watch: {
		menu: {
			handler: function (newVal) {
				if (newVal) {
					this.$store
						.dispatch('emailTemplates/get', {
							updateStore: true,
							refresh: true,
						})
						.then(() => {
							this.initForm();
						});
				}
			},
		},
	},
};
</script>
