var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":`${_vm.isDark ? '' : 'grey lighten-3'}`,"flat":"","dense":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("event")]),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.formatDate({ timestamp: _vm.eventEditable.start, format: 'yyyy-MM-dd HH:mm', }))+"-"+_vm._s(_vm.formatDate({ timestamp: _vm.eventEditable.end, format: 'HH:mm', }))+" ")]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('fieldset',{class:`talentics-fieldset talentics-fieldset-paddings ${
							_vm.isPreviewMode ? 'talentics-fieldset-disabled' : ''
						}`},[_c('legend',[_vm._v(_vm._s(_vm.$t('label.organizer')))]),_c('UserChips',{attrs:{"userIds":[_vm.currentUser.id]}})],1)])],1),_c('v-row',[_c('v-col',[_c('fieldset',{class:`talentics-fieldset talentics-fieldset-paddings ${
							_vm.isPreviewMode ? 'talentics-fieldset-disabled' : ''
						}`},[_c('legend',[_vm._v(_vm._s(_vm.$t('label.interviewers')))]),_c('UserChips',{attrs:{"userIds":_vm.eventEditable.interviewers}})],1)])],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.emailTemplates.filter(item => item.active),"item-text":item => item.label,"item-value":"id","label":_vm.$t('label.template-optional'),"outlined":"","hide-details":"","dense":"","disabled":_vm.isPreviewMode},on:{"change":id => _vm.handleEmailTemplateChange({ id })},model:{value:(_vm.emailTemplateId),callback:function ($$v) {_vm.emailTemplateId=$$v},expression:"emailTemplateId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":`${_vm.$t('label.event-name')}`,"outlined":"","hide-details":"","dense":"","disabled":_vm.isPreviewMode},model:{value:(_vm.eventEditable.name),callback:function ($$v) {_vm.$set(_vm.eventEditable, "name", $$v)},expression:"eventEditable.name"}})],1)],1),(_vm.isPreviewMode)?_c('v-row',[_c('v-col',[_c('fieldset',{class:`talentics-fieldset talentics-fieldset-paddings ${
							_vm.isPreviewMode ? 'talentics-fieldset-disabled' : ''
						}`},[_c('legend',[_vm._v(" "+_vm._s(_vm.$t('label.description-optional'))+" ")]),(_vm.eventEditable.description)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.eventEditable.description)}}):_c('div',[_vm._v("-")])])])],1):_c('v-row',[_c('v-col',[_c('fieldset',{class:`talentics-fieldset talentics-fieldset-paddings ${
							_vm.isPreviewMode ? 'talentics-fieldset-disabled' : ''
						}`},[_c('legend',[_vm._v(" "+_vm._s(_vm.$t('label.description-optional'))+" ")]),_c('RichEditor',{attrs:{"refName":"closing"},model:{value:(_vm.eventEditable.descriptionEditable),callback:function ($$v) {_vm.$set(_vm.eventEditable, "descriptionEditable", $$v)},expression:"eventEditable.descriptionEditable"}})],1)])],1),(_vm.provider === 'microsoft.com')?_c('v-row',[_c('v-col',[_c('Attachments',{attrs:{"attachments":_vm.eventEditable.attachment_files,"onNewAttachmentAdded":_vm.handleNewAttachmentAdded,"onAttachmentDeleteClick":_vm.handleAttachmentDeleteClick,"readOnly":_vm.isPreviewMode}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-checkbox',{staticStyle:{"margin-top":"0","padding-top":"0"},attrs:{"label":_vm.provider === 'google.com'
								? `${_vm.$t('label.add-google-meet-video-conferencing')}`
								: `${_vm.$t('label.add-microsoft-teams-video-conferencing')}`,"dense":"","hide-details":"","false-value":false,"true-value":true,"disabled":_vm.isPreviewMode},model:{value:(_vm.eventEditable.googleMeet),callback:function ($$v) {_vm.$set(_vm.eventEditable, "googleMeet", $$v)},expression:"eventEditable.googleMeet"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{staticStyle:{"margin-top":"0","padding-top":"0"},attrs:{"label":`${_vm.$t('label.notify-candidate')}`,"dense":"","hide-details":"","false-value":false,"true-value":true,"disabled":_vm.isPreviewMode},model:{value:(_vm.eventEditable.notifyCandidate),callback:function ($$v) {_vm.$set(_vm.eventEditable, "notifyCandidate", $$v)},expression:"eventEditable.notifyCandidate"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{staticStyle:{"margin-top":"0","padding-top":"0"},attrs:{"label":`${_vm.$t('label.private-event')}`,"dense":"","hide-details":"","false-value":"default","true-value":"private","disabled":_vm.isPreviewMode},model:{value:(_vm.eventEditable.visibility),callback:function ($$v) {_vm.$set(_vm.eventEditable, "visibility", $$v)},expression:"eventEditable.visibility"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }