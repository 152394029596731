<template>
	<div>
		<NewFeedbackMenu
			:candidate="candidate"
			:onStart="handleAddNewFeedback"
		></NewFeedbackMenu>

		<ShareFeedbacksMenu
			:feedbacks="feedbacks.filter(({ status }) => status === 'completed')"
			:candidateName="candidate.name"
			:candidateId="candidate.id"
		></ShareFeedbacksMenu>

		<div v-if="drafts.length > 0" style="margin-top: 1rem">
			<v-divider></v-divider>

			<v-subheader>{{ $t('subheader.your-drafts') }}</v-subheader>

			<v-divider></v-divider>

			<div v-for="(feedback, index) in drafts" :key="`draft_${index}`">
				<v-container>
					<v-row>
						<v-col
							cols="auto"
							align-self="center"
							@click="() => handleEditFeedback({ id: feedback.id })"
							style="cursor: pointer"
						>
							<Avatar :userId="feedback.created_by"></Avatar>
						</v-col>
						<v-col
							@click="() => handleEditFeedback({ id: feedback.id })"
							align-self="center"
							style="cursor: pointer"
						>
							<h3 v-if="feedback.pipeline_status || feedback.job_id">
								<span v-if="feedback.pipeline_status"
									>{{ $t(`pipeline.steps.${feedback.pipeline_status}`) }}
									-
								</span>
								<span v-if="feedback.job_id">{{
									handleGetJobName({ jobId: feedback.job_id })
								}}</span>
							</h3>
							<div>
								<strong
									v-if="feedback.overall_score === 4"
									style="color: #2e7d32"
									><v-icon style="color: #2e7d32">thumb_up</v-icon>
									{{ $t('text.strong-yes') }}</strong
								>
								<strong
									v-else-if="feedback.overall_score === 3"
									style="color: #66bb6a"
									><v-icon style="color: #66bb6a">thumb_up</v-icon>
									{{ $t('text.yes') }}</strong
								>
								<strong
									v-else-if="feedback.overall_score === 2"
									style="color: #f44336"
									><v-icon style="color: #f44336">thumb_down</v-icon>
									{{ $t('text.no') }}</strong
								>
								<strong
									v-else-if="feedback.overall_score === 1"
									style="color: #b71c1c"
									><v-icon style="color: #b71c1c">thumb_down</v-icon>
									{{ $t('text.strong-no') }}</strong
								>
							</div>
							<div v-html="formatDate({ timestamp: feedback.created })"></div>
						</v-col>
						<v-col align-self="center" cols="auto">
							<v-btn
								v-if="feedback.created_by === currentUser.id"
								@click="() => handleEditFeedback({ id: feedback.id })"
								style="margin-top: 0.5rem"
								block
							>
								<v-icon left>edit</v-icon>
								{{ $t('button.edit-feedback') }}
							</v-btn>
							<v-btn
								v-if="feedback.created_by === currentUser.id"
								@click="
									() =>
										handleDeleteFeedback({
											id: feedback.id,
										})
								"
								style="margin-top: 0.5rem"
								block
								color="error"
							>
								<v-icon left>delete</v-icon>
								{{ $t('button.delete-feedback') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<v-divider></v-divider>
			</div>
		</div>

		<div v-if="feedbacks.length > 0" style="margin-top: 1rem">
			<v-divider></v-divider>

			<v-subheader>{{ $t('subheader.received-feedbacks') }}</v-subheader>

			<v-divider></v-divider>

			<div v-for="(feedback, index) in feedbacks" :key="`feedback_${index}`">
				<v-container>
					<v-row>
						<v-col
							cols="auto"
							align-self="center"
							@click="() => handleShowFeedback({ id: feedback.id })"
							style="cursor: pointer"
						>
							<Avatar :userId="feedback.created_by"></Avatar>
						</v-col>
						<v-col
							@click="() => handleShowFeedback({ id: feedback.id })"
							align-self="center"
							style="cursor: pointer"
						>
							<h3 v-if="feedback.pipeline_status || feedback.job_id">
								<span v-if="feedback.pipeline_status"
									>{{ $t(`pipeline.steps.${feedback.pipeline_status}`) }}
									-
								</span>
								<span v-if="feedback.job_id">{{
									handleGetJobName({ jobId: feedback.job_id })
								}}</span>
							</h3>
							<div>
								<strong
									v-if="feedback.overall_score === 4"
									style="color: #2e7d32"
									><v-icon style="color: #2e7d32">thumb_up</v-icon>
									{{ $t('text.strong-yes') }}</strong
								>
								<strong
									v-else-if="feedback.overall_score === 3"
									style="color: #66bb6a"
									><v-icon style="color: #66bb6a">thumb_up</v-icon>
									{{ $t('text.yes') }}</strong
								>
								<strong
									v-else-if="feedback.overall_score === 2"
									style="color: #f44336"
									><v-icon style="color: #f44336">thumb_down</v-icon>
									{{ $t('text.no') }}</strong
								>
								<strong
									v-else-if="feedback.overall_score === 1"
									style="color: #b71c1c"
									><v-icon style="color: #b71c1c">thumb_down</v-icon>
									{{ $t('text.strong-no') }}</strong
								>
							</div>
							<div v-html="formatDate({ timestamp: feedback.created })"></div>
						</v-col>
						<v-col align-self="center" cols="auto">
							<v-btn
								@click="() => handleShowFeedback({ id: feedback.id })"
								block
							>
								<v-icon left>launch</v-icon>
								{{ $t('button.view-feedback') }}
							</v-btn>
							<v-btn
								v-if="feedback.created_by === currentUser.id"
								@click="() => handleEditFeedback({ id: feedback.id })"
								style="margin-top: 0.5rem"
								block
							>
								<v-icon left>edit</v-icon>
								{{ $t('button.edit-feedback') }}
							</v-btn>
							<v-btn
								v-if="feedback.created_by === currentUser.id"
								@click="
									() =>
										handleDeleteFeedback({
											id: feedback.id,
										})
								"
								style="margin-top: 0.5rem"
								block
								color="error"
							>
								<v-icon left>delete</v-icon>
								{{ $t('button.delete-feedback') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<v-divider></v-divider>
			</div>
		</div>
		<div v-else style="margin-top: 1rem">
			{{ $t('err.no-feedbacks-found') }}
		</div>
		<ViewFeedbackDialog
			v-if="isViewFeedbackDialogVisible"
			:feedbackId="feedbackIdToView"
			:onClose="handleViewFeedbackDialogClose"
		></ViewFeedbackDialog>
		<EditFeedbackDialog
			v-if="isEditFeedbackDialogVisible"
			:feedbackId="feedbackIdToEdit"
			:candidate="candidate"
			:onClose="handleEditFeedbackDialogClose"
		></EditFeedbackDialog>
		<Confirm
			v-if="isDeleteFeedbackConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
			><div style="padding-top: 20px">
				{{ $t('text.confirm-deleting-feedback') }}
			</div></Confirm
		>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import ViewFeedbackDialog from './viewFeedbackDialog/ViewFeedbackDialog';
import EditFeedbackDialog from './editFeedbackDialog/EditFeedbackDialog';
import NewFeedbackMenu from './newFeedbackMenu/NewFeedbackMenu';
import ShareFeedbacksMenu from './shareFeedbacksMenu/ShareFeedbacksMenu';
import Avatar from '@/components/shared/avatar/Avatar';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';

export default {
	name: 'Feedbacks',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
	},
	components: {
		ViewFeedbackDialog,
		EditFeedbackDialog,
		NewFeedbackMenu,
		Avatar,
		ShareFeedbacksMenu,
		Confirm,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			jobs: state => state.jobs.items,
		}),
	},
	data() {
		return {
			format,
			feedbacks: [],
			drafts: [],
			isViewFeedbackDialogVisible: false,
			feedbackIdToView: null,
			isEditFeedbackDialogVisible: false,
			feedbackIdToEdit: null,
			isDeleteFeedbackConfirmVisible: false,
			feedbackIdToDelete: null,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		getCandidateFeedbacks() {
			const { candidateId } = this.$route.params;
			this.$store
				.dispatch('feedbacks/get', {
					candidate_id: candidateId,
					status: 'completed',
					refresh: true,
				})
				.then(response => {
					this.feedbacks = [...response.data.items];
				});
		},
		getCandidateFeedbackDrafts() {
			const { candidateId } = this.$route.params;
			this.$store
				.dispatch('feedbacks/get', {
					candidate_id: candidateId,
					status: 'draft',
					refresh: true,
				})
				.then(response => {
					this.drafts = [...response.data.items];
				});
		},
		async handleAddNewFeedback({
			feedbackTemplateId,
			applicationId,
			pipelineStatus,
			jobId,
		}) {
			const { candidateId } = this.$route.params;
			const feedbackTemplateResponse = await this.$store.dispatch(
				'feedbackTemplates/get',
				{ id: feedbackTemplateId, refresh: true },
			);
			const { created_json_schema, instructions } =
				feedbackTemplateResponse.data.items[0];
			const draftFeedbackResponse = await this.$store.dispatch(
				'feedbacks/post',
				{
					items: [
						{
							json_schema: created_json_schema,
							context_schema: {},
							overall_score: null,
							candidate_id: candidateId,
							application_id: applicationId,
							pipeline_status: pipelineStatus,
							pipeline_status_translation: this.$t(
								`pipeline.steps.${pipelineStatus}`,
							),
							job_id: jobId,
							status: 'draft',
							instructions,
						},
					],
				},
			);
			this.$store.dispatch('drafts/get_feedbacks', {});
			const { id } = draftFeedbackResponse.data.items[0];
			this.handleEditFeedback({ id });
			this.getCandidateFeedbackDrafts();
		},
		handleShowFeedback({ id }) {
			this.feedbackIdToView = id;
			this.isViewFeedbackDialogVisible = true;
		},
		handleViewFeedbackDialogClose() {
			this.isViewFeedbackDialogVisible = false;
		},
		handleEditFeedback({ id }) {
			this.feedbackIdToEdit = id;
			this.isEditFeedbackDialogVisible = true;
		},
		handleDeleteFeedback({ id }) {
			this.feedbackIdToDelete = id;
			this.isDeleteFeedbackConfirmVisible = true;
		},
		handleEditFeedbackDialogClose({ needsRefresh = false }) {
			this.isEditFeedbackDialogVisible = false;
			if (needsRefresh) {
				this.getCandidateFeedbacks();
				this.getCandidateFeedbackDrafts();
			}
		},
		handleGetJobName({ jobId }) {
			const { jobs } = this;
			const job = jobs.find(item => item.id === jobId);
			return job ? job.name : jobId;
		},
		handleConfirmDialogConfirm() {
			const { feedbackIdToDelete } = this;

			this.$store
				.dispatch('feedbacks/delete', { id: feedbackIdToDelete })
				.then(() => {
					this.getCandidateFeedbacks();
					this.getCandidateFeedbackDrafts();
					this.isDeleteFeedbackConfirmVisible = false;
					this.$store.commit('candidates/setItemNeedsToRefresh');
					this.$store.dispatch('drafts/get_feedbacks', {});
				});
		},
		handleConfirmDialogClose() {
			this.isDeleteFeedbackConfirmVisible = false;
		},
	},
	created() {
		this.$store.dispatch('feedbackTemplates/get', { updateStore: true });
	},
	watch: {
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.getCandidateFeedbacks();
					this.getCandidateFeedbackDrafts();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
