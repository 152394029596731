var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{staticClass:"talentics-fieldset"},[_c('legend',[_vm._v(_vm._s(_vm.label))]),_c('v-container',{staticStyle:{"padding-top":"0"}},[_c('Draggable',{on:{"start":_vm.handleDragStart,"end":_vm.handleDragEnd},model:{value:(_vm.linkQuestions),callback:function ($$v) {_vm.linkQuestions=$$v},expression:"linkQuestions"}},_vm._l((_vm.linkQuestions),function(item,index){return _c('div',{key:`linkQuestion_${index}`},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('span',{staticClass:"all-scroll"},[_c('v-icon',[_vm._v("drag_indicator")])],1)]),_c('v-col',{attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-col',{staticClass:"disp-flex flex-end",attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"text":"","icon":"","disabled":_vm.userRole < 666},on:{"click":() => _vm.handleEditClick({ index })}},[_c('v-icon',[_vm._v("edit")])],1),_c('v-btn',{attrs:{"text":"","icon":"","disabled":_vm.userRole < 666},on:{"click":() =>
									_vm.handleEditLinkQuestion({
										index,
										field: 'required',
									})}},[_c('v-icon',[_vm._v(_vm._s(item.required ? 'star' : 'star_outline'))])],1),_c('v-btn',{attrs:{"text":"","icon":"","disabled":_vm.userRole < 666},on:{"click":() =>
									_vm.handleEditLinkQuestion({
										index,
										field: 'sensitive',
									})}},[_c('v-icon',[_vm._v(_vm._s(item.sensitive ? 'mdi-shield-check' : 'mdi-shield-check-outline'))])],1),_c('v-btn',{attrs:{"text":"","icon":"","disabled":_vm.userRole < 666},on:{"click":() => _vm.handleDeleteLinkQuestion({ index })}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1),_c('v-divider')],1)}),0),_c('v-row',{style:(`margin-top: ${_vm.linkQuestions.length > 0 ? '.25rem' : '0'};`)},[_c('v-col',[_c('v-btn',{on:{"click":_vm.handleAddNewLinkQuestion}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('button.add'))+" ")],1)],1)],1)],1),(_vm.isAdditionalApplicationFormQuestionVisible)?_c('AdditionalApplicationFormQuestion',{attrs:{"item":_vm.questionToEdit,"onClose":_vm.handleAdditionalApplicationFormQuestionClose,"onUpdate":_vm.handleAdditionalApplicationFormQuestionUpdate}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }