<template>
	<div>
		<v-dialog
			:value="true"
			max-width="880"
			@click:outside="onClose"
			@keydown.esc="onClose"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn icon @click="onClose" v-on="on">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('tooltip.close') }}</span>
						</v-tooltip>
						<v-toolbar-title>{{ original_file_name }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn color="primary" @click="handleDownload" :disabled="!ready">
							<v-icon left>download</v-icon>
							{{ $t('button.download') }}
						</v-btn>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>

				<v-card-text style="padding: 0">
					<div v-if="ready">
						<iframe
							:src="`${base64}#toolbar=0`"
							style="width: 100%; height: 500px; border: 1px solid silver"
							border="0"
						></iframe>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
	name: 'ViewFile',
	props: {
		fileId: {
			type: Number,
			required: true,
		},
		onClose: {
			type: Function,
			required: true,
		},
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			base64: null,
			original_file_name: null,
			ready: false,
		};
	},
	mounted() {
		this.handleGetFile({ fileId: this.fileId });
	},
	methods: {
		async handleGetFile({ fileId }) {
			const base64Response = await api.files.base64.get({ id: fileId });
			const { base64, original_file_name, content_type } = base64Response.data;
			this.original_file_name = original_file_name;
			this.base64 = `data:${content_type};base64,${base64}`;

			if (
				content_type === 'application/pdf' ||
				content_type === 'image/jpeg' ||
				content_type === 'image/gif' ||
				content_type === 'image/png'
			) {
				this.ready = true;
			} else {
				this.handleDownload();
				this.onClose();
			}
		},
		async handleDownload() {
			const base64Fetch = await fetch(this.base64);
			const blob = await base64Fetch.blob();
			const FileSaver = require('file-saver');
			FileSaver.saveAs(blob, this.original_file_name);
		},
	},
};
</script>
