<template>
	<ValidationProvider :rules="rules" v-slot="{ errors }">
		<v-autocomplete
			:value="value"
			:items="items"
			item-text="name"
			item-value="id"
			hide-no-data
			hide-details
			outlined
			:label="label"
			@change="handleChange"
			:multiple="multiple"
			:chips="multiple"
			:deletable-chips="multiple"
			:clearable="clearable"
			class="v-select-talentics-chips"
			:error-messages="errors"
			:dense="dense"
			:disabled="disabled"
		>
			<template v-slot:prepend-item>
				<div>
					<div v-if="hideClosedJobs !== true">
						<div style="padding-left: 1rem; padding-bottom: 0.5rem">
							<v-switch
								v-model="listClosedJobs"
								color="success"
								class="v-input--template-switch"
								hide-details
								inset
								dense
								:label="$t('label.show-closed-jobs')"
							></v-switch>
						</div>
						<v-divider></v-divider>
					</div>

					<div v-if="hideArchivedJobs !== true">
						<div
							style="
								padding-left: 1rem;
								padding-bottom: 0.5rem;
								padding-top: 0.5rem;
							"
						>
							<v-switch
								v-model="listArchivedJobs"
								color="success"
								class="v-input--template-switch"
								hide-details
								inset
								dense
								:label="$t('label.show-archived-jobs')"
							></v-switch>
						</div>
						<v-divider></v-divider>
					</div>

					<div v-if="hideDraftJobs !== true">
						<div
							style="
								padding-left: 1rem;
								padding-bottom: 0.5rem;
								padding-top: 0.5rem;
							"
						>
							<v-switch
								v-model="listDraftJobs"
								color="success"
								class="v-input--template-switch"
								hide-details
								inset
								dense
								:label="$t('label.show-draft-jobs')"
							></v-switch>
						</div>
						<v-divider></v-divider>
					</div>

					<div v-if="hidePendingJobs !== true">
						<div
							style="
								padding-left: 1rem;
								padding-bottom: 0.5rem;
								padding-top: 0.5rem;
							"
						>
							<v-switch
								v-model="listPendingJobs"
								color="success"
								class="v-input--template-switch"
								hide-details
								inset
								dense
								:label="$t('label.show-pending-jobs')"
							></v-switch>
						</div>
						<v-divider></v-divider>
					</div>
				</div>
			</template>
		</v-autocomplete>
	</ValidationProvider>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider } from 'vee-validate';

export default {
	name: 'JobPicker',
	props: {
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		value: {
			type: [Number, Array],
			required: false,
			default() {
				return null;
			},
		},
		label: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		multiple: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		clearable: {
			type: Boolean,
			required: false,
			default() {
				return true;
			},
		},
		rules: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		hideInactiveTeams: {
			type: Boolean,
			required: false,
		},
		hideInactiveDepartments: {
			type: Boolean,
			required: false,
		},
		hideDraftJobs: {
			type: Boolean,
			required: false,
		},
		hideClosedJobs: {
			type: Boolean,
			required: false,
		},
		hideArchivedJobs: {
			type: Boolean,
			required: false,
		},
		hidePendingJobs: {
			type: Boolean,
			required: false,
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		ValidationProvider,
	},
	computed: {
		...mapState({
			departments: state => state.departments.items,
			teams: state => state.teams.items,
		}),
		items() {
			const {
				departments,
				jobs,
				teams,
				hideInactiveDepartments,
				hideInactiveTeams,
				listClosedJobs,
				listArchivedJobs,
				listDraftJobs,
				listPendingJobs,
			} = this;

			const filterDepartments = department => {
				if (
					listClosedJobs ||
					listArchivedJobs ||
					listDraftJobs ||
					listPendingJobs
				) {
					return true;
				}
				return hideInactiveDepartments ? department.active : true;
			};

			const filterTeams = team => {
				if (
					listClosedJobs ||
					listArchivedJobs ||
					listDraftJobs ||
					listPendingJobs
				) {
					return true;
				}
				return hideInactiveTeams ? team.active : true;
			};

			const deptTeams = departments
				.filter(filterDepartments)
				.reduce((acc, department) => {
					const matchingTeams = teams
						.filter(filterTeams)
						.filter(({ department_id }) => department_id === department.id)
						.map(team => ({
							header: `${department.name} - ${team.name}`,
							team_id: team.id,
						}));
					return [...acc, ...matchingTeams];
				}, []);

			const processedJobs = deptTeams.reduce((acc, deptTeam) => {
				const deptTeamJobs = jobs
					.filter(({ team_id }) => team_id === deptTeam.team_id)
					.map(job => ({
						...job,
						name: `${job.name} ${
							job.status === 'open' ? '' : `(${this.$t(`label.${job.status}`)})`
						}`,
					}));

				return [
					...acc,
					...(deptTeamJobs.length > 0 ? [deptTeam] : []),
					...deptTeamJobs,
				];
			}, []);

			return processedJobs
				.filter(item => (listClosedJobs ? item : item.status !== 'closed'))
				.filter(item => (listArchivedJobs ? item : item.status !== 'archived'));
		},
	},
	data() {
		return {
			listClosedJobs: false,
			listArchivedJobs: false,
			listDraftJobs: false,
			listPendingJobs: false,
		};
	},
	methods: {
		handleChange(newVal) {
			this.$emit('input', newVal);
		},
	},
};
</script>
