<template>
	<div>
		<v-timeline align-top dense>
			<v-timeline-item small hide-dot>
				<span style="text-decoration: underline">{{
					$t('label.organizations')
				}}</span>
			</v-timeline-item>
			<v-timeline-item
				v-for="(organizationItem, organizationIndex) in organizations"
				:key="`organization_${organizationIndex}`"
				small
				:color="organizationItem.active ? 'green' : 'grey'"
			>
				<div>
					<v-btn
						outlined
						small
						@click="
							() =>
								addOrEditItem({
									type: 'organization',
									id: organizationItem.id,
									operation: 'edit',
								})
						"
					>
						{{ organizationItem.name }}
					</v-btn>
				</div>
				<div>
					<v-timeline align-top dense>
						<v-timeline-item small hide-dot>
							<span style="text-decoration: underline">{{
								$t('label.departments')
							}}</span>
						</v-timeline-item>
						<v-timeline-item
							v-for="(departmentItem, departmentIndex) in departments.filter(
								item => item.organization_id === organizationItem.id,
							)"
							:key="`department_${departmentIndex}`"
							small
							:color="departmentItem.active ? 'green' : 'grey'"
						>
							<div>
								<v-btn
									outlined
									small
									@click="
										() =>
											addOrEditItem({
												type: 'department',
												id: departmentItem.id,
												operation: 'edit',
											})
									"
								>
									{{ departmentItem.name }}
								</v-btn>
								<v-btn
									icon
									small
									outlined
									style="margin-left: 0.5rem"
									@click="
										() =>
											handleDepartmentToggleClick({
												department_id: departmentItem.id,
											})
									"
								>
									<v-icon v-if="visibleDepartmentId === departmentItem.id"
										>keyboard_arrow_down</v-icon
									>
									<v-icon v-else>keyboard_arrow_right</v-icon>
								</v-btn>
							</div>
							<div v-if="visibleDepartmentId === departmentItem.id">
								<v-timeline align-top dense>
									<v-timeline-item small hide-dot>
										<span style="text-decoration: underline">{{
											$t('label.teams')
										}}</span>
									</v-timeline-item>
									<v-timeline-item
										v-for="(teamItem, teamIndex) in teams.filter(
											item => item.department_id === departmentItem.id,
										)"
										:key="`team_${teamIndex}`"
										small
										:color="teamItem.active ? 'green' : 'grey'"
									>
										<div>
											<v-btn
												outlined
												small
												@click="
													() =>
														addOrEditItem({
															type: 'team',
															id: teamItem.id,
															operation: 'edit',
														})
												"
											>
												{{ teamItem.name }}
											</v-btn>
											<v-btn
												icon
												small
												outlined
												style="margin-left: 0.5rem"
												@click="
													() =>
														handleTeamToggleClick({
															team_id: teamItem.id,
														})
												"
											>
												<v-icon v-if="visibleTeamId === teamItem.id"
													>keyboard_arrow_down</v-icon
												>
												<v-icon v-else>keyboard_arrow_right</v-icon>
											</v-btn>
										</div>
										<div v-if="visibleTeamId === teamItem.id">
											<v-timeline align-top dense>
												<v-timeline-item small hide-dot>
													<span style="text-decoration: underline">{{
														$t('label.positions')
													}}</span>
												</v-timeline-item>
												<v-timeline-item
													v-for="(
														predefinedPositionItem, predefinedPositionItemIndex
													) in predefinedPositions.filter(
														item => item.team_id === teamItem.id,
													)"
													:key="`team_${predefinedPositionItemIndex}`"
													small
													:color="
														predefinedPositionItem.active ? 'green' : 'grey'
													"
												>
													<div>
														<v-btn
															outlined
															small
															@click="
																() =>
																	addOrEditItem({
																		type: 'predefinedPosition',
																		id: predefinedPositionItem.id,
																		operation: 'edit',
																	})
															"
														>
															{{ predefinedPositionItem.name }}
														</v-btn>
													</div>
												</v-timeline-item>
												<v-timeline-item small color="grey">
													<v-btn
														color="secondary"
														small
														@click="
															() =>
																addOrEditItem({
																	type: 'predefinedPosition',
																	team_id: teamItem.id,
																	operation: 'add',
																})
														"
													>
														{{ $t('button.add-position') }}
													</v-btn>
												</v-timeline-item>
											</v-timeline>
										</div>
									</v-timeline-item>
									<v-timeline-item small color="grey">
										<v-btn
											color="secondary"
											small
											@click="
												() =>
													addOrEditItem({
														type: 'team',
														department_id: departmentItem.id,
														operation: 'add',
													})
											"
										>
											{{ $t('button.add-team') }}
										</v-btn>
									</v-timeline-item>
								</v-timeline>
							</div>
						</v-timeline-item>
						<v-timeline-item small color="grey">
							<v-btn
								color="secondary"
								small
								@click="
									() =>
										addOrEditItem({
											type: 'department',
											organization_id: organizationItem.id,
											operation: 'add',
										})
								"
							>
								{{ $t('button.add-department') }}
							</v-btn>
						</v-timeline-item>
					</v-timeline>
				</div>
			</v-timeline-item>
			<v-timeline-item small color="grey">
				<v-btn
					color="secondary"
					small
					@click="
						() =>
							addOrEditItem({
								type: 'organization',
								operation: 'add',
							})
					"
				>
					{{ $t('button.add-organization') }}
				</v-btn>
			</v-timeline-item>
		</v-timeline>

		<OrganizationAttrs
			v-if="isOrganizationAttrsVisible"
			:method="organizationActionMethod"
			:organization="organizationToEdit"
			:onClose="handleOrganizationAttrsClose"
		></OrganizationAttrs>
		<DepartmentAttrs
			v-if="isDepartmentAttrsVisible"
			:method="departmentActionMethod"
			:department="departmentToEdit"
			:onClose="handleDepartmentAttrsClose"
		></DepartmentAttrs>
		<TeamAttrs
			v-if="isTeamAttrsVisible"
			:method="teamActionMethod"
			:team="teamToEdit"
			:onClose="handleTeamAttrsClose"
		></TeamAttrs>
		<PredefinedPositionAttrs
			v-if="isPredefinedPositionAttrsVisible"
			:method="predefinedPositionActionMethod"
			:team="predefinedPositionToEdit"
			:onClose="handlePredefinedPositionAttrsClose"
		></PredefinedPositionAttrs>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import DepartmentAttrs from './departmentAttrs/DepartmentAttrs';
import TeamAttrs from './teamAttrs/TeamAttrs';
import OrganizationAttrs from './organizationAttrs/OrganizationAttrs';
import PredefinedPositionAttrs from './predefinedPositionAttrs/PredefinedPositionAttrs';

export default {
	name: 'DeptTeams',
	computed: {
		...mapState({
			organizations: state => state.organizations.items,
			departments: state => state.departments.items,
			teams: state => state.teams.items,
			predefinedPositions: state => state.predefinedPositions.items,
		}),
		departmentsWithTeams() {
			const { departments, teams, predefinedPositions } = this;
			return departments.map(department => ({
				...department,
				teams: teams
					.filter(({ department_id }) => department_id === department.id)
					.map(team => ({
						...team,
						predefinedPositions: predefinedPositions.filter(
							({ team_id }) => team_id === team.id,
						),
					})),
			}));
		},
	},
	components: {
		DepartmentAttrs,
		TeamAttrs,
		OrganizationAttrs,
		PredefinedPositionAttrs,
	},
	created() {
		this.$store.dispatch('organizations/get', {
			updateStore: true,
			refresh: true,
		});
		this.$store.dispatch('departments/get', {
			updateStore: true,
			refresh: true,
		});
		this.$store.dispatch('teams/get', { updateStore: true, refresh: true });
		this.$store.dispatch('predefinedPositions/get', {
			updateStore: true,
			refresh: true,
		});
	},
	data() {
		return {
			isDepartmentAttrsVisible: false,
			departmentToEdit: {},
			departmentActionMethod: 'edit',
			isTeamAttrsVisible: false,
			teamToEdit: {},
			teamActionMethod: 'edit',
			isOrganizationAttrsVisible: false,
			organizationToEdit: {},
			organizationActionMethod: 'edit',
			isPredefinedPositionAttrsVisible: false,
			predefinedPositionToEdit: {},
			predefinedPositionActionMethod: 'edit',
			visibleDepartmentId: null,
			visibleTeamId: null,
		};
	},
	methods: {
		addOrEditItem({
			type,
			id,
			operation,
			team_id,
			department_id,
			organization_id,
		}) {
			if (type === 'department') {
				if (operation === 'edit') {
					this.departmentActionMethod = 'edit';
					this.departmentToEdit = this.departments.find(item => item.id === id);
					this.isDepartmentAttrsVisible = true;
				} else if (operation === 'add') {
					this.departmentActionMethod = 'add';
					this.departmentToEdit = {
						active: true,
						name: '',
						organization_id,
					};
					this.isDepartmentAttrsVisible = true;
				}
			} else if (type === 'team') {
				if (operation === 'edit') {
					this.teamActionMethod = 'edit';
					this.teamToEdit = this.teams.find(item => item.id === id);
					this.isTeamAttrsVisible = true;
				} else if (operation === 'add') {
					this.teamActionMethod = 'add';
					this.teamToEdit = {
						active: true,
						name: '',
						department_id,
					};
					this.isTeamAttrsVisible = true;
				}
			} else if (type === 'organization') {
				if (operation === 'edit') {
					this.organizationActionMethod = 'edit';
					this.organizationToEdit = this.organizations.find(
						item => item.id === id,
					);
					this.isOrganizationAttrsVisible = true;
				} else if (operation === 'add') {
					this.organizationActionMethod = 'add';
					this.organizationToEdit = {
						active: true,
						name: '',
					};
					this.isOrganizationAttrsVisible = true;
				}
			} else if (type === 'predefinedPosition') {
				if (operation === 'edit') {
					this.predefinedPositionActionMethod = 'edit';
					this.predefinedPositionToEdit = this.predefinedPositions.find(
						item => item.id === id,
					);
					this.isPredefinedPositionAttrsVisible = true;
				} else if (operation === 'add') {
					this.predefinedPositionActionMethod = 'add';
					this.predefinedPositionToEdit = {
						active: true,
						name: '',
						team_id,
					};
					this.isPredefinedPositionAttrsVisible = true;
				}
			}
		},
		handleOrganizationAttrsClose() {
			this.isOrganizationAttrsVisible = false;
		},
		handleDepartmentAttrsClose() {
			this.isDepartmentAttrsVisible = false;
		},
		handleTeamAttrsClose() {
			this.isTeamAttrsVisible = false;
		},
		handlePredefinedPositionAttrsClose() {
			this.isPredefinedPositionAttrsVisible = false;
		},
		handleDepartmentToggleClick({ department_id }) {
			if (department_id !== this.visibleDepartmentId) {
				this.visibleDepartmentId = department_id;
			} else {
				this.visibleDepartmentId = null;
			}
		},
		handleTeamToggleClick({ team_id }) {
			if (team_id !== this.visibleTeamId) {
				this.visibleTeamId = team_id;
			} else {
				this.visibleTeamId = null;
			}
		},
	},
};
</script>
