<template>
	<div>
		<v-overlay :value="visible"></v-overlay>
		<v-bottom-sheet
			:value="visible"
			hide-overlay
			attach=".calendar-drawer"
			persistent
			class="v-bottom-sheet-calendar"
		>
			<v-card tile elevation="0">
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<div v-on="on">
								<v-btn icon small @click="onClickClose">
									<v-icon>close</v-icon>
								</v-btn>
							</div>
						</template>
						{{ $t('tooltip.close') }}
					</v-tooltip>
				</v-card-actions>
				<v-card-text>
					<v-row>
						<v-col cols="2"><v-icon>receipt</v-icon></v-col>
						<v-col cols="10">
							<h3>{{ event.summary }}</h3>
							<div v-if="event && event.start && event.start.dateTime">
								{{
									formatDate({
										timestamp: event.start.dateTime,
										format: 'EEEE, MMMM d',
									})
								}}
								&bull;
								{{
									formatDate({
										timestamp: event.start.dateTime,
										format: 'h:mm',
									})
								}}
								{{
									formatDate({
										timestamp: event.end.dateTime,
										format: 'h:mm',
									})
								}}{{
									formatDate({
										timestamp: event.start.dateTime,
										format: 'aaa',
									})
								}}
							</div>
						</v-col>
					</v-row>

					<v-row v-if="event.location">
						<v-col cols="2"><v-icon>place</v-icon></v-col>
						<v-col cols="10">{{ event.location }}</v-col>
					</v-row>

					<v-row v-if="event.attendees && event.attendees.length > 0">
						<v-col cols="2"><v-icon>people</v-icon></v-col>
						<v-col cols="10">
							<ul style="padding-left: 15px">
								<li v-for="(attendee, key) in event.attendees" :key="key">
									<span v-if="attendee.displayName">{{
										attendee.displayName
									}}</span>
									<span v-else>{{ attendee.email }}</span>
								</li>
							</ul>
						</v-col>
					</v-row>
					<v-row v-if="event.onlineMeetingUrl">
						<v-col cols="2"><v-icon>video_camera_front</v-icon></v-col>
						<v-col cols="10">
							<a :href="event.onlineMeetingUrl" target="_blank">
								{{
									event.providerId === 'google.com'
										? $t('text.join-with-google-meet')
										: $t('text.join-with-microsoft-teams')
								}}
							</a>
							<div
								v-if="event.providerId === 'google.com'"
								style="font-size: 0.75rem"
							>
								{{ event.onlineMeetingUrl }}
							</div>
						</v-col>
					</v-row>
					<v-row v-if="event.isCandidateEvent && event.candidateProfileUrl">
						<v-col cols="2"><v-icon>person</v-icon></v-col>
						<v-col cols="10">
							<router-link :to="`${event.candidateProfileUrl}`">
								{{ $t('text.go-to-profile') }}
							</router-link>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-bottom-sheet>
	</div>
</template>

<script>
import { format } from 'date-fns';

export default {
	name: 'BottomSheetEvent',
	props: {
		event: {
			type: Object,
			required: false,
			default() {
				return {
					summary: '',
				};
			},
		},
		visible: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		onClickClose: {
			type: Function,
			return: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			format,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
	},
};
</script>

<style lang="scss">
.calendar-drawer {
	.v-bottom-sheet {
		margin-left: 1px !important;
	}
}
</style>
