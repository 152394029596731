<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="800"
			@click:outside="onClose"
			@keydown.esc="onClose"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title
							>{{ $t(`tab.${candidateStatus}`) }} -
							{{ $t(`pipeline.tab-steps.${pipelineStatusValue}`) }}
							({{ formatDate({ timestamp: date }) }})</v-toolbar-title
						>
						<v-spacer></v-spacer>
						<div
							v-if="candidates.length > 0"
							style="
								font-size: 0.75rem;
								color: #5f6368;
								white-space: nowrap;
								line-height: 36px;
							"
						>
							{{
								$t('text.candidates-length', {
									total,
									startIndex,
									endIndex,
								})
							}}
						</div>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<div v-on="on">
									<v-btn
										icon
										@click="
											() =>
												handlePageChange({
													page: page - 1,
												})
										"
										:disabled="page === 1"
									>
										<v-icon>keyboard_arrow_left</v-icon>
									</v-btn>
								</div>
							</template>
							{{ $t('tooltip.newer') }}
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<div v-on="on">
									<v-btn
										icon
										@click="
											() =>
												handlePageChange({
													page: page + 1,
												})
										"
										:disabled="total <= page * 50"
									>
										<v-icon>keyboard_arrow_right</v-icon>
									</v-btn>
								</div>
							</template>
							{{ $t('tooltip.older') }}
						</v-tooltip>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<div v-if="candidates.length === 0 && loading === true">
						<v-simple-table class="candidates-table">
							<tbody>
								<tr
									v-for="(item, key) in (0,
									1,
									2,
									3,
									4,
									5,
									6,
									7,
									8,
									9,
									10,
									11,
									12,
									13,
									14,
									15,
									16,
									17,
									18,
									19)"
									:key="`placeholder_${key}`"
								>
									<td>
										<v-progress-linear
											color="grey lighten-2"
											height="6"
											indeterminate
											reverse
										></v-progress-linear>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</div>
					<div
						v-else-if="candidates.length === 0 && loading === false"
						style="padding: 1rem"
					>
						{{ $t('err.no-candidates-found') }}
					</div>
					<div v-else>
						<v-simple-table class="candidates-table">
							<tbody>
								<tr
									v-for="(candidate, index) in candidates"
									:key="index"
									style="cursor: pointer"
								>
									<td
										@click="
											() =>
												handleCandidateClick({
													id: candidate.id,
												})
										"
									>
										<span style="white-space: nowrap">{{
											candidate.name
										}}</span>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</div>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="onClose">{{
						$t('button.close')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import api from '@/api';

export default {
	name: 'ReportCandidatesDialog',
	props: {
		candidateStatus: {
			type: String,
			required: true,
		},
		pipelineStatusKey: {
			type: String,
			required: false,
		},
		pipelineStatusValue: {
			type: String,
			required: false,
		},
		date: {
			type: String,
			required: true,
		},
		onClose: {
			type: Function,
			required: true,
		},
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			format,
			isVisible: false,
			candidates: [],
			loading: true,
			page: 1,
			startIndex: 0,
			endIndex: 0,
			total: 0,
		};
	},
	methods: {
		loadCandidates() {
			const {
				candidateStatus,
				pipelineStatusKey,
				pipelineStatusValue,
				date,
				page,
			} = this;
			const { owner, job_id } = this.$route.query;
			this.loading = true;
			api.reports.candidates[candidateStatus]
				.get({
					page,
					date,
					[pipelineStatusKey]: pipelineStatusValue,
					owner,
					job_id,
				})
				.then(response => {
					const { items, start_index, end_index, total } = response.data;
					this.candidates = items;
					this.startIndex = start_index;
					this.endIndex = end_index;
					this.total = total;
					this.loading = false;
				});
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handlePageChange({ page }) {
			this.page = page;
			this.loadCandidates();
		},
		handleCandidateClick({ id }) {
			const { currentLang, pipelineStatusKey, pipelineStatusValue } = this;
			const query = {
				...(this.candidateStatus === 'lead'
					? { is_lead: 1, [pipelineStatusKey]: pipelineStatusValue }
					: {}),
				...(this.candidateStatus === 'archive'
					? {
							is_archived: 1,
							[pipelineStatusKey]: pipelineStatusValue,
					  }
					: {}),
				...(this.candidateStatus === 'interview'
					? {
							is_interview: 1,
							[pipelineStatusKey]: pipelineStatusValue,
					  }
					: {}),
				is_search: 1,
			};
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${id}`,
				query,
			});
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
		this.loadCandidates();
	},
};
</script>
