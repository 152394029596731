import api from '@/api';

const state = {
	items: [],
	updated: 0,
	updatedCounters: 0,
	itemsNeedToRefresh: 0,
	counters: {
		leads: 0,
		applieds: 0,
		interviews: 0,
		archived: 0,
	},
	interviewCounters: {},
	archiveCounters: {},
	leadCounters: {},
	loading: false,
	countersLoading: false,
	leadCountersLoading: false,
	interviewCountersLoading: false,
	archiveCountersLoading: false,
};

const getters = {};

const actions = {
	get_counters: (
		{ commit },
		{ refresh = false, updateStore = false, ...params },
	) => {
		return new Promise((resolve, reject) => {
			const { updatedCounters } = state;
			if (!refresh && updatedCounters > 0) {
				resolve({ data: { counters: state.counters } });
			}
			commit('setLoadingIndicators', {
				propName: 'countersLoading',
				value: true,
			});
			api.applicants.counters
				.get(params)
				.then(response => {
					if (updateStore) {
						const { counters } = response.data;
						commit('setCounterItems', { counters });
						commit('setLoadingIndicators', {
							propName: 'countersLoading',
							value: false,
						});
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	get_counters_interview: (
		{ commit },
		{ refresh = false, updateStore = false, ...params },
	) => {
		return new Promise((resolve, reject) => {
			const { updatedCounters } = state;
			if (!refresh && updatedCounters > 0) {
				resolve({ data: { counters: state.interviewCounters } });
			}
			commit('setLoadingIndicators', {
				propName: 'interviewCountersLoading',
				value: true,
			});
			api.applicants.counters.interview
				.get(params)
				.then(response => {
					if (updateStore) {
						const { counters } = response.data;
						commit('setInterviewCounterItems', { counters });
						commit('setLoadingIndicators', {
							propName: 'interviewCountersLoading',
							value: false,
						});
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	get_counters_archive: (
		{ commit },
		{ refresh = false, updateStore = false, ...params },
	) => {
		return new Promise((resolve, reject) => {
			const { updatedCounters } = state;
			if (!refresh && updatedCounters > 0) {
				resolve({ data: { counters: state.archiveCounters } });
			}
			commit('setLoadingIndicators', {
				propName: 'archiveCountersLoading',
				value: true,
			});
			api.applicants.counters.archive
				.get(params)
				.then(response => {
					if (updateStore) {
						const { counters } = response.data;
						commit('setArchiveCounterItems', { counters });
						commit('setLoadingIndicators', {
							propName: 'archiveCountersLoading',
							value: false,
						});
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	get_counters_lead: (
		{ commit },
		{ refresh = false, updateStore = false, ...params },
	) => {
		return new Promise((resolve, reject) => {
			const { updatedCounters } = state;
			if (!refresh && updatedCounters > 0) {
				resolve({ data: { counters: state.leadCounters } });
			}
			commit('setLoadingIndicators', {
				propName: 'leadCountersLoading',
				value: true,
			});
			api.applicants.counters.lead
				.get(params)
				.then(response => {
					if (updateStore) {
						const { counters } = response.data;
						commit('setLeadCounterItems', { counters });
						commit('setLoadingIndicators', {
							propName: 'leadCountersLoading',
							value: false,
						});
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	get: ({ commit }, { refresh = false, updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			const { updated } = state;
			if (!refresh && updated > 0) {
				resolve({ data: { items: state.items } });
			}
			commit('setLoading', { loading: true });
			api.applicants
				.get(params)
				.then(response => {
					if (updateStore) {
						const { items } = response.data;
						commit('setItems', { items });
					}
					commit('setLoading', { loading: false });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	post: ({ commit }, { updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			api.applicants
				.post(params)
				.then(response => {
					if (updateStore) {
						const { items } = response.data;
						commit('addItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	put: ({ commit }, { updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			api.applicants
				.put(params)
				.then(response => {
					if (updateStore) {
						const { items } = response.data;
						commit('updateItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setItems: (state, { jobs }) => {
		const now = new Date();
		state.items = jobs;
		state.updated = now.getTime();
	},
	setItemsNeedToRefresh: state => {
		const now = new Date();
		state.itemsNeedToRefresh = now.getTime();
		state.updated = now.getTime();
	},
	updateItems: (state, { items }) => {
		const now = new Date();
		state.items = state.items.map(stateItem => {
			const itemMatches = items.find(({ id }) => stateItem.id === id);
			return itemMatches ? itemMatches : stateItem;
		});
		state.updated = now.getTime();
	},
	setCounterItems(state, { counters }) {
		const now = new Date();
		state.counters = counters;
		state.updatedCounters = now.getTime();
	},
	setInterviewCounterItems(state, { counters }) {
		const now = new Date();
		state.interviewCounters = counters;
		state.updatedCounters = now.getTime();
	},
	setArchiveCounterItems(state, { counters }) {
		const now = new Date();
		state.archiveCounters = counters;
		state.updatedCounters = now.getTime();
	},
	setLeadCounterItems(state, { counters }) {
		const now = new Date();
		state.leadCounters = counters;
		state.updatedCounters = now.getTime();
	},
	setLoading(state, { loading }) {
		state.loading = loading;
	},
	setLoadingIndicators(state, { propName, value }) {
		state[propName] = value;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
