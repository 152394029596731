<template>
	<v-card
		tile
		elevation="0"
		style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
	>
		<div
			:style="`padding: .5rem; background: ${isDark ? '#121212' : '#ffffff'};`"
		>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon @click="handleRefresh">
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</div>

		<v-divider></v-divider>

		<div
			:style="`position: absolute; top: 53px; right: 0; bottom: 0; left: 0; overflow: auto; background: ${
				isDark ? '#424242' : '#eeeeee'
			};`"
		>
			<v-container fluid fill-height style="padding: 0">
				<v-layout>
					<v-flex>
						<v-container>
							<v-row>
								<v-col>
									<v-card tile elevation="1">
										<v-container>
											<v-row>
												<v-col>
													<DatePicker
														v-model="datePickerDate"
														@input="handleDatePickerInput"
														no-title
														reactive
														dense
														range
														:label="$t('label.date-range')"
													></DatePicker>
												</v-col>
											</v-row>
											<v-row>
												<v-col>
													<JobPicker
														v-model="filters.job_id"
														:jobs="jobs"
														:label="$t('label.jobs')"
														multiple
														@input="() => handleFilterChange()"
														hide-inactive-teams
														hide-inactive-departments
														hide-draft-jobs
														hide-pending-jobs
														dense
													></JobPicker>
												</v-col>
											</v-row>
											<v-row>
												<v-col>
													<UserPicker
														v-model="filters.owner"
														:users="users.filter(item => item.active)"
														:label="$t('label.owner')"
														multiple
														@input="() => handleFilterChange()"
														dense
													></UserPicker>
												</v-col>
											</v-row>
										</v-container>
									</v-card>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card tile elevation="1">
										<v-card-title class="pa-0">
											<v-toolbar flat>
												<v-tabs
													align-with-title
													slider-size="3"
													slider-color="primary"
												>
													<v-tab>
														{{ $t('tab.applicant') }}
													</v-tab>
												</v-tabs>
												<v-spacer></v-spacer>
											</v-toolbar>
										</v-card-title>

										<v-divider></v-divider>
										<v-card-text>
											<ChartStackedBar
												:chartdata="applicantChartdata"
												:onClick="
													({ index, datasetIndex }) =>
														handleChartClick({
															index,
															datasetIndex,
															chartDataKey: 'applicantChartdata',
															candidateStatus: 'applicant',
														})
												"
											></ChartStackedBar>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card tile elevation="1">
										<v-card-title class="pa-0">
											<v-toolbar flat>
												<v-tabs
													align-with-title
													slider-size="3"
													slider-color="primary"
												>
													<v-tab>
														{{ $t('tab.lead') }}
													</v-tab>
												</v-tabs>
												<v-spacer></v-spacer>
											</v-toolbar>
										</v-card-title>
										<v-divider></v-divider>
										<v-card-text>
											<ChartStackedBar
												:chartdata="leadChartdata"
												:onClick="
													({ index, datasetIndex }) =>
														handleChartClick({
															index,
															datasetIndex,
															chartDataKey: 'leadChartdata',
															candidateStatus: 'lead',
														})
												"
											></ChartStackedBar>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card tile elevation="1">
										<v-card-title class="pa-0">
											<v-toolbar flat>
												<v-tabs
													align-with-title
													slider-size="3"
													slider-color="primary"
												>
													<v-tab>
														{{ $t('tab.interview') }}
													</v-tab>
												</v-tabs>
												<v-spacer></v-spacer>
											</v-toolbar>
										</v-card-title>
										<v-divider></v-divider>
										<v-card-text>
											<ChartStackedBar
												:chartdata="interviewChartdata"
												:onClick="
													({ index, datasetIndex }) =>
														handleChartClick({
															index,
															datasetIndex,
															chartDataKey: 'interviewChartdata',
															candidateStatus: 'interview',
														})
												"
											></ChartStackedBar>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card tile elevation="1">
										<v-card-title class="pa-0">
											<v-toolbar flat>
												<v-tabs
													align-with-title
													slider-size="3"
													slider-color="primary"
												>
													<v-tab>
														{{ $t('tab.archive') }}
													</v-tab>
												</v-tabs>
												<v-spacer></v-spacer>
											</v-toolbar>
										</v-card-title>
										<v-divider></v-divider>
										<v-card-text>
											<ChartStackedBar
												:chartdata="archiveChartdata"
												:onClick="
													({ index, datasetIndex }) =>
														handleChartClick({
															index,
															datasetIndex,
															chartDataKey: 'archiveChartdata',
															candidateStatus: 'archive',
														})
												"
											></ChartStackedBar>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-container>
					</v-flex>
				</v-layout>
			</v-container>
		</div>

		<ReportCandidatesDialog
			v-if="isReportCandidatesDialogVisible"
			:candidateStatus="reportCandidatesDialogCandidateStatus"
			:pipelineStatusKey="reportCandidatesDialogPipelineStatusKey"
			:pipelineStatusValue="reportCandidatesDialogPipelineStatusValue"
			:date="reportCandidatesDialogDate"
			:onClose="handleReportCandidatesDialogClose"
		></ReportCandidatesDialog>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import ChartStackedBar from '@/components/shared/chartStackedBar/ChartStackedBar';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import JobPicker from '@/components/shared/jobPicker/JobPicker';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import ReportCandidatesDialog from '../reportCandidatesDialog/ReportCandidatesDialog';
import api from '@/api';

export default {
	name: 'DailySnapshots',
	components: {
		ChartStackedBar,
		UserPicker,
		JobPicker,
		DatePicker,
		ReportCandidatesDialog,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			lang: state => state.currentUser.lang,
			drawer: state => state.currentRoute.drawer,
			isDark: state => state.currentUser.isDark,
			jobs: state => state.jobs.items,
			users: state => state.users.items,
		}),
	},
	data() {
		const { owner, job_id, min_date, max_date } = this.$route.query;

		const end_date = new Date();
		end_date.setDate(end_date.getDate() - 1);
		const start_date = new Date(end_date);
		start_date.setDate(start_date.getDate() - 7);

		const max_date_formatted = max_date
			? max_date
			: format(end_date, 'yyyy-MM-dd');
		const min_date_formatted = min_date
			? min_date
			: format(start_date, 'yyyy-MM-dd');

		const datePickerDate = [min_date_formatted, max_date_formatted];

		return {
			format,
			applicantChartdata: {
				labels: [],
				datasets: [],
			},
			leadChartdata: {
				labels: [],
				datasets: [],
			},
			interviewChartdata: {
				labels: [],
				datasets: [],
			},
			archiveChartdata: {
				labels: [],
				datasets: [],
			},
			filters: {
				job_id: job_id ? job_id.split(',').map(item => parseInt(item, 10)) : [],
				owner: owner ? owner.split(',').map(item => parseInt(item, 10)) : [],
				max_date: max_date_formatted,
				min_date: min_date_formatted,
			},
			datePickerDate,
			isReportCandidatesDialogVisible: false,
			reportCandidatesDialogCandidateStatus: null,
			reportCandidatesDialogPipelineStatusKey: null,
			reportCandidatesDialogPipelineStatusValue: null,
			reportCandidatesDialogDate: null,
		};
	},
	methods: {
		loadReports({ min_date, max_date, owner, job_id }) {
			api.reports.counters.interview
				.get({ min_date, max_date, owner, job_id })
				.then(response => {
					const { data } = response;
					this.interviewChartdata = {
						...data,
						datasets: data.datasets.map(dataset => ({
							...dataset,
							label: this.$t(`pipeline.steps.${dataset.label}`),
							labelKey: dataset.label,
						})),
					};
				});
			api.reports.counters.archive
				.get({ min_date, max_date, owner, job_id })
				.then(response => {
					const { data } = response;
					this.archiveChartdata = {
						...data,
						datasets: data.datasets.map(dataset => ({
							...dataset,
							label: this.$t(`pipeline.steps.${dataset.label}`),
							labelKey: dataset.label,
						})),
					};
				});
			api.reports.counters.lead
				.get({ min_date, max_date, owner, job_id })
				.then(response => {
					const { data } = response;
					this.leadChartdata = {
						...data,
						datasets: data.datasets.map(dataset => ({
							...dataset,
							label: this.$t(`pipeline.steps.${dataset.label}`),
							labelKey: dataset.label,
						})),
					};
				});
			api.reports.counters.applicant
				.get({ min_date, max_date, owner, job_id })
				.then(response => {
					const { data } = response;
					this.applicantChartdata = {
						...data,
						datasets: data.datasets.map(dataset => ({
							...dataset,
							label: this.$t(`pipeline.steps.${dataset.label}`),
							labelKey: dataset.label,
						})),
					};
				});
		},
		handleRefresh() {
			this.loadReports(this.$route.query);
		},
		handleDatePickerInput(dates) {
			if (dates && dates.length === 2) {
				const { query } = this.$route;
				const { currentLang } = this;
				(this.filters = {
					...this.filters,
					min_date: dates[0],
					max_date: dates[1],
				}),
					this.$router.push({
						path: `/${currentLang}/reports/daily-snapshots`,
						query: {
							...query,
							min_date: dates[0],
							max_date: dates[1],
						},
					});
			}
		},
		handleFilterChange() {
			const { job_id, owner, min_date, max_date } = this.filters;
			const query = {
				job_id: job_id.length > 0 ? job_id.join(',') : undefined,
				owner: owner.length > 0 ? owner.join(',') : undefined,
				min_date,
				max_date,
			};
			this.$router.push({
				path: `/${this.currentLang}/reports/daily-snapshots`,
				query,
			});
		},
		handleChartClick({ index, datasetIndex, chartDataKey, candidateStatus }) {
			const { labelKey } = this[chartDataKey].datasets[datasetIndex];
			const date = this[chartDataKey].labels[index];
			this.reportCandidatesDialogCandidateStatus = candidateStatus;
			this.reportCandidatesDialogPipelineStatusKey = `${candidateStatus}_status`;
			this.reportCandidatesDialogPipelineStatusValue = labelKey;
			this.reportCandidatesDialogDate = date;
			this.isReportCandidatesDialogVisible = true;
		},
		handleReportCandidatesDialogClose() {
			this.isReportCandidatesDialogVisible = false;
		},
	},
	watch: {
		'$route.query': {
			handler: function (newVal, oldVal) {
				if (
					oldVal === undefined ||
					newVal.job_id !== oldVal.job_id ||
					newVal.owner !== oldVal.owner ||
					newVal.min_date !== oldVal.min_date ||
					newVal.max_date !== oldVal.max_date
				) {
					const { min_date, max_date } = newVal;
					if (!min_date || !max_date) {
						const { query } = this.$route;
						const { currentLang } = this;
						this.$router.push({
							path: `/${currentLang}/reports/daily-snapshots`,
							query: {
								...query,
								min_date: this.filters.min_date,
								max_date: this.filters.max_date,
							},
						});
					} else {
						this.loadReports({ ...newVal });
					}
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
