var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"value":_vm.drawer,"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":`${_vm.isDark ? '#121212' : 'white'}`}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items.filter(item => {
				// Check user role first
				if (_vm.userRole < item.minRole) {
					return false;
				}

				// Check additional permissions if the role is not sufficient
				if (item.additionalPermissions.length > 0) {
					const permissions = item.additionalPermissions
						.map(permission => _vm.currentUser[permission])
						.filter(permission => permission === true);

					if (permissions.length > 0) {
						return true;
					}
				}

				return true;
			})),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":`/${_vm.lang}/${item.route}`}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(`sidenav-menu.${item.name}`)))])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }