const getCandidateLastName = ({ name }) => {
    if (name) {
        const trimmedName = name.trim().replace(/ +(?= )/g, '');
        const nameParts = trimmedName.split(' ');
        if (nameParts.length > 1) {
            const lastNameIndex = nameParts.length - 1;
            const lastName = nameParts[lastNameIndex];
            return lastName;
        }
    }
    return '';
};

const getCandidateFirstName = ({ name }) => {
    if (name) {
        const nameArr = name
            .trim()
            .replace(/ +(?= )/g, '')
            .split(' ');
        return nameArr[0];
    }
    return '';
};

export {
    getCandidateLastName,
    getCandidateFirstName
}