<template>
	<div>
		<NewFormMenu
			:candidate="candidate"
			:onStart="handleAddNewForm"
		></NewFormMenu>

		<ShareFormsMenu
			:forms="forms.filter(({ status }) => status === 'completed')"
			:candidateName="candidate.name"
			:candidateId="candidate.id"
		></ShareFormsMenu>

		<div v-if="drafts.length > 0" style="margin-top: 1rem">
			<v-divider></v-divider>

			<v-subheader>{{ $t('subheader.your-drafts') }}</v-subheader>

			<v-divider></v-divider>

			<div v-for="(form, index) in drafts" :key="`draft_${index}`">
				<v-container>
					<v-row>
						<v-col
							cols="auto"
							align-self="center"
							@click="() => handleEditForm({ id: form.id })"
							style="cursor: pointer"
						>
							<Avatar :userId="form.created_by"></Avatar>
						</v-col>
						<v-col
							@click="() => handleEditForm({ id: form.id })"
							align-self="center"
							style="cursor: pointer"
						>
							<h3 v-if="form.pipeline_status || form.job_id">
								<span v-if="form.pipeline_status"
									>{{ $t(`pipeline.steps.${form.pipeline_status}`) }}
									-
								</span>
								<span v-if="form.job_id">{{
									handleGetJobName({ jobId: form.job_id })
								}}</span>
							</h3>
							<span
								>{{ $t('label.form-template') }}:
								{{ form.form_template_name || '-' }}</span
							>
							<div v-html="formatDate({ timestamp: form.created })"></div>
						</v-col>
						<v-col align-self="center" cols="auto">
							<v-btn
								v-if="form.created_by === currentUser.id"
								@click="() => handleEditForm({ id: form.id })"
								style="margin-top: 0.5rem"
								block
							>
								<v-icon left>edit</v-icon>
								{{ $t('button.edit-form') }}
							</v-btn>
							<v-btn
								v-if="form.created_by === currentUser.id"
								@click="() => handleDeleteForm({ id: form.id })"
								style="margin-top: 0.5rem"
								block
								color="error"
							>
								<v-icon left>delete</v-icon>
								{{ $t('button.delete-form') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<v-divider></v-divider>
			</div>
		</div>

		<div v-if="forms.length > 0" style="margin-top: 1rem">
			<v-divider></v-divider>

			<v-subheader>{{ $t('subheader.received-forms') }}</v-subheader>

			<v-divider></v-divider>

			<div v-for="(form, index) in forms" :key="`form_${index}`">
				<v-container>
					<v-row>
						<v-col
							cols="auto"
							align-self="center"
							@click="() => handleShowForm({ id: form.id })"
							style="cursor: pointer"
						>
							<Avatar :userId="form.created_by"></Avatar>
						</v-col>
						<v-col
							@click="() => handleShowForm({ id: form.id })"
							align-self="center"
							style="cursor: pointer"
						>
							<h3 v-if="form.pipeline_status || form.job_id">
								<span v-if="form.pipeline_status"
									>{{ $t(`pipeline.steps.${form.pipeline_status}`) }}
									-
								</span>
								<span v-if="form.job_id">{{
									handleGetJobName({ jobId: form.job_id })
								}}</span>
							</h3>
							<span
								>{{ $t('label.form-template') }}:
								{{ form.form_template_name || '-' }}</span
							>
							<div v-html="formatDate({ timestamp: form.created })"></div>
						</v-col>
						<v-col align-self="center" cols="auto">
							<v-btn @click="() => handleShowForm({ id: form.id })" block>
								<v-icon left>launch</v-icon>
								{{ $t('button.view-form') }}
							</v-btn>
							<v-btn
								v-if="form.created_by === currentUser.id"
								@click="() => handleEditForm({ id: form.id })"
								style="margin-top: 0.5rem"
								block
							>
								<v-icon left>edit</v-icon>
								{{ $t('button.edit-form') }}
							</v-btn>
							<v-btn
								v-if="form.created_by === currentUser.id"
								@click="() => handleDeleteForm({ id: form.id })"
								style="margin-top: 0.5rem"
								block
								color="error"
							>
								<v-icon left>delete</v-icon>
								{{ $t('button.delete-form') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<v-divider></v-divider>
			</div>
		</div>
		<div v-else style="margin-top: 1rem">
			{{ $t('err.no-forms-found') }}
		</div>
		<ViewFormDialog
			v-if="isViewFormDialogVisible"
			:formId="formIdToView"
			:onClose="handleViewFormDialogClose"
		></ViewFormDialog>
		<EditFormDialog
			v-if="isEditFormDialogVisible"
			:formId="formIdToEdit"
			:candidate="candidate"
			:onClose="handleEditFormDialogClose"
		></EditFormDialog>
		<Confirm
			v-if="isDeleteFormConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
			><div style="padding-top: 20px">
				{{ $t('text.confirm-deleting-form') }}
			</div></Confirm
		>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import ViewFormDialog from './viewFormDialog/ViewFormDialog';
import EditFormDialog from './editFormDialog/EditFormDialog';
import NewFormMenu from './newFormMenu/NewFormMenu';
import ShareFormsMenu from './shareFormsMenu/ShareFormsMenu';
import Avatar from '@/components/shared/avatar/Avatar';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';

export default {
	name: 'Forms',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
	},
	components: {
		ViewFormDialog,
		EditFormDialog,
		NewFormMenu,
		Avatar,
		ShareFormsMenu,
		Confirm,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			jobs: state => state.jobs.items,
		}),
	},
	data() {
		return {
			format,
			forms: [],
			drafts: [],
			isViewFormDialogVisible: false,
			formIdToView: null,
			isEditFormDialogVisible: false,
			formIdToEdit: null,
			isDeleteFormConfirmVisible: false,
			formIdToDelete: null,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		getCandidateForms() {
			const { candidateId } = this.$route.params;
			this.$store
				.dispatch('forms/get', {
					candidate_id: candidateId,
					status: 'completed',
					refresh: true,
				})
				.then(response => {
					this.forms = [...response.data.items];
				});
		},
		getCandidateFormDrafts() {
			const { candidateId } = this.$route.params;
			this.$store
				.dispatch('forms/get', {
					candidate_id: candidateId,
					status: 'draft',
					refresh: true,
				})
				.then(response => {
					this.drafts = [...response.data.items];
				});
		},
		async handleAddNewForm({
			formTemplateId,
			applicationId,
			pipelineStatus,
			jobId,
		}) {
			const { candidateId } = this.$route.params;
			const formTemplateResponse = await this.$store.dispatch(
				'formTemplates/get',
				{ id: formTemplateId, refresh: true },
			);
			const { created_json_schema, predefined_type } =
				formTemplateResponse.data.items[0];
			const draftFormResponse = await this.$store.dispatch('forms/post', {
				items: [
					{
						json_schema: created_json_schema,
						context_schema: {},
						candidate_id: candidateId,
						application_id: applicationId,
						pipeline_status: pipelineStatus,
						pipeline_status_translation: this.$t(
							`pipeline.steps.${pipelineStatus}`,
						),
						job_id: jobId,
						status: 'draft',
						form_template_predefined_type: predefined_type,
						form_template_id: formTemplateId,
					},
				],
			});
			this.$store.dispatch('drafts/get_forms', {});
			const { id } = draftFormResponse.data.items[0];
			this.handleEditForm({ id });
			this.getCandidateFormDrafts();
		},
		handleShowForm({ id }) {
			this.formIdToView = id;
			this.isViewFormDialogVisible = true;
		},
		handleViewFormDialogClose() {
			this.isViewFormDialogVisible = false;
		},
		handleEditForm({ id }) {
			this.formIdToEdit = id;
			this.isEditFormDialogVisible = true;
		},
		handleDeleteForm({ id }) {
			this.formIdToDelete = id;
			this.isDeleteFormConfirmVisible = true;
		},
		handleEditFormDialogClose({ needsRefresh = false }) {
			this.isEditFormDialogVisible = false;
			if (needsRefresh) {
				this.getCandidateForms();
				this.getCandidateFormDrafts();
			}
		},
		handleGetJobName({ jobId }) {
			const { jobs } = this;
			const job = jobs.find(item => item.id === jobId);
			return job ? job.name : jobId;
		},
		handleConfirmDialogConfirm() {
			const { formIdToDelete } = this;

			this.$store.dispatch('forms/delete', { id: formIdToDelete }).then(() => {
				this.getCandidateForms();
				this.getCandidateFormDrafts();
				this.isDeleteFormConfirmVisible = false;
				this.$store.commit('candidates/setItemNeedsToRefresh');
				this.$store.dispatch('drafts/get_forms', {});
			});
		},
		handleConfirmDialogClose() {
			this.isDeleteFormConfirmVisible = false;
		},
	},
	created() {
		this.$store.dispatch('formTemplates/get', { updateStore: true });
	},
	watch: {
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.getCandidateForms();
					this.getCandidateFormDrafts();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
