<template>
	<div style="position: relative">
		<fieldset class="talentics-fieldset talentics-fieldset-paddings">
			<legend>
				{{ $t('label.approval-flow') }}
				<span
					v-if="mode === 'mini-preview' && status && status === 'draft'"
					style="color: red"
					>({{ $t(`status.${status}`) }})</span
				>
				<span v-if="mode === 'edit' && !consistentEditing">
					(<a href="javascript:void(0)" @click="handleToggleEdit">
						<span v-if="isEditing">{{ $t('button.close-editing') }}</span>
						<span v-else>{{ $t('button.edit') }}</span> </a
					>)
				</span>
			</legend>
			<div style="margin-top: 0.5rem">
				<div v-if="json_schema.length === 0">
					<v-btn small>
						<v-icon left>open_in_new</v-icon>
						{{ $t('status.draft') }}
					</v-btn>
				</div>
				<div v-for="(item, index) in json_schema" :key="`item_${index}`">
					<fieldset class="talentics-fieldset talentics-fieldset-paddings">
						<legend>
							{{ $t('label.step-no', { stepNo: index + 1 }) }}
						</legend>
						<v-row
							v-if="
								mode === 'preview' ||
								mode === 'mini-preview' ||
								(mode === 'edit' && !isEditing)
							"
						>
							<v-col>
								<ApprovalFlowUserChip
									v-for="(approver, index) in item.approvers"
									:key="`approver_${index}`"
									:userId="approver.user_id"
									:status="approver.status"
									:approvalRequestId="approver.approval_request_id"
									:approvalRequest="
										userApprovalRequests.find(
											userApprovalRequests =>
												userApprovalRequests.id ===
												approver.approval_request_id,
										)
									"
									:onClick="handleUserChipClick"
								></ApprovalFlowUserChip>
							</v-col>
						</v-row>
						<div v-if="isEditing">
							<div
								v-for="(approver, approverIndex) in item.approvers"
								:key="`approver_${index}_${approverIndex}`"
							>
								<v-row>
									<v-col cols="6">
										<UserPicker
											v-model="approver.user_id"
											:users="users.filter(item => item.active)"
											:label="$t('label.user')"
											dense
											rules="required"
											@input="
												newVal =>
													handlePropChange({
														propKey: 'user_id',
														newVal,
														step: index + 1,
													})
											"
										></UserPicker>
									</v-col>
									<!-- hidden -->
									<v-col cols="3" v-if="false">
										<fieldset
											class="talentics-fieldset talentics-fieldset-paddings"
											style="padding-top: 0; padding-bottom: 5px"
										>
											<legend>
												{{ $t('label.mandatory') }}:
												{{
													approver.is_mandatory
														? $t('label.yes')
														: $t('label.no')
												}}
											</legend>
											<div style="margin-top: -4px">
												<v-switch
													v-model="approver.is_mandatory"
													color="success"
													class="v-input--template-switch"
													hide-details
													inset
													dense
													@change="
														() =>
															handlePropChange({
																propKey: 'is_mandatory',
															})
													"
												></v-switch>
											</div>
										</fieldset>
									</v-col>
									<v-col cols="3">
										<v-btn
											block
											@click="
												() =>
													handleDeleteUserFromStep({
														step: index,
														userIndex: approverIndex,
													})
											"
											:disabled="
												json_schema.length === 1 && item.approvers.length === 1
											"
										>
											<v-icon left>person_remove</v-icon>
											{{ $t('button.delete') }}
										</v-btn>
									</v-col>
								</v-row>
							</div>
						</div>
						<v-row v-if="isEditing">
							<v-col>
								<v-btn
									@click="
										() =>
											handleAddNewUserToStep({
												step: index,
											})
									"
									><v-icon left>person_add</v-icon>
									{{
										$t('button.add-new-user-to-step-no', {
											stepNo: index + 1,
										})
									}}</v-btn
								>
							</v-col>
						</v-row>
					</fieldset>
					<div
						:style="`text-align: center; padding-top: ${
							mode === 'mini-preview' ? 0.25 : 1
						}rem; padding-bottom: ${mode === 'mini-preview' ? 0.25 : 1}rem;`"
						v-if="index < json_schema.length - 1"
					>
						<v-icon
							:style="`font-size: ${mode === 'mini-preview' ? 1.5 : 3}rem;`"
							>arrow_circle_down</v-icon
						>
					</div>
				</div>
				<div style="margin-top: 1rem" v-if="isEditing">
					<v-btn @click="() => handleAddNewStep({ user_id: null })"
						><v-icon left>add</v-icon> {{ $t('button.add-new-step') }}</v-btn
					>
				</div>
			</div>
		</fieldset>
		<div
			class="approval-flow-overlay"
			v-if="mode === 'mini-preview'"
			@click="() => onMiniPreviewClick({ requisition_id: requisitionId })"
		></div>

		<UserApprovalRequestDialog
			v-if="isUserApprovalRequestDialogVisible"
			:approvalRequestId="approvalRequestIdToShow"
			:onClose="handleUserApprovalRequestDialogClose"
		></UserApprovalRequestDialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import ApprovalFlowUserChip from './approvalFlowUserChip/ApprovalFlowUserChip';
import UserApprovalRequestDialog from './userApprovalRequestDialog/UserApprovalRequestDialog';
import api from '@/api';

export default {
	name: 'ApprovalFlow',
	computed: {
		...mapState({
			users: state => state.users.items,
			company: state => state.company.company,
		}),
	},
	components: {
		UserPicker,
		ApprovalFlowUserChip,
		UserApprovalRequestDialog,
	},
	props: {
		value: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		mode: {
			type: String,
			required: false,
			default() {
				return 'edit';
			},
		},
		onMiniPreviewClick: {
			type: Function,
			required: false,
			default() {
				return () => {};
			},
		},
		requisitionId: {
			type: Number,
			required: false,
			default() {
				return null;
			},
		},
		userApprovalRequests: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onRefreshData: {
			type: Function,
			required: false,
			default() {
				return () => {};
			},
		},
		consistentEditing: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		status: {
			type: String,
			required: false,
		},
	},
	data() {
		const { consistentEditing } = this;
		return {
			json_schema: [],
			isUserApprovalRequestDialogVisible: false,
			approvalRequestIdToShow: null,
			isEditing: consistentEditing,
		};
	},
	methods: {
		handleAddNewStep({
			user_id = null,
			is_mandatory = true,
			checkUserReportsTo = false,
			step,
		}) {
			const json_schema = [
				...this.json_schema,
				{
					approvers: [{ user_id, is_mandatory }],
				},
			];

			this.$emit('input', json_schema);

			if (checkUserReportsTo) {
				setTimeout(
					function () {
						this.handlePropChange({
							propKey: 'user_id',
							newVal: user_id,
							step,
						});
					}.bind(this),
					50,
				);
			}
		},
		handleAddNewUserToStep({ step }) {
			const json_schema = this.json_schema.map((item, itemIndex) => {
				if (itemIndex !== step) {
					return item;
				}
				return {
					...item,
					approvers: [
						...(item.approvers ? item.approvers : []),
						{ user_id: null, is_mandatory: true },
					],
				};
			});

			this.$emit('input', json_schema);
		},
		handleDeleteUserFromStep({ step, userIndex }) {
			let json_schema = this.json_schema
				.map((item, itemIndex) => {
					if (itemIndex !== step) {
						return item;
					}
					return {
						...item,
						approvers: item.approvers.filter(
							(item, itemIndex) => itemIndex !== userIndex,
						),
					};
				})
				.filter(item => {
					const { approvers } = item;
					return approvers.length > 0;
				});

			this.$emit('input', json_schema);
		},
		handlePropChange({ propKey, newVal, step }) {
			setTimeout(
				function () {
					this.$emit('input', this.json_schema);

					if (
						propKey === 'user_id' &&
						newVal &&
						this.json_schema.length === step &&
						this.company.user_has_reports_to_prop
					) {
						api.users.get({ id: newVal }).then(response => {
							const { items } = response.data;
							if (items.length > 0) {
								const { reports_to_user_id } = items[0];
								if (reports_to_user_id) {
									this.handleAddNewStep({
										user_id: reports_to_user_id,
										checkUserReportsTo: true,
										step: step + 1,
									});
								}
							}
						});
					}
				}.bind(this),
				50,
			);
		},
		handleUserChipClick({ approval_request_id }) {
			if (approval_request_id) {
				this.approvalRequestIdToShow = approval_request_id;
				this.isUserApprovalRequestDialogVisible = true;
			}
		},
		handleUserApprovalRequestDialogClose({ refresh }) {
			this.isUserApprovalRequestDialogVisible = false;

			if (refresh) {
				this.onRefreshData();
			}
		},
		handleToggleEdit() {
			this.isEditing = !this.isEditing;
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					this.json_schema = JSON.parse(JSON.stringify(newVal));
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
.approval-flow-overlay {
	position: absolute;
	top: 8px;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: pointer;
}

.approval-flow-overlay:hover {
	background-color: rgba(255, 255, 255, 0.2);
}
</style>
