<template>
	<v-dialog
		style="background-color: white !important"
		v-model="dialog"
		width="80vw"
		@click:outside="onClose"
		@keydown.esc="onClose"
	>
		<v-card variant="tonal" height="70vh">
			<v-card-title>{{ $t('label.assessment-report') }}</v-card-title>
			<iframe
				style="width: 100%; height: calc(100% - 110px)"
				:src="reportUrl"
			></iframe>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'AssessmentReport',
	props: {
		dialog: {
			type: Boolean,
			required: true,
		},
		onClose: {
			type: Function,
			required: true,
			default() {
				return false;
			},
		},
		assessment: {
			type: Object,
			required: true,
		},
		moduleId: {
			type: Number,
			required: false,
		},
	},
	data() {
		return {
			reportUrl: null,
		};
	},
	unmounted() {
		this.moduleId = null;
		this.assessment = null;
	},
	mounted() {
		if (this.assessment.spica_report_path && !this.moduleId) {
			this.reportUrl = this.assessment.spica_report_path;
		} else {
			const { candidateId } = this.$route.params;
			this.$store
				.dispatch('assessments/getAssessmentReport', {
					candidateId,
					spicaLinkId: this.assessment.spica_link_id,
					moduleId: this.moduleId,
				})
				.then(({ data: { data } }) => {
					this.reportUrl = data.spica_report_path;
				});
		}
	},
};
</script>
