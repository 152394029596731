<template>
	<div
		class="job-container"
		:style="`background: ${isDark ? '#424242' : '#eeeeee'};`"
	>
		<div
			class="job-header"
			:style="`background: ${isDark ? '#121212' : 'white'};`"
		>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn icon @click="onClose" v-on="on">
						<v-icon>arrow_back</v-icon>
					</v-btn>
				</template>
				<span v-if="isSearch">{{ $t('tooltip.back') }}</span>
				<span v-else>{{ $t('tooltip.back-to-jobs') }}</span>
			</v-tooltip>

			<v-tooltip bottom v-if="method === 'edit'">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="handleRefresh" v-on="on">
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>

			<h3 style="margin-left: 0.5rem">{{ job.name }}</h3>

			<v-tooltip v-if="job.identifier" bottom>
				<template v-slot:activator="{ on }">
					<div v-on="on">
						<p
							@click="handleCopyJobCodeToClipboard"
							class="job-identifier-text fw-light fs-6 mb-0 ml-1"
						>
							{{ `(${job.identifier})` }}
						</p>
					</div>
				</template>
				{{ $t('button.copy-job-identifier') }}
			</v-tooltip>

			<v-spacer></v-spacer>

			<v-btn
				@click="handleDuplicateJobPostClick"
				v-if="method === 'edit'"
				style="margin-right: 0.5rem"
			>
				<v-icon left>content_copy</v-icon>
				{{ $t('button.duplicate-job-post') }}
			</v-btn>

			<v-btn
				:to="`/${currentLang}/candidates?job_id=${job.id}`"
				target="_blank"
				v-if="method === 'edit'"
				style="margin-right: 0.5rem"
			>
				<v-icon left>open_in_new</v-icon>
				{{ $t('button.see-candidates') }}
			</v-btn>

			<v-btn @click="handleSubmit" color="primary">
				{{ $t('button.save') }} &amp; {{ $t('button.close') }}
			</v-btn>
		</div>

		<v-divider></v-divider>

		<div class="job-body">
			<ValidationObserver ref="form" tag="form" @submit="handleSubmit">
				<v-container fluid style="max-width: 1785px">
					<v-row>
						<v-col cols="12" sm="12" md="8" lg="8" xl="9">
							<v-card>
								<v-card-title class="pa-0">
									<v-toolbar flat>
										<v-tabs
											v-model="tab"
											align-with-title
											slider-size="3"
											slider-color="primary"
										>
											<v-tab>
												{{ $t('tab.posting') }}
											</v-tab>
											<v-tab>
												{{ $t('tab.application-form') }}
											</v-tab>
										</v-tabs>
										<v-spacer></v-spacer>
									</v-toolbar>
								</v-card-title>
								<v-divider></v-divider>
								<v-card-text>
									<v-tabs-items v-model="tab">
										<v-tab-item>
											<v-container>
												<v-row>
													<v-col>
														<ValidationProvider
															rules="required"
															v-slot="{ errors }"
														>
															<v-text-field
																v-model="job.name"
																outlined
																:label="$t('label.title')"
																:error-messages="errors"
																hide-details
																dense
															></v-text-field>
														</ValidationProvider>
													</v-col>
												</v-row>
												<v-row>
													<v-col cols="12" md="4">
														<ValidationProvider
															rules="required"
															v-slot="{ errors }"
														>
															<v-autocomplete
																v-model="job.location"
																:items="
																	locations.map(item => ({
																		value: item.id,
																		text: item.name,
																		disabled: !item.active,
																	}))
																"
																item-text="text"
																item-value="value"
																item-disabled="disabled"
																hide-no-data
																outlined
																:label="$t('label.location')"
																:error-messages="errors"
																hide-details
																dense
															></v-autocomplete>
														</ValidationProvider>
													</v-col>
													<v-col cols="12" md="4">
														<TeamPicker
															v-model="job.team_id"
															:label="$t('label.department-team')"
															dense
															returnObject
															@input="handleTeamChange"
															rules="required"
														></TeamPicker>
													</v-col>
													<v-col cols="12" md="4">
														<ValidationProvider
															rules="required"
															v-slot="{ errors }"
														>
															<v-autocomplete
																v-model="job.work_type"
																:items="
																	workTypes.map(item => ({
																		value: item.id,
																		text: item.name,
																		disabled: !item.active,
																	}))
																"
																item-text="text"
																item-value="value"
																item-disabled="disabled"
																hide-no-data
																outlined
																:label="$t('label.work-type')"
																:error-messages="errors"
																hide-details
																dense
															></v-autocomplete>
														</ValidationProvider>
													</v-col>
												</v-row>
												<v-row>
													<v-col>
														<div>
															<label
																class="v-label v-label--active"
																style="font-size: 12px"
																>{{ $t('label.description') }}</label
															>
															<RichEditor
																v-model="job.description"
																refName="description"
															></RichEditor>
														</div>
													</v-col>
												</v-row>
												<v-row>
													<v-col>
														<div>
															<label
																class="v-label v-label--active"
																style="font-size: 12px"
																>{{ $t('label.closing') }}</label
															>
															<RichEditor
																v-model="job.closing"
																refName="closing"
															></RichEditor>
														</div>
													</v-col>
												</v-row>
												<v-row>
													<v-col>
														<div>
															<label
																class="v-label v-label--active"
																style="font-size: 12px"
																>{{ $t('label.internal-notes') }}</label
															>
															<RichEditor
																v-model="job.internal_notes"
																refName="internal"
															></RichEditor>
														</div>
													</v-col>
												</v-row>
												<v-row v-if="method === 'edit'">
													<v-col>
														<fieldset class="talentics-fieldset">
															<legend>
																{{ $t('label.copy-job-url') }}
															</legend>
															<CopyJobUrl
																:job="job"
																:onClickCopy="handleClickCopy"
															></CopyJobUrl>
														</fieldset>
														<!-- do not remove line above -->
														<div id="copyArea"></div>
													</v-col>
												</v-row>
											</v-container>
										</v-tab-item>
										<v-tab-item>
											<v-container>
												<v-row>
													<v-col>
														<AdditionalApplicationFormQuestions
															v-model="job.additional_questions"
															:label="$t('label.job-specific-questions')"
														></AdditionalApplicationFormQuestions>
													</v-col>
												</v-row>
											</v-container>
										</v-tab-item>
									</v-tabs-items>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12" sm="12" md="4" lg="4" xl="3">
							<v-card>
								<v-container>
									<v-row>
										<v-col>
											<v-select
												v-model="job.status"
												:label="$t('label.status')"
												:items="filterStatusOptions(job.status)"
												item-value="value"
												:item-text="status => $t(`status.${status.text}`)"
												outlined
												hide-details
												dense
											>
												<template v-slot:prepend-inner>
													<v-icon
														:color="
															job.status === 'draft'
																? 'orange'
																: job.status === 'open'
																? 'green'
																: 'red'
														"
														>fiber_manual_record</v-icon
													>
												</template>
											</v-select>
											<p v-if="job.status === 'closed'">
												{{ $t('text.you-cannot-reopen-closed-job-post') }}
											</p>
										</v-col>
									</v-row>
								</v-container>

								<v-divider></v-divider>

								<v-list v-if="job.status === 'open'">
									<v-list-item>
										<v-list-item-action>
											<v-switch
												v-model="job.list_on_external"
												color="success"
												inset
											></v-switch>
										</v-list-item-action>
										<v-list-item-title>{{
											$t('text.list-external-job')
										}}</v-list-item-title>
									</v-list-item>
								</v-list>

								<v-divider v-if="job.status === 'open'"></v-divider>

								<v-container>
									<v-row>
										<v-col>
											<UserPicker
												v-model="job.owner"
												:users="users"
												:label="$t('label.posting-owner')"
												rules="required"
												@input="handleMandatoryUserIdsChange"
												dense
											></UserPicker>
										</v-col>
									</v-row>
									<v-row
										v-if="
											job.owner && initialOwner && job.owner !== initialOwner
										"
									>
										<v-col>
											<v-alert outlined type="error" style="margin-bottom: 0">
												<v-radio-group
													v-model="job.update_candidate_owners"
													dense
													hide-details
													style="margin-top: 0; padding-top: 0"
												>
													<template v-slot:label>
														<div>
															Do you want to update the current candidates with
															the new owner?
														</div>
													</template>
													<v-radio :value="true">
														<template v-slot:label>
															<div>
																Update to
																{{
																	getUserName({
																		userId: job.owner,
																	})
																}}
															</div>
														</template>
													</v-radio>
													<v-radio :value="false">
														<template v-slot:label>
															<div>
																No keep
																{{
																	getUserName({
																		userId: initialOwner,
																	})
																}}
															</div>
														</template>
													</v-radio>
												</v-radio-group>
												<v-divider style="margin: 0.5rem 0"></v-divider>
												<div>
													{{
														getUserName({
															userId: job.owner,
														})
													}}
													will become the owner for all new candidates going
													forward.
												</div>
											</v-alert>
										</v-col>
									</v-row>
									<v-row>
										<v-col>
											<UserPicker
												v-model="job.hiring_manager"
												:users="users"
												:label="$t('label.hiring-manager')"
												rules="required"
												@input="handleMandatoryUserIdsChange"
												dense
											></UserPicker>
										</v-col>
									</v-row>
									<v-row>
										<v-col>
											<UserPicker
												v-model="job.access_users"
												:users="accessPickerUsers"
												:label="$t('label.access')"
												multiple
												dense
											></UserPicker>
										</v-col>
									</v-row>
								</v-container>
								<v-container v-if="job.requisitions.length > 0">
									<v-row
										v-for="(requisition, index) in job.requisitions"
										:key="`requisitionRow_${index}`"
									>
										<v-col>
											<ApprovalFlow
												v-model="requisition.approval_flow_json_schema"
												mode="mini-preview"
												:requisitionId="requisition.id"
												:userApprovalRequests="
													requisition.user_approval_requests
												"
												:onMiniPreviewClick="handleApprovalFlowClick"
												:status="requisition.status"
											></ApprovalFlow>
										</v-col>
									</v-row>
								</v-container>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</ValidationObserver>
			<v-snackbar
				v-model="snackbar.isVisible"
				:timeout="snackbar.timeout"
				left
				bottom
			>
				{{ snackbar.text }}
				<template v-slot:action="{ attrs }">
					<v-btn
						dark
						text
						icon
						v-bind="attrs"
						@click="snackbar.isVisible = false"
						style="margin-right: 0.5rem"
					>
						<v-icon>close</v-icon>
					</v-btn>
				</template>
			</v-snackbar>
		</div>
		<v-dialog v-model="showDuplicateDialog" max-width="600" persistent>
			<v-card>
				<v-card-title>{{ $t('text.duplicate-job-post') }}</v-card-title>
				<v-card-text>
					<p>{{ $t('text.are-you-sure-to-duplicate-job-post') }}</p>
				</v-card-text>
				<v-card-actions>
					<v-btn @click="onDuplicateJobPost" color="primary" class="ml-auto">{{
						$t('button.yes')
					}}</v-btn>
					<v-btn @click="showDuplicateDialog = false">{{
						$t('button.no')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<router-view></router-view>
	</div>
</template>

<script>
// import { format } from 'date-fns';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import AdditionalApplicationFormQuestions from '@/components/shared/additionalApplicationFormQuestions/AdditionalApplicationFormQuestions';
import TeamPicker from '@/components/shared/teamPicker/TeamPicker';
import ApprovalFlow from '@/components/shared/approvalFlow/ApprovalFlow';
import CopyJobUrl from './copyJobUrl/CopyJobUrl';
// import api from '@/api';

export default {
	name: 'JobDialog',
	props: {
		jobId: {
			type: [Number, String],
			required: true,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
		RichEditor,
		UserPicker,
		AdditionalApplicationFormQuestions,
		TeamPicker,
		ApprovalFlow,
		CopyJobUrl,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			locations: state => state.locations.items,
			workTypes: state => state.workTypes.items,
			isDark: state => state.currentUser.isDark,
			currentLang: state => state.currentUser.lang,
			itemNeedsToRefresh: state => state.jobs.itemNeedsToRefresh,
			ogPreferences: state => state.ogPreferences.items,
			knownHosts: state => state.knownHosts.items,
		}),
		accessPickerUsers() {
			const { owner, hiring_manager } = this.job;
			return this.users.map(user => ({
				...user,
				disabled: [owner, hiring_manager].indexOf(user.id) > -1,
			}));
		},
	},
	data() {
		const { is_search } = this.$route.query;
		return {
			showDuplicateDialog: false,
			tab: null,
			job: {
				name: '',
				location: null,
				status: null,
				team_id: null,
				work_type: null,
				department_id: null,
				hiring_manager: null,
				owner: null,
				description: null,
				closing: null,
				internal_notes: null,
				list_on_external: false,
				access_users: [],
				approval_requests: [],
				additional_questions: [],
				requisitions: [],
			},
			method: null,
			statusMenu: false,
			initialOwner: null,
			isSearch: is_search,
			isRequisitionDialogVisible: false,
			hostname: null,
			snackbar: {
				isVisible: false,
				timeout: 3000,
				text: '',
			},
		};
	},
	methods: {
		handleCopyJobCodeToClipboard() {
			const {
				job: { identifier },
			} = this;

			navigator.clipboard.writeText(identifier).then(
				() => {
					this.snackbar = {
						isVisible: true,
						timeout: 3000,
						text: 'Copied!',
					};
				},
				err => {
					console.error('Could not copy text: ', err);
				},
			);
		},
		filterStatusOptions(value) {
			const statusArray = [
				{ text: 'draft', value: 'draft' },
				{ text: 'open', value: 'open' },
				{ text: 'closed', value: 'closed' },
				{ text: 'pending', value: 'pending' },
				{ text: 'archived', value: 'archived' },
			];

			// New Job case
			if (!value || this.$route.path.includes('jobs/new')) {
				return statusArray.filter(item =>
					['open', 'draft', 'pending'].includes(item.value),
				);
			}

			// Edit Job case
			if (value === 'open') {
				return statusArray.filter(
					item => item.value === 'closed' || item.value === value,
				);
			} else if (value === 'closed') {
				return statusArray.filter(
					item => item.value === 'archived' || item.value === value,
				);
			} else if (value === 'archived') {
				return [{ text: 'archived', value: 'archived' }];
			} else {
				return statusArray; // return the original array if the value doesn't match any condition
			}
		},
		handleTeamChange(team) {
			if (
				!team ||
				(team && typeof team === 'object' && Object.keys(team).length === 0)
			)
				return;

			const { team_id, department_id } = team;
			this.job = {
				...this.job,
				team_id,
				department_id,
			};
		},
		handleRefresh() {
			const { jobId } = this;
			this.getJob({ id: jobId });
			this.$store.dispatch('userApprovalRequests/get', {
				updateStore: true,
				refresh: true,
			});
		},
		getJob({ id }) {
			this.$store.dispatch('jobs/get', { id, refresh: true }).then(response => {
				const { items } = response.data;
				if (items.length > 0) {
					const { owner } = items[0];
					this.initialOwner = owner;
					this.job = {
						...items[0],
						update_candidate_owners: false,
					};
				}
			});
		},
		setJobStatus({ status }) {
			this.job = {
				...this.job,
				status,
			};
		},
		handleDuplicateJobPostClick() {
			this.showDuplicateDialog = true;
		},
		onDuplicateJobPost() {
			const { job } = this;
			const jobPost = Object.assign({}, job);

			jobPost.status = 'draft';
			jobPost.list_on_external = false;
			jobPost.name = jobPost.name + ' [DUPLICATE]';

			this.$store
				.dispatch('jobs/post', {
					items: [jobPost],
				})
				.then(newJob => {
					this.showDuplicateDialog = false;
					this.onClose();
					this.$router.push({
						path: `/${this.currentLang}/jobs/edit/${newJob.data.items[0].id}`,
					});
				});
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form.validate();
			if (isValid) {
				const { method } = this;

				const og_preference =
					this.ogPreferences.length > 0
						? {
								font_size: this.ogPreferences[0].font_size,
								font_name: this.ogPreferences[0].font_name,
								font_color: this.ogPreferences[0].font_color,
								background_color: this.ogPreferences[0].background_color,
								action_text: this.ogPreferences[0].action_text,
								button_color: this.ogPreferences[0].button_color,
								button_text_color: this.ogPreferences[0].button_text_color,
								logo_url: this.ogPreferences[0].logo_url,
								background_image: this.ogPreferences[0].background_image,
						  }
						: null;

				if (method === 'edit') {
					this.$store
						.dispatch('jobs/put', {
							items: [
								{
									...this.job,
									og_preference,
									regenerate_og_image: Boolean(og_preference),
								},
							],
						})
						.then(() => {
							this.onClose();
						});
				} else if (method === 'add') {
					this.$store
						.dispatch('jobs/post', {
							items: [
								{
									...this.job,
									og_preference,
								},
							],
						})
						.then(() => {
							this.onClose();
						});
				}
			}
		},
		handleMandatoryUserIdsChange(userId) {
			const { access_users } = this.job;
			const userHasNoAccess = access_users.indexOf(userId) === -1;
			if (userHasNoAccess && userId) {
				this.job = {
					...this.job,
					access_users: [userId, ...(access_users ? access_users : [])],
				};
			}
		},
		getUserName({ userId }) {
			const { users } = this;
			const user = users.find(item => item.id === userId);
			if (user) {
				const { name, email } = user;
				if (name) {
					return name;
				}
				return email;
			}
			return '-';
		},
		handleApprovalFlowClick({ requisition_id }) {
			const { currentLang, job } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/jobs/edit/${job.id}/requisitions/${requisition_id}`,
				query,
			});
		},
		handleClickCopy({ text }) {
			var textArea = document.createElement('textarea');
			textArea.style.position = 'fixed';
			textArea.style.top = 0;
			textArea.style.left = 0;
			textArea.style.width = '2em';
			textArea.style.height = '2em';
			textArea.style.padding = 0;
			textArea.style.border = 'none';
			textArea.style.outline = 'none';
			textArea.style.boxShadow = 'none';
			textArea.style.background = 'transparent';
			textArea.value = text;
			document.getElementById('copyArea').appendChild(textArea);
			textArea.focus();
			textArea.select();
			try {
				var success = document.execCommand('copy');
				if (success) {
					this.snackbar = {
						isVisible: true,
						timeout: 3000,
						text: 'Copied!',
					};
				}
			} catch (err) {
				console.log('Oops, unable to copy');
			}
			document.getElementById('copyArea').removeChild(textArea);
		},
	},
	mounted() {
		this.$store.dispatch('knownHosts/get', { updateStore: true });
	},
	watch: {
		jobId: {
			handler: function (newVal) {
				if (newVal > -1) {
					this.getJob({ id: newVal });
					this.method = 'edit';
				} else {
					this.method = 'add';
					this.job = {
						...this.job,
						status: 'open',
					};
				}
			},
			deep: true,
			immediate: true,
		},
		'$route.query.is_search': {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.isSearch = Boolean(newVal);
				}
			},
			deep: true,
			immediate: true,
		},
		itemNeedsToRefresh: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					const { id } = this.job;
					if (id) {
						this.getJob({ id });
					}
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>

<style lang="scss">
.job-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;

	.job-header {
		padding: 0.5rem;
		display: flex;
		align-items: center;

		h3 {
			font-weight: normal;
			line-height: 36px;
		}

		.job-identifier-text {
			font-size: 0.8rem;
			margin-left: 0.5rem;
			color: rgba(0, 0, 0, 0.32);
			cursor: copy;

			&:hover {
				color: rgba(0, 0, 0, 0.76);
			}
		}
	}

	.job-body {
		position: absolute;
		top: 53px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow: auto;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
}
</style>
