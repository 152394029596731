var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":"","bottom":"","left":"","max-height":500,"max-width":500},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-badge',{attrs:{"color":"pink","overlap":"","bottom":"","content":_vm.unseenNotificationsCounter,"value":_vm.unseenNotificationsCounter}},[_c('v-icon',[_vm._v("notifications")])],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(!_vm.menu ? _vm.$t('tooltip.notifications') : '')+" ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-list',[(_vm.notifications.length > 0)?_c('v-list-item-group',[_vm._l((_vm.notifications),function(notification,index){return [_c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleClick(notification)}}},[_c('v-list-item-avatar',[_c('Avatar',{attrs:{"userId":notification.created_by_id}})],1),_c('v-list-item-content',[(notification.type === 'new_feedback')?_c('v-list-item-title',{staticStyle:{"white-space":"normal"},domProps:{"innerHTML":_vm._s(
								_vm.$t('notifications.has-new-feedback', {
									candidate: notification.candidate_name,
								})
							)}}):_vm._e(),(notification.type === 'new_form')?_c('v-list-item-title',{staticStyle:{"white-space":"normal"},domProps:{"innerHTML":_vm._s(
								_vm.$t('notifications.has-new-form', {
									candidate: notification.candidate_name,
								})
							)}}):_vm._e(),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
								_vm.formatDate({
									timestamp: notification.created,
								})
							)}})],1)],1),(index < _vm.notifications.length - 1)?_c('v-divider',{key:`divider_${index}`}):_vm._e()]})],2):_c('v-list-item',{attrs:{"disabled":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('text.no-new-notifications')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }