var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.activities.length > 0)?_c('div',[_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.activities),function(activity,index){return _c('v-timeline-item',{key:index,attrs:{"small":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('Avatar',{attrs:{"userId":activity.user_id}})]},proxy:true}],null,true)},[_c('v-card',{staticClass:"elevation-1"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.formatDate({ timestamp: activity.occured }))+" ")]),(activity.activity_code === 'new_application_by_candidate')?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.new-job-application', {
								candidate: _vm.candidate.name,
							})
						)}}):(activity.activity_code === 'new_application_by_user')?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.added-as-lead-candidate', {
								candidate: _vm.candidate.name,
							})
						)}}):(
							activity.activity_code === 'application_status_change_by_user'
						)?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.application-status-changed', {
								candidate: _vm.candidate.name,
								activity: _vm.$t(
									`pipeline.steps.${
										activity.new_val ? activity.new_val : 'new-applicant'
									}`,
								),
							})
						)}}):(activity.activity_code === 'email_scheduled_by_user')?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.new-scheduled-email', {
								candidate: _vm.candidate.name,
								activity: activity.new_val,
							})
						)}}):(
							activity.activity_code === 'scheduled_email_cancelled_by_user'
						)?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.scheduled-email-cancelled', {
								candidate: _vm.candidate.name,
								activity: activity.new_val,
							})
						)}}):(activity.activity_code === 'user_viewed_candidate')?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.user-viewed-candidate', {
								candidate: _vm.candidate.name,
								user: activity.user_name,
							})
						)}}):(activity.activity_code === 'feedback_given_by_user')?_c('v-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(
								_vm.$t('text.has-new-feedback', {
									candidate: _vm.candidate.name,
								})
							)}}),(activity.new_val_int === 4)?_c('strong',{staticStyle:{"color":"#2e7d32"}},[_c('v-icon',{staticStyle:{"color":"#2e7d32"}},[_vm._v("thumb_up")]),_vm._v(" "+_vm._s(_vm.$t('text.strong-yes'))+" ")],1):(activity.new_val_int === 3)?_c('strong',{staticStyle:{"color":"#66bb6a"}},[_c('v-icon',{staticStyle:{"color":"#66bb6a"}},[_vm._v("thumb_up")]),_vm._v(" "+_vm._s(_vm.$t('text.yes'))+" ")],1):(activity.new_val_int === 2)?_c('strong',{staticStyle:{"color":"#f44336"}},[_c('v-icon',{staticStyle:{"color":"#f44336"}},[_vm._v("thumb_down")]),_vm._v(" "+_vm._s(_vm.$t('text.no'))+" ")],1):(activity.new_val_int === 1)?_c('strong',{staticStyle:{"color":"#b71c1c"}},[_c('v-icon',{staticStyle:{"color":"#b71c1c"}},[_vm._v("thumb_down")]),_vm._v(" "+_vm._s(_vm.$t('text.strong-no'))+" ")],1):_vm._e()]):(activity.activity_code === 'feedback_updated_by_user')?_c('v-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(
								_vm.$t('text.existing-feedback-updated', {
									candidate: _vm.candidate.name,
								})
							)}}),(activity.new_val_int === 4)?_c('strong',{staticStyle:{"color":"#2e7d32"}},[_c('v-icon',{staticStyle:{"color":"#2e7d32"}},[_vm._v("thumb_up")]),_vm._v(" "+_vm._s(_vm.$t('text.strong-yes'))+" ")],1):(activity.new_val_int === 3)?_c('strong',{staticStyle:{"color":"#66bb6a"}},[_c('v-icon',{staticStyle:{"color":"#66bb6a"}},[_vm._v("thumb_up")]),_vm._v(" "+_vm._s(_vm.$t('text.yes'))+" ")],1):(activity.new_val_int === 2)?_c('strong',{staticStyle:{"color":"#f44336"}},[_c('v-icon',{staticStyle:{"color":"#f44336"}},[_vm._v("thumb_down")]),_vm._v(" "+_vm._s(_vm.$t('text.no'))+" ")],1):(activity.new_val_int === 1)?_c('strong',{staticStyle:{"color":"#b71c1c"}},[_c('v-icon',{staticStyle:{"color":"#b71c1c"}},[_vm._v("thumb_down")]),_vm._v(" "+_vm._s(_vm.$t('text.strong-no'))+" ")],1):_vm._e()]):(
							activity.activity_code === 'application_job_changed_by_user'
						)?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.application-job-changed-by-user', {
								candidate: _vm.candidate.name,
							})
						)}}):(activity.activity_code === 'form_filled_by_user')?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.has-new-form', {
								candidate: _vm.candidate.name,
							})
						)}}):(activity.activity_code === 'user_pinned_candidate')?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.user-starred-candidate', {
								candidate: _vm.candidate.name,
								user: activity.user_name,
							})
						)}}):(activity.activity_code === 'user_unpinned_candidate')?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
							_vm.$t('text.user-unstarred-candidate', {
								candidate: _vm.candidate.name,
								user: activity.user_name,
							})
						)}}):_c('v-card-text',[_vm._v(" "+_vm._s(activity.activity_code)+" ")])],1)],1)}),1)],1):_c('div',{staticStyle:{"margin-top":"1rem"}},[_vm._v(" "+_vm._s(_vm.$t('err.no-activities-found'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }