import api from '@/api';

const state = {
	items: [],
	updated: 0,
};

const getters = {};

const actions = {
	get: ({ commit }, { refresh = false, updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			const { updated } = state;
			if (!refresh && updated > 0) {
				resolve({ data: { items: state.items } });
			}
			api.predefinedPositionPresets
				.get(params)
				.then(response => {
					const { items } = response.data;
					if (updateStore) {
						commit('setItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	post: ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			api.predefinedPositionPresets
				.post(params)
				.then(response => {
					const { items } = response.data;
					commit('addItems', { items });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	put: ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			api.predefinedPositionPresets
				.put(params)
				.then(response => {
					const { items } = response.data;
					commit('updateItems', { items });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	delete: ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			api.predefinedPositionPresets
				.delete(params)
				.then(response => {
					const { items } = response.data;
					commit('deleteItems', { items });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setItems: (state, { items }) => {
		const now = new Date();
		state.items = items;
		state.updated = now.getTime();
	},
	updateItems: (state, { items }) => {
		state.items = state.items.map(stateItem => {
			const itemMatches = items.find(({ id }) => stateItem.id === id);
			return itemMatches ? itemMatches : stateItem;
		});
	},
	addItems: (state, { items }) => {
		const now = new Date();
		state.items = [...state.items, ...items];
		state.updated = now.getTime();
	},
	deleteItems: (state, { items }) => {
		const now = new Date();
		const deletedItemIds = items.map(({ id }) => id);
		state.items = state.items.filter(
			item => deletedItemIds.indexOf(item.id) === -1,
		);
		state.updated = now.getTime();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
