var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('fieldset',{staticClass:"talentics-fieldset talentics-fieldset-paddings"},[_c('legend',[_vm._v(" "+_vm._s(_vm.$t('label.approval-flow'))+" "),(_vm.mode === 'mini-preview' && _vm.status && _vm.status === 'draft')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("("+_vm._s(_vm.$t(`status.${_vm.status}`))+")")]):_vm._e(),(_vm.mode === 'edit' && !_vm.consistentEditing)?_c('span',[_vm._v(" ("),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.handleToggleEdit}},[(_vm.isEditing)?_c('span',[_vm._v(_vm._s(_vm.$t('button.close-editing')))]):_c('span',[_vm._v(_vm._s(_vm.$t('button.edit')))])]),_vm._v(") ")]):_vm._e()]),_c('div',{staticStyle:{"margin-top":"0.5rem"}},[(_vm.json_schema.length === 0)?_c('div',[_c('v-btn',{attrs:{"small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("open_in_new")]),_vm._v(" "+_vm._s(_vm.$t('status.draft'))+" ")],1)],1):_vm._e(),_vm._l((_vm.json_schema),function(item,index){return _c('div',{key:`item_${index}`},[_c('fieldset',{staticClass:"talentics-fieldset talentics-fieldset-paddings"},[_c('legend',[_vm._v(" "+_vm._s(_vm.$t('label.step-no', { stepNo: index + 1 }))+" ")]),(
							_vm.mode === 'preview' ||
							_vm.mode === 'mini-preview' ||
							(_vm.mode === 'edit' && !_vm.isEditing)
						)?_c('v-row',[_c('v-col',_vm._l((item.approvers),function(approver,index){return _c('ApprovalFlowUserChip',{key:`approver_${index}`,attrs:{"userId":approver.user_id,"status":approver.status,"approvalRequestId":approver.approval_request_id,"approvalRequest":_vm.userApprovalRequests.find(
										userApprovalRequests =>
											userApprovalRequests.id ===
											approver.approval_request_id,
									),"onClick":_vm.handleUserChipClick}})}),1)],1):_vm._e(),(_vm.isEditing)?_c('div',_vm._l((item.approvers),function(approver,approverIndex){return _c('div',{key:`approver_${index}_${approverIndex}`},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('UserPicker',{attrs:{"users":_vm.users.filter(item => item.active),"label":_vm.$t('label.user'),"dense":"","rules":"required"},on:{"input":newVal =>
												_vm.handlePropChange({
													propKey: 'user_id',
													newVal,
													step: index + 1,
												})},model:{value:(approver.user_id),callback:function ($$v) {_vm.$set(approver, "user_id", $$v)},expression:"approver.user_id"}})],1),(false)?_c('v-col',{attrs:{"cols":"3"}},[_c('fieldset',{staticClass:"talentics-fieldset talentics-fieldset-paddings",staticStyle:{"padding-top":"0","padding-bottom":"5px"}},[_c('legend',[_vm._v(" "+_vm._s(_vm.$t('label.mandatory'))+": "+_vm._s(approver.is_mandatory ? _vm.$t('label.yes') : _vm.$t('label.no'))+" ")]),_c('div',{staticStyle:{"margin-top":"-4px"}},[_c('v-switch',{staticClass:"v-input--template-switch",attrs:{"color":"success","hide-details":"","inset":"","dense":""},on:{"change":() =>
														_vm.handlePropChange({
															propKey: 'is_mandatory',
														})},model:{value:(approver.is_mandatory),callback:function ($$v) {_vm.$set(approver, "is_mandatory", $$v)},expression:"approver.is_mandatory"}})],1)])]):_vm._e(),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"block":"","disabled":_vm.json_schema.length === 1 && item.approvers.length === 1},on:{"click":() =>
												_vm.handleDeleteUserFromStep({
													step: index,
													userIndex: approverIndex,
												})}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("person_remove")]),_vm._v(" "+_vm._s(_vm.$t('button.delete'))+" ")],1)],1)],1)],1)}),0):_vm._e(),(_vm.isEditing)?_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":() =>
										_vm.handleAddNewUserToStep({
											step: index,
										})}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("person_add")]),_vm._v(" "+_vm._s(_vm.$t('button.add-new-user-to-step-no', { stepNo: index + 1, })))],1)],1)],1):_vm._e()],1),(index < _vm.json_schema.length - 1)?_c('div',{style:(`text-align: center; padding-top: ${
						_vm.mode === 'mini-preview' ? 0.25 : 1
					}rem; padding-bottom: ${_vm.mode === 'mini-preview' ? 0.25 : 1}rem;`)},[_c('v-icon',{style:(`font-size: ${_vm.mode === 'mini-preview' ? 1.5 : 3}rem;`)},[_vm._v("arrow_circle_down")])],1):_vm._e()])}),(_vm.isEditing)?_c('div',{staticStyle:{"margin-top":"1rem"}},[_c('v-btn',{on:{"click":() => _vm.handleAddNewStep({ user_id: null })}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('button.add-new-step')))],1)],1):_vm._e()],2)]),(_vm.mode === 'mini-preview')?_c('div',{staticClass:"approval-flow-overlay",on:{"click":() => _vm.onMiniPreviewClick({ requisition_id: _vm.requisitionId })}}):_vm._e(),(_vm.isUserApprovalRequestDialogVisible)?_c('UserApprovalRequestDialog',{attrs:{"approvalRequestId":_vm.approvalRequestIdToShow,"onClose":_vm.handleUserApprovalRequestDialogClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }