<template>
	<v-container fluid fill-height style="padding: 0">
		<SettingsDrawer :isOpened="isDrawerOpened"></SettingsDrawer>
		<v-layout>
			<v-flex>
				<router-view></router-view>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import SettingsDrawer from './settingsDrawer/SettingsDrawer';

export default {
	name: 'Settings',
	components: {
		SettingsDrawer,
	},
	computed: {
		...mapState({
			drawer: state => state.currentRoute.drawer,
		}),
	},
	data() {
		return {
			isDrawerOpened: true,
		};
	},
	created() {
		this.$store.commit('currentRoute/setRouteFirstLevel', {
			routeName: 'settings',
		});
		this.$store.dispatch('users/get', { updateStore: true });
	},
};
</script>
