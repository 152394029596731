<template>
	<div>
		<v-dialog
			scrollable
			:value="isVisible"
			transition="dialog-bottom-transition"
			max-width="600"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title v-if="method === 'put'">
							{{ $t('label.edit-kolay-ik-record-for') }} {{ candidate.name }}
						</v-toolbar-title>
						<v-toolbar-title v-else-if="method === 'post'">
							{{ $t('label.create-kolay-ik-record-for') }} {{ candidate.name }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<ValidationObserver
						ref="edit_form"
						tag="form"
						@submit="handleSubmit"
						id="edit_form"
					>
						<template>
							<v-container fluid>
								<v-row>
									<v-col>
										<ValidationProvider rules="required" v-slot="{ errors }">
											<v-text-field
												v-model="kolay.firstName"
												:label="`${$t('label.kolayik.first-name')}*`"
												hide-no-data
												hide-details
												dense
												outlined
												chips
												deletable-chips
												class="v-select-talentics-chips"
												:error-messages="errors"
											></v-text-field>
										</ValidationProvider>
									</v-col>
									<v-col>
										<ValidationProvider rules="required" v-slot="{ errors }">
											<v-text-field
												v-model="kolay.lastName"
												:label="`${$t('label.kolayik.last-name')}*`"
												hide-no-data
												hide-details
												dense
												outlined
												chips
												deletable-chips
												class="v-select-talentics-chips"
												:error-messages="errors"
											></v-text-field>
										</ValidationProvider>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field
											v-model="kolay.email"
											:label="$t('label.kolayik.email')"
											hide-no-data
											hide-details
											dense
											outlined
											chips
											deletable-chips
											class="v-select-talentics-chips"
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="kolay.workEmail"
											:label="$t('label.kolayik.work-email')"
											hide-no-data
											hide-details
											dense
											outlined
											chips
											deletable-chips
											class="v-select-talentics-chips"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row v-for="unit in units" :key="unit.name">
									<!-- Make 'Birim' unit optional for company with id 78 (Wellbees) -->
									<v-col>
										<ValidationProvider
											:rules="
												(candidate.company_id == 78 && unit.name == 'Birim') ||
												(candidate.company_id == 133 && unit.name == 'Takım')
													? ''
													: 'required'
											"
											v-slot="{ errors }"
										>
											<v-select
												v-model="unit.value"
												:items="
													unit.items.map(o => ({
														text: o.name,
														value: o.name,
													}))
												"
												:label="`
                          ${$t(`label.kolayik.${unit.name}`)}
                          ${
														(candidate.company_id == 78 &&
															unit.name == 'Birim') ||
														(candidate.company_id == 133 &&
															unit.name == 'Takım')
															? ''
															: '*'
													}
                        `"
												outlined
												hide-details
												dense
												:error-messages="errors"
											></v-select>
										</ValidationProvider>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<label
											class="v-label v-label--active"
											style="font-size: 12px"
											>{{ $t('label.kolayik.manager') }}</label
										>
										<Autocomplete
											v-model="manager"
											label=""
											:items="
												managers.length === 0 && manager.id
													? [{ ...manager }]
													: [...managers]
											"
											item-text="fullName"
											item-value="id"
											filled
											dense
											flat
											solo
											outlined
											hide-details
											:onKeyUp="handleManagerSearchInput"
											@change="handleManagerChange"
											:resetValueAfterChange="false"
										></Autocomplete>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field
											v-model="kolay.mobilePhone"
											:label="$t('label.kolayik.phone-personal')"
											outlined
											hide-details
											dense
										></v-text-field>
									</v-col>
									<v-col>
										<v-text-field
											v-model="kolay.workPhone"
											:label="$t('label.kolayik.phone-work')"
											outlined
											hide-details
											dense
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<DatePicker
											v-model="kolay.employmentStartDate"
											@input="handleEmploymentStartDate"
											:label="$t('label.kolayik.employment-start')"
											full-width
											no-title
											reactive
											dense
										></DatePicker>
									</v-col>
									<v-col>
										<DatePicker
											v-model="kolay.birthday"
											@input="handleBirthday"
											:label="$t('label.kolayik.birthday')"
											full-width
											no-title
											reactive
											dense
										></DatePicker>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field
											v-model="kolay.educationalInstitution"
											:label="$t('label.kolayik.education')"
											outlined
											hide-details
											dense
										></v-text-field>
									</v-col>
									<v-col>
										<v-select
											v-model="kolay.educationLevel"
											:items="
												educationLevels.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.education-level')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
									<v-col>
										<v-select
											v-model="kolay.educationStatus"
											:items="
												educationStatuses.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.education-status')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-select
											v-model="kolay.bloodType"
											:items="
												bloodTypes.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.blood-types')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
									<v-col>
										<v-select
											v-model="kolay.accessType"
											:items="
												accessTypes.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.access-types')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-select
											v-model="kolay.contractType"
											:items="
												contractTypes.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.contract-type')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
									<v-col v-if="kolay.contractType === 'definite'">
										<ValidationProvider rules="required" v-slot="{ errors }">
											<DatePicker
												v-model="kolay.contractEndDate"
												@input="handleContractEndDate"
												:label="$t('label.kolayik.contract-end-date')"
												full-width
												no-title
												reactive
												dense
												:error-messages="errors"
											></DatePicker>
										</ValidationProvider>
									</v-col>
									<v-col>
										<v-select
											v-model="kolay.gender"
											:items="
												genders.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.gender')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
								</v-row>
								<v-row v-if="kolay.militaryService === 'delayed'">
									<v-col>
										<DatePicker
											v-model="kolay.militaryServiceDelayDate"
											@input="handleMilitaryServiceDelayDate"
											:label="$t('label.kolayik.military-delay-date')"
											full-width
											no-title
											reactive
											dense
										></DatePicker>
									</v-col>
								</v-row>
								<v-row> </v-row>
								<v-row>
									<v-col>
										<v-select
											v-model="kolay.levelOfDisability"
											:items="
												levelsOfDisability.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.disability-level')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
									<v-col>
										<v-select
											v-model="kolay.maritalStatus"
											:items="maritalStatus"
											:label="$t('label.kolayik.marital-status')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<CountryPicker
											v-model="kolay.nationality"
											:label="$t('label.kolayik.nationality')"
										></CountryPicker>
									</v-col>
									<v-col>
										<v-select
											v-model="kolay.militaryService"
											:items="
												militaryService.map(item => ({
													text: item.text,
													value: item.value,
												}))
											"
											:label="$t('label.kolayik.military-service')"
											outlined
											hide-details
											dense
										></v-select>
									</v-col>
									<v-col v-if="kolay.nationality === 'TR'">
										<v-text-field
											v-model="kolay.idNumber"
											:label="$t('label.kolayik.id-number')"
											hide-no-data
											hide-details
											dense
											outlined
											chips
											deletable-chips
											class="v-select-talentics-chips"
										></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</template>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="() => onClose({ needsRefresh: false })">{{
						$t('button.cancel')
					}}</v-btn>

					<v-btn color="primary" @click="handleSubmit">
						{{ $t('button.send') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import api from '@/api';
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Autocomplete from '@/components/shared/autocomplete/Autocomplete';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import CountryPicker from '@/components/shared/countryPicker/CountryPicker';
import { format } from 'date-fns';

export default {
	name: 'EditKolayIKRecordDialog',
	components: {
		ValidationObserver,
		ValidationProvider,
		Autocomplete,
		DatePicker,
		CountryPicker,
	},
	data() {
		const candidateId = this.candidate.id;
		const { files } = this.candidate;

		return {
			candidateId,
			files: files,
			isVisible: true,
			isHoldingCompany: false,
			units: [],
			manager: {
				id: null,
				fullName: null,
			},
			kolay: {
				firstName: null,
				lastName: null,
				email: null,
				workEmail: null,
				mobilePhone: null,
				workPhone: null,
				employmentStartDate: null,
				birthday: null,
				educationalInstitution: null,
				educationLevel: null,
				educationStatus: null,
				bloodType: null,
				accessType: null,
				contractType: 'indefinite',
				contractEndDate: null,
				gender: null,
				levelOfDisability: 'notDisabled',
				maritalStatus: null,
				nationality: 'TR',
				militaryService: null,
				militaryServiceDelayDate: null,
				idNumber: null,
			},
			managers: [],
			bloodTypes: [
				{ text: 'A+', value: 'A+' },
				{ text: 'A-', value: 'A-' },
				{ text: 'B+', value: 'B+' },
				{ text: 'B-', value: 'B-' },
				{ text: 'AB+', value: 'AB+' },
				{ text: 'AB-', value: 'AB-' },
				{ text: '0', value: '0' },
			],
			accessTypes: [
				{
					text: this.$t(`label.access-type-employee`),
					value: 'employee',
				},
				{
					text: this.$t(`label.access-type-manager`),
					value: 'manager',
				},
				{
					text: this.$t(`label.access-type-owner`),
					value: 'owner',
				},
			],
			genders: [
				{ text: this.$t(`label.male`), value: 'male' },
				{ text: this.$t(`label.female`), value: 'female' },
			],
			educationLevels: [
				{ text: this.$t(`label.primary`), value: 'primary' },
				{ text: this.$t(`label.secondary`), value: 'secondary' },
				{ text: this.$t(`label.high_school`), value: 'high_school' },
				{ text: this.$t(`label.associate`), value: 'associate' },
				{ text: this.$t(`label.bachelor`), value: 'bachelor' },
				{ text: this.$t(`label.master`), value: 'master' },
				{ text: this.$t(`label.doctorate`), value: 'doctorate' },
				{ text: this.$t(`label.not-available`), value: 'none' },
			],
			contractTypes: [
				{ text: this.$t(`label.definite-contract`), value: 'definite' },
				{ text: this.$t(`label.indefinite-contract`), value: 'indefinite' },
			],
			levelsOfDisability: [
				{ text: this.$t(`label.not-disabled`), value: 'notDisabled' },
				{ text: this.$t(`label.level-1-disabled`), value: 'firstLevel' },
				{ text: this.$t(`label.level-2-disabled`), value: 'secondLevel' },
				{ text: this.$t(`label.level-3-disabled`), value: 'thirdLevel' },
			],
			maritalStatus: [
				{ text: this.$t(`label.single`), value: 'single' },
				{
					text: this.$t(`label.married`),
					value: 'married',
				},
				{
					text: this.$t(`label.divorced`),
					value: 'divorced',
				},
				{
					text: this.$t(`label.unspecified`),
					value: 'unspecified',
				},
			],
			militaryService: [
				{
					text: this.$t(`label.military-service-completed`),
					value: 'completed',
				},
				{
					text: this.$t(`label.military-service-not-completed`),
					value: 'uncompleted',
				},
				{ text: this.$t(`label.military-service-exempt`), value: 'exempt' },
				{ text: this.$t(`label.military-service-delayed`), value: 'delayed' },
				{ text: this.$t(`label.military-service-deserter`), value: 'deserter' },
				{
					text: this.$t(`label.military-service-draft-evader`),
					value: 'draftEvader',
				},
			],
			educationStatuses: [
				{ text: this.$t(`label.education-graduate`), value: 'graduate' },
				{ text: this.$t(`label.education-student`), value: 'student' },
			],
		};
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		candidate: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
		onClose: {
			type: Function,
			required: true,
		},
		method: {
			type: String,
			required: true,
		},
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleMilitaryServiceDelayDate(date) {
			this.kolay.militaryServiceDelayDate = format(
				new Date(date),
				'yyyy-MM-dd',
			);
		},
		handleBirthday(date) {
			this.kolay.birthday = format(new Date(date), 'yyyy-MM-dd');
		},
		handleEmploymentStartDate(date) {
			this.kolay.employmentStartDate = format(new Date(date), 'yyyy-MM-dd');
		},
		handleContractEndDate(date) {
			this.kolay.contractEndDate = format(new Date(date), 'yyyy-MM-dd');
		},
		handleRemoveManager() {
			this.manager = {
				id: null,
				fullName: null,
			};
		},
		handleManagerChange(newVal) {
			if (newVal) {
				this.manager = { ...newVal };
				this.managers = [];
			}
		},
		handleManagerSearchInput({ newVal }) {
			if (newVal && newVal.length > 1) {
				api.searchManager.get({ q: newVal }).then(response => {
					const { people } = response.data;
					this.managers = people.map(person => ({
						fullName: `${person.firstName + ' ' + person.lastName}`,
						id: person.id,
					}));
				});
			} else {
				this.managers = [];
			}
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.edit_form.validate();

			if (isValid) {
				const { method } = this;
				const { kolay, manager } = this;
				const payload = {
					files: [],
					person: {
						...kolay,
						unitList: [
							{
								startDate: kolay.employmentStartDate
									? kolay.employmentStartDate
									: format(new Date(), 'yyyy-MM-dd'),
								endDate: kolay.contractEndDate ? kolay.contractEndDate : null,
								managerId: manager ? (manager.id ? manager.id : null) : null,
								active: true,
								default: true,
								items: this.units.map(unit => {
									return { unitName: unit.name, unitItemName: unit.value };
								}),
							},
						],
					},
				};
				if (method === 'put') {
					api.kolay.put({ id: this.candidateId, payload }).then(() => {
						this.onClose({ refresh: true });
					});
				} else if (method === 'post') {
					api.kolay.post({ id: this.candidateId, payload }).then(() => {
						this.onClose({ refresh: true });
					});
				}
			}
		},
		async getAndSetHierarchy() {
			const res = await api.hierarchy.get({});
			this.units = res.data.result;
		},
		setUnitData(person) {
			const { unitList } = person;
			if (unitList && unitList.length > 0) {
				const [data] = unitList;
				if (data.managerId) {
					this.setManagerData(data.managerId);
				}
				if (data.items && data.items.length > 0) {
					data.items.forEach(({ unitName, unitItemName }) => {
						const u = this.units.find(unit => unit.name === unitName);
						if (u) {
							u.value = unitItemName;
						}
					});
				}
			}
		},
		setManagerData(managerId) {
			api.kolay
				.getByKolayId({ id: managerId })
				.then(response => {
					if (
						response.status === 200 &&
						response.data &&
						!response.data.error
					) {
						const manager = response.data.data;
						const { firstName, lastName } = manager;
						this.manager.id = managerId;
						this.manager.fullName = `${firstName} ${lastName}`;
					}
				})
				.catch(err => {
					console.log('Error fetching manager', err);
				});
		},
		getKolayRelatedFields(candidate) {
			let kolayFields = {};
			if (candidate.email && candidate.email.length > 0) {
				kolayFields['email'] = candidate.email[0];
			}
			if (candidate.name) {
				const nameList = candidate.name.split(' ');
				if (nameList.length === 1) {
					kolayFields['firstName'] = nameList[0];
				}
				if (nameList.length > 1) {
					kolayFields['firstName'] = nameList
						.slice(0, nameList.length - 1)
						.join(' ');
					kolayFields['lastName'] = nameList[nameList.length - 1];
				}
			}
			if (
				candidate.education &&
				candidate.education.length > 0 &&
				candidate.education[0].organization
			) {
				kolayFields['educationalInstitution'] =
					candidate.education[0].organization;
			} else if (candidate.last_school) {
				kolayFields['educationalInstitution'] = candidate.last_school;
			}
			if (candidate.phone && candidate.phone.length > 0) {
				kolayFields['mobilePhone'] = candidate.phone[0];
			}
			return kolayFields;
		},
	},
	async mounted() {
		await this.getAndSetHierarchy();
		const { method } = this;

		if (method === 'put') {
			api.kolay
				.getByCandidateId({ id: this.candidateId })
				.then(response => {
					if (response.status === 200) {
						if (response.data && response.data.person) {
							const { data } = response.data.person;
							if (data) {
								this.kolay = {
									...this.kolay,
									...data,
								};
								this.setUnitData(data);
							}
						}
					}
				})
				.catch(() => {
					console.log('Error fetching record');
				});
		} else if (method === 'post') {
			this.kolay = {
				...this.kolay,
				...this.getKolayRelatedFields(this.candidate),
			};
		}

		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			500,
		);
	},
};
</script>
