<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="600"
			@click:outside="() => onClose({ checkRuleset: false })"
			@keydown.esc="() => onClose({ checkRuleset: false })"
		>
			<ArchiveCandidate
				v-if="page === 1"
				:archiveForm="archiveCandidateForm"
				:candidate="candidate"
				:candidates="candidates"
				:bulkAction="bulkAction"
				:onArchive="handleArchive"
				:onNextStep="handleGoToNextStep"
				:onClose="() => onClose({ checkRuleset: false })"
				@change-form="handleChangeArchiveCandidateForm"
			></ArchiveCandidate>

			<ArchiveCandidateWithEmails
				v-else-if="page === 2"
				:applicationsFlat="applicationsFlat"
				:candidate="candidate"
				:candidates="candidates"
				:bulkAction="bulkAction"
				:archiveReasonName="archiveCandidateForm.archiveReasonName"
				:onArchiveAndSend="handleArchiveAndSend"
				:onPreviousStep="handleGoToPreviousStep"
				:onEmailTemplateIdChange="handleEmailTemplateIdChange"
				@change-applications="handleChangeApplicationsFlat"
			></ArchiveCandidateWithEmails>
		</v-dialog>
	</div>
</template>

<script>
import ArchiveCandidate from './archiveCandidate/ArchiveCandidate';
import ArchiveCandidateWithEmails from './archiveCandidateWithEmails/ArchiveCandidateWithEmails';

export default {
	name: 'ArchiveCandidateDialog',
	props: {
		candidate: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
		candidates: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		bulkAction: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		jobIdInitial: {
			type: [Number, String],
			required: false,
		},
		applicationIdInitial: {
			type: [Number, String],
			required: false,
		},
		archiveReasonInitial: {
			type: String,
			required: false,
		},
		prevStatusInitial: {
			type: [Number, String],
			required: false,
		},
	},
	components: {
		ArchiveCandidate,
		ArchiveCandidateWithEmails,
	},
	data() {
		const { archiveReasonInitial } = this;
		return {
			archiveCandidateForm: {
				jobIds: [],
				applicationIds: this.initializeAllApplicationsForSameJob(),
				archiveReason: archiveReasonInitial ? archiveReasonInitial : null,
				archiveReasonName: null,
			},
			applicationsFlat: [],
			page: 1,
			isVisible: false,
			emailTemplateId: null,
		};
	},
	methods: {
		async getAllApplications() {
			const candidate_ids = this.candidates.map(candidate => candidate.id);
			const { query } = this.$route;
			const response = await this.$store.dispatch(
				'candidates/get_applications',
				{
					candidate_ids: candidate_ids.join(','),
					job_ids: this.archiveCandidateForm.jobIds.join(','),
					refresh: true,
					...query,
				},
			);
			const { items } = response.data;
			if (items.length > 0) {
				return items.reduce((acc, item) => {
					return [...acc, ...item];
				});
			}
			return [];
		},
		initializeAllApplicationsForSameJob() {
			const { bulkAction, candidate, jobIdInitial } = this;
			return !bulkAction && candidate && candidate.applications
				? candidate.applications
						.filter(item => !item.is_archived && jobIdInitial == item.job_id)
						.map(item => item.id)
				: [];
		},
		async handleGoToNextStep() {
			await this.handleSetApplicationsFlat();
			this.page = 2;
		},
		handleGoToPreviousStep() {
			this.page = 1;
		},
		handleChangeArchiveCandidateForm(newVal) {
			this.archiveCandidateForm = {
				...this.archiveCandidateForm,
				...newVal,
			};
		},
		handleChangeApplicationsFlat(newVal) {
			this.applicationsFlat = JSON.parse(JSON.stringify(newVal));
		},
		async handleSetApplicationsFlat() {
			const { bulkAction } = this;

			if (bulkAction) {
				this.applicationsFlat = await this.getAllApplications();
			} else {
				const { candidate } = this;
				const { applicationIds } = this.archiveCandidateForm;

				this.applicationsFlat = applicationIds.map(applicationId => ({
					id: applicationId,
					candidate_id: candidate.id,
					job_id: candidate.applications.find(item => item.id == applicationId)
						.job_id,
				}));
			}
		},
		async handleArchive({ getApplications = true }) {
			const { bulkAction } = this;
			const { archiveReason, applicationIds } = this.archiveCandidateForm;

			if (getApplications && bulkAction) {
				this.applicationsFlat = await this.getAllApplications();
			}

			const applications = bulkAction
				? [...this.applicationsFlat]
				: applicationIds.map(item => ({ id: item }));
			const items = applications.map(item => ({
				id: item.id,
				is_interview: false,
				interview_status: null,
				is_archived: true,
				archive_status: archiveReason,
				updateStore: false,
			}));

			this.$store.dispatch('applicants/put', { items }).then(() => {
				this.$store.commit('candidates/setItemsNeedToRefresh');
				this.$store.commit('candidates/setItemNeedsToRefresh');
				if (bulkAction) {
					this.onClose({ checkRuleset: false });
				} else {
					const { jobIdInitial, applicationIdInitial, prevStatusInitial } =
						this;
					const { archiveReason } = this.archiveCandidateForm;
					this.onClose({
						checkRuleset: true,
						prevStatus: prevStatusInitial,
						nextStatus: archiveReason,
						applicationId: applicationIdInitial,
						jobId: jobIdInitial,
					});
				}
			});
		},
		async handleArchiveAndSend() {
			const { emailTemplateId } = this;
			// 1. handle scheduling email
			const items = this.applicationsFlat.map(
				({
					id,
					candidate_id,
					subjectReplaced,
					templateReplaced,
					sent_from,
					send_to,
					cc,
					bcc,
					attachments,
					send_at,
					is_sent,
					is_cancelled,
				}) => ({
					application_id: id,
					candidate_id,
					subject: subjectReplaced,
					template: templateReplaced,
					sent_from,
					send_to: [send_to],
					cc,
					bcc,
					attachments,
					send_at,
					is_sent,
					is_cancelled,
					email_template_id: emailTemplateId,
				}),
			);

			this.$store.dispatch('emails/post', { items }).then(() => {
				// 2. handle archiving
				this.handleArchive({ getApplications: false });
			});
		},
		handleEmailTemplateIdChange({ email_template_id }) {
			this.emailTemplateId = email_template_id;
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
};
</script>
