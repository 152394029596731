<template>
	<div>
		<v-dialog
			:value="true"
			max-width="400"
			@click:outside="handleClose"
			@keydown.esc="handleClose"
		>
			<v-card>
				<ValidationObserver ref="form" tag="form" @submit="handleSubmit">
					<v-container>
						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-text-field
										v-model="teamEditable.name"
										outlined
										:label="$t('label.name')"
										:error-messages="errors"
										hide-details
										dense
									></v-text-field>
								</ValidationProvider>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-select
										v-model="teamEditable.department_id"
										:items="departments"
										item-text="name"
										item-value="id"
										:label="$t('label.department')"
										outlined
										:error-messages="errors"
										hide-details
										dense
									></v-select>
								</ValidationProvider>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.status') }}:
										{{
											teamEditable.active
												? $t('label.active')
												: $t('label.inactive')
										}}
									</legend>
									<v-switch
										v-model="teamEditable.active"
										color="success"
										inset
										hide-details
										dense
										class="v-input--template-switch"
									></v-switch>
								</fieldset>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleClose">{{
						$t('button.close')
					}}</v-btn>
					<v-btn
						color="error"
						outlined
						@click="hanleDeleteClick"
						v-if="method === 'edit'"
						>{{ $t('button.delete') }}</v-btn
					>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<Confirm
			v-if="isDeleteConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
			primaryActionColor="error"
			><div style="padding-top: 20px">
				{{ $t('text.confirm-deleting-team') }}
			</div></Confirm
		>
	</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';
import { mapState } from 'vuex';

export default {
	name: 'TeamAttrs',
	components: {
		ValidationObserver,
		ValidationProvider,
		Confirm,
	},
	computed: {
		...mapState({
			departments: state => state.departments.items,
		}),
	},
	props: {
		method: {
			type: String,
			required: true,
		},
		team: {
			type: Object,
			required: false,
			default() {
				return {
					active: true,
					name: '',
				};
			},
		},
		onClose: {
			type: Function,
			required: true,
		},
	},
	data() {
		const { team } = this;
		return {
			teamEditable: JSON.parse(JSON.stringify(team)),
			isDeleteConfirmVisible: false,
		};
	},
	methods: {
		handleClose() {
			this.onClose();
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form.validate();
			if (isValid) {
				const { teamEditable, method } = this;
				if (method === 'edit') {
					this.$store
						.dispatch('teams/put', {
							items: [{ ...teamEditable }],
						})
						.then(() => {
							this.handleClose();
						});
				} else if (method === 'add') {
					this.$store
						.dispatch('teams/post', {
							items: [{ ...teamEditable }],
						})
						.then(() => {
							this.handleClose();
						});
				}
			}
		},
		handleConfirmDialogConfirm() {
			const { id } = this.teamEditable;
			this.$store.dispatch('teams/delete', { id }).then(() => {
				this.handleClose();
			});
		},
		handleConfirmDialogClose() {
			this.isDeleteConfirmVisible = false;
		},
		hanleDeleteClick() {
			this.isDeleteConfirmVisible = true;
		},
	},
	watch: {
		team(newVal) {
			this.teamEditable = { ...newVal };
		},
	},
};
</script>
