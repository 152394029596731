import api from '@/api';

const state = {
	items: [],
	updated: 0,
	sendTimes: [
		{ value: '0d', days: 0 },
		{ value: '1d', days: 1 },
		{ value: '2d', days: 2 },
		{ value: '3d', days: 3 },
		{ value: '4d', days: 4 },
		{ value: '5d', days: 5 },
		{ value: '6d', days: 6 },
		{ value: '1w', days: 7 },
		{ value: '2w', days: 14 },
		{ value: '3w', days: 21 },
		{ value: '4w', days: 28 },
		{ value: 'afc', days: 36500 },
	],
};

const getters = {};

const actions = {
	get: ({ commit }, { refresh = false, updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			const { updated } = state;
			if (!refresh && updated > 0) {
				resolve({ data: { items: state.items } });
			}
			api.emailTemplates
				.get(params)
				.then(response => {
					const { items } = response.data;
					if (updateStore) {
						commit('setItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	put: ({ commit }, { updateStore = true, ...params }) => {
		return new Promise((resolve, reject) => {
			api.emailTemplates
				.put(params)
				.then(response => {
					const { items } = response.data;
					if (updateStore) {
						commit('updateItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	post: ({ commit }, { updateStore = true, ...params }) => {
		return new Promise((resolve, reject) => {
			api.emailTemplates
				.post(params)
				.then(response => {
					const { items } = response.data;
					if (updateStore) {
						commit('addItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setItems: (state, { items }) => {
		const now = new Date();
		state.items = items;
		state.updated = now.getTime();
	},
	updateItems: (state, { items }) => {
		const now = new Date();
		state.items = state.items.map(stateItem => {
			const itemMatches = items.find(({ id }) => stateItem.id === id);
			return itemMatches ? itemMatches : stateItem;
		});
		state.updated = now.getTime();
	},
	addItems: (state, { items }) => {
		const now = new Date();
		state.items = [...state.items, ...items];
		state.updated = now.getTime();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
