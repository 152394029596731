<template>
	<div>
		<v-dialog
			:value="true"
			max-width="600"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
			scrollable
		>
			<v-card>
				<v-card-text style="padding: 0">
					<ValidationObserver ref="form" tag="form" @submit="handleSubmit">
						<v-list-item two-line>
							<v-list-item-avatar tile size="80">
								<Avatar v-if="user.id" :userId="user.id" :size="64"></Avatar>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title class="headline mb-1">{{
									user.name || `(${$t('text.invited')})`
								}}</v-list-item-title>
								<v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
							</v-list-item-content>
							<v-tooltip left v-if="currentUser.is_agent">
								<template v-slot:activator="{ on }">
									<div
										v-on="on"
										style="
											display: flex;
											justify-content: flex-end;
											margin-left: auto;
										"
									>
										<v-btn
											color="pink darken-2"
											dark
											small
											@click="handleClearFirebaseSession"
										>
											<v-icon>published_with_changes</v-icon>
										</v-btn>
									</div>
								</template>
								{{ $t('tooltip.clear-firebase-session') }}
							</v-tooltip>
						</v-list-item>
						<v-divider></v-divider>
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}
										</legend>
										<v-switch
											v-model="user.active"
											color="success"
											:disabled="
												currentUser &&
												(userRole < user.role || currentUser.id === user.id)
											"
											:label="
												user.active ? $t('label.active') : $t('label.inactive')
											"
											inset
											hide-details
											dense
											class="v-input--template-switch"
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row v-if="user.last_sign_in === null">
								<v-col>
									<ValidationProvider
										rules="required|email"
										v-slot="{ errors }"
									>
										<v-text-field
											v-model="user.email"
											type="email"
											outlined
											:label="`${$t('label.email')}*`"
											:error-messages="errors"
											hide-details
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row
								v-if="
									user.last_sign_in === null &&
									company.user_has_display_email_prop
								"
							>
								<v-col>
									<ValidationProvider rules="email" v-slot="{ errors }">
										<v-text-field
											v-model="user.display_email"
											type="email"
											outlined
											:label="`${$t('label.display-email')}`"
											:error-messages="errors"
											hide-details
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="user.role"
											:items="
												roles.map(role => ({
													...role,
													disabled: userRole < role.value,
												}))
											"
											:label="$t('label.role')"
											outlined
											hide-details
											:disabled="
												currentUser &&
												(userRole < user.role || currentUser.id === user.id)
											"
											:error-messages="errors"
											dense
											@change="handleRoleChange"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row v-if="company.user_has_reports_to_prop">
								<v-col>
									<UserPicker
										v-model="user.reports_to_user_id"
										:users="users"
										:label="$t('label.reports-to')"
										:multiple="false"
										dense
									></UserPicker>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.permissions') }}
										</legend>
										<div>
											<v-switch
												v-model="user.can_create_job_requisitions"
												color="success"
												:label="$t('label.can-create-job-requisitions')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_create_job_openings"
												color="success"
												:label="$t('label.can-create-job-openings')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_create_offer_requisitions"
												color="success"
												:label="$t('label.can-create-offer-requisitions')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_schedule_calendar_events"
												color="success"
												:label="$t('label.can-schedule-calendar-events')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_schedule_emails"
												color="success"
												:label="$t('label.can-schedule-emails')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_edit_career_pages"
												color="success"
												:label="$t('label.can-edit-career-pages')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_view_sensitive_data"
												color="success"
												:label="$t('label.can-view-sensitive-data')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-autocomplete
											v-model="user.timezone"
											:label="$t('label.timezone')"
											hide-details
											:items="timezoneItems"
											:item-text="item => `${item.text} - ${item.value}`"
											prepend-inner-icon="public"
											outlined
											:error-messages="errors"
											dense
										></v-autocomplete>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.restricted-candidates') }}
										</legend>
										<div
											v-if="user.restricted_candidates_with_names.length > 0"
											style="margin-bottom: 1rem"
										>
											<v-card elevation="1">
												<v-list tile dense>
													<v-list-item
														v-for="(
															item, index
														) in user.restricted_candidates_with_names"
														:key="index"
													>
														<v-list-item-content>
															<v-list-item-title>{{
																item.name
															}}</v-list-item-title>
														</v-list-item-content>
														<v-list-item-action>
															<v-btn
																icon
																x-small
																@click="
																	() =>
																		handleRemoveCandidate({
																			index,
																		})
																"
																:disabled="userRole < user.role"
															>
																<v-icon>close</v-icon>
															</v-btn>
														</v-list-item-action>
													</v-list-item>
												</v-list>
											</v-card>
										</div>
										<Autocomplete
											:label="$t('label.search-candidates')"
											:filled="true"
											:dense="true"
											:flat="true"
											:solo="true"
											:solo-inverted="false"
											:hide-details="true"
											:items="autocompleteItems"
											:onKeyUp="handleAutocompleteInput"
											prepend-inner-icon="search"
											@change="handleAutocompleteChange"
											:outlined="true"
											:disabled="userRole < user.role"
										></Autocomplete>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn
						v-if="userRole >= 666 && user.last_sign_in == null && user.active"
						small
						:disabled="invitation_email_sent"
						@click="handleResendInvitationEmail"
					>
						<v-icon left v-if="invitation_email_sent">mark_email_read</v-icon>
						<v-icon left v-else>email</v-icon>
						<span v-if="invitation_email_sent">{{
							$t('button.invitation-sent')
						}}</span>
						<span v-else>{{ $t('button.resend-invitation-email') }}</span>
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleClose">{{
						$t('button.close')
					}}</v-btn>
					<v-btn
						color="primary"
						@click="handleSubmit"
						:disabled="userRole < user.role"
						>{{ $t('button.save') }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Avatar from '@/components/shared/avatar/Avatar';
import Autocomplete from '@/components/shared/autocomplete/Autocomplete';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import api from '@/api';

export default {
	name: 'User',
	components: {
		Avatar,
		Autocomplete,
		ValidationObserver,
		ValidationProvider,
		UserPicker,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
			currentLang: state => state.currentUser.lang,
			timezones: state => state.timezones.items,
			isDark: state => state.currentUser.isDark,
			users: state => state.users.items,
			company: state => state.company.company,
		}),
		timezoneItems() {
			const { timezones } = this;
			return timezones.reduce((acc, item) => {
				return [
					...acc,
					{ header: item.group },
					...item.zones.map(zone => ({
						text: zone.name,
						value: zone.value,
						group: item.group,
					})),
				];
			}, []);
		},
	},
	data() {
		return {
			invitation_email_sent: false,
			user: {
				id: null,
				name: '',
				email: null,
				display_email: null,
				active: false,
				role: null,
				restricted_candidates: [],
				restricted_candidates_with_names: [],
				can_create_job_requisitions: false,
				can_create_job_openings: false,
				can_create_offer_requisitions: false,
				can_schedule_calendar_events: false,
				can_edit_career_pages: false,
				can_schedule_emails: false,
				can_view_sensitive_data: false,
				reports_to_user_id: null,
			},
			roles: [
				{
					text: 'Super Admin',
					value: 777,
				},
				{
					text: 'Admin',
					value: 666,
				},
				{
					text: 'Team Member',
					value: 555,
				},
				{
					text: 'Limited Team Member',
					value: 444,
				},
				{
					text: 'Interviewer',
					value: 333,
				},
			],
			autocompleteItems: [],
			isVisible: false,
		};
	},
	methods: {
		getUser({ id }) {
			this.$store
				.dispatch('users/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.user = items[0];
					}
				});
		},
		handleClose() {
			const { query } = this.$route;
			const { currentLang } = this;
			this.$router.push({
				path: `/${currentLang}/settings/users`,
				query,
			});
		},
		handleSubmit() {
			const { user } = this;
			this.$store
				.dispatch('users/put', {
					...user,
					restricted_candidates: user.restricted_candidates_with_names.map(
						({ id }) => id,
					),
				})
				.then(() => {
					this.$store.commit('users/setItemsNeedToRefresh');
					this.handleClose();
				});
		},
		handleAutocompleteInput({ newVal }) {
			if (newVal && newVal.length > 2) {
				api.search.get({ q: newVal }).then(response => {
					const { candidates } = response.data;
					const autocompleteItems = [
						...(candidates.length > 0 ? [{ header: 'Candidates' }] : []),
						...candidates.map(item => ({
							text: item.name,
							value: item.id,
							group: 'candidates',
						})),
					];
					this.autocompleteItems = autocompleteItems;
				});
			} else {
				this.autocompleteItems = [];
			}
		},
		handleClearFirebaseSession() {
			const { email } = this.user;

			api.users
				.clearUsersFirebaseSession(email)
				.then(response => {
					return response.json();
				})
				.then(data => {
					console.log(data);
				})
				.catch(console.error);
		},
		handleAutocompleteChange(newVal) {
			if (newVal) {
				const { text, value } = newVal;
				this.user = {
					...this.user,
					restricted_candidates_with_names: [
						...this.user.restricted_candidates_with_names,
						{
							id: value,
							name: text,
						},
					],
				};
			}
		},
		handleRemoveCandidate({ index }) {
			this.user = {
				...this.user,
				restricted_candidates_with_names:
					this.user.restricted_candidates_with_names.filter(
						(item, itemIndex) => itemIndex !== index,
					),
			};
		},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleRoleChange() {
			const { role } = this.user;
			if (role === 777 || role === 666) {
				this.user = {
					...this.user,
					can_create_job_requisitions: true,
					can_create_job_openings: true,
					can_create_offer_requisitions: true,
					can_schedule_calendar_events: true,
					can_edit_career_pages: true,
					can_schedule_emails: true,
					can_view_sensitive_data: true,
				};
			} else {
				this.user = {
					...this.user,
					can_create_job_requisitions: false,
					can_create_job_openings: false,
					can_create_offer_requisitions: false,
					can_schedule_calendar_events: false,
					can_edit_career_pages: false,
					can_schedule_emails: false,
					can_view_sensitive_data: false,
				};
			}
		},
		handleResendInvitationEmail() {
			api.users.reinvite({ send_to: this.user.email })
				.then(response => {
					const { message } = response.data;
					this.invitation_email_sent = message === 'sent';
				});
		},
	},
	created() {
		const { userId } = this.$route.params;
		this.getUser({ id: userId });
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
};
</script>
