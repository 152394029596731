var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('v-timeline-item',{attrs:{"small":"","hide-dot":""}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(_vm.$t('label.organizations')))])]),_vm._l((_vm.organizations),function(organizationItem,organizationIndex){return _c('v-timeline-item',{key:`organization_${organizationIndex}`,attrs:{"small":"","color":organizationItem.active ? 'green' : 'grey'}},[_c('div',[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":() =>
							_vm.addOrEditItem({
								type: 'organization',
								id: organizationItem.id,
								operation: 'edit',
							})}},[_vm._v(" "+_vm._s(organizationItem.name)+" ")])],1),_c('div',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('v-timeline-item',{attrs:{"small":"","hide-dot":""}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(_vm.$t('label.departments')))])]),_vm._l((_vm.departments.filter(
							item => item.organization_id === organizationItem.id,
						)),function(departmentItem,departmentIndex){return _c('v-timeline-item',{key:`department_${departmentIndex}`,attrs:{"small":"","color":departmentItem.active ? 'green' : 'grey'}},[_c('div',[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":() =>
										_vm.addOrEditItem({
											type: 'department',
											id: departmentItem.id,
											operation: 'edit',
										})}},[_vm._v(" "+_vm._s(departmentItem.name)+" ")]),_c('v-btn',{staticStyle:{"margin-left":"0.5rem"},attrs:{"icon":"","small":"","outlined":""},on:{"click":() =>
										_vm.handleDepartmentToggleClick({
											department_id: departmentItem.id,
										})}},[(_vm.visibleDepartmentId === departmentItem.id)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1),(_vm.visibleDepartmentId === departmentItem.id)?_c('div',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('v-timeline-item',{attrs:{"small":"","hide-dot":""}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(_vm.$t('label.teams')))])]),_vm._l((_vm.teams.filter(
										item => item.department_id === departmentItem.id,
									)),function(teamItem,teamIndex){return _c('v-timeline-item',{key:`team_${teamIndex}`,attrs:{"small":"","color":teamItem.active ? 'green' : 'grey'}},[_c('div',[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":() =>
													_vm.addOrEditItem({
														type: 'team',
														id: teamItem.id,
														operation: 'edit',
													})}},[_vm._v(" "+_vm._s(teamItem.name)+" ")]),_c('v-btn',{staticStyle:{"margin-left":"0.5rem"},attrs:{"icon":"","small":"","outlined":""},on:{"click":() =>
													_vm.handleTeamToggleClick({
														team_id: teamItem.id,
													})}},[(_vm.visibleTeamId === teamItem.id)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1),(_vm.visibleTeamId === teamItem.id)?_c('div',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('v-timeline-item',{attrs:{"small":"","hide-dot":""}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(_vm.$t('label.positions')))])]),_vm._l((_vm.predefinedPositions.filter(
													item => item.team_id === teamItem.id,
												)),function(predefinedPositionItem,predefinedPositionItemIndex){return _c('v-timeline-item',{key:`team_${predefinedPositionItemIndex}`,attrs:{"small":"","color":predefinedPositionItem.active ? 'green' : 'grey'}},[_c('div',[_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":() =>
																_vm.addOrEditItem({
																	type: 'predefinedPosition',
																	id: predefinedPositionItem.id,
																	operation: 'edit',
																})}},[_vm._v(" "+_vm._s(predefinedPositionItem.name)+" ")])],1)])}),_c('v-timeline-item',{attrs:{"small":"","color":"grey"}},[_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":() =>
															_vm.addOrEditItem({
																type: 'predefinedPosition',
																team_id: teamItem.id,
																operation: 'add',
															})}},[_vm._v(" "+_vm._s(_vm.$t('button.add-position'))+" ")])],1)],2)],1):_vm._e()])}),_c('v-timeline-item',{attrs:{"small":"","color":"grey"}},[_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":() =>
												_vm.addOrEditItem({
													type: 'team',
													department_id: departmentItem.id,
													operation: 'add',
												})}},[_vm._v(" "+_vm._s(_vm.$t('button.add-team'))+" ")])],1)],2)],1):_vm._e()])}),_c('v-timeline-item',{attrs:{"small":"","color":"grey"}},[_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":() =>
									_vm.addOrEditItem({
										type: 'department',
										organization_id: organizationItem.id,
										operation: 'add',
									})}},[_vm._v(" "+_vm._s(_vm.$t('button.add-department'))+" ")])],1)],2)],1)])}),_c('v-timeline-item',{attrs:{"small":"","color":"grey"}},[_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":() =>
						_vm.addOrEditItem({
							type: 'organization',
							operation: 'add',
						})}},[_vm._v(" "+_vm._s(_vm.$t('button.add-organization'))+" ")])],1)],2),(_vm.isOrganizationAttrsVisible)?_c('OrganizationAttrs',{attrs:{"method":_vm.organizationActionMethod,"organization":_vm.organizationToEdit,"onClose":_vm.handleOrganizationAttrsClose}}):_vm._e(),(_vm.isDepartmentAttrsVisible)?_c('DepartmentAttrs',{attrs:{"method":_vm.departmentActionMethod,"department":_vm.departmentToEdit,"onClose":_vm.handleDepartmentAttrsClose}}):_vm._e(),(_vm.isTeamAttrsVisible)?_c('TeamAttrs',{attrs:{"method":_vm.teamActionMethod,"team":_vm.teamToEdit,"onClose":_vm.handleTeamAttrsClose}}):_vm._e(),(_vm.isPredefinedPositionAttrsVisible)?_c('PredefinedPositionAttrs',{attrs:{"method":_vm.predefinedPositionActionMethod,"team":_vm.predefinedPositionToEdit,"onClose":_vm.handlePredefinedPositionAttrsClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }