<template>
	<div>
		<v-dialog
			:value="true"
			max-width="600"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClose"
			persistent
		>
			<v-card>
				<ValidationObserver ref="form" tag="form" @submit="handleSubmit">
					<v-container>
						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.status') }}:
										{{
											eventType.active
												? $t('label.active')
												: $t('label.inactive')
										}}
									</legend>
									<v-switch
										v-model="eventType.active"
										color="success"
										hide-details
										inset
										dense
										class="v-input--template-switch"
									></v-switch>
								</fieldset>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-text-field
										v-model="eventType.name"
										type="text"
										outlined
										:label="`${$t('label.name')}*`"
										:error-messages="errors"
										hide-details
										dense
									></v-text-field>
								</ValidationProvider>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-select
										v-model="eventType.default_length"
										:items="lengthOptions"
										:label="`${$t('label.length-minutes')}*`"
										dense
										hide-details
										outlined
										:error-messages="errors"
									></v-select>
								</ValidationProvider>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<UserPicker
									v-model="eventType.organizer_id"
									:users="interviewerUsers"
									:label="$t('label.organizer')"
									dense
									rules="required"
								></UserPicker>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<UserPicker
									v-model="eventType.default_interviewers"
									:users="interviewerUsers"
									:label="$t('label.interviewers')"
									multiple
									dense
								></UserPicker>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.day-starts-at') }}
									</legend>
									<v-row>
										<v-col cols="6">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-select
													v-model="eventType.day_starts_at_hours"
													:label="$t('label.hours')"
													:items="hourItems"
													outlined
													hide-details
													:error-messages="errors"
													dense
												></v-select>
											</ValidationProvider>
										</v-col>
										<v-col cols="6">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-select
													v-model="eventType.day_starts_at_minutes"
													:label="$t('label.minutes')"
													:items="minuteItems"
													outlined
													hide-details
													:error-messages="errors"
													dense
												></v-select>
											</ValidationProvider>
										</v-col>
									</v-row>
								</fieldset>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.day-ends-at') }}
									</legend>
									<v-row>
										<v-col cols="6">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-select
													v-model="eventType.day_ends_at_hours"
													:label="$t('label.hours')"
													:items="hourItems"
													outlined
													hide-details
													:error-messages="errors"
													dense
												></v-select>
											</ValidationProvider>
										</v-col>
										<v-col cols="6">
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-select
													v-model="eventType.day_ends_at_minutes"
													:label="$t('label.minutes')"
													:items="minuteItems"
													outlined
													hide-details
													:error-messages="errors"
													dense
												></v-select>
											</ValidationProvider>
										</v-col>
									</v-row>
								</fieldset>
							</v-col>
						</v-row>

						<v-row v-if="method === 'put'">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>{{ $t('label.url') }}</legend>
									<v-row>
										<v-col cols="12">
											<div style="font-size: 0.75rem">
												https://instant-booking.talentics.app/{{
													eventType.uuidv4
												}}
											</div>
										</v-col>
									</v-row>
								</fieldset>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn
						color="error"
						outlined
						@click="handleDelete"
						v-if="method === 'put'"
						>{{ $t('button.delete') }}</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleClose">{{
						$t('button.close')
					}}</v-btn>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<Confirm
			v-if="isDeleteFeedbackConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
			primaryActionColor="error"
			><div style="padding-top: 20px">
				{{ $t('text.confirm-deleting-event-type') }}
			</div></Confirm
		>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';

export default {
	name: 'AddEventType',
	components: {
		ValidationObserver,
		ValidationProvider,
		UserPicker,
		Confirm,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			currentLang: state => state.currentUser.lang,
		}),
		interviewerUsers() {
			return this.users;
		},
	},
	data() {
		const { eventId } = this.$route.params;
		return {
			eventType: {
				active: true,
				name: '',
				default_length: 30,
				organizer_id: null,
				default_interviewers: [],
				uuidv4: null,
				day_starts_at_hours: 9,
				day_starts_at_minutes: 0,
				day_ends_at_hours: 18,
				day_ends_at_minutes: 0,
			},
			method: eventId ? 'put' : 'post',
			isDeleteFeedbackConfirmVisible: false,
			lengthOptions: [
				{ text: '15 min', value: 15 },
				{ text: '30 min', value: 30 },
				{ text: '45 min', value: 45 },
				{ text: '60 min', value: 60 },
				{ text: '90 min', value: 90 },
			],
			hourItems: [
				{ text: '00', value: 0 },
				{ text: '01', value: 1 },
				{ text: '02', value: 2 },
				{ text: '03', value: 3 },
				{ text: '04', value: 4 },
				{ text: '05', value: 5 },
				{ text: '06', value: 6 },
				{ text: '07', value: 7 },
				{ text: '08', value: 8 },
				{ text: '09', value: 9 },
				{ text: '10', value: 10 },
				{ text: '11', value: 11 },
				{ text: '12', value: 12 },
				{ text: '13', value: 13 },
				{ text: '14', value: 14 },
				{ text: '15', value: 15 },
				{ text: '16', value: 16 },
				{ text: '17', value: 17 },
				{ text: '18', value: 18 },
				{ text: '19', value: 19 },
				{ text: '20', value: 20 },
				{ text: '21', value: 21 },
				{ text: '22', value: 22 },
				{ text: '23', value: 23 },
			],
			minuteItems: [
				{ text: '00', value: 0 },
				// { text: '01', value: 1 },
				// { text: '02', value: 2 },
				// { text: '03', value: 3 },
				// { text: '04', value: 4 },
				// { text: '05', value: 5 },
				// { text: '06', value: 6 },
				// { text: '07', value: 7 },
				// { text: '08', value: 8 },
				// { text: '09', value: 9 },
				// { text: '10', value: 10 },
				// { text: '11', value: 11 },
				// { text: '12', value: 12 },
				// { text: '13', value: 13 },
				// { text: '14', value: 14 },
				{ text: '15', value: 15 },
				// { text: '16', value: 16 },
				// { text: '17', value: 17 },
				// { text: '18', value: 18 },
				// { text: '19', value: 19 },
				// { text: '20', value: 20 },
				// { text: '21', value: 21 },
				// { text: '22', value: 22 },
				// { text: '23', value: 23 },
				// { text: '24', value: 24 },
				// { text: '25', value: 25 },
				// { text: '26', value: 26 },
				// { text: '27', value: 27 },
				// { text: '28', value: 28 },
				// { text: '29', value: 29 },
				{ text: '30', value: 30 },
				// { text: '31', value: 31 },
				// { text: '32', value: 32 },
				// { text: '34', value: 34 },
				// { text: '35', value: 35 },
				// { text: '36', value: 36 },
				// { text: '37', value: 37 },
				// { text: '38', value: 38 },
				// { text: '39', value: 39 },
				// { text: '40', value: 40 },
				// { text: '41', value: 41 },
				// { text: '42', value: 42 },
				// { text: '43', value: 43 },
				// { text: '44', value: 44 },
				{ text: '45', value: 45 },
				// { text: '46', value: 46 },
				// { text: '47', value: 47 },
				// { text: '48', value: 48 },
				// { text: '49', value: 49 },
				// { text: '50', value: 50 },
				// { text: '51', value: 51 },
				// { text: '52', value: 52 },
				// { text: '53', value: 53 },
				// { text: '54', value: 54 },
				// { text: '55', value: 55 },
				// { text: '56', value: 56 },
				// { text: '57', value: 57 },
				// { text: '58', value: 58 },
				// { text: '59', value: 59 },
			],
		};
	},
	methods: {
		handleClickOutside() {
			return false;
		},
		handleClose() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/settings/instant-booking`,
				query,
			});
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form.validate();
			if (isValid) {
				const { eventType, method } = this;
				if (method === 'put') {
					this.$store
						.dispatch('eventTypes/put', {
							items: [
								{
									...eventType,
								},
							],
						})
						.then(() => {
							this.handleClose();
						});
				} else if (method === 'post') {
					this.$store
						.dispatch('eventTypes/post', {
							items: [
								{
									...eventType,
								},
							],
						})
						.then(() => {
							this.handleClose();
						});
				}
			}
		},
		handleGetEvent({ id }) {
			this.$store
				.dispatch('eventTypes/get', {
					refresh: true,
					updateStore: false,
					id,
				})
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.eventType = items[0];
					}
				});
		},
		handleDelete() {
			this.isDeleteFeedbackConfirmVisible = true;
		},
		handleConfirmDialogConfirm() {
			const { eventId } = this.$route.params;

			this.$store.dispatch('eventTypes/delete', { id: eventId }).then(() => {
				// this.getCandidateFeedbacks();
				// this.getCandidateFeedbackDrafts();
				// this.isDeleteFeedbackConfirmVisible = false;
				// this.$store.commit('candidates/setItemNeedsToRefresh');
				this.$store.dispatch('drafts/get_feedbacks', {});
				this.handleClose();
			});
		},
		handleConfirmDialogClose() {
			this.isDeleteFeedbackConfirmVisible = false;
		},
	},
	created() {
		const { eventId } = this.$route.params;
		if (eventId) {
			this.handleGetEvent({ id: eventId });
		}
	},
};
</script>
