<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="true"
			max-width="680px"
			@click:outside="handleClose"
			@keydown.esc="handleClose"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>{{ candidate.name }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<AutofillCandidateItem
						requestMethod="put"
						:fileId="parseAndAutofillFileId"
						:jobId="null"
						:candidateProps="candidate"
						ref="edit_candidate_item"
					></AutofillCandidateItem>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="handleClose">{{ $t('button.close') }}</v-btn>
					<v-btn @click="handleSubmit" color="primary" dark>
						{{ $t('button.apply') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import AutofillCandidateItem from '../autofill/autofillCandidateItem/AutofillCandidateItem';

export default {
	name: 'EditCandidateProps',
	components: {
		AutofillCandidateItem,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		onClose: {
			type: Function,
			required: true,
		},
		parseAndAutofillFileId: {
			type: [Number, String, Object],
			required: false,
		},
	},
	data() {
		return {};
	},
	methods: {
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.edit_candidate_item.handleValidate();

			if (isValid) {
				const {
					name,
					current_company,
					last_school,
					linkedin_profile,
					email,
					phone,
					link_questions,
					tags,
					source,
					total_years_experience,
				} = this.$refs.edit_candidate_item.handleGetCandidateItem();

				this.$store
					.dispatch('candidates/put', {
						items: [
							{
								id: this.candidate.id,
								name,
								current_company,
								last_school,
								linkedin_profile,
								email,
								phone,
								link_questions,
								tags,
								source,
								total_years_experience,
							},
						],
					})
					.then(() => {
						this.$store.commit('candidates/setItemsNeedToRefresh');
						this.onClose({ needsRefresh: true });
					});
			}
		},
		handleClose() {
			this.onClose({ needsRefresh: false });
		},
	},
};
</script>
