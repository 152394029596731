<template>
	<v-dialog
		style="background-color: white !important"
		v-model="dialog"
		width="500"
		@click:outside="onClose"
		@keydown.esc="onClose"
	>
		<v-card class="my-1" variant="tonal">
			<v-card-title class="mb-3">{{
				$t('label.assessment-results')
			}}</v-card-title>
			<v-card-text v-for="result in assessment.results" :key="result.boyutID">
				<v-row>
					<v-col cols="12"
						><strong>{{ result.boyutName }}</strong></v-col
					>
				</v-row>
				<v-row v-if="result.tpuan_duz_metin">
					<v-col cols="12">{{ result.tpuan_duz_metin }}</v-col>
				</v-row>
				<v-row>
					<v-col col="1"
						><strong>{{ $t('label.assessment-score') }}:</strong></v-col
					>
					<v-col cols="10">{{ result.tpuan_orj }}</v-col>
				</v-row>
				<v-divider class="my-2 mt-2" />
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'AssessmentResults',
	props: {
		dialog: {
			type: Boolean,
			required: true,
		},
		onClose: {
			type: Function,
			required: true,
			default() {
				return false;
			},
		},
		assessment: {
			type: Object,
			required: true,
		},
	},
	mounted() {
		if (
			!this.assessment.results ||
			(this.assessment.results && this.assessment.results.length === 0)
		) {
			const { candidateId } = this.$route.params;

			this.$store
				.dispatch('assessments/getResults', {
					candidateId,
					spicaLinkId: this.assessment.spica_link_id,
				})
				.then(({ data: { data } }) => {
					this.assessment.results = data;
				});
		}
	},
};
</script>
