<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-col cols="12" sm="12" md="8" lg="6">
					<fieldset class="talentics-fieldset talentics-fieldset-paddings">
						<legend>{{ $t('label.open-graph-settings') }}</legend>
						<v-container fluid>
							<v-row>
								<v-col>
									<v-select
										v-model="ogPreferenceId"
										:items="items"
										:label="$t('label.og-preference')"
										outlined
										hide-details
										item-text="name"
										item-value="id"
										dense
										@change="
											newVal =>
												handleOgPreferenceChange({
													newVal,
												})
										"
									></v-select>
								</v-col>
							</v-row>

							<div v-if="ogPreferenceId !== null">
								<ValidationObserver
									ref="form_og_preference"
									tag="form"
									@submit="handleOgPreferenceUpdateClick"
									id="form_og_preference"
								>
									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="item.name"
													:label="$t('label.name')"
													outlined
													dense
													hide-details
													:error-messages="errors"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<CdnFilePicker
												v-model="item.logo_url"
												:label="$t('label.logo-image')"
												@input="handleOgImagePropChange"
											></CdnFilePicker>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-select
													v-model="item.font_name"
													:items="[
														{
															text: 'Georgia',
															value: 'Georgia',
														},
														{
															text: 'Verdana',
															value: 'Verdana',
														},
													]"
													:label="$t('label.font-name')"
													outlined
													hide-details
													dense
													:error-messages="errors"
													@change="handleOgImagePropChange"
												></v-select>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-select
													v-model="item.font_size"
													:items="
														Array.from(Array(85).keys()).map(i => ({
															text: `${i + 12}px`,
															value: `${i + 12}px`,
														}))
													"
													:label="$t('label.font-size')"
													outlined
													hide-details
													dense
													:error-messages="errors"
													@change="handleOgImagePropChange"
												></v-select>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ColorPicker
												:label="$t('label.background-color')"
												v-model="item.background_color"
												dense
												@input="handleOgImagePropChange"
											></ColorPicker>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<CdnFilePicker
												v-model="item.background_image"
												:label="$t('label.background-image')"
												@input="handleOgImagePropChange"
											></CdnFilePicker>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ColorPicker
												:label="$t('label.font-color')"
												v-model="item.font_color"
												dense
												@input="handleOgImagePropChange"
											></ColorPicker>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="item.action_text"
													:label="$t('label.button-text')"
													outlined
													dense
													hide-details
													:error-messages="errors"
													@blur="handleOgImagePropChange"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ColorPicker
												:label="$t('label.button-color')"
												v-model="item.button_color"
												dense
												@input="handleOgImagePropChange"
											></ColorPicker>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ColorPicker
												:label="$t('label.button-text-color')"
												v-model="item.button_text_color"
												dense
												@input="handleOgImagePropChange"
											></ColorPicker>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="sampleText"
													:label="$t('label.sample-text')"
													outlined
													dense
													hide-details
													:error-messages="errors"
													@blur="handleOgImagePropChange"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row v-if="previewImage">
										<v-col>
											<fieldset
												class="talentics-fieldset talentics-fieldset-paddings"
											>
												<legend>
													{{ $t('label.preview') }}
												</legend>
												<img :src="previewImage" style="width: 100%" />
											</fieldset>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<v-btn
												color="secondary"
												@click="handleOgPreferenceUpdateClick"
											>
												<v-icon left>send</v-icon>
												<span v-if="ogPreferenceId === 0">{{
													$t('button.add')
												}}</span>
												<span v-else>{{ $t('button.update') }}</span>
											</v-btn>
											<v-btn text @click="handleCancelClick">
												{{ $t('button.cancel') }}
											</v-btn>
										</v-col>
									</v-row>
								</ValidationObserver>
							</div>
						</v-container>
					</fieldset>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ColorPicker from '@/components/shared/colorPicker/ColorPicker';
import CdnFilePicker from '@/components/shared/cdnFilePicker/CdnFilePicker';

export default {
	name: 'OpenGraph',
	components: {
		ValidationObserver,
		ValidationProvider,
		ColorPicker,
		CdnFilePicker,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			items: [],
			ogPreferenceId: null,
			previewImage: null,
			item: {
				id: null,
				action_text: 'JOIN US',
				background_color: '#000000',
				button_color: '#ffffff',
				button_text_color: '#000000',
				font_color: '#ffffff',
				font_name: 'Verdana',
				font_size: '30px',
				logo_url:
					'https://cdn.talentics.app/talentics-public/talentics-og-logo-white.png',
				name: this.$t('text.new-preference'),
				background_image: null,
			},
			sampleText: 'JavaScript Engineer',
		};
	},
	methods: {
		handleGetOgPreference({ id = null }) {
			api.ogPreference.get({ id }).then(response => {
				if (id) {
					this.item = { ...response.data };
					this.handleGetPreviewImage();
				} else {
					const { items } = response.data;
					this.items = [
						...(items.length === 0
							? [{ name: this.$t('text.new-preference'), id: 0 }]
							: []),
						...items,
					];
				}
			});
		},
		handleGetPreviewImage() {
			const {
				action_text,
				background_color,
				button_color,
				button_text_color,
				font_color,
				font_name,
				font_size,
				logo_url,
				background_image,
			} = this.item;
			const { sampleText } = this;
			const payload = {
				...{
					job_name: sampleText,
				},
				...{
					og_preference: {
						action_text,
						background_color,
						button_color,
						button_text_color,
						font_color,
						font_name,
						font_size,
						logo_url,
						background_image,
					},
				},
			};
			api.ogImage.preview.post(payload).then(response => {
				const { data } = response.data;
				this.previewImage = data;
			});
		},
		handleOgPreferenceChange({ newVal }) {
			if (newVal !== null && newVal !== 0) {
				this.handleGetOgPreference({ id: newVal });
			} else if (newVal === 0) {
				this.item = {
					...this.item,
					id: 0,
				};
				this.handleGetPreviewImage();
			}
		},
		handleOgPreferenceUpdateClick() {
			const { item } = this;
			const { id, ...rest } = item;
			if (id === 0) {
				api.ogPreference.post({ ...rest }).then(() => {
					this.ogPreferenceId = null;
					this.handleGetOgPreference({ id: null });
					this.$store.dispatch('ogPreferences/get', {
						updateStore: true,
						refresh: true,
					});
				});
			} else {
				api.ogPreference.put({ id, ...rest }).then(() => {
					this.ogPreferenceId = null;
					this.$store.dispatch('ogPreferences/get', {
						updateStore: true,
						refresh: true,
					});
				});
			}
		},
		handleCancelClick() {
			this.ogPreferenceId = null;
		},
		handleOgImagePropChange() {
			this.handleGetPreviewImage();
		},
	},
	mounted() {
		this.handleGetOgPreference({ id: null });
	},
};
</script>
