<template>
	<div>
		<div v-for="(item, index) in jsonSchema" :key="index">
			<DynamicFormText
				v-if="item.type === 'text' || item.type === 'number'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:maxLength="item.maxLength"
				:inputType="item.type"
				:rules="item.rules"
				:min="item.min"
				:max="item.max"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormText>

			<DynamicFormTextArea
				v-else-if="item.type === 'textarea'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:maxLength="item.maxLength"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormTextArea>

			<DynamicFormDatePicker
				v-else-if="item.type === 'datePicker'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormDatePicker>

			<DynamicFormSwitch
				v-else-if="item.type === 'switch'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormSwitch>

			<DynamicFormRadio
				v-else-if="item.type === 'radio'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:options="item.options"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormRadio>

			<DynamicFormSelect
				v-else-if="item.type === 'select'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:options="item.options"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormSelect>

			<div v-else-if="item.type === 'file'">
				<DynamicFormFile
					:value="contextSchema[item.paramName]"
					:label="item.label"
					:description="item.description"
					:acceptedFiles="item.acceptedFiles"
					:required="item.required"
					:onChange="
						({ newVal }) =>
							handleFormParamChange({
								newVal,
								paramName: item.paramName,
							})
					"
					:readOnly="readOnly"
				></DynamicFormFile>
			</div>

			<DynamicFormCheckbox
				v-else-if="item.type === 'checkbox'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:required="item.required"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormCheckbox>
			<DynamicFormWysiwyg
				v-else-if="item.type === 'wysiwyg'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormWysiwyg>

			<DynamicFormScorecard
				v-else-if="item.type === 'scorecard'"
				:label="item.label"
				:description="item.description"
				:jsonSchema="
					jsonSchema.find(schemaItem => schemaItem.paramName === item.paramName)
						.json_schema
				"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormScorecard>

			<DynamicFormScorecardSliderItem
				v-else-if="item.type === 'scorecardSliderItem'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:options="item.options"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormScorecardSliderItem>

			<DynamicFormScorecardDropdownItem
				v-else-if="item.type === 'scorecardDropdownItem'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:options="item.options"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormScorecardDropdownItem>

			<DynamicFormScorecardRadioItem
				v-else-if="item.type === 'scorecardRadioItem'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:description="item.description"
				:required="item.required"
				:options="item.options"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormScorecardRadioItem>

			<DynamicFormUserPicker
				v-else-if="item.type === 'userPicker'"
				:value="contextSchema[item.paramName]"
				:label="item.label"
				:required="item.required"
				:multiple="item.multiple"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormUserPicker>

			<DynamicFormTextBuilder
				v-else-if="item.type === 'textBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormTextBuilder>

			<DynamicFormTextAreaBuilder
				v-else-if="item.type === 'textareaBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormTextAreaBuilder>

			<DynamicFormDatePickerBuilder
				v-else-if="item.type === 'datePickerBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormDatePickerBuilder>

			<DynamicFormSelectBuilder
				v-else-if="item.type === 'selectBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormSelectBuilder>

			<DynamicFormFileBuilder
				v-else-if="item.type === 'fileBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormFileBuilder>

			<DynamicFormSwitchBuilder
				v-else-if="item.type === 'switchBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormSwitchBuilder>

			<DynamicFormRadioBuilder
				v-else-if="item.type === 'radioBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormRadioBuilder>

			<DynamicFormCheckboxBuilder
				v-else-if="item.type === 'checkboxBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormCheckboxBuilder>

			<DynamicFormWysiwygBuilder
				v-else-if="item.type === 'wysiwygBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormWysiwygBuilder>

			<DynamicFormScorecardSliderItemBuilder
				v-else-if="item.type === 'scorecardSliderItemBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormScorecardSliderItemBuilder>

			<DynamicFormScorecardDropdownItemBuilder
				v-else-if="item.type === 'scorecardDropdownItemBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormScorecardDropdownItemBuilder>

			<DynamicFormScorecardRadioItemBuilder
				v-else-if="item.type === 'scorecardRadioItemBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormScorecardRadioItemBuilder>

			<DynamicFormScorecardBuilder
				v-else-if="item.type === 'scorecardBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormScorecardBuilder>

			<DynamicFormUserPickerBuilder
				v-else-if="item.type === 'userPickerBuilder'"
				:description="item.description"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:isFirstItem="index === 0"
				:isLastItem="index === jsonSchema.length - 1"
				:onMoveItem="
					({ direction }) =>
						handleMoveItem({
							formDirection: direction,
							formItemIndex: index,
						})
				"
			></DynamicFormUserPickerBuilder>

			<DynamicFormArray
				v-else-if="item.type === 'array'"
				:label="item.label"
				:description="item.description"
				:jsonSchema="
					jsonSchema.find(schemaItem => schemaItem.paramName === item.paramName)
						.children
				"
				:contextSchema="contextSchema[item.paramName]"
				:onChange="
					({ newVal }) =>
						handleFormParamChange({
							newVal,
							paramName: item.paramName,
						})
				"
				:onDelete="
					() =>
						onDelete({
							formItemIndex: index,
							formItemKey: item.paramName,
						})
				"
				:readOnly="readOnly"
			></DynamicFormArray>

			<div v-else>
				{{ $t('text.type-not-supported-yet', { type: item.type }) }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DynamicForm',
	components: {
		DynamicFormText: () => import('./dynamicFormText/DynamicFormText'),
		DynamicFormFile: () => import('./dynamicFormFile/DynamicFormFile'),
		DynamicFormSwitch: () => import('./dynamicFormSwitch/DynamicFormSwitch'),
		DynamicFormRadio: () => import('./dynamicFormRadio/DynamicFormRadio'),
		DynamicFormSelect: () => import('./dynamicFormSelect/DynamicFormSelect'),
		DynamicFormTextArea: () =>
			import('./dynamicFormTextArea/DynamicFormTextArea'),
		DynamicFormDatePicker: () =>
			import('./dynamicFormDatePicker/DynamicFormDatePicker'),
		DynamicFormCheckbox: () =>
			import('./dynamicFormCheckbox/DynamicFormCheckbox'),
		DynamicFormWysiwyg: () => import('./dynamicFormWysiwyg/DynamicFormWysiwyg'),
		DynamicFormScorecardSliderItem: () =>
			import('./dynamicFormScorecardSliderItem/DynamicFormScorecardSliderItem'),
		DynamicFormScorecardDropdownItem: () =>
			import(
				'./dynamicFormScorecardDropdownItem/DynamicFormScorecardDropdownItem'
			),
		DynamicFormScorecardRadioItem: () =>
			import('./dynamicFormScorecardRadioItem/DynamicFormScorecardRadioItem'),
		DynamicFormScorecard: () =>
			import('./dynamicFormScorecard/DynamicFormScorecard'),
		DynamicFormUserPicker: () =>
			import('./dynamicFormUserPicker/DynamicFormUserPicker'),
		DynamicFormArray: () => import('./dynamicFormArray/DynamicFormArray'),
		DynamicFormTextBuilder: () =>
			import('./dynamicFormTextBuilder/DynamicFormTextBuilder'),
		DynamicFormTextAreaBuilder: () =>
			import('./dynamicFormTextAreaBuilder/DynamicFormTextAreaBuilder'),
		DynamicFormSelectBuilder: () =>
			import('./dynamicFormSelectBuilder/DynamicFormSelectBuilder'),
		DynamicFormFileBuilder: () =>
			import('./dynamicFormFileBuilder/DynamicFormFileBuilder'),
		DynamicFormSwitchBuilder: () =>
			import('./dynamicFormSwitchBuilder/DynamicFormSwitchBuilder'),
		DynamicFormRadioBuilder: () =>
			import('./dynamicFormRadioBuilder/DynamicFormRadioBuilder'),
		DynamicFormDatePickerBuilder: () =>
			import('./dynamicFormDatePickerBuilder/DynamicFormDatePickerBuilder'),
		DynamicFormCheckboxBuilder: () =>
			import('./dynamicFormCheckboxBuilder/DynamicFormCheckboxBuilder'),
		DynamicFormWysiwygBuilder: () =>
			import('./dynamicFormWysiwygBuilder/DynamicFormWysiwygBuilder'),
		DynamicFormScorecardSliderItemBuilder: () =>
			import(
				'./dynamicFormScorecardSliderItemBuilder/DynamicFormScorecardSliderItemBuilder'
			),
		DynamicFormScorecardDropdownItemBuilder: () =>
			import(
				'./dynamicFormScorecardDropdownItemBuilder/DynamicFormScorecardDropdownItemBuilder'
			),
		DynamicFormScorecardRadioItemBuilder: () =>
			import(
				'./dynamicFormScorecardRadioItemBuilder/DynamicFormScorecardRadioItemBuilder'
			),
		DynamicFormScorecardBuilder: () =>
			import('./dynamicFormScorecardBuilder/DynamicFormScorecardBuilder'),
		DynamicFormUserPickerBuilder: () =>
			import('./dynamicFormUserPickerBuilder/DynamicFormUserPickerBuilder'),
	},
	props: {
		jsonSchema: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		contextSchema: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
		onChange: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onDelete: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onMove: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		readOnly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	methods: {
		handleFormParamChange({ newVal, paramName }) {
			this.onChange({
				newVal: {
					...this.contextSchema,
					[paramName]: newVal,
				},
			});
		},
		handleMoveItem({ formDirection, formItemIndex }) {
			const newFormItemIndex =
				formItemIndex + (formDirection === 'upwards' ? -1 : 1);
			const { jsonSchema } = this;
			let jsonSchemaEditable = JSON.parse(JSON.stringify(jsonSchema));
			const tempElement = jsonSchemaEditable[formItemIndex];

			jsonSchemaEditable[formItemIndex] = jsonSchemaEditable[newFormItemIndex];
			jsonSchemaEditable[newFormItemIndex] = tempElement;
			this.onMove({
				newVal: [...jsonSchemaEditable],
			});
		},
	},
};
</script>
