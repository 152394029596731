import api from '@/api';

const state = {
	feedback_items: [],
	form_items: [],
};

const getters = {};

const actions = {
	get_feedbacks: ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			api.feedbacks.incomplete
				.get(params)
				.then(response => {
					const { items } = response.data;
					commit('setFeedbackItems', { items });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	get_forms: ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			api.forms.incomplete
				.get(params)
				.then(response => {
					const { items } = response.data;
					commit('setFormItems', { items });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setFeedbackItems: (state, { items }) => {
		state.feedback_items = items;
	},
	setFormItems: (state, { items }) => {
		state.form_items = items;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
