import api from '@/api';

const state = {
	items: [],
	lastUpdatedAt: 0,
};

const getters = {};

const actions = {
	get: ({ commit }, { timeMin, timeMax, userId }) => {
		return new Promise((resolve, reject) => {
			api.events
				.get({ timeMin, timeMax, userId })
				.then(response => {
					const { items } = response.data;
					commit('setEvents', { items });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setEvents: (state, { items }) => {
		const now = new Date();
		state.items = items;
		state.lastUpdatedAt = now.getTime();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
