<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex>
				<v-card tile elevation="0">
					<div style="padding: 0.5rem">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									icon
									:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
									@click="handleRefresh"
								>
									<v-icon>refresh</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.refresh') }}
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
									icon
									@click="handleAddEventType"
								>
									<v-icon>add</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.add') }}
						</v-tooltip>
					</div>
					<v-divider></v-divider>
					<v-simple-table class="event-types-table">
						<thead>
							<tr>
								<th class="text-left">
									{{ $t('label.name') }}
								</th>
								<th class="text-left">
									{{ $t('label.length') }}
								</th>
								<th class="text-left">
									{{ $t('label.organizer') }}
								</th>
								<th class="text-left">
									{{ $t('label.interviewers') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="eventTypes.length === 0">
							<tr>
								<td colspan="4" class="text-center">
									{{ $t('err.no-event-types-found') }}
								</td>
							</tr>
						</tbody>
						<tbody>
							<tr
								v-for="(eventType, index) in eventTypes"
								:key="index"
								@click="() => handleEventClick({ id: eventType.id })"
								:style="`cursor: pointer; opacity: ${
									eventType.active ? '1' : '0.5'
								};`"
							>
								<td>{{ eventType.name }}</td>
								<td>{{ eventType.default_length }}</td>
								<td>
									<UserChips
										:userIds="
											eventType.organizer_id ? [eventType.organizer_id] : []
										"
									></UserChips>
								</td>
								<td>
									<UserChips
										:userIds="eventType.default_interviewers"
									></UserChips>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card>
				<router-view></router-view>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import UserChips from '@/components/shared/userChips/UserChips';

export default {
	name: 'Users',
	components: {
		UserChips,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			isDark: state => state.currentUser.isDark,
			eventTypes: state => state.eventTypes.items,
			currentLang: state => state.currentUser.lang,
		}),
	},
	data() {
		return {
			tab: 0,
		};
	},
	methods: {
		handleRefresh() {
			this.$store.dispatch('eventTypes/get', {
				refresh: true,
				updateStore: true,
			});
		},
		handleAddEventType() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/settings/instant-booking/new`,
				query,
			});
		},
		handleEventClick({ id }) {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/settings/instant-booking/edit/${id}`,
				query,
			});
		},
	},
	created() {
		this.handleRefresh();
	},
};
</script>

<style lang="scss">
.v-data-table {
	&.event-types-table {
		border-radius: 0;
		border-top: thin solid rgba(0, 0, 0, 0.12);
		border-bottom: thin solid rgba(0, 0, 0, 0.12);

		&.theme--light {
			background-color: rgba(242, 245, 245, 0.8);
		}
	}
}
</style>
