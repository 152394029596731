var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"max-width":"980px","padding-top":"1rem"}},[_c('v-container',[_vm._l((_vm.formRulesets),function(formRuleset,index){return _c('v-row',{key:`formRuleset_${index}`},[_c('v-col',[_c('fieldset',{staticClass:"talentics-fieldset"},[_c('legend',[_vm._v(" "+_vm._s(_vm.$t('label.rule-no', { index: index + 1 }))+" ")]),_c('v-container',[_c('v-row',[_c('v-col',[_c('StagePicker',{attrs:{"label":_vm.$t('label.prev-status'),"rules":"required","disabled":""},model:{value:(formRuleset.prev_status),callback:function ($$v) {_vm.$set(formRuleset, "prev_status", $$v)},expression:"formRuleset.prev_status"}})],1),_c('v-col',[_c('StagePicker',{attrs:{"label":_vm.$t('label.next-status'),"rules":"required","disabled":""},model:{value:(formRuleset.next_status),callback:function ($$v) {_vm.$set(formRuleset, "next_status", $$v)},expression:"formRuleset.next_status"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"label":_vm.$t('label.form-template'),"items":_vm.formTemplates.map(item => ({
												value: item.id,
												text: item.label,
											})),"hide-details":"","outlined":"","dense":"","error-messages":errors,"disabled":""},model:{value:(formRuleset.form_id),callback:function ($$v) {_vm.$set(formRuleset, "form_id", $$v)},expression:"formRuleset.form_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.handleDeleteItem({
											id: formRuleset.id,
										})}}},[_vm._v(" "+_vm._s(_vm.$t('button.delete'))+" ")])],1)],1)],1)],1)])],1)}),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',[_c('fieldset',{staticClass:"talentics-fieldset"},[_c('legend',[_vm._v(_vm._s(_vm.$t('label.add-new-rule')))]),_c('ValidationObserver',{ref:"form_rulesets_form",attrs:{"tag":"form","id":"form_rulesets_form"},on:{"submit":_vm.handleSubmit}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('StagePicker',{attrs:{"label":_vm.$t('label.prev-status'),"rules":"required"},model:{value:(_vm.form.prev_status),callback:function ($$v) {_vm.$set(_vm.form, "prev_status", $$v)},expression:"form.prev_status"}})],1),_c('v-col',[_c('StagePicker',{attrs:{"label":_vm.$t('label.next-status'),"rules":"required"},model:{value:(_vm.form.next_status),callback:function ($$v) {_vm.$set(_vm.form, "next_status", $$v)},expression:"form.next_status"}})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"label":_vm.$t('label.form-template'),"items":_vm.formTemplates.map(item => ({
													value: item.id,
													text: item.label,
												})),"hide-details":"","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.form.form_id),callback:function ($$v) {_vm.$set(_vm.form, "form_id", $$v)},expression:"form.form_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('button.add'))+" ")])],1)],1)],1)],1)],1)])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }