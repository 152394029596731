import axios from 'axios';
import api from '@/api';
import { signOut } from 'firebase/auth';
import { auth } from '../../main';

const state = {
	currentUser: null,
	googleUser: null,
	lang: 'en',
	isDark: false,
	lastUpdatedAt: 0,
	provider: null,
	paneMode: 'nosplit',
	listViewMode: 'basic',
	userRole: 300,
};

const getters = {};

const actions = {
	setRole: ({ commit }, { role }) => {
		commit('setRole', { role });
	},
	authUser: ({ commit, dispatch }, { idToken }) => {
		return new Promise((resolve, reject) => {
			api.authUser
				.post({ idToken })
				.then(response => {
					commit('setCurrentUser', { currentUser: response.data });
					commit('setLang', { lang: response.data.lang });
					commit('setIsDark', { isDark: response.data.is_dark });
					commit('setPaneMode', {
						paneMode: response.data.pane_mode,
					});
					commit('setListViewMode', {
						listViewMode: response.data.list_view_mode,
					});
					resolve(response);
				})
				.catch(error => {
					dispatch('signOut');
					reject(error);
				});
		});
	},
	signOut: ({ commit }) => {
		return new Promise((resolve, reject) => {
			if (state.provider === 'microsoft') {
				signOut(auth)
					.then(() => {
						commit('emptyCurrentUser');
						resolve('success');
					})
					.catch(error => {
						reject(error);
					});
			} else {
				window.gapi.auth2
					.getAuthInstance()
					.signOut()
					.then(() => {
						signOut(auth)
							.then(() => {
								commit('emptyCurrentUser');
								resolve('success');
							})
							.catch(error => {
								reject(error);
							});
					});
			}
		});
	},
	putCode: ({ commit }, { code, is_calendar_synced }) => {
		// eslint-disable-line
		return new Promise((resolve, reject) => {
			api.code
				.put({ code, is_calendar_synced })
				.then(response => {
					const { is_calendar_synced } = response.data;
					commit('setCalendarSynced', { is_calendar_synced });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	revokeMicrosoftCalendarConsent: ({ commit }, { userId }) => {
		return new Promise((resolve, reject) => {
			api.revokeMicrosoftCalendarConsent
				.post({ userId })
				.then(response => {
					if (response.status === 200) {
						commit('setCalendarSynced', {
							is_calendar_synced: false,
						});
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	revokeMicrosoftMailConsent: ({ commit }, { userId }) => {
		return new Promise((resolve, reject) => {
			api.revokeMicrosoftMailConsent
				.post({ userId })
				.then(response => {
					if (response.status === 200) {
						commit('setMailSynced', { is_mail_synced: false });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setCurrentUser: (state, { currentUser }) => {
		const now = new Date();
		const { id_token } = currentUser;
		axios.defaults.headers.common['X-Auth-Token'] = id_token;
		state.currentUser = currentUser;
		state.lastUpdatedAt = now.getTime();
	},
	setRole: (state, { role }) => {
		state.userRole = role;
	},
	setIdToken: (state, { id_token }) => {
		axios.defaults.headers.common['X-Auth-Token'] = id_token;
		state.currentUser.id_token = id_token;
	},
	emptyCurrentUser: state => {
		const now = new Date();
		delete axios.defaults.headers.common['X-Auth-Token'];
		state.currentUser = null;
		state.lastUpdatedAt = now.getTime();
	},
	setGoogleUser: (state, { user }) => {
		state.googleUser = user;
	},
	setLang: (state, { lang }) => {
		state.lang = lang;
	},
	setIsDark: (state, { isDark }) => {
		state.isDark = isDark;
	},
	setCalendarSynced: (state, { is_calendar_synced }) => {
		state.currentUser = {
			...state.currentUser,
			is_calendar_synced,
		};
	},
	setMailSynced: (state, { is_mail_synced }) => {
		state.currentUser = {
			...state.currentUser,
			is_mail_synced,
		};
	},
	setProvider: (state, { provider }) => {
		state.provider = provider;
	},
	setUuidv4: (state, { auth_uuidv4 }) => {
		state.currentUser = {
			...state.currentUser,
			auth_uuidv4,
		};
	},
	setPaneMode: (state, { paneMode }) => {
		state.paneMode = paneMode;
	},
	setListViewMode: (state, { listViewMode }) => {
		state.listViewMode = listViewMode;
	},
	setTimezone: (state, { timezone }) => {
		state.currentUser = {
			...state.currentUser,
			timezone,
		};
	},
	setProps: (state, params) => {
		state.currentUser = {
			...state.currentUser,
			...params,
		};
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
