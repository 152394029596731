var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(`
      background: ${_vm.isDark ? '#424242' : '#eeeeee'};
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-y: auto;
    `)},[_c('v-container',[_vm._l((_vm.reportTemplates),function(reportTemplate,index){return _c('v-row',{key:`reportTemplates_${index}`},[_c('v-col',[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v(_vm._s(reportTemplate.label))])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"secondary","to":`/${_vm.currentLang}/reports/export/${reportTemplate.uuid}`}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("upload_file")]),_vm._v(" "+_vm._s(_vm.$t('button.export'))+" ")],1)],1)],1)],1)],1)],1)],1)],1)}),(_vm.reportTemplates.length === 0)?_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"color":"red","dark":"","icon":"report_problem"}},[_vm._v(" "+_vm._s(_vm.$t('err.no-feedback-templates-found'))+" ")])],1)],1)],1)],1)],1)],1)],1):_vm._e()],2),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }