<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="800px"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title v-if="requestMethod === 'post'">{{
							$t('text.add-new-candidate')
						}}</v-toolbar-title>
						<v-toolbar-title v-else>{{
							$t('text.edit-candidate')
						}}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>

				<v-card-text
					style="padding: 0 0.5rem"
					ref="autofillCandidatePropsDialogContent"
				>
					<ValidationObserver
						ref="form_job_picker"
						tag="form"
						@submit="handleSubmit"
						id="form_job_picker"
					>
						<v-container fluid>
							<v-row v-if="requestMethod === 'post'">
								<v-col>
									<JobPicker
										v-model="job_id"
										:jobs="jobs.filter(item => item.status === 'open')"
										:label="`${$t('label.job')}*`"
										@input="handleJobChange"
										rules="required"
										hide-inactive-teams
										hide-inactive-departments
										dense
									></JobPicker>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row v-if="items.length > 0">
								<v-col class="d-flex" align-self="center">
									<!-- <span class="ml-1">{{ applications[applicationIndex].job_name }}</span> -->
									<v-spacer></v-spacer>
									<span class="mx-3">{{
										$t('text.email-counts', {
											index: currentItemIndex + 1,
											counts: items.length,
										})
									}}</span>
								</v-col>
								<v-col cols="auto">
									<v-btn
										@click="currentItemIndex -= 1"
										:disabled="currentItemIndex === 0"
										small
									>
										<v-icon left>keyboard_arrow_left</v-icon>
										{{ $t('button.prev') }}
									</v-btn>
								</v-col>
								<v-col cols="auto">
									<v-btn
										@click="currentItemIndex += 1"
										:disabled="currentItemIndex === items.length - 1"
										small
									>
										{{ $t('button.next') }}
										<v-icon right>keyboard_arrow_right</v-icon>
									</v-btn>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>

					<v-tabs-items v-model="currentItemIndex">
						<v-tab-item v-for="(item, key) in items" :key="`item_${key}`" eager>
							<AutofillCandidateItem
								:requestMethod="requestMethod"
								:fileId="item.fileId"
								:jobId="job_id"
								:ref="`autofill_candidate_item_${item.fileId}`"
							></AutofillCandidateItem>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="handleClose">{{ $t('button.close') }}</v-btn>
					<v-btn @click="handleSubmit" color="primary" dark>{{
						$t('button.apply')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
import JobPicker from '@/components/shared/jobPicker/JobPicker';
import AutofillCandidateItem from './autofillCandidateItem/AutofillCandidateItem';
import api from '@/api';

export default {
	name: 'Autofill',
	components: {
		ValidationObserver,
		JobPicker,
		AutofillCandidateItem,
	},
	props: {
		onClose: {
			type: Function,
			required: false,
			default: function () {
				return false;
			},
		},
		candidate_id: {
			type: [Number, String],
			required: false,
		},
		requestMethod: {
			type: String,
			required: false,
			default: function () {
				return 'put';
			},
		},
		fileId: {
			type: [Number, String],
			required: false,
		},
		items: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			jobs: state => state.jobs.items,
		}),
	},
	data() {
		return {
			job_id: null,
			isVisible: false,
			currentItemIndex: 0,
		};
	},
	methods: {
		handleJobChange(job_id) {
			this.job_id = job_id;
		},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleClose() {
			this.onClose({ needsRefresh: false });
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isJobPickerValid = await this.$refs.form_job_picker.validate();
			if (!isJobPickerValid) {
				return false;
			}

			const areCandidateProfilesValid = await Promise.all(
				this.items.map(async item => {
					return await this.$refs[
						`autofill_candidate_item_${item.fileId}`
					][0].handleValidate();
				}),
			);

			const firstInValidCanditeProfileIndex = areCandidateProfilesValid.reduce(
				(acc, item, itemIndex) => {
					if (acc === null && item !== true) {
						return itemIndex;
					}
					return acc;
				},
				null,
			);

			if (firstInValidCanditeProfileIndex !== null) {
				this.currentItemIndex = firstInValidCanditeProfileIndex;
				return false;
			}

			const items = await Promise.all(
				this.items.map(async item => {
					return this.$refs[
						`autofill_candidate_item_${item.fileId}`
					][0].handleGetCandidateItem();
				}),
			);

			if (this.requestMethod === 'put') {
				api.candidates.put({ items }).then(() => {
					this.onClose({ needsRefresh: true });
				});
			} else if (this.requestMethod === 'post') {
				api.candidates.post({ items }).then(() => {
					this.onClose({ needsRefresh: true });
				});
			}
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			500,
		);
	},
};
</script>
