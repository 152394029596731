<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="600"
			persistent
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
		>
			<v-card>
				<v-card-text style="padding: 1rem">
					<ValidationObserver
						ref="form_hiree_info"
						tag="form"
						@submit="handleSubmit"
						id="form_hiree_info"
					>
						<v-container>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="form.hiree_type"
											:items="[
												{
													text: $t('label.new-position'),
													value: 'new-position',
												},
												{
													text: $t('label.replacement'),
													value: 'replacement',
												},
											]"
											:label="$t('label.type')"
											outlined
											hide-details
											dense
											@change="handleChangeHireeType"
											:error-messages="errors"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row v-if="form.is_replacement">
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="form.replacement_for"
											:label="`${$t('label.replacement-for')}*`"
											flat
											hide-details
											outlined
											:error-messages="errors"
											name="name"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.is-internally-referenced') }}:
											{{
												form.is_internally_referenced
													? $t('label.yes')
													: $t('label.no')
											}}
										</legend>
										<v-switch
											v-model="form.is_internally_referenced"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row v-if="form.is_internally_referenced">
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="form.internal_reference_name"
											:label="`${$t('label.internal-refererence-name')}*`"
											flat
											hide-details
											outlined
											:error-messages="errors"
											name="name"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.is-internal-search') }}:
											{{
												form.is_internal_search
													? $t('label.yes')
													: $t('label.no')
											}}
										</legend>
										<v-switch
											v-model="form.is_internal_search"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row v-if="form.is_internal_search">
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="form.internal_search_user_name"
											:label="`${$t('label.internal-searcher-name')}*`"
											flat
											hide-details
											outlined
											:error-messages="errors"
											name="name"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.is-recruitment-agency') }}:
											{{
												form.is_recruitment_agency
													? $t('label.yes')
													: $t('label.no')
											}}
										</legend>
										<v-switch
											v-model="form.is_recruitment_agency"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row v-if="form.is_recruitment_agency">
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="form.recruitment_agency_name"
											:label="`${$t('label.recruitment-agency-name')}*`"
											flat
											hide-details
											outlined
											:error-messages="errors"
											name="name"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<DatePicker
											v-model="form.start_date"
											@input="handleDateChange"
											:label="$t('label.first-touch')"
											full-width
											no-title
											reactive
											dense
											:error-messages="errors"
										></DatePicker>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<DatePicker
											v-model="form.end_date"
											@input="handleDateChange"
											:label="$t('label.marked-as-hired')"
											full-width
											no-title
											reactive
											dense
											:error-messages="errors"
										></DatePicker>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.time-to-hire') }}
										</legend>
										<div>{{ form.time_to_hire }} days</div>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="handleClose" text>{{ $t('button.close') }}</v-btn>
					<v-btn color="primary" @click="handleSubmit">
						<v-icon left>task</v-icon>
						{{ $t('button.save') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import api from '@/api';

export default {
	components: {
		ValidationObserver,
		ValidationProvider,
		DatePicker,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
		}),
	},
	data() {
		return {
			isVisible: false,
			form: {
				id: null,
				hiree_type: null,
				is_replacement: false,
				is_new_position: false,
				is_internally_referenced: false,
				is_internal_search: false,
				is_recruitment_agency: false,
				internal_reference_name: '',
				internal_search_user_name: '',
				recruitment_agency_name: '',
				start_date: null,
				end_date: null,
				time_to_hire: 0,
			},
		};
	},
	methods: {
		handleGetHireeInfo({ id }) {
			api.hiredCandidates.get({ id }).then(response => {
				const { items } = response.data;

				if (items && items.length > 0) {
					const {
						is_replacement,
						id,
						is_new_position,
						is_internally_referenced,
						is_internal_search,
						is_recruitment_agency,
						internal_reference_name,
						internal_search_user_name,
						recruitment_agency_name,
						start_date,
						end_date,
						time_to_hire,
						replacement_for,
					} = items[0];

					this.form = {
						...this.form,
						id,
						is_replacement,
						is_new_position,
						is_internally_referenced,
						is_internal_search,
						is_recruitment_agency,
						internal_reference_name,
						internal_search_user_name,
						recruitment_agency_name,
						start_date,
						end_date,
						time_to_hire,
						replacement_for,
						hiree_type: is_replacement
							? 'replacement'
							: is_new_position
							? 'new-position'
							: null,
					};
				}
			});
		},
		async handleSubmit() {
			const { form } = this;

			const isValid = await this.$refs.form_hiree_info.validate();

			if (isValid) {
				api.hiredCandidates.put({ items: [{ ...form }] }).then(() => {
					this.handleClose();
				});
			}
		},
		handleDateChange() {},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleChangeHireeType(newVal) {
			this.form = {
				...this.form,
				is_replacement: newVal === 'replacement',
				is_new_position: newVal === 'new-position',
			};
		},
		handleClose() {
			const { candidateId } = this.$route.params;
			const { query } = this.$route;
			const { currentLang } = this;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${candidateId}`,
				query,
			});
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
	watch: {
		'$route.params.hireeInfoId': {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.handleGetHireeInfo({ id: newVal });
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
