var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding":"0"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-flex',[_c('div',{staticStyle:{"padding":"0.5rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","color":`${_vm.isDark ? 'grey lighten-3' : 'grey darken-3'}`},on:{"click":_vm.handleRefresh}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltip.refresh'))+" ")])],1),_c('v-divider'),_c('div',[(_vm.tags.length === 0)?_c('div',{staticStyle:{"padding":"1rem"}},[_vm._v(" "+_vm._s(_vm.$t('err.no-tags-found'))+" ")]):_vm._e(),_c('v-list',[_vm._l((_vm.tags),function(item,index){return [_c('v-list-item',{key:item.created,on:{"click":() =>
									_vm.addOrEditItem({
										id: item.id,
										operation: 'edit',
									})}},[_c('v-list-item-avatar',[(!item.addButton)?_c('v-badge',{attrs:{"bottom":"","color":item.active ? 'green' : 'grey',"dot":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',[_vm._v("folder_open")])],1):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}})],1)],1),(index < _vm.tags.length - 1)?_c('v-divider',{key:index,attrs:{"inset":""}}):_vm._e()]})],2),_c('div',{staticStyle:{"padding":"1rem"}},[_c('v-btn',{attrs:{"small":""},on:{"click":() =>
								_vm.addOrEditItem({
									operation: 'add',
								})}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('button.add-tag'))+" ")],1)],1),(_vm.isTagsAttrsVisible)?_c('TagAttrs',{attrs:{"method":_vm.tagActionMethod,"tag":_vm.tagToEdit,"onClose":_vm.handleTagAttrsClose}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }