<template>
	<fieldset class="talentics-fieldset">
		<legend>{{ $t('label.link-questions') }}</legend>
		<v-container fluid>
			<v-row>
				<v-col>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'LinkedIn',
										})
								"
							>
								<v-icon>mdi-linkedin</v-icon>
							</v-btn>
						</template>
						LinkedIn
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Git',
										})
								"
							>
								<v-icon>mdi-git</v-icon>
							</v-btn>
						</template>
						Git
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Stackoverflow',
										})
								"
							>
								<v-icon>mdi-stack-overflow</v-icon>
							</v-btn>
						</template>
						Stack Overflow
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Behance',
										})
								"
							>
								<v-icon>mdi-bootstrap</v-icon>
							</v-btn>
						</template>
						Behance
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Google Drive',
										})
								"
							>
								<v-icon>mdi-google-drive</v-icon>
							</v-btn>
						</template>
						Google Drive
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Twitter',
										})
								"
							>
								<v-icon>mdi-twitter</v-icon>
							</v-btn>
						</template>
						Twitter
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Instagram',
										})
								"
							>
								<v-icon>mdi-instagram</v-icon>
							</v-btn>
						</template>
						Instagram
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Facebook',
										})
								"
							>
								<v-icon>mdi-facebook</v-icon>
							</v-btn>
						</template>
						Facebook
					</v-tooltip>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								icon
								v-on="on"
								@click="
									() =>
										handleAddPredefinedQuestion({
											platform: 'Youtube',
										})
								"
							>
								<v-icon>mdi-youtube</v-icon>
							</v-btn>
						</template>
						Youtube
					</v-tooltip>
					<v-menu
						v-model="menu"
						:close-on-content-click="false"
						:nudge-width="200"
						offset-y
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn text v-bind="attrs" v-on="on">
								{{ $t('button.other') }}
							</v-btn>
						</template>

						<v-card>
							<v-card-text>
								<ValidationObserver
									ref="otherPlatformForm"
									tag="form"
									@submit="handleAddLinkQuestion"
									id="otherPlatformForm"
								>
									<ValidationProvider v-slot="{ errors }">
										<v-text-field
											v-model="newLinkQuestion"
											outlined
											hide-details
											dense
											:placeholder="$t('label.other-platform')"
											ref="otherPlatform"
											:error-messages="errors"
										></v-text-field>
									</ValidationProvider>
								</ValidationObserver>
							</v-card-text>
							<v-divider></v-divider>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn text @click="menu = false"> Cancel </v-btn>
								<v-btn color="primary" @click="handleAddLinkQuestion">
									{{ $t('button.add') }}
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-menu>
				</v-col>
			</v-row>

			<v-row v-if="valueEditable.length > 0">
				<v-col><v-divider></v-divider></v-col>
			</v-row>

			<v-row v-for="(item, index) in valueEditable" :key="index">
				<v-col>
					<ValidationProvider v-slot="{ errors }">
						<v-select
							v-if="item.type === 'dropdown'"
							v-model="item.value"
							:label="item.label"
							:items="
								item.options.map(option => ({
									text: option.label,
									value: option.value,
								}))
							"
							outlined
							hide-details
							dense
							:error-messages="errors"
							:name="item.propName"
							@change="handleValueChange"
							:placeholder="item.label"
							class="link-question-input"
						></v-select>
						<v-text-field
							v-else
							v-model="item.value"
							:label="item.label"
							flat
							hide-details
							outlined
							:error-messages="errors"
							:name="item.propName"
							dense
							:disabled="isCandidateEdit ? true : false"
							@keyup="handleValueChange"
							:placeholder="item.label"
							class="link-question-input"
						></v-text-field>
					</ValidationProvider>
				</v-col>
				<v-col cols="auto">
					<v-btn icon @click="() => handleDeleteLinkQuestion({ index })"
						><v-icon>delete</v-icon></v-btn
					>
				</v-col>
			</v-row>
		</v-container>
	</fieldset>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: 'LinkQuestionsForm',
	props: {
		value: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		candidate: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			newLinkQuestion: null,
			valueEditable: [],
			menu: false,
			isCandidateEdit: this.$route.path.includes('candidates/edit'),
		};
	},
	methods: {
		async handleAddLinkQuestion(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.otherPlatformForm.validate();
			if (isValid) {
				this.$emit('input', [
					...this.valueEditable,
					{
						label: this.newLinkQuestion,
						value: null,
						required: false,
					},
				]);
				this.newLinkQuestion = null;
				this.menu = false;
				setTimeout(
					function () {
						this.handleFocusLastInput();
					}.bind(this),
					250,
				);
			}
		},
		handleDeleteLinkQuestion({ index }) {
			this.$emit('input', [
				...this.valueEditable.filter((item, itemIndex) => itemIndex !== index),
			]);
		},
		handleValueChange() {
			this.$emit('input', [...this.valueEditable]);
		},
		handleClickOther() {
			setTimeout(
				function () {
					this.$refs.otherPlatform.focus();
				}.bind(this),
				250,
			);
		},
		handleAddPredefinedQuestion({ platform }) {
			this.$emit('input', [
				...this.valueEditable,
				{ label: platform, value: null, required: false },
			]);
			setTimeout(
				function () {
					this.handleFocusLastInput();
				}.bind(this),
				250,
			);
		},
		handleFocusLastInput() {
			const divs = document.getElementsByClassName('link-question-input');
			if (divs.length > 0) {
				const lastDiv = divs[divs.length - 1];
				const inputs = lastDiv.getElementsByTagName('input');
				if (inputs.length > 0) {
					inputs[0].focus();
				}
			}
		},
		generateOptionsMap(linkQuestions) {
			const optionsMap = {};
			linkQuestions?.forEach(question => {
				if (!question) return;

				optionsMap[question.propName] = {};
				question.options?.forEach(option => {
					optionsMap[question.propName][option.value] = option.label;
				});
			});

			return optionsMap;
		},
		updateEditableValues(valueEditable, optionsMap) {
			return valueEditable.map(item => {
				if (item.value?.includes('option_')) {
					const { propName } = item;
					const options = optionsMap[propName];
					if (options) {
						const option = options[item.value];
						if (option) {
							item.value = option;
						}
					}
				}
				return item;
			});
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					this.valueEditable = JSON.parse(JSON.stringify(newVal));
					const candidateApplications = this.candidate?.applications;

					if (candidateApplications) {
						const linkQuestions =
							candidateApplications[0]?.link_questions_set || [];
						const optionsMap = this.generateOptionsMap(linkQuestions);

						this.valueEditable = this.updateEditableValues(
							this.valueEditable,
							optionsMap,
						);
					}
				}
			},
			deep: true,
			immediate: true,
		},
		menu: {
			handler: function (newVal) {
				if (newVal) {
					setTimeout(
						function () {
							this.$refs.otherPlatform.focus();
						}.bind(this),
						100,
					);
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
