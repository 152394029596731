<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		offset-y
		:nudge-width="250"
		top
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" icon small>
				<v-icon>add_circle</v-icon>
			</v-btn>
		</template>

		<v-card>
			<ValidationObserver
				ref="form_new_feedback"
				tag="form"
				@submit="handleStart"
				id="form_new_feedback"
			>
				<v-container fluid>
					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="template_id"
									:items="approvalTemplates.filter(item => item.active)"
									item-text="label"
									item-value="id"
									:label="$t('label.choose-approval-template')"
									outlined
									hide-details
									dense
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="email"
									:items="candidate.email"
									item-text="label"
									item-value="id"
									:label="$t('label.choose-candidate-email')"
									outlined
									hide-details
									dense
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>
				</v-container>
			</ValidationObserver>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="menu = false">
					{{ $t('button.cancel') }}
				</v-btn>
				<v-btn color="primary" @click="handleStart">
					{{ $t('button.request-approval') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: 'RequestApprovalMenu',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		onRequest: {
			type: Function,
			required: true,
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	computed: {
		...mapState({
			approvalTemplates: state => state.approvalTemplates.items,
		}),
	},
	data() {
		return {
			template_id: null,
			email: null,
			menu: false,
		};
	},
	methods: {
		initForm() {
			const { email } = this.candidate;
			if (email.length > 0) {
				this.email = email[0];
			}
			const activeApprovalTemplates = this.approvalTemplates.filter(
				item => item.active,
			);
			if (activeApprovalTemplates.length > 0) {
				const { id } = activeApprovalTemplates[0];
				this.template_id = id;
			}
		},
		async handleStart(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_new_feedback.validate();
			if (isValid) {
				const { template_id, email, candidate } = this;
				this.onRequest({
					template_id,
					status: 'requested',
					candidate_id: candidate.id,
					email,
				});
				this.menu = false;
			}
		},
	},
	watch: {
		menu: {
			handler: function (newVal) {
				if (newVal) {
					this.initForm();
				}
			},
		},
	},
};
</script>
