var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.jsonSchema),function(item,index){return _c('div',{key:index},[(item.type === 'text' || item.type === 'number')?_c('DynamicFormText',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"maxLength":item.maxLength,"inputType":item.type,"rules":item.rules,"min":item.min,"max":item.max,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'textarea')?_c('DynamicFormTextArea',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"maxLength":item.maxLength,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'datePicker')?_c('DynamicFormDatePicker',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'switch')?_c('DynamicFormSwitch',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'radio')?_c('DynamicFormRadio',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"options":item.options,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'select')?_c('DynamicFormSelect',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"options":item.options,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'file')?_c('div',[_c('DynamicFormFile',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"acceptedFiles":item.acceptedFiles,"required":item.required,"onChange":({ newVal }) =>
						_vm.handleFormParamChange({
							newVal,
							paramName: item.paramName,
						}),"readOnly":_vm.readOnly}})],1):(item.type === 'checkbox')?_c('DynamicFormCheckbox',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"required":item.required,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'wysiwyg')?_c('DynamicFormWysiwyg',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'scorecard')?_c('DynamicFormScorecard',{attrs:{"label":item.label,"description":item.description,"jsonSchema":_vm.jsonSchema.find(schemaItem => schemaItem.paramName === item.paramName)
					.json_schema,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'scorecardSliderItem')?_c('DynamicFormScorecardSliderItem',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"options":item.options,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'scorecardDropdownItem')?_c('DynamicFormScorecardDropdownItem',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"options":item.options,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'scorecardRadioItem')?_c('DynamicFormScorecardRadioItem',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"description":item.description,"required":item.required,"options":item.options,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'userPicker')?_c('DynamicFormUserPicker',{attrs:{"value":_vm.contextSchema[item.paramName],"label":item.label,"required":item.required,"multiple":item.multiple,"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"readOnly":_vm.readOnly}}):(item.type === 'textBuilder')?_c('DynamicFormTextBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'textareaBuilder')?_c('DynamicFormTextAreaBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'datePickerBuilder')?_c('DynamicFormDatePickerBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'selectBuilder')?_c('DynamicFormSelectBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'fileBuilder')?_c('DynamicFormFileBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'switchBuilder')?_c('DynamicFormSwitchBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'radioBuilder')?_c('DynamicFormRadioBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'checkboxBuilder')?_c('DynamicFormCheckboxBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'wysiwygBuilder')?_c('DynamicFormWysiwygBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'scorecardSliderItemBuilder')?_c('DynamicFormScorecardSliderItemBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'scorecardDropdownItemBuilder')?_c('DynamicFormScorecardDropdownItemBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'scorecardRadioItemBuilder')?_c('DynamicFormScorecardRadioItemBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'scorecardBuilder')?_c('DynamicFormScorecardBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'userPickerBuilder')?_c('DynamicFormUserPickerBuilder',{attrs:{"description":item.description,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"isFirstItem":index === 0,"isLastItem":index === _vm.jsonSchema.length - 1,"onMoveItem":({ direction }) =>
					_vm.handleMoveItem({
						formDirection: direction,
						formItemIndex: index,
					})}}):(item.type === 'array')?_c('DynamicFormArray',{attrs:{"label":item.label,"description":item.description,"jsonSchema":_vm.jsonSchema.find(schemaItem => schemaItem.paramName === item.paramName)
					.children,"contextSchema":_vm.contextSchema[item.paramName],"onChange":({ newVal }) =>
					_vm.handleFormParamChange({
						newVal,
						paramName: item.paramName,
					}),"onDelete":() =>
					_vm.onDelete({
						formItemIndex: index,
						formItemKey: item.paramName,
					}),"readOnly":_vm.readOnly}}):_c('div',[_vm._v(" "+_vm._s(_vm.$t('text.type-not-supported-yet', { type: item.type }))+" ")])],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }