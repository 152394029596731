<template>
	<v-container fluid>
		<v-row>
			<v-col>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-select
						v-model="editableEmailForm.send_time"
						@change="value => handleChangeForm({ key: 'send_time', value })"
						:items="
							sendTimes.filter(({ value }) => {
								if (sendOnApprovalFlowComplete) {
									return true;
								}
								return value !== 'afc';
							})
						"
						:item-text="item => $t(`days.${item.value}`)"
						item-value="value"
						:label="$t('label.send-time')"
						outlined
						hide-details
						dense
						:readonly="sendOnApprovalFlowComplete || previewMode"
						:error-messages="errors"
					>
						<template
							v-slot:selection="{ item }"
							v-if="!sendOnApprovalFlowComplete"
						>
							<span>{{ $t(`days.${item.value}`) }}</span>
							<span
								class="grey--text caption"
								v-if="editableEmailForm.send_at"
								style="margin-left: 0.5rem"
							>
								({{
									formatDate({
										timestamp: editableEmailForm.send_at,
									})
								}}
								<v-btn
									x-small
									text
									icon
									@click="handleEditSendAtClick"
									v-if="editableEmailForm.send_time !== '0d'"
									><v-icon>edit</v-icon></v-btn
								>)
							</span>
						</template>
					</v-select>
				</ValidationProvider>
			</v-col>
		</v-row>

		<v-row v-if="isCustomSendTimeVisible">
			<v-col>
				<fieldset class="talentics-fieldset">
					<v-container>
						<v-row>
							<v-col>
								<v-select
									v-model="customSendTime.hours"
									:items="
										Array.from(Array(24).keys()).map(item => ({
											text: formatDateItem({
												val: item,
											}),
											value: item,
										}))
									"
									hide-details
									dense
									outlined
									:label="$t('label.hours')"
									@change="
										newVal =>
											handleCustomSendTimeChange({
												newVal,
												prop: 'hours',
											})
									"
								></v-select>
							</v-col>
							<v-col>
								<v-select
									v-model="customSendTime.minutes"
									:items="
										Array.from(Array(60).keys()).map(item => ({
											text: formatDateItem({
												val: item,
											}),
											value: item,
										}))
									"
									hide-details
									dense
									outlined
									:label="$t('label.minutes')"
									@change="
										newVal =>
											handleCustomSendTimeChange({
												newVal,
												prop: 'minutes',
											})
									"
								></v-select>
							</v-col>
							<v-col>
								<v-select
									v-model="customSendTime.seconds"
									:items="
										Array.from(Array(60).keys()).map(item => ({
											text: formatDateItem({
												val: item,
											}),
											value: item,
										}))
									"
									hide-details
									dense
									outlined
									:label="$t('label.seconds')"
									@change="
										newVal =>
											handleCustomSendTimeChange({
												newVal,
												prop: 'seconds',
											})
									"
								></v-select>
							</v-col>
						</v-row>
					</v-container>
				</fieldset>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-select
						v-model="editableEmailForm.sent_from"
						@change="value => handleChangeForm({ key: 'sent_from', value })"
						:items="fromEmails"
						item-text="text"
						item-value="value"
						:label="$t('label.from')"
						outlined
						hide-details
						dense
						:readonly="previewMode"
						:error-messages="errors"
					></v-select>
				</ValidationProvider>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-select
						v-model="editableEmailForm.send_to"
						@change="value => handleChangeForm({ key: 'send_to', value })"
						:items="
							candidateEmails.map(item => ({
								text: item,
								value: item,
							}))
						"
						item-text="text"
						item-value="value"
						:label="$t('label.to')"
						outlined
						hide-details
						dense
						:readonly="previewMode"
						:error-messages="errors"
					></v-select>
				</ValidationProvider>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-autocomplete
					v-model="editableEmailForm.cc"
					@change="value => handleChangeForm({ key: 'cc', value })"
					:items="users.map(item => ({ value: item.email }))"
					:label="$t('label.cc')"
					:readonly="previewMode"
					item-text="value"
					item-value="value"
					hide-no-data
					hide-details
					dense
					outlined
					multiple
					chips
					deletable-chips
					class="v-select-talentics-chips"
				>
				</v-autocomplete>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-autocomplete
					v-model="editableEmailForm.bcc"
					@change="value => handleChangeForm({ key: 'bcc', value })"
					:items="users.map(item => ({ value: item.email }))"
					:label="$t('label.bcc')"
					:readonly="previewMode"
					item-text="value"
					item-value="value"
					hide-no-data
					hide-details
					dense
					outlined
					multiple
					chips
					deletable-chips
					class="v-select-talentics-chips"
				>
				</v-autocomplete>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<v-text-field
						v-if="previewMode"
						v-model="completedEmailForm.subject"
						:label="$t('label.subject')"
						outlined
						dense
						hide-details
						:disabled="true"
						:error-messages="errors"
					></v-text-field>
					<v-text-field
						v-else
						v-model="editableEmailForm.subject"
						@input="value => handleChangeForm({ key: 'subject', value })"
						:label="$t('label.subject')"
						outlined
						dense
						hide-details
						:error-messages="errors"
					></v-text-field>
				</ValidationProvider>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<RichEditor
					v-if="previewMode"
					v-model="completedEmailForm.template"
					refName="completed_email_template"
					:disabled="true"
				></RichEditor>
				<RichEditor
					v-else
					v-model="editableEmailForm.template"
					@input="value => handleChangeForm({ key: 'template', value })"
					refName="email_template"
				></RichEditor>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<Attachments
					:attachments="editableEmailForm.attachment_files"
					:onNewAttachmentAdded="onNewAttachmentAdded"
					:onAttachmentDeleteClick="onAttachmentDeleteClick"
					:readOnly="previewMode"
				></Attachments>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { format } from 'date-fns';
import { mapState } from 'vuex';
import api from '@/api';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import Attachments from '@/components/shared/attachments/Attachments';

export default {
	name: 'ScheduleEmailForm',
	components: {
		ValidationProvider,
		RichEditor,
		Attachments,
	},
	props: {
		emailForm: {
			type: Object,
			required: true,
		},
		completedEmailForm: {
			type: Object,
			required: true,
		},
		previewMode: {
			type: Boolean,
			required: true,
		},
		candidateEmails: {
			type: Array,
			required: true,
			default() {
				return [];
			},
		},
		onNewAttachmentAdded: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onAttachmentDeleteClick: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		sendOnApprovalFlowComplete: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			sendTimes: state => state.emailTemplates.sendTimes,
		}),
	},
	data() {
		return {
			format: format,
			editableEmailForm: {
				attachment_files: [],
			},
			fromEmails: [
				{
					value: 'noreply@talentics.app',
					text: 'noreply@talentics.app',
				},
			],
			customSendTime: {
				hours: 0,
				minutes: 0,
				seconds: 0,
			},
			isCustomSendTimeVisible: false,
		};
	},
	methods: {
		handleChangeForm({ key, value }) {
			this.$emit('change-form', { [key]: value });
		},
		handleGetSendAt(newVal) {
			if (newVal) {
				const { sendTimes } = this;
				const sendTime = sendTimes.find(item => item.value === newVal);
				const { days } = sendTime;
				const send_at = new Date();
				send_at.setDate(send_at.getDate() + days);
				return send_at;
			}
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleEditSendAtClick(evt) {
			evt.stopPropagation();
			if (this.isCustomSendTimeVisible) {
				this.isCustomSendTimeVisible = false;
			} else {
				const { send_at } = this.editableEmailForm;
				const sendAtDate = new Date(send_at);
				const hours = parseInt(this.format(sendAtDate, 'H'), 10);
				const minutes = parseInt(this.format(sendAtDate, 'm'), 10);
				const seconds = parseInt(this.format(sendAtDate, 's'), 10);
				this.customSendTime = {
					hours,
					minutes,
					seconds,
				};
				this.isCustomSendTimeVisible = true;
			}
		},
		handleCustomSendTimeChange({ newVal, prop }) {
			const { send_at } = this.editableEmailForm;
			const sendAtDate = new Date(send_at);

			if (prop === 'hours') {
				sendAtDate.setHours(newVal);
			} else if (prop === 'minutes') {
				sendAtDate.setMinutes(newVal);
			} else if (prop === 'seconds') {
				sendAtDate.setSeconds(newVal);
			}

			this.editableEmailForm = {
				...this.editableEmailForm,
				send_at: sendAtDate,
			};
		},
		formatDateItem({ val }) {
			return val < 10 ? `0${val}` : val;
		},
	},
	created() {
		api.emailsConsented.get({ include: 'self' }).then(response => {
			const { items } = response.data;
			this.fromEmails = [
				...this.fromEmails,
				...items.map(item => ({ text: item.email, value: item.email })),
			];
		});
	},
	watch: {
		emailForm: {
			handler: function (newVal) {
				this.editableEmailForm = JSON.parse(JSON.stringify(newVal));
			},
			immediate: true,
			deep: true,
		},
		'editableEmailForm.send_time': {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					const send_at = this.handleGetSendAt(newVal);
					this.editableEmailForm = {
						...this.editableEmailForm,
						send_at,
					};
					this.handleChangeForm({ key: 'send_at', value: send_at });
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>

<style></style>
