import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import * as Sentry from '@sentry/vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { i18n } from './plugins/i18n';
import './plugins/vee-validate';

Vue.use(
	VueGtag,
	{
		config: { id: process.env.VUE_APP_G_TAG },
		pageTrackerTemplate(to) {
			return {
				page_title: to.path,
				page_path: to.path,
			};
		},
	},
	router,
);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.config.productionTip = false;

const firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_FIREBASE_APP_ID,
	scopes: ['email', 'profile'],
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
			new Sentry.Replay(),
		],
		tracesSampleRate: 0.0001,
		tracePropagationTargets: [process.env.VUE_APP_HOST_API_KEY],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.1,
		ignoreErrors: [
			// gapi errors
			/null is not an object \(evaluating 'a\.Qa\.style'\)/,
			/null is not an object \(evaluating 'Object\(r\["n"]\)\(e\)\.getIdToken'\)/,
			/Cannot read properties of null \(reading 'style'\)/,
			/Cannot read properties of null \(reading 'getIdToken'\)/,
			/undefined is not an object \(evaluating 'window\.gapi\.signin2\.render'\)/,
			/undefined is not an object \(evaluating 'window\.gapi\.auth2\.getAuthInstance'\)/,
			/ua/,
			// Firebase errors
			/'Firebase: Error \(auth\/duplicate-raw-id\)'/,
			/'Firebase: Error \(auth\/popup-closed-by-user\)'/,
			/'Firebase: Error \(auth\/cancelled-popup-request\)'/,
			/'Firebase: Error \(auth\/popup-blocked\)'/,
		],
		beforeSend(event) {
			const exceptionsToIgnore = [
				{
					type: '<unknown>',
					value:
						'Object captured as promise rejection with keys: config, data, headers, request, status',
				},
				{
					type: 'Error',
					value: 'wa',
				},
				{
					type: 'NotFoundError',
					value:
						"Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
				},
			];

			if (event.exception) {
				const exception = event.exception?.values[0];

				if (exception) {
					for (const { type, value } of exceptionsToIgnore) {
						if (exception.type === type && exception.value === value) {
							return null;
						}
					}
				}
			}

			return event;
		},
	});
}

new Vue({
	vuetify,
	i18n,
	render: h => h(App),
	router,
	store,
}).$mount('#app');

export { firebaseApp, db, auth };
