<template>
	<div class="durations-container">
		<div
			v-for="(item, index) in durations"
			:key="`item_${index}`"
			class="duration-item"
		>
			<div class="duration-item-days">
				{{ item.value }} days
				<div class="triangle" v-if="index !== 0"></div>
			</div>
			<div class="duration-item-label">
				{{ $t(`pipeline.steps.${item.key}`) }}
			</div>
		</div>
	</div>
</template>

<script>
import api from '@/api';

export default {
	name: 'PipelineDurations',
	props: {
		candidateId: {
			required: true,
		},
		applicationId: {
			required: true,
		},
	},
	data() {
		return {
			durations: [],
		};
	},
	mounted() {
		const { candidateId, applicationId } = this;
		api.candidates.applications.durations.interview
			.get({
				candidate_id: candidateId,
				application_id: applicationId,
			})
			.then(response => {
				const { durations } = response.data;
				this.durations = durations;
			});
	},
};
</script>

<style lang="scss" scoped>
.durations-container {
	display: flex;
	flex-direction: row;

	& > .duration-item {
		flex: 1;

		&:first-child {
			& > .duration-item-days {
				-webkit-border-top-left-radius: 8px;
				-webkit-border-bottom-left-radius: 8px;
				-moz-border-radius-topleft: 8px;
				-moz-border-radius-bottomleft: 8px;
				border-top-left-radius: 8px;
				border-bottom-left-radius: 8px;
			}
		}

		&:last-child {
			& > .duration-item-days {
				-webkit-border-top-right-radius: 8px;
				-webkit-border-bottom-right-radius: 8px;
				-moz-border-radius-topright: 8px;
				-moz-border-radius-bottomright: 8px;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
			}
		}

		& > .duration-item-days {
			background-color: silver;
			text-align: center;
			height: 30px;
			line-height: 30px;
			white-space: nowrap;
			position: relative;
		}

		& > .duration-item-label {
			transform: rotate(45deg);
			margin-top: 25px;
			margin-left: 10px;
			font-size: 0.75rem;
			line-height: 0.75rem;
		}
	}
}

.arrow-right {
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 15px solid red;
	position: absolute;
	left: -5px;
	top: 0;
	box-shadow: 5px 10px #888888;
}

.triangle {
	width: 0;
	height: 0;
	position: absolute;
	top: 0;
	left: -10px;
	border-bottom: solid 15px transparent;
	border-top: solid 15px transparent;
	border-left: solid 15px black;
	border-right: solid 15px transparent;
}

.triangle:before {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	border-bottom: solid 14px transparent;
	border-top: solid 14px transparent;
	border-left: solid 14px silver;
	border-right: solid 14px transparent;
	top: -14px;
	left: -15px;
}
</style>
