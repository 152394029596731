var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","value":_vm.isVisible,"max-width":"680px","persistent":""},on:{"click:outside":_vm.handleClickOutside,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleClickOutside.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":`${_vm.isDark ? '' : 'grey lighten-3'}`,"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('text.add-new-candidate')))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.handleBulkImportClick}},[_vm._v(" "+_vm._s(_vm.$t('button.bulk-import'))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{ref:"newCandidateDialogContent",staticStyle:{"padding":"0 0.5rem"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('p',[_c('v-icon',{attrs:{"left":""}},[_vm._v("auto_fix_high")]),_vm._v(_vm._s(_vm.$t('text.please-upload-candidate-resume'))+" ")],1),_c('Dropzone',{attrs:{"id":"candidateFilesDropzone","options":{
									maxFilesize: 20,
									maxFiles: 10,
									acceptedFiles:
										'image/png, image/jpeg, image/gif, .pdf, .doc, .docx, .txt, .html',
								},"initialFiles":[],"onSuccess":(file, response) => _vm.handleDropzoneSuccess({ response })}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider'),_c('div',{staticStyle:{"text-align":"center","margin-top":"-10px"}},[_c('span',{style:(`background: ${
										_vm.isDark ? '#1E1E1E' : '#ffffff'
									}; padding-left: 10px; padding-right: 10px;`)},[_vm._v("OR")])])],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"}},[_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":_vm.handleSkipUploadingClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("skip_next")]),_vm._v(_vm._s(_vm.$t('button.skip-uploading-resume')))],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleClose}},[_vm._v(_vm._s(_vm.$t('button.close')))])],1)],1)],1),(_vm.isAutofillVisible)?_c('Autofill',{attrs:{"items":_vm.autofillItems,"onClose":_vm.handleAutofillClose,"requestMethod":"post"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }