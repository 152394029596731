<template>
	<div
		@mouseenter="() => handleEventHover({ hover: true })"
		@mouseleave="() => handleEventHover({ hover: false })"
	>
		<v-row>
			<v-col cols="auto" align-self="center">
				<span class="all-scroll">
					<v-icon :color="event.mainColor">drag_indicator</v-icon>
				</span>
			</v-col>

			<v-col>
				<v-row>
					<v-col cols="2" align-self="center">
						<ValidationProvider rules="" v-slot="{ errors }">
							<v-select
								v-model="event.event_type_id"
								:items="[
									{
										id: null,
										name: 'New interview ',
										default_length: 30,
										default_interviewers: [],
									},
									...eventTypes,
								]"
								outlined
								dense
								:label="$t('label.interview-type')"
								hide-details
								item-value="id"
								item-text="name"
								@change="newVal => handleEventTypeChange({ newVal })"
								:error-messages="errors"
							></v-select>
						</ValidationProvider>
					</v-col>
					<v-col cols="2" align-self="center">
						<DatePicker
							v-model="event.date"
							full-width
							no-title
							reactive
							dense
							@input="handleDatePickerChange"
							:label="`${$t('label.date')}`"
						></DatePicker>
					</v-col>
					<v-col cols="1" align-self="center">
						<TimePicker
							v-model="event.start"
							full-width
							no-title
							reactive
							dense
							@input="handleTimePickerChange"
							:label="`${$t('label.time')}`"
						></TimePicker>
					</v-col>
					<v-col cols="1" align-self="center">
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-text-field
								v-model="event.length"
								type="number"
								outlined
								dense
								hide-details
								:label="$t('label.length')"
								@change="newVal => handleLengthChange({ newVal })"
								:error-messages="errors"
							></v-text-field>
						</ValidationProvider>
					</v-col>
					<v-col cols="6" align-self="center">
						<UserPicker
							v-model="event.interviewers"
							:users="users.filter(user => user.active)"
							:label="$t('label.interviewers')"
							multiple
							dense
							rules="required"
							@input="handleUserPickerChange"
						></UserPicker>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="auto" align-self="center">
				<v-btn text icon @click="onDeleteNewEvent">
					<v-icon>delete</v-icon>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import { ValidationProvider } from 'vee-validate';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import TimePicker from '@/components/shared/timePicker/TimePicker';

export default {
	name: 'NewEventRow',
	props: {
		value: {
			type: Object,
			required: true,
		},
		job_id: {
			required: true,
		},
		candidate: {
			required: true,
		},
		onDeleteNewEvent: {
			required: true,
		},
	},
	components: {
		ValidationProvider,
		UserPicker,
		DatePicker,
		TimePicker,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			jobs: state => state.jobs.items,
			eventTypes: state => state.eventTypes.items,
		}),
	},
	data() {
		return {
			format,
			event: {
				event_type_id: null,
				length: 0,
				interviewers: [],
			},
		};
	},
	methods: {
		handleEventHover({ hover }) {
			const newVal = {
				...this.event,
				color: hover
					? `${this.event.mainColor} lighten-2`
					: `${this.event.mainColor} darken-1`,
			};
			this.$emit('input', newVal);
		},
		handleEventTypeChange({ newVal }) {
			const { event } = this;
			const eventType = this.eventTypes.find(item => item.id === newVal);
			const startDate = new Date(event.start);
			const start = format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss');
			const endDate = new Date(event.start);
			endDate.setTime(
				endDate.getTime() + (eventType?.default_length || 30) * 60000,
			);
			const end = format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss');
			const newInterviewers = eventType
				? eventType.default_interviewers.filter(
						item => event.interviewers.indexOf(item) === -1,
				  )
				: [];

			const { job_id, jobs } = this;
			const job = jobs.find(item => item.id === job_id);
			const newEvent = {
				...event,
				name: `${eventType.name} / ${job ? job.name : ''} - ${
					this.candidate.name
				}`,
				length: eventType?.default_length || 30,
				start,
				end,
				interviewers: [...event.interviewers, ...newInterviewers],
				color: 'blue darken-1',
				event_type_id: newVal,
			};
			this.$emit('input', newEvent);
		},
		handleLengthChange({ newVal }) {
			const { event } = this;
			const startDate = new Date(event.start);
			const start = format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss');
			const endDate = new Date(event.start);
			endDate.setTime(endDate.getTime() + newVal * 60000);
			const end = format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss');
			const newEvent = {
				...event,
				length: newVal,
				start,
				end,
			};
			this.$emit('input', newEvent);
		},
		handleUserPickerChange(newVal) {
			const newEvent = {
				...this.event,
				interviewers: [...newVal],
			};
			this.$emit('input', newEvent);
		},
		handleDatePickerChange(newVal) {
			const { event } = this;
			const startDate = new Date(event.start);
			const newStartDate = new Date(newVal);
			const newYear = newStartDate.getFullYear();
			const newMonth = newStartDate.getMonth();
			const newDate = newStartDate.getDate();
			startDate.setFullYear(newYear);
			startDate.setMonth(newMonth);
			startDate.setDate(newDate);
			const endDate = new Date(startDate);
			endDate.setTime(endDate.getTime() + event.length * 60000);
			const start = format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss');
			const end = format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss');
			const newEvent = {
				...event,
				start,
				end,
			};
			this.$emit('input', newEvent);
		},
		handleTimePickerChange(newVal) {
			const { event } = this;
			const startDate = new Date(newVal);
			const endDate = new Date(startDate);
			endDate.setTime(endDate.getTime() + event.length * 60000);
			const start = format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss');
			const end = format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss');
			const newEvent = {
				...event,
				start,
				end,
			};
			this.$emit('input', newEvent);
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				this.event = JSON.parse(JSON.stringify(newVal));
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
