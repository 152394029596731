<template>
	<div>
		<hr />
		<div
			v-for="(cdnFile, index) in cdnFiles"
			:key="index"
			:style="`background-image: url(https://cdn.talentics.app/${cdnFile}); position: relative; overflow: hidden;`"
			@click="() => onClickSelect({ file: cdnFile })"
			class="cdn-file-preview"
		>
			<span
				style="
					white-space: nowrap;
					position: absolute;
					bottom: 0;
					right: 0;
					background: rgba(0, 0, 0, 0.3);
					color: white;
					font-size: 0.7rem;
				"
				>https://cdn.talentics.app/{{ cdnFile }}</span
			>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'CdnFilePickerFiles',
	computed: {
		...mapState({
			cdnFiles: state => state.cdnFiles.items,
		}),
	},
	created() {
		this.$store.dispatch('cdnFiles/get', { updateStore: true });
	},
	props: {
		onClickSelect: {
			type: Function,
			required: true,
		},
	},
};
</script>

<style lang="scss">
.cdn-file-preview {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	height: 200px;
	width: 200px;
	float: left;
	margin: 0.5rem;
	cursor: pointer;
	border: 1px solid silver;
	background-color: #f9f9f9;

	&:hover {
		border: 1px solid #0047b3;
	}
}
</style>
