import api from '@/api';

const state = {
	company: {},
	applicationForm: {},
	lastUpdatedAt: 0,
};

const getters = {};

const actions = {
	get: ({ commit }) => {
		return new Promise((resolve, reject) => {
			api.company
				.get()
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						const { application_form_json_schema, ...rest } = items[0];
						commit('setCompany', { company: rest });
						commit('setApplicationForm', {
							applicationForm: application_form_json_schema,
						});
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	put: ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			api.company
				.put(params)
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						const { application_form_json_schema, ...rest } = items[0];
						commit('setCompany', { company: rest });
						commit('setApplicationForm', {
							applicationForm: application_form_json_schema,
						});
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setCompany: (state, { company }) => {
		const now = new Date();
		state.company = company;
		state.lastUpdatedAt = now.getTime();
	},
	setApplicationForm: (state, { applicationForm }) => {
		if (applicationForm) {
			const now = new Date();
			state.applicationForm = applicationForm;
			state.lastUpdatedAt = now.getTime();
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
