<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		offset-y
		:nudge-width="250"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" x-small icon>
				<v-icon>edit</v-icon>
			</v-btn>
		</template>

		<v-card>
			<v-card-text style="padding: 0">
				<ValidationObserver
					:ref="refName"
					tag="form"
					@submit="handleAdd"
					:id="refName"
				>
					<v-container fluid>
						<v-row>
							<v-col>
								<JobPicker
									v-model="job_id"
									:jobs="jobs.filter(item => item.status === 'open')"
									:label="`${$t('label.job')}*`"
									rules="required"
									hide-inactive-teams
									hide-inactive-departments
									hide-draft-jobs
									hide-pending-jobs
									:clearable="false"
								></JobPicker>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="menu = false">
					{{ $t('button.cancel') }}
				</v-btn>
				<v-btn color="primary" @click="handleAdd">
					{{ $t('button.update') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import JobPicker from '@/components/shared/jobPicker/JobPicker';

export default {
	name: 'NewJobMenu',
	components: {
		ValidationObserver,
		JobPicker,
	},
	props: {
		candidateId: {
			type: Number,
			required: false,
		},
		jobId: {
			type: Number,
			required: false,
		},
		applicationId: {
			type: Number,
			required: false,
		},
		refName: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState({
			jobs: state => state.jobs.items,
		}),
	},
	data() {
		return {
			menu: false,
			job_id: null,
		};
	},
	methods: {
		async handleAdd(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const { refName } = this;
			const isValid = await this.$refs[refName].validate();
			if (isValid) {
				const { job_id, candidateId, applicationId } = this;
				await this.$store.dispatch('candidates/put_applications', {
					id: candidateId,
					application_id: applicationId,
					job_id,
				});
				this.menu = false;
				this.$store.commit('candidates/setItemNeedsToRefresh');
				this.$store.commit('candidates/setItemsNeedToRefresh');
			}
		},
	},
	watch: {
		jobId: {
			handler: function (newVal) {
				if (newVal) {
					this.job_id = newVal;
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
