<template>
	<div>
		<v-dialog
			v-if="step === 1"
			style="background-color: white !important"
			v-model="dialog"
			width="750px"
			@click:outside="onClose"
			@keydown.esc="onClose"
		>
			<v-card variant="tonal">
				<v-card-title>{{ $t('label.new-assessment') }}</v-card-title>
				<ValidationObserver
					ref="new_assessment_form"
					tag="form"
					@submit="
						sendEmailToCandidate ? handleGoToNextStep : onSubmitNewAssessment
					"
					id="new_assessment_form"
				>
					<v-card-text>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-text-field
								v-model="newAssessmentForm.name"
								:label="$t('label.kolayik.first-name')"
								:error="errors && errors.length ? true : false"
								name="name"
								type="text"
								outlined
								flat
								dense
							></v-text-field>
						</ValidationProvider>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-text-field
								v-model="newAssessmentForm.surname"
								:label="$t('label.kolayik.last-name')"
								:error="errors && errors.length ? true : false"
								name="last-name"
								type="text"
								outlined
								flat
								dense
							></v-text-field>
						</ValidationProvider>
						<ValidationProvider rules="required|email" v-slot="{ errors }">
							<v-text-field
								v-model="newAssessmentForm.email"
								:label="$t('label.email')"
								:error="errors && errors.length ? true : false"
								name="email"
								type="email"
								outlined
								flat
								dense
							></v-text-field>
						</ValidationProvider>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								v-model="newAssessmentForm.language"
								:items="languages"
								:label="$t('label.language')"
								:error="errors && errors.length ? true : false"
								name="language"
								outlined
								flat
								dense
							></v-select>
						</ValidationProvider>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								v-model="newAssessmentForm.product"
								:items="products"
								:label="$t('label.assessment-product')"
								:error="errors && errors.length ? true : false"
								name="product"
								outlined
								flat
								dense
							></v-select>
						</ValidationProvider>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								v-model="newAssessmentForm.application_id"
								:items="applicationOptions"
								:label="$t('label.assessment-application')"
								:error="errors && errors.length ? true : false"
								name="application-id"
								outlined
								flat
								dense
							></v-select>
						</ValidationProvider>
						<v-checkbox
							v-model="sendEmailToCandidate"
							hide-details
							dense
							style="margin: auto; padding: 0"
							:label="$t('label.send-email-candidate')"
							prepend-icon="email"
						></v-checkbox>
					</v-card-text>
					<v-card-actions>
						<v-btn
							v-if="sendEmailToCandidate && step === 1"
							class="ml-auto mr-2"
							@click="handleGoToNextStep"
							color="primary"
						>
							{{ $t('button.next-step') }}
						</v-btn>
						<v-btn
							color="primary"
							v-else
							class="ml-auto mr-2"
							@click="handleSubmitClick"
						>
							{{ $t('button.save') }}
						</v-btn>
						<v-btn @click="onClose">{{ $t('button.close') }}</v-btn>
					</v-card-actions>
				</ValidationObserver>
			</v-card>
		</v-dialog>
		<SelectEmailTemplate
			v-else-if="step === 2"
			:dialog="selectEmailTemplateDialogVisible"
			:candidate="candidate"
			:onStart="handleScheduleNewEmail"
			:onCancel="() => (step = 1)"
			:assessment="assessment"
		/>
		<ScheduleEmailDialog
			v-else-if="step === 3"
			:email="email"
			:candidate="candidate"
			:onClose="onScheduleEmailDialogClose"
			:onScheduleEmailComplete="handleNewEmailScheduled"
			:assessment="assessment"
		/>
	</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { format } from 'date-fns';

import ScheduleEmailDialog from '../emails/scheduleEmailDialog/ScheduleEmailDialog';
import SelectEmailTemplate from '../emails/selectEmailTemplate/SelectEmailTemplate';

export default {
	name: 'NewAssessment',
	components: {
		ScheduleEmailDialog,
		ValidationObserver,
		ValidationProvider,
		SelectEmailTemplate,
	},
	props: {
		dialog: {
			type: Boolean,
			required: true,
		},
		onClose: {
			type: Function,
			required: true,
			default() {
				return false;
			},
		},
		onSubmit: {
			type: Function,
			required: true,
			default() {},
		},
		assessment: {
			type: Object,
			required: false,
			default() {
				return {
					first_name: '',
					last_name: '',
					email: '',
				};
			},
		},
	},
	data() {
		return {
			selectEmailTemplateDialogVisible: false,
			candidate: {},
			step: 1,
			sendEmailToCandidate: false,
			format,
			products: [],
			application_id: null,
			applicationOptions: [],
			emails: [],
			email: {},
			languages: ['TR', 'EN'],
			newAssessmentForm: {
				name: '',
				surname: '',
				email: this.emails ? this.emails[0].text : '-',
				language: '',
				product: '',
				application_id: '',
			},
		};
	},
	methods: {
		handleSubmitClick() {
			this.onSubmit(this.newAssessmentForm);
			this.onClose();
		},
		handleScheduleNewEmail(email) {
			this.email = email;
			this.step = 3;
		},
		handleNewEmailScheduled() {
			const { candidateId } = this.$route.params;

			this.$store
				.dispatch('assessments/get', {
					candidate_id: candidateId,
					refresh: true,
					updateStore: true,
				})
				.then(() => this.onClose());
		},
		async handleGoToNextStep(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.new_assessment_form.validate();
			if (isValid) {
				await this.onSubmitNewAssessment();
				this.step = 2;
				this.selectEmailTemplateDialogVisible = true;
			}
		},
		onScheduleEmailDialogClose() {
			this.scheduleEmailDialogVisible = false;
			this.step = 2;
		},
		getCandidate() {
			const { candidateId } = this.$route.params;
			const { items: jobs } = this.$store.state.jobs;

			this.$store
				.dispatch('candidates/get', { id: candidateId, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.candidate = items[0];
						const { applications } = items[0];
						this.emails = this.candidate.email.map(e => ({
							text: e,
							value: e,
						}));
						if (applications && applications.length > 0) {
							this.applicationOptions = applications.map(a => {
								const job = jobs.find(j => j.id === a.job_id);

								if (job) {
									return {
										text: `${job.name} - ${this.formatDate({
											timestamp: a.created,
										})}`,
										value: a.id,
									};
								}
							});
						}
					}
				});
		},
		async onSubmitNewAssessment() {
			const isValid = await this.$refs.new_assessment_form.validate();
			if (isValid) {
				await this.onSubmit(this.newAssessmentForm, true);
			}
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
	},
	computed: {
		companyProducts() {
			return this.$store.state.company.company.spica_products;
		},
	},
	mounted() {
		this.getCandidate();
	},
	watch: {
		companyProducts: {
			handler(newValue) {
				if (newValue && newValue.length > 0) {
					this.products = newValue.map(product => ({
						text: `${product.productName} - ${product.productDesc}`,
						value: product.productID,
					}));
				}
			},
			immediate: true,
			deep: true,
		},
		emails: {
			handler(newValue) {
				if (newValue && newValue.length > 0) {
					this.newAssessmentForm.email = newValue[0].text;
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
