import api from '@/api';

const state = {
	items: [],
	updated: 0,
};

const getters = {};

const actions = {
	get: ({ commit }, { refresh = false, updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			const { updated } = state;
			if (!refresh && updated > 0) {
				resolve({ data: { items: state.items } });
			}
			api.timezones
				.get(params)
				.then(response => {
					const { items } = response.data;
					if (updateStore) {
						commit('setItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setItems: (state, { items }) => {
		const now = new Date();
		state.items = items;
		state.updated = now.getTime();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
