<template>
	<div>
		<v-card elevation="1">
			<v-card-title class="pa-0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-icon left>event</v-icon>
					<v-toolbar-title>
						{{
							formatDate({
								timestamp: eventEditable.start,
								format: 'yyyy-MM-dd HH:mm',
							})
						}}-{{
							formatDate({
								timestamp: eventEditable.end,
								format: 'HH:mm',
							})
						}}
					</v-toolbar-title>
					<v-spacer></v-spacer>
				</v-toolbar>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text>
				<v-row>
					<v-col>
						<fieldset
							:class="`talentics-fieldset talentics-fieldset-paddings ${
								isPreviewMode ? 'talentics-fieldset-disabled' : ''
							}`"
						>
							<legend>{{ $t('label.organizer') }}</legend>
							<UserChips :userIds="[currentUser.id]"></UserChips>
						</fieldset>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<fieldset
							:class="`talentics-fieldset talentics-fieldset-paddings ${
								isPreviewMode ? 'talentics-fieldset-disabled' : ''
							}`"
						>
							<legend>{{ $t('label.interviewers') }}</legend>
							<UserChips :userIds="eventEditable.interviewers"></UserChips>
						</fieldset>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-select
							v-model="emailTemplateId"
							:items="emailTemplates.filter(item => item.active)"
							:item-text="item => item.label"
							item-value="id"
							:label="$t('label.template-optional')"
							outlined
							hide-details
							dense
							@change="id => handleEmailTemplateChange({ id })"
							:disabled="isPreviewMode"
						></v-select>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-text-field
							v-model="eventEditable.name"
							:label="`${$t('label.event-name')}`"
							outlined
							hide-details
							dense
							:disabled="isPreviewMode"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row v-if="isPreviewMode">
					<v-col>
						<fieldset
							:class="`talentics-fieldset talentics-fieldset-paddings ${
								isPreviewMode ? 'talentics-fieldset-disabled' : ''
							}`"
						>
							<legend>
								{{ $t('label.description-optional') }}
							</legend>
							<div
								v-if="eventEditable.description"
								v-html="eventEditable.description"
							></div>
							<div v-else>-</div>
						</fieldset>
					</v-col>
				</v-row>

				<v-row v-else>
					<v-col>
						<fieldset
							:class="`talentics-fieldset talentics-fieldset-paddings ${
								isPreviewMode ? 'talentics-fieldset-disabled' : ''
							}`"
						>
							<legend>
								{{ $t('label.description-optional') }}
							</legend>
							<RichEditor
								v-model="eventEditable.descriptionEditable"
								refName="closing"
							></RichEditor>
						</fieldset>
					</v-col>
				</v-row>

				<v-row v-if="provider === 'microsoft.com'">
					<v-col>
						<Attachments
							:attachments="eventEditable.attachment_files"
							:onNewAttachmentAdded="handleNewAttachmentAdded"
							:onAttachmentDeleteClick="handleAttachmentDeleteClick"
							:readOnly="isPreviewMode"
						></Attachments>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-checkbox
							v-model="eventEditable.googleMeet"
							:label="
								provider === 'google.com'
									? `${$t('label.add-google-meet-video-conferencing')}`
									: `${$t('label.add-microsoft-teams-video-conferencing')}`
							"
							dense
							hide-details
							:false-value="false"
							:true-value="true"
							style="margin-top: 0; padding-top: 0"
							:disabled="isPreviewMode"
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-checkbox
							v-model="eventEditable.notifyCandidate"
							:label="`${$t('label.notify-candidate')}`"
							dense
							hide-details
							:false-value="false"
							:true-value="true"
							style="margin-top: 0; padding-top: 0"
							:disabled="isPreviewMode"
						></v-checkbox>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-checkbox
							v-model="eventEditable.visibility"
							:label="`${$t('label.private-event')}`"
							dense
							hide-details
							false-value="default"
							true-value="private"
							style="margin-top: 0; padding-top: 0"
							:disabled="isPreviewMode"
						></v-checkbox>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import UserChips from '@/components/shared/userChips/UserChips';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import Attachments from '@/components/shared/attachments/Attachments';
import { getCandidateLastName, getCandidateFirstName } from '../../../utils/helpers';

export default {
	name: 'EventOrganizer',
	components: {
		UserChips,
		RichEditor,
		Attachments,
	},
	props: {
		event: {
			type: Object,
			required: false,
			default() {
				return {
					start: null,
					end: null,
					length: null,
					interviewers: [],
					name: null,
					description: '',
					descriptionEditable: '',
					googleMeet: false,
					notifyCandidate: false,
					visibility: false,
					attachments: [],
					attachment_files: [],
				};
			},
		},
		isPreviewMode: {
			type: Boolean,
			required: true,
		},
		candidate: {
			required: true,
		},
		jobId: {
			required: true,
		},
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			isDark: state => state.currentUser.isDark,
			provider: state => state.currentUser.provider,
			emailTemplates: state => state.emailTemplates.items,
			currentUser: state => state.currentUser.currentUser,
			company: state => state.company.company,
			jobs: state => state.jobs.items,
		}),
	},
	data() {
		return {
			format,
			eventEditable: {
				start: null,
				end: null,
				length: null,
				interviewers: [],
				name: null,
				description: '',
				descriptionEditable: '',
				googleMeet: false,
				notifyCandidate: false,
				visibility: false,
				attachments: [],
				attachment_files: [],
			},
			emailTemplateId: null,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		async handleNewAttachmentAdded({ newFiles = [] }) {
			this.eventEditable = {
				...this.eventEditable,
				attachments: [
					...this.eventEditable.attachments,
					...newFiles.map(({ id }) => id),
				],
				attachment_files: [...this.eventEditable.attachment_files, ...newFiles],
			};
		},
		handleAttachmentDeleteClick({ index }) {
			this.eventEditable = {
				...this.eventEditable,
				attachments: this.eventEditable.attachments.filter(
					(item, itemIndex) => index !== itemIndex,
				),
				attachment_files: this.eventEditable.attachment_files.filter(
					(item, itemIndex) => index !== itemIndex,
				),
			};
		},
		handleEmailTemplateChange({ id }) {
			this.$store
				.dispatch('emailTemplates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						const { template, attachments, attachment_files } = items[0];
						this.eventEditable = {
							...this.eventEditable,
							descriptionEditable: template,
							attachments,
							attachment_files,
						};
					}
				});
		},
		handleReplaceEventProps() {
			const candidateRegex = /({{\s+|{{)(candidate)(\s+}}|}})/gi;
			const candidateFirstNameRegex =
				/({{\s+|{{)(candidateFirstName)(\s+}}|}})/gi;
			const candidateLastNameRegex =
				/({{\s+|{{)(candidateLastName)(\s+}}|}})/gi;
			const companyRegex = /({{\s+|{{)(company)(\s+}}|}})/gi;
			const jobRegex = /({{\s+|{{)(job)(\s+}}|}})/gi;

			const { descriptionEditable } = this.eventEditable;
			const { candidate, company } = this;
			const { jobId, jobs } = this;

			const job = jobs.find(item => item.id === jobId);

			const description = descriptionEditable
				.replace(candidateRegex, candidate.name)
				.replace(
					candidateFirstNameRegex,
					getCandidateFirstName({ name: candidate.name }),
				)
				.replace(
					candidateLastNameRegex,
					getCandidateLastName({ name: candidate.name }),
				)
				.replace(companyRegex, company.name)
				.replace(jobRegex, job ? job.name : '');
			this.eventEditable = {
				...this.eventEditable,
				description,
			};
		},
		handeExportItem() {
			const {
				start,
				end,
				length,
				interviewers,
				name,
				description,
				descriptionEditable,
				googleMeet,
				notifyCandidate,
				visibility,
				attachments,
			} = this.eventEditable;
			return {
				start,
				end,
				length,
				interviewers,
				name,
				description,
				descriptionEditable,
				googleMeet,
				notifyCandidate,
				visibility,
				attachments,
			};
		},
	},
	watch: {
		event: {
			handler: function (newVal) {
				if (newVal) {
					const eventEditable = JSON.parse(JSON.stringify(newVal));
					const {
						start,
						end,
						length,
						interviewers,
						name,
						description,
						googleMeet,
						notifyCandidate,
						visibility,
						attachments,
						attachment_files,
					} = eventEditable;
					this.eventEditable = {
						...this.eventEditable,
						start,
						end,
						length,
						interviewers: interviewers ? interviewers : [],
						name,
						description,
						googleMeet,
						notifyCandidate,
						visibility,
						attachments: attachments ? attachments : [],
						attachment_files: attachment_files ? attachment_files : [],
					};
				}
			},
			immediate: true,
			deep: true,
		},
		isPreviewMode: {
			handler(newVal, oldVal) {
				if (newVal !== oldVal && newVal === true) {
					this.handleReplaceEventProps();
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
