<template>
	<v-tooltip top>
		<template v-slot:activator="{ on }" v-if="targetUser">
			<v-avatar
				:size="`${size}px`"
				item
				:left="left"
				color="secondary"
				dark
				v-on="on"
			>
				<v-img
					v-if="targetUser && targetUser.picture && !hasError"
					:src="targetUser.picture"
					:alt="targetUser.email"
					:color="color"
					@error="handleError"
				>
				</v-img>
				<span v-else class="white--text headline">
					{{
						handleGetUserDisplayProp({
							name: targetUser.name,
							email: targetUser.email,
							isInitial: true,
						})
					}}
				</span>
			</v-avatar>
		</template>
		<span v-if="targetUser"
			>{{ tooltipAppend }}
			{{
				handleGetUserDisplayProp({
					name: targetUser.name,
					email: targetUser.email,
					isInitial: false,
				})
			}}
			{{ tooltipPrepend }}</span
		>
	</v-tooltip>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'Avatar',
	props: {
		userId: {
			type: Number,
			required: false,
		},
		size: {
			type: Number,
			required: false,
			default() {
				return 32;
			},
		},
		color: {
			type: String,
			required: false,
			default() {
				return 'red';
			},
		},
		left: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		tooltipPrepend: {
			typs: String,
			required: false,
		},
		tooltipAppend: {
			typs: String,
			required: false,
		},
	},
	computed: {
		...mapState({
			users: state => state.users.items,
		}),
		targetUser() {
			const { users, userId } = this;
			const user = users.find(item => item.id === userId);
			return user ? user : { email: '-' };
		},
	},
	data() {
		return {
			hasError: false,
		};
	},
	methods: {
		handleError() {
			this.hasError = true;
		},
		handleGetUserDisplayProp({ name, email, isInitial }) {
			if (name && name.length > 0) {
				return isInitial ? name[0].toUpperCase() : name;
			} else if (email && email.length > 0) {
				return isInitial ? email[0].toUpperCase() : email;
			}
			return '-';
		},
	},
};
</script>
