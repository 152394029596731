<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex>
				<v-card tile elevation="0">
					<div style="padding: 0.5rem">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									icon
									:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
									@click="handleRefresh"
								>
									<v-icon>refresh</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.refresh') }}
						</v-tooltip>
					</div>
					<v-divider></v-divider>
					<div>
						<v-tabs v-model="tab" slider-size="3" slider-color="primary">
							<v-tab>
								{{ $t('tab.deparments-uppercase') }} &amp;
								{{ $t('tab.teams-uppercase') }}
							</v-tab>
							<v-tab>
								{{ $t('tab.locations-uppercase') }}
							</v-tab>
							<v-tab>
								{{ $t('tab.work-types-uppercase') }}
							</v-tab>
							<v-tab>
								{{ $t('tab.requisitions-uppercase') }}
							</v-tab>
							<v-tab>
								{{ $t('tab.open-graph') }}
							</v-tab>
						</v-tabs>
					</div>
					<v-divider></v-divider>
					<v-tabs-items v-model="tab">
						<v-tab-item>
							<DeptTeams></DeptTeams>
						</v-tab-item>
						<v-tab-item>
							<Locations></Locations>
						</v-tab-item>
						<v-tab-item>
							<WorkTypes></WorkTypes>
						</v-tab-item>
						<v-tab-item>
							<RequisitionSettings></RequisitionSettings>
						</v-tab-item>
						<v-tab-item>
							<OpenGraph></OpenGraph>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
				<router-view></router-view>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import DeptTeams from './deptTeams/DeptTeams';
import Locations from './locations/Locations';
import WorkTypes from './workTypes/WorkTypes';
import RequisitionSettings from './requisitionSettings/RequisitionSettings';
import OpenGraph from './openGraph/OpenGraph';

export default {
	name: 'Users',
	components: {
		DeptTeams,
		Locations,
		WorkTypes,
		RequisitionSettings,
		OpenGraph,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			isDark: state => state.currentUser.isDark,
			provider: state => state.currentUser.provider,
		}),
	},
	data() {
		return {
			tab: 0,
		};
	},
	methods: {
		handleRefresh() {
			this.$store.dispatch('organizations/get', {
				updateStore: true,
				refresh: true,
			});
			this.$store.dispatch('departments/get', {
				updateStore: true,
				refresh: true,
			});
			this.$store.dispatch('teams/get', {
				updateStore: true,
				refresh: true,
			});
			this.$store.dispatch('locations/get', {
				updateStore: true,
				refresh: true,
			});
			this.$store.dispatch('workTypes/get', {
				updateStore: true,
				refresh: true,
			});
		},
	},
};
</script>
