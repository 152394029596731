<template>
	<div
		small
		:color="organization.active ? 'green' : 'grey'"
		:class="`${childrenCount > 0 ? 'd-block' : 'd-none'} sector-item-wrapper`"
	>
		<div class="sector-title">
			{{ organization.name }} ({{ childrenCount }})
			<v-btn icon @click="handleToggleCollapseClick">
				<v-icon v-if="collapsed">keyboard_arrow_down</v-icon>
				<v-icon v-else>keyboard_arrow_right</v-icon>
			</v-btn>
		</div>
		<div
			:style="`display: ${childrenCount > 0 && collapsed ? 'block' : 'none'};`"
		>
			<JobsTreeDepartments
				:organizationId="organization.id"
				:organizationName="organization.name"
				:jobs="jobs"
				@newchild="handleNewChild"
				:onArchiveJob="onArchiveJob"
				:unfold="unfold"
			></JobsTreeDepartments>
		</div>
	</div>
</template>

<script>
import JobsTreeDepartments from '../jobsTreeDepartments/JobsTreeDepartments';

export default {
	name: 'JobsTreeOrganizationItem',
	props: {
		organization: {
			type: Object,
		},
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
		unfold: {
			type: Boolean,
			required: true,
		},
	},
	components: {
		JobsTreeDepartments,
	},
	data() {
		return {
			childrenCount: 0,
			collapsed: true,
		};
	},
	methods: {
		handleNewChild() {
			this.childrenCount += 1;
			this.$emit('newchild');
		},
		handleToggleCollapseClick() {
			this.collapsed = !this.collapsed;
		},
	},
	watch: {
		jobs: {
			handler: function () {
				this.childrenCount = 0;
			},
			immediate: true,
			deep: true,
		},
		unfold: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.collapsed = newVal;
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
