<template>
	<div style="margin-top: -1px; border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
		<JobsTreeOrganizations
			:jobs="jobs"
			:onArchiveJob="onArchiveJob"
			:unfold="unfold"
		></JobsTreeOrganizations>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import JobsTreeOrganizations from './jobsTreeOrganizations/JobsTreeOrganizations';

export default {
	components: {
		JobsTreeOrganizations,
	},
	computed: {
		...mapState({
			organizations: state => state.organizations.items,
		}),
	},
	props: {
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
		unfold: {
			type: Boolean,
			required: true,
		},
	},
};
</script>

<style lang="scss">
.v-timeline-item {
	&.v-timeline-item-display-none {
		display: none;
	}
}

.sector-title {
	padding: 0.5rem 1rem;
}

.sector-item-wrapper {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
