var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{attrs:{"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{staticClass:"v-select-talentics-chips",attrs:{"value":_vm.value,"items":_vm.users,"item-text":"name","item-value":"id","hide-no-data":"","hide-details":"","outlined":"","label":_vm.label,"multiple":_vm.multiple,"chips":_vm.multiple,"error-messages":errors,"dense":_vm.dense,"disabled":_vm.disabled,"menu-props":{
			closeOnClick: true,
			closeOnContentClick: true,
		},"readonly":_vm.readonly},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":!data.item.disabled,"pill":""},on:{"click:close":function($event){!_vm.readonly ? _vm.remove(data.item) : null}}},'v-chip',data.attrs,false),[_c('Avatar',{attrs:{"userId":data.item.id,"left":true}}),_c('span',{domProps:{"innerHTML":_vm._s(
						`${data.item.name ? data.item.name : data.item.email}${
							data.item.active
								? ''
								: ` <span style='color: red;'>(${_vm.$t(
										'label.inactive-user',
								  )})</span>`
						}`
					)}})],1)]}},{key:"item",fn:function(data){return [[_c('v-list-item-avatar',[_c('Avatar',{attrs:{"userId":data.item.id,"left":true}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
							`${data.item.name ? data.item.name : data.item.email}${
								data.item.active
									? ''
									: ` <span style='color: red;'>(${_vm.$t(
											'label.inactive-user',
									  )})</span>`
							}`
						)}})],1)]]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }