<template>
	<v-dialog
		:value="true"
		max-width="600"
		@click:outside="onClose"
		@keydown.esc="onClose"
	>
		<v-card>
			<v-card-text style="padding-top: 1rem">
				<div v-if="answer">{{ answer }}</div>
				<div v-else>-</div>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="onClose">{{ $t('button.close') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'ViewLinkQuestionAnswer',
	props: {
		answer: {
			type: [String, Number, Object],
			required: false,
			default() {
				return '';
			},
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
};
</script>
