<template>
	<v-badge avatar overlap bordered :offset-x="12" :offset-y="36">
		<template v-slot:badge>
			<v-avatar color="grey darken-1">
				<span class="white--text">{{ score }}</span>
			</v-avatar>
		</template>
		<Avatar :userId="userId" :tooltip-prepend="`: ${score}`"></Avatar>
	</v-badge>
</template>

<script>
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'CandidateFeedbackIcon',
	props: {
		score: {
			type: [String, Number],
			required: true,
		},
		userId: {
			type: [String, Number],
			required: true,
		},
	},
	components: {
		Avatar,
	},
};
</script>
