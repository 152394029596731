<template>
	<div>
		<v-dialog
			:value="isVisible"
			max-width="600"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
			scrollable
		>
			<v-card>
				<v-card-text style="padding: 0">
					<ValidationObserver ref="form" tag="form" @submit="handleSubmit">
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}
										</legend>
										<v-switch
											v-model="user.active"
											:label="
												user.active ? $t('label.active') : $t('label.inactive')
											"
											color="success"
											inset
											dense
											hide-details
											class="v-input--template-switch"
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<ValidationProvider
										rules="required|email"
										v-slot="{ errors }"
									>
										<v-text-field
											v-model="user.email"
											type="email"
											outlined
											:label="`${$t('label.email')}*`"
											:error-messages="errors"
											hide-details
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>
							<v-row v-if="company.user_has_display_email_prop">
								<v-col>
									<ValidationProvider rules="email" v-slot="{ errors }">
										<v-text-field
											v-model="user.display_email"
											type="email"
											outlined
											:label="`${$t('label.display-email')}`"
											:error-messages="errors"
											hide-details
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="user.role"
											:items="
												roles.map(role => ({
													...role,
													disabled: userRole < role.value,
												}))
											"
											:label="`${$t('label.role')}*`"
											outlined
											:error-messages="errors"
											hide-details
											dense
											@change="handleRoleChange"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row v-if="company.user_has_reports_to_prop">
								<v-col>
									<UserPicker
										v-model="user.reports_to_user_id"
										:users="users"
										:label="$t('label.reports-to')"
										:multiple="false"
										dense
									></UserPicker>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.permissions') }}
										</legend>
										<div>
											<v-switch
												v-model="user.can_create_job_requisitions"
												color="success"
												:label="$t('label.can-create-job-requisitions')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_create_job_openings"
												color="success"
												:label="$t('label.can-create-job-openings')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_create_offer_requisitions"
												color="success"
												:label="$t('label.can-create-offer-requisitions')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_schedule_calendar_events"
												color="success"
												:label="$t('label.can-schedule-calendar-events')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_schedule_emails"
												color="success"
												:label="$t('label.can-schedule-emails')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_edit_career_pages"
												color="success"
												:label="$t('label.can-edit-career-pages')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
										<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
											<v-divider></v-divider>
										</div>
										<div>
											<v-switch
												v-model="user.can_view_sensitive_data"
												color="success"
												:label="$t('label.can-view-sensitive-data')"
												inset
												hide-details
												dense
												class="v-input--template-switch"
												:readonly="user.role === 777 || user.role === 666"
											></v-switch>
										</div>
									</fieldset>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-autocomplete
											v-model="user.timezone"
											:label="`${$t('label.timezone')}*`"
											hide-details
											:items="timezoneItems"
											:item-text="item => `${item.text} - ${item.value}`"
											prepend-inner-icon="public"
											outlined
											:error-messages="errors"
											dense
										></v-autocomplete>
									</ValidationProvider>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.restricted-candidates') }}
										</legend>
										<div
											v-if="user.restricted_candidates_with_names.length > 0"
											style="margin-bottom: 1rem"
										>
											<v-card elevation="1">
												<v-list dense tile>
													<v-list-item
														v-for="(
															item, index
														) in user.restricted_candidates_with_names"
														:key="index"
													>
														<v-list-item-content>
															<v-list-item-title
																v-text="item.name"
															></v-list-item-title>
														</v-list-item-content>
														<v-list-item-action>
															<v-btn
																icon
																x-small
																@click="
																	() =>
																		handleRemoveCandidate({
																			index,
																		})
																"
															>
																<v-icon>close</v-icon>
															</v-btn>
														</v-list-item-action>
													</v-list-item>
												</v-list>
											</v-card>
										</div>
										<Autocomplete
											:label="$t('label.search-candidates')"
											:filled="true"
											:dense="true"
											:flat="true"
											:solo="true"
											:solo-inverted="false"
											:hide-details="true"
											:items="autocompleteItems"
											:onKeyUp="handleAutocompleteInput"
											prepend-inner-icon="search"
											@change="handleAutocompleteChange"
											:outlined="true"
										></Autocomplete>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleClose">{{
						$t('button.close')
					}}</v-btn>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Autocomplete from '@/components/shared/autocomplete/Autocomplete';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import api from '@/api';

export default {
	name: 'AddUser',
	components: {
		ValidationObserver,
		ValidationProvider,
		Autocomplete,
		UserPicker,
	},
	computed: {
		...mapState({
			userRole: state => state.currentUser.userRole,
			currentLang: state => state.currentUser.lang,
			timezones: state => state.timezones.items,
			company: state => state.company.company,
			users: state => state.users.items,
		}),
		timezoneItems() {
			const { timezones } = this;
			return timezones.reduce((acc, item) => {
				return [
					...acc,
					{ header: item.group },
					...item.zones.map(zone => ({
						text: zone.name,
						value: zone.value,
						group: item.group,
					})),
				];
			}, []);
		},
	},
	data() {
		return {
			user: {
				id: null,
				name: '',
				email: null,
				display_email: null,
				active: true,
				role: null,
				restricted_candidates: [],
				restricted_candidates_with_names: [],
				timezone: null,
				can_create_job_requisitions: false,
				can_create_job_openings: false,
				can_create_offer_requisitions: false,
				can_schedule_calendar_events: false,
				can_edit_career_pages: false,
				can_schedule_emails: false,
				can_view_sensitive_data: false,
				reports_to_user_id: null,
			},
			roles: [
				{
					text: 'Super Admin',
					value: 777,
				},
				{
					text: 'Admin',
					value: 666,
				},
				{
					text: 'Team Member',
					value: 555,
				},
				{
					text: 'Limited Team Member',
					value: 444,
				},
				{
					text: 'Interviewer',
					value: 333,
				},
			],
			autocompleteItems: [],
			isVisible: false,
		};
	},
	methods: {
		handleClose() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/settings/users`,
				query,
			});
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form.validate();
			if (isValid) {
				const { user } = this;
				this.$store
					.dispatch('users/post', {
						items: [
							{
								...user,
								restricted_candidates:
									user.restricted_candidates_with_names.map(({ id }) => id),
							},
						],
					})
					.then(() => {
						this.$store.commit('users/setItemsNeedToRefresh');
						this.handleClose();
					})
					.catch(err => {
						if (err === 'not-azure-member') {
							// foo
						}
					});
			}
		},
		handleAutocompleteInput({ newVal }) {
			if (newVal && newVal.length > 2) {
				api.search.get({ q: newVal }).then(response => {
					const { candidates } = response.data;
					const autocompleteItems = [
						...(candidates.length > 0 ? [{ header: 'Candidates' }] : []),
						...candidates.map(item => ({
							text: item.name,
							value: item.id,
							group: 'candidates',
						})),
					];
					this.autocompleteItems = autocompleteItems;
				});
			} else {
				this.autocompleteItems = [];
			}
		},
		handleAutocompleteChange(newVal) {
			if (newVal) {
				const { text, value } = newVal;
				this.user = {
					...this.user,
					restricted_candidates_with_names: [
						...this.user.restricted_candidates_with_names,
						{
							id: value,
							name: text,
						},
					],
				};
			}
		},
		handleRemoveCandidate({ index }) {
			this.user = {
				...this.user,
				restricted_candidates_with_names:
					this.user.restricted_candidates_with_names.filter(
						(item, itemIndex) => itemIndex !== index,
					),
			};
		},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleRoleChange() {
			const { role } = this.user;
			if (role === 777 || role === 666) {
				this.user = {
					...this.user,
					can_create_job_requisitions: true,
					can_create_job_openings: true,
					can_create_offer_requisitions: true,
					can_schedule_calendar_events: true,
					can_edit_career_pages: true,
					can_schedule_emails: true,
					can_view_sensitive_data: true,
				};
			} else {
				this.user = {
					...this.user,
					can_create_job_requisitions: false,
					can_create_job_openings: false,
					can_create_offer_requisitions: false,
					can_schedule_calendar_events: false,
					can_edit_career_pages: false,
					can_schedule_emails: false,
					can_view_sensitive_data: false,
				};
			}
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
	watch: {
		company: {
			handler: function (newVal) {
				if (newVal && newVal.timezone) {
					this.user = {
						...this.user,
						timezone: newVal.timezone,
					};
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
