<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-col>
					<v-select
						v-model="source"
						:items="sources"
						hide-details
						outlined
						dense
						:label="$t('label.source')"
					></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<fieldset class="talentics-fieldset">
						<v-list>
							<v-list-item
								v-for="(item, index) in suitableKnownHosts"
								:key="`suitableKnownHosts_${index}`"
							>
								<v-list-item-content>
									https://{{ item.hostname }}/jobs/{{ job.uuidv4 }}?source={{
										source
									}}
								</v-list-item-content>
								<v-list-item-action>
									<v-btn
										icon
										color="secondary"
										@click="
											() =>
												onClickCopy({
													text: `https://${item.hostname}/jobs/${job.uuidv4}?source=${source}`,
												})
										"
									>
										<v-icon>content_copy</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</fieldset>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'CopyJobUrl',
	computed: {
		...mapState({
			knownHosts: state => state.knownHosts.items,
			departments: state => state.departments.items,
			teams: state => state.teams.items,
		}),
		suitableKnownHosts() {
			const { team_id } = this.job;
			const { teams, departments, knownHosts } = this;

			if (!team_id) {
				return [];
			}

			const team = teams.find(({ id }) => id === team_id);

			if (!team) {
				return [];
			}

			const department = departments.find(
				({ id }) => id === team.department_id,
			);

			if (!department) {
				return [];
			}

			return knownHosts
				.filter(item => {
					if (!item.active) {
						return false;
					}
					if (item.organizations.length === 0) {
						return true;
					}
					if (item.organizations.indexOf(department.organization_id) > -1) {
						return true;
					}
					return false;
				})
				.reduce((acc, knownHostItem) => {
					return [
						...acc,
						...knownHostItem.hostnames.map(item => ({
							hostname: item,
							template_name: knownHostItem.template_name,
						})),
					];
				}, []);
		},
	},
	props: {
		job: {
			type: Object,
			required: true,
		},
		onClickCopy: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			source: 'linkedin',
			sources: [
				{
					text: 'LinkedIn',
					value: 'linkedin',
				},
				{
					text: 'Kariyer.Net',
					value: 'kariyernet',
				},
				{
					text: 'Other',
					value: 'other',
				},
			],
		};
	},
};
</script>
