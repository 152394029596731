var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","value":_vm.isVisible,"max-width":"600"},on:{"click:outside":_vm.onClose,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onClose.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":`${_vm.isDark ? '' : 'grey lighten-3'}`,"flat":"","dense":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(_vm._s(_vm.$t('text.send-email')))])]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"padding":"0"}},[_c('ValidationObserver',{ref:"form_send_bulk_emails",attrs:{"tag":"form","id":"form_send_bulk_emails"},on:{"submit":_vm.handleSendBulkEmails}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.emailTemplates.filter(item => item.active),"item-text":item => item.label,"item-value":"id","label":_vm.$t('label.template'),"outlined":"","hide-details":"","dense":"","disabled":_vm.previewMode,"error-messages":errors},on:{"change":id =>
												_vm.handleEmailTemplateChange({
													id,
												})},model:{value:(_vm.emailTemplateId),callback:function ($$v) {_vm.emailTemplateId=$$v},expression:"emailTemplateId"}})]}}])})],1)],1),(_vm.emails.length > 0 && _vm.emailTemplateId)?_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"align-self":"center"}},[_c('v-spacer'),_c('span',{staticClass:"mx-3"},[_vm._v(_vm._s(_vm.$t('text.email-counts', { index: _vm.emailIndex + 1, counts: _vm.emails.length, })))])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.emailIndex === 0,"small":""},on:{"click":function($event){_vm.emailIndex -= 1}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("keyboard_arrow_left")]),_vm._v(" "+_vm._s(_vm.$t('button.prev'))+" ")],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":_vm.emailIndex === _vm.emails.length - 1,"small":""},on:{"click":function($event){_vm.emailIndex += 1}}},[_vm._v(" "+_vm._s(_vm.$t('button.next'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("keyboard_arrow_right")])],1)],1)],1):_vm._e()],1),_c('v-divider'),(_vm.emails.length > 0 && _vm.emailTemplateId)?_c('ScheduleEmailForm',{attrs:{"emailForm":_vm.emails[_vm.emailIndex],"completedEmailForm":{
							template: _vm.emails[_vm.emailIndex].templateReplaced,
							subject: _vm.emails[_vm.emailIndex].subjectReplaced,
						},"candidateEmails":_vm.emails[_vm.emailIndex].send_to_array,"previewMode":_vm.previewMode,"onNewAttachmentAdded":_vm.handleNewAttachmentAdded,"onAttachmentDeleteClick":_vm.handleAttachmentDeleteClick},on:{"change-form":_vm.handleChangeScheduleEmailForm}}):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(_vm.previewMode)?_c('v-btn',{staticStyle:{"margin-right":"0.5rem"},attrs:{"text":""},on:{"click":_vm.goToEditingMode}},[_vm._v(" "+_vm._s(_vm.$t('button.go-back'))+" ")]):_vm._e(),(_vm.emailTemplateId && !_vm.previewMode)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goToPreviewMode}},[_c('v-icon',{staticStyle:{"margin-right":"0.25rem"}},[_vm._v("visibility")]),_vm._v(" "+_vm._s(_vm.$t('button.preview'))+" ")],1):_vm._e(),(_vm.previewMode)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSendBulkEmails}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("send")]),_vm._v(" "+_vm._s(_vm.$t('button.send'))+" "+_vm._s(`(${_vm.emails.length})`)+" ")],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }