<template>
	<div>
		<ValidationProvider :rules="rules" v-slot="{ errors }">
			<v-select
				v-model="currentStatus"
				:items="statuses"
				:item-text="
					item => {
						if (item.text) {
							return $t(`pipeline.steps.${item.text}`);
						}
						return item;
					}
				"
				item-value="value"
				:label="label"
				outlined
				hide-details
				dense
				:disabled="disabled"
				@change="newVal => handleChange({ newVal })"
				:prepend-inner-icon="innerIcon"
				:error-messages="errors"
			></v-select>
		</ValidationProvider>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider } from 'vee-validate';

export default {
	name: 'StagePicker',
	components: {
		ValidationProvider,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			pipelineInterviewItems: state => state.pipelineInterview.items,
			pipelineLeadItems: state => state.pipelineLead.items,
			pipelineArchiveItems: state => state.pipelineArchive.items,
			isDark: state => state.currentUser.isDark,
		}),
		statuses() {
			const {
				pipelineInterviewItems,
				pipelineLeadItems,
				pipelineArchiveItems,
			} = this;
			return [
				{ text: 'any', value: '*' },
				{ header: 'Lead' },
				...pipelineLeadItems.map(item => ({ text: item, value: item })),
				{ divider: true },
				{ header: 'Application' },
				{ text: 'new-applicant', value: 'new-applicant' },
				{ divider: true },
				{ header: 'Interview' },
				...pipelineInterviewItems.map(item => ({
					text: item,
					value: item,
				})),
				{ header: 'Archive' },
				...pipelineArchiveItems.map(item => ({
					text: item,
					value: item,
				})),
			];
		},
		innerIcon() {
			const {
				pipelineInterviewItems,
				pipelineLeadItems,
				pipelineArchiveItems,
				value,
			} = this;

			if (pipelineInterviewItems.indexOf(value) > -1) {
				return 'person_search';
			} else if (pipelineLeadItems.indexOf(value) > -1) {
				return 'verified';
			} else if (pipelineArchiveItems.indexOf(value) > -1) {
				return 'archive';
			}
			return 'person_pin';
		},
	},
	data() {
		return {
			currentStatus: null,
		};
	},
	props: {
		value: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		rules: {
			type: String,
			required: false,
		},
	},
	methods: {
		handleChange({ newVal }) {
			this.$emit('input', newVal);
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				this.currentStatus = newVal;
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
