<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-col cols="12" sm="12" md="8" lg="6">
					<fieldset class="talentics-fieldset talentics-fieldset-paddings">
						<legend>{{ $t('label.requisition-settings') }}</legend>
						<div>
							<v-checkbox
								v-model="
									requisitionSettings.apply_approval_flow_on_new_job_openings
								"
								dense
								hide-details
								class="candidate-checkbox"
								:color="isDark ? 'white' : '#121212'"
								:label="$t('label.apply-approval-flow-on-new-job-openings')"
							></v-checkbox>
						</div>
						<div style="margin-top: 0.25rem; margin-bottom: 0.25rem">
							<v-divider></v-divider>
						</div>
						<div>
							<v-checkbox
								v-model="requisitionSettings.apply_approval_flow_on_new_offers"
								dense
								hide-details
								class="candidate-checkbox"
								:color="isDark ? 'white' : '#121212'"
								:label="$t('label.apply-approval-flow-on-new-offers')"
							></v-checkbox>
						</div>
					</fieldset>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn
						color="secondary"
						@click="handleUpdateRequisitionSettingsButtonClick"
					>
						<v-icon left>send</v-icon>
						{{ $t('button.update') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
	name: 'RequisitionSettings',
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			requisitionSettings: {
				apply_approval_flow_on_new_job_openings: false,
				apply_approval_flow_on_new_offers: false,
			},
		};
	},
	methods: {
		handleGetRequisitionSettings() {
			api.requisitionSettings.get().then(response => {
				const {
					apply_approval_flow_on_new_job_openings,
					apply_approval_flow_on_new_offers,
				} = response.data;
				this.requisitionSettings = {
					apply_approval_flow_on_new_job_openings,
					apply_approval_flow_on_new_offers,
				};
			});
		},
		handleUpdateRequisitionSettingsButtonClick() {
			api.requisitionSettings.put(this.requisitionSettings).then(() => {
				this.$store.dispatch('company/get', {
					refresh: true,
					updateStore: true,
				});
			});
		},
	},
	mounted() {
		this.handleGetRequisitionSettings();
	},
};
</script>
