<template>
	<div>
		<v-dialog
			:value="true"
			max-width="500"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
			scrollable
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>{{ $t('label.positions') }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<v-container>
						<div
							v-for="(
								predefinedPositionPreset, index
							) in predefinedPositionPresets"
							:key="`predefinedPositionPreset_${index}`"
						>
							<v-row>
								<v-col>
									{{ predefinedPositionPreset.name }}
								</v-col>
							</v-row>
							<v-row v-if="index < predefinedPositionPresets.length - 1">
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>
						</div>
					</v-container>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn @click="handleAddOrEditPositionAttrs">{{
						$t('button.add-position')
					}}</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="onClose">{{
						$t('button.close')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<PredefinedPositionPresetAttrs
			v-if="isPredefinedPositionPresetAttrsVisible"
			:onClose="handlePredefinedPositionPresetAttrsClose"
			:method="method"
		></PredefinedPositionPresetAttrs>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import PredefinedPositionPresetAttrs from './predefinedPositionPresetAttrs/PredefinedPositionPresetAttrs';

export default {
	name: 'PredefinedPositionPresets',
	components: {
		PredefinedPositionPresetAttrs,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			predefinedPositionPresets: state => state.predefinedPositionPresets.items,
		}),
	},
	props: {
		onClose: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			isPredefinedPositionPresetAttrsVisible: false,
			method: 'add',
		};
	},
	created() {
		this.$store.dispatch('predefinedPositionPresets/get', {
			updateStore: true,
		});
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handlePredefinedPositionPresetAttrsClose() {
			this.isPredefinedPositionPresetAttrsVisible = false;
		},
		handleAddOrEditPositionAttrs() {
			this.isPredefinedPositionPresetAttrsVisible = true;
		},
	},
};
</script>
