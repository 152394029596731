<template>
	<v-sheet tile style="width: 100%; position: relative">
		<v-calendar
			color="primary"
			type="day"
			:value="calendarDate"
			:events="calendarEvents"
			hide-header
			:show-interval-label="
				() => {
					return interviewerIndex === 0;
				}
			"
			:interval-width="interviewerIndex === 0 ? 60 : 0"
			@mousedown:event="startDrag"
			@mousedown:time="startTime"
			@mousemove:time="mouseMove"
			@mouseup:time="endDrag"
			@mouseleave.native="cancelDrag"
		>
			<template v-slot:event="{ event, timed, eventSummary }">
				<div class="v-event-draggable">
					<component :is="{ render: eventSummary }"></component>
				</div>
				<div
					v-if="timed"
					class="v-event-drag-bottom"
					@mousedown.stop="extendBottom(event)"
				></div>
			</template>
		</v-calendar>
		<v-overlay :value="overlay" absolute color="white">
			<v-alert type="error" v-if="calendarNoAccessError">
				{{
					$t('err.no-calendar-access', {
						interviewer: interviewer.name,
					})
				}}
			</v-alert>
			<v-alert type="error" v-if="calendarInactiveMailboxError">
				{{ $t('err.mailbox-inactive', { name: interviewer.name }) }}
			</v-alert>
			<v-alert type="error" v-if="calendarProviderTokenExpiredError">
				{{ $t('err.provider-token-expired', { name: interviewer.name }) }}
			</v-alert>
			<v-alert type="error" v-if="calendarUnexpectedError">
				{{ $t('err.an-error-occurred') }}
			</v-alert>
		</v-overlay>
	</v-sheet>
</template>

<script>
import { format } from 'date-fns';
import api from '@/api';

export default {
	name: 'InterviewerCalendar',
	props: {
		interviewer: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
		startDate: {
			type: String,
			required: false,
		},
		onMouseDown: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		newEvents: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		interviewerIndex: {
			type: Number,
			required: false,
			default() {
				return 0;
			},
		},
		onEventModify: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	computed: {
		calendarEvents() {
			const { events, newEvents } = this;

			const calendarEvents = [
				...events
					.filter(event => event.start.dateTime)
					.map(event => {
						return {
							name: event.summary,
							date: format(new Date(event.start.dateTime), 'yyyy-MM-dd'),
							start: format(
								new Date(event.start.dateTime),
								'yyyy-MM-dd HH:mm:ss',
							),
							end: format(new Date(event.end.dateTime), 'yyyy-MM-dd HH:mm:ss'),
							color: 'grey darken-1',
						};
					}),
				...newEvents.filter(
					item => item.interviewers.indexOf(this.interviewer.id) > -1,
				),
			];

			return calendarEvents;
		},
	},
	data() {
		const { startDate } = this;
		const calendarDate = format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss');
		return {
			events: [],
			calendarDate,
			overlay: false,
			dragEvent: null,
			dragStart: null,
			createEvent: null,
			createStart: null,
			extendOriginal: null,
			calendarInactiveMailboxError: false,
			calendarUnexpectedError: false,
			calendarProviderTokenExpiredError: false,
			calendarNoAccessError: false,
		};
	},
	methods: {
		getEvents({ timeMin, timeMax }) {
			const userId = this.interviewer.id;
			api.events
				.get({ timeMin, timeMax, userId })
				.then(
					response => {
						const { items } = response.data;
						this.events = items;
					},
					err => {
						const { message } = err.data;
						if (err.status === 401) {
							if (message === 'provider-token-expired') {
								this.calendarProviderTokenExpiredError = true;
							} else if (message === 'no-calendar-access') {
								this.calendarNoAccessError = true;
							} else if (message === 'mailbox-inactive') {
								this.calendarInactiveMailboxError = true;
							}
						}

						if (err.status === 500) {
							this.calendarUnexpectedError = true;
						}

						this.overlay = true;
					},
				)
				.catch(() => {
					// foo 
				});
		},
		toDate({ year, month, day, hour, minute }) {
			const modifiedMinute = minute < 30 ? 0 : 30;
			return new Date(year, month - 1, day, hour, modifiedMinute);
		},
		handleMouseDown(tms) {
			const date = this.toDate(tms);
			this.onMouseDown({ date });
		},
		startDrag({ event, timed }) {
			if (event && timed) {
				this.dragEvent = event;
				this.dragTime = null;
				this.extendOriginal = null;
			}
		},
		startTime(tms) {
			const mouse = this.toTime(tms);

			if (this.dragEvent && this.dragTime === null) {
				const start = new Date(this.dragEvent.start);
				this.dragTime = mouse - start;
			} else {
				const date = this.toDate(tms);
				this.onMouseDown({ date });
			}
		},
		extendBottom(event) {
			this.createEvent = event;
			this.createStart = event.start;
			this.extendOriginal = event.end;
		},
		mouseMove(tms) {
			const mouse = this.toTime(tms);

			if (this.dragEvent && this.dragTime !== null) {
				if (!this.dragEvent.uuid) {
					return false;
				}
				const start = new Date(this.dragEvent.start);
				const end = new Date(this.dragEvent.end);
				const duration = end - start;
				const newStartTime = mouse - this.dragTime;
				const newStart = this.roundTime(newStartTime);
				const newEnd = newStart + duration;
				this.onEventModify({
					newStart,
					newEnd,
					uuid: this.dragEvent.uuid,
				});
			} else if (this.createEvent && this.createStart !== null) {
				const mouseRounded = this.roundTime(mouse, false);
				const min = Math.min(mouseRounded, new Date(this.createStart));
				const max = Math.max(mouseRounded, new Date(this.createStart));
				this.onEventModify({
					newStart: min,
					newEnd: max,
					uuid: this.createEvent.uuid,
				});
			}
		},
		endDrag() {
			this.dragTime = null;
			this.dragEvent = null;
			this.createEvent = null;
			this.createStart = null;
			this.extendOriginal = null;
		},
		cancelDrag() {
			this.createEvent = null;
			this.createStart = null;
			this.dragTime = null;
			this.dragEvent = null;
		},
		roundTime(time, down = true) {
			const roundTo = 15;
			const roundDownTime = roundTo * 60 * 1000;
			return down
				? time - (time % roundDownTime)
				: time + (roundDownTime - (time % roundDownTime));
		},
		toTime(tms) {
			return new Date(
				tms.year,
				tms.month - 1,
				tms.day,
				tms.hour,
				tms.minute,
			).getTime();
		},
		getEventColor(event) {
			const rgb = parseInt(event.color.substring(1), 16);
			const r = (rgb >> 16) & 0xff;
			const g = (rgb >> 8) & 0xff;
			const b = (rgb >> 0) & 0xff;

			return event === this.dragEvent
				? `rgba(${r}, ${g}, ${b}, 0.7)`
				: event === this.createEvent
				? `rgba(${r}, ${g}, ${b}, 0.7)`
				: event.color;
		},
		rndElement(arr) {
			return arr[this.rnd(0, arr.length - 1)];
		},
	},
	watch: {
		startDate: {
			handler(startDateTimeStamp) {
				this.calendarDate = format(
					new Date(startDateTimeStamp),
					'yyyy-MM-dd HH:mm:ss',
				);
				const timeMin = new Date(startDateTimeStamp);
				const timeMax = new Date(timeMin);
				timeMax.setDate(timeMax.getDate() + 1);
				this.getEvents({ timeMin, timeMax });
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>

<style scoped lang="scss">
.v-event-draggable {
	padding-left: 6px;
}

.v-event-timed {
	user-select: none;
	-webkit-user-select: none;
}

.v-event-drag-bottom {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 4px;
	height: 4px;
	cursor: ns-resize;

	&::after {
		display: none;
		position: absolute;
		left: 50%;
		height: 4px;
		border-top: 1px solid white;
		border-bottom: 1px solid white;
		width: 16px;
		margin-left: -8px;
		opacity: 0.8;
		content: '';
	}

	&:hover::after {
		display: block;
	}
}
</style>
