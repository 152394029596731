var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"padding":"0"},attrs:{"fluid":"","fill-height":""}},[_c('v-navigation-drawer',{attrs:{"value":_vm.drawer,"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":`${_vm.isDark ? '#121212' : 'white'}`}},[_c('v-container',[(_vm.currentUser && _vm.currentUser.can_create_job_openings)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"rounded":"","large":""},on:{"click":_vm.handleNewJobClick}},[_c('v-icon',[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('button.new-job-uppercase'))+" ")],1)],1)],1):_vm._e(),(
					_vm.company &&
					_vm.company.apply_approval_flow_on_new_job_openings &&
					_vm.currentUser &&
					_vm.currentUser.can_create_job_requisitions
				)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"rounded":""},on:{"click":_vm.handleNewRequisitionClick}},[_c('v-icon',[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('button.new-job-requisition-uppercase'))+" ")],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.statusItems,"item-text":item => _vm.$t(`label.${item.text}`),"item-value":"value","hide-no-data":"","hide-details":"","outlined":"","label":_vm.$t('label.status'),"dense":""},on:{"change":_vm.handleFilterChange},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(_vm._s(data.item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(_vm.$t(`label.${data.item.text}`))}})],1)]]}}]),model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1)],1),_c('v-row',[_c('v-col',[_c('UserPicker',{attrs:{"users":_vm.users.filter(item => item.active),"label":_vm.$t('label.owner'),"multiple":"","dense":""},on:{"input":_vm.handleFilterChange},model:{value:(_vm.filters.owner),callback:function ($$v) {_vm.$set(_vm.filters, "owner", $$v)},expression:"filters.owner"}})],1)],1),_c('v-row',[_c('v-col',[_c('UserPicker',{attrs:{"users":_vm.users.filter(item => item.active),"label":_vm.$t('label.hiring-manager'),"multiple":"","dense":""},on:{"input":_vm.handleFilterChange},model:{value:(_vm.filters.hiring_manager),callback:function ($$v) {_vm.$set(_vm.filters, "hiring_manager", $$v)},expression:"filters.hiring_manager"}})],1)],1),_c('v-row',[_c('v-col',[_c('TeamPicker',{attrs:{"label":_vm.$t('label.department-team'),"multiple":"","dense":""},on:{"input":_vm.handleFilterChange},model:{value:(_vm.filters.team_id),callback:function ($$v) {_vm.$set(_vm.filters, "team_id", $$v)},expression:"filters.team_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"v-select-talentics-chips",attrs:{"items":_vm.locations.map(item => ({
								value: item.id,
								text: item.name,
							})),"item-text":"text","item-value":"value","hide-no-data":"","hide-details":"","outlined":"","label":_vm.$t('label.location'),"multiple":"","chips":"","deletable-chips":"","dense":""},on:{"change":_vm.handleFilterChange},model:{value:(_vm.filters.location),callback:function ($$v) {_vm.$set(_vm.filters, "location", $$v)},expression:"filters.location"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"v-select-talentics-chips",attrs:{"items":_vm.workTypes,"item-text":"name","item-value":"id","hide-no-data":"","hide-details":"","outlined":"","label":_vm.$t('label.work-type'),"multiple":"","chips":"","deletable-chips":"","dense":""},on:{"change":_vm.handleFilterChange},model:{value:(_vm.filters.work_type),callback:function ($$v) {_vm.$set(_vm.filters, "work_type", $$v)},expression:"filters.work_type"}})],1)],1)],1)],1),_c('v-layout',[_c('v-flex',{staticStyle:{"position":"relative"}},[_c('v-card',{staticStyle:{"position":"absolute","top":"0","right":"0","bottom":"0","left":"0"},attrs:{"tile":"","elevation":"0"}},[_c('div',{style:(`padding: .5rem; background: ${
						_vm.isDark ? '#121212' : '#ffffff'
					};`)},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.handleRefresh}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltip.refresh'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.handleUnfoldAll}},on),[(_vm.allUnfolded)?_c('v-icon',[_vm._v("unfold_less")]):_c('v-icon',[_vm._v("unfold_more")])],1)]}}])},[(_vm.allUnfolded)?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.fold-up')))]):_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.unfold')))])])],1),_c('v-divider'),_c('div',{staticStyle:{"position":"absolute","top":"53px","right":"0","bottom":"0","left":"0","overflow":"auto"}},[(_vm.jobs.length === 0)?_c('div',{staticStyle:{"padding":"1rem"}},[_vm._v(" "+_vm._s(_vm.$t('err.no-jobs-found'))+" ")]):_c('div',[_c('JobsTree',{attrs:{"jobs":_vm.jobs,"onArchiveJob":_vm.handleArchiveJob,"unfold":_vm.allUnfolded}}),(_vm.isConfirmVisible)?_c('Confirm',{attrs:{"onConfirm":_vm.handleConfirmDialogConfirm,"onClose":_vm.handleConfirmDialogClose,"confirmText":"archive","primaryActionColor":"error"}},[_c('div',{staticStyle:{"padding-top":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('text.confirm-archive-job', { jobName: _vm.jobNameToArchive, }))+" ")])]):_vm._e()],1)]),_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }