<template>
	<div>
		<v-dialog
			:value="true"
			max-width="600"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			scrollable
			persistent
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>{{
							$t('text.new-approval-request')
						}}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<v-container>
						<v-row v-if="approvalRequest.created">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>{{ $t('label.status') }}</legend>
									<v-tooltip top v-if="approvalRequest.status === 'pending'">
										<template v-slot:activator="{ on }">
											<v-icon v-on="on" color="warning" left>pending</v-icon>
										</template>
										{{ $t('status.pending') }}
									</v-tooltip>
									<v-tooltip
										top
										v-else-if="approvalRequest.status === 'approved'"
									>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on" color="success" left
												>check_circle</v-icon
											>
										</template>
										{{ $t('status.approved') }}
									</v-tooltip>
									<v-tooltip
										top
										v-else-if="approvalRequest.status === 'rejected'"
									>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on" color="error">cancel</v-icon>
										</template>
										{{ $t('status.rejected') }}
									</v-tooltip>
									<v-tooltip
										top
										v-else-if="approvalRequest.status === 'queued'"
									>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on">pause_circle_filled</v-icon>
										</template>
										{{ $t('status.rejected') }}
									</v-tooltip>
									{{ $t(`status.${approvalRequest.status}`) }}
								</fieldset>
							</v-col>
						</v-row>
						<v-row v-if="approvalRequest.status === 'approved'">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.approved-at') }}
									</legend>
									<div
										v-html="
											format(
												new Date(approvalRequest.approved),
												'yyyy-MM-dd HH:mm:ss',
											)
										"
									></div>
								</fieldset>
							</v-col>
						</v-row>
						<v-row v-if="approvalRequest.status === 'approved'">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.approved-by') }}
									</legend>
									<UserChips
										:userIds="[approvalRequest.requested_by]"
										chipColor="transparent"
										cursor="pointer"
									></UserChips>
								</fieldset>
							</v-col>
						</v-row>
						<v-row v-if="approvalRequest.status === 'rejected'">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.rejected-at') }}
									</legend>
									<div
										v-html="
											format(
												new Date(approvalRequest.rejected),
												'yyyy-MM-dd HH:mm:ss',
											)
										"
									></div>
								</fieldset>
							</v-col>
						</v-row>
						<v-row v-if="approvalRequest.status === 'rejected'">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.rejected-by') }}
									</legend>
									<UserChips
										:userIds="[approvalRequest.rejected_by]"
										chipColor="transparent"
										cursor="pointer"
									></UserChips>
								</fieldset>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.requested-from') }}
									</legend>
									<UserChips
										:userIds="[approvalRequest.requested_from]"
										chipColor="transparent"
										cursor="pointer"
									></UserChips>
								</fieldset>
							</v-col>
						</v-row>
						<v-row v-if="approvalRequest.created">
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.requested-at') }}
									</legend>
									<div
										v-html="
											format(
												new Date(approvalRequest.created),
												'yyyy-MM-dd HH:mm:ss',
											)
										"
									></div>
								</fieldset>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.requested-by') }}
									</legend>
									<div>
										<UserChips
											:userIds="[approvalRequest.requested_by]"
											chipColor="transparent"
											cursor="pointer"
										></UserChips>
									</div>
								</fieldset>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="() => onClose({ refresh: false })"
						>{{ $t('button.close') }}</v-btn
					>
					<v-btn
						v-if="
							approvalRequest.requested_from === currentUser.id &&
							(approvalRequest.status === 'pending' ||
								approvalRequest.status === 'rejected')
						"
						color="error"
						@click="() => handleChangeStatus({ newVal: 'rejected' })"
					>
						<v-icon left>cancel</v-icon>
						{{ $t('button.reject') }}
					</v-btn>
					<v-btn
						v-if="
							(approvalRequest.requested_from === currentUser.id ||
								userRole === 777) &&
							(approvalRequest.status === 'pending' ||
								approvalRequest.status === 'rejected')
						"
						color="success"
						@click="() => handleChangeStatus({ newVal: 'approved' })"
					>
						<v-icon left>check_circle</v-icon>
						{{ $t('button.approve') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import UserChips from '@/components/shared/userChips/UserChips';
import api from '@/api';

export default {
	name: 'UserApprovalRequestDialog',
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			users: state => state.users.items,
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
		}),
	},
	components: {
		UserChips,
	},
	props: {
		onClose: {
			type: Function,
			required: true,
		},
		approvalRequestId: {
			type: [Number, String],
			required: true,
		},
		onDeleteConfirm: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onChangeStatus: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			format,
			isDeleteApprovalRequestConfirmVisible: false,
			approvalRequest: {},
		};
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleGetUserApprovalRequest({ id }) {
			api.userApprovalRequests.get({ id }).then(response => {
				const { items } = response.data;

				if (items.length > 0) {
					this.approvalRequest = items[0];
				}
			});
		},
		handleChangeStatus({ newVal }) {
			return api.userApprovalRequests
				.put({
					id: this.approvalRequest.id,
					status: newVal,
				})
				.then(() => {
					this.onClose({ refresh: true });
				});
		},
	},
	watch: {
		approvalRequestId: {
			handler: function (newVal) {
				this.handleGetUserApprovalRequest({ id: newVal });
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
