<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" outlined text class="checkbox-with-menu-button">
				<v-checkbox
					v-model="checkboxModel"
					:indeterminate="indeterminateModel"
					dense
					hide-details
					class="checkbox-with-menu-checkbox"
					@change="handleChange"
					:color="isDark ? 'white' : '#121212'"
				></v-checkbox>
				<span v-on="on" class="checkbox-with-menu-icon-container">
					<v-icon>arrow_drop_down</v-icon>
				</span>
			</v-btn>
		</template>
		<slot></slot>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'CheckboxWithMenu',
	props: {
		checked: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		indeterminate: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		onSelectNone: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onSelectAll: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			checkboxModel: false,
			indeterminateModel: false,
		};
	},
	methods: {
		handleChange() {
			const { checked, indeterminate } = this;
			if (checked || indeterminate) {
				this.onSelectNone();
			} else {
				this.onSelectAll();
			}
		},
		setCheckboxModel() {
			const { checked, indeterminate } = this;
			this.checkboxModel = checked;
			this.indeterminateModel = indeterminate;
		},
	},
	watch: {
		checked: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					setTimeout(
						function () {
							this.setCheckboxModel();
						}.bind(this),
						10,
					);
				}
			},
			immediate: true,
			deep: true,
		},
		indeterminate: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					setTimeout(
						function () {
							this.setCheckboxModel();
						}.bind(this),
						10,
					);
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.v-btn {
	&.checkbox-with-menu-button {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
.checkbox-with-menu-icon-container {
	margin-left: -0.5rem;
}
.v-input--selection-controls {
	&.checkbox-with-menu-checkbox {
		margin-top: 0;
	}
}
</style>
