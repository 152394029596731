<template>
	<ValidationProvider :rules="rules" v-slot="{ errors }">
		<v-autocomplete
			:value="value"
			:items="items"
			:item-text="
				item =>
					`${item.job_name} (${formatDate({
						timestamp: item.created,
					})})`
			"
			:item-value="item => item.id"
			hide-no-data
			hide-details
			outlined
			:label="label"
			@change="handleChange"
			:multiple="multiple"
			:chips="multiple"
			:deletable-chips="multiple"
			:clearable="clearable"
			class="v-select-talentics-chips"
			:error-messages="errors"
			:dense="dense"
		>
		</v-autocomplete>
	</ValidationProvider>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import { ValidationProvider } from 'vee-validate';

export default {
	name: 'ApplicationPicker',
	props: {
		applications: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		value: {
			type: [Number, Array],
			required: false,
			default() {
				return null;
			},
		},
		label: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		multiple: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		clearable: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		rules: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		ValidationProvider,
	},
	computed: {
		...mapState({
			jobs: state => state.jobs.items,
		}),
		items() {
			const { applications } = this;
			return applications.map(({ id, job_id, created }) => {
				const { jobs } = this;
				const job = jobs.find(item => item.id === job_id);
				return {
					job_name: job ? job.name : '',
					id,
					created,
				};
			});
		},
	},
	data() {
		return {
			format,
		};
	},
	methods: {
		handleChange(newVal) {
			this.$emit('input', newVal);
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
	},
};
</script>
