<template>
	<div>
		<v-row v-for="(item, index) in answerSet" :key="`answer_${index}`">
			<v-col>
				<fieldset class="talentics-fieldset talentics-fieldset-paddings">
					<legend>{{ handleGetQuestion({ item }) }}</legend>
					<div>{{ handleGetAnswer({ item }) }}</div>
				</fieldset>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'AdditionalQuestions',
	props: {
		questionSet: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		answerSet: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
	},
	methods: {
		handleGetAnswer({ item }) {
			const { questionSet } = this;
			const { propName } = item;
			let foundAnswerValueText = '';

			if (propName) {
				const question = questionSet.find(
					questionSetItem => questionSetItem.propName === propName,
				);
				if (item.value && this.answerSet) {
					const foundAnswer = this.answerSet.find(
						answer => answer.value === item.value,
					);

					foundAnswerValueText = foundAnswer.options?.find(
						option => option.value === item.value,
					)?.label;
				}

				if (question) {
					if (question.type === 'dropdown') {
						const { options } = question;
						const option = options.find(
							optionItem => optionItem.value === item.value,
						);
						if (option) {
							return option.label;
						} else {
							return foundAnswerValueText || item.value || '-';
						}
					} else {
						return foundAnswerValueText || item.value || '-';
					}
				} else {
					return foundAnswerValueText || item.value || '-';
				}
			} else {
				return foundAnswerValueText || item.value || '-';
			}
		},
		handleGetQuestion({ item }) {
			const { questionSet } = this;
			const { propName } = item;

			if (propName) {
				const question = questionSet.find(
					questionSetItem => questionSetItem.propName === propName,
				);

				if (question) {
					return question.label;
				} else {
					return item.label;
				}
			} else {
				return item.label;
			}
		},
	},
};
</script>
