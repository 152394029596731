import api from '@/api';

const state = {
	items: [],
	total: 0,
	updated: 0,
};

const getters = {};

const actions = {
	get: ({ commit }, { refresh = false, updateStore = false }) => {
		return new Promise((resolve, reject) => {
			const { updated } = state;
			if (!refresh && updated > 0) {
				resolve({ data: { items: state.items } });
			}
			api.groupMail
				.get()
				.then(response => {
					const { data } = response;
					if (updateStore) {
						commit('setItems', { data });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	post: (_, params) => {
		return new Promise((resolve, reject) => {
			api.groupMail
				.post({ emailAddress: params.emailAddress })
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	put: (_, params) => {
		return new Promise((resolve, reject) => {
			api.groupMail
				.put(
					{
						isActive: params.is_active,
						emailAddress: params.email_address,
					},
					params.id,
				)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	revokeConsent: (_, params) => {
		return new Promise((resolve, reject) => {
			api.groupMail
				.revokeConsent(params.email_address, params.currentUserID)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setItems: (state, { data = [] }) => {
		const now = new Date();
		state.items = data.data;
		state.updated = now.getTime();
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
