<template>
	<div>
		<v-dialog
			:value="true"
			max-width="400"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
		>
			<v-card>
				<ValidationObserver ref="pppa_form" tag="form" @submit="handleSubmit">
					<v-container>
						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-text-field
										v-model="predefinedPositionPresetEditable.name"
										outlined
										:label="$t('label.name')"
										:error-messages="errors"
										hide-details
										dense
									></v-text-field>
								</ValidationProvider>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.status') }}:
										{{
											predefinedPositionPresetEditable.active
												? $t('label.active')
												: $t('label.inactive')
										}}
									</legend>
									<v-switch
										v-model="predefinedPositionPresetEditable.active"
										color="success"
										inset
										hide-details
										dense
										class="v-input--template-switch"
									></v-switch>
								</fieldset>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleClose">{{
						$t('button.close')
					}}</v-btn>
					<!-- <v-btn color="error" outlined @click="hanleDeleteClick" v-if="method === 'edit'">{{ $t('button.delete') }}</v-btn> -->
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- <Confirm
      v-if="isDeleteConfirmVisible"
      :onConfirm="handleConfirmDialogConfirm"
      :onClose="handleConfirmDialogClose"
      primaryActionColor="error"
    ><div style="padding-top: 20px;">{{ $t('text.confirm-deleting-position') }}</div></Confirm> -->
	</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
// import Confirm from '@/components/shared/dialogs/confirm/Confirm';
// import { mapState } from 'vuex';

export default {
	name: 'PredefinedPositionPresetAttrs',
	components: {
		ValidationObserver,
		ValidationProvider,
		// Confirm,
	},
	props: {
		method: {
			type: String,
			required: true,
		},
		predefinedPositionPreset: {
			type: Object,
			required: false,
			default() {
				return {
					active: true,
					name: '',
				};
			},
		},
		onClose: {
			type: Function,
			required: true,
		},
	},
	data() {
		const { predefinedPositionPreset } = this;
		return {
			predefinedPositionPresetEditable: JSON.parse(
				JSON.stringify(predefinedPositionPreset),
			),
			// isDeleteConfirmVisible: false,
		};
	},
	methods: {
		handleClose() {
			this.onClose();
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.pppa_form.validate();
			if (isValid) {
				const { predefinedPositionPresetEditable, method } = this;
				if (method === 'edit') {
					this.$store
						.dispatch('predefinedPositionPresets/put', {
							items: [{ ...predefinedPositionPresetEditable }],
						})
						.then(() => {
							this.handleClose();
						});
				} else if (method === 'add') {
					this.$store
						.dispatch('predefinedPositionPresets/post', {
							items: [{ ...predefinedPositionPresetEditable }],
						})
						.then(() => {
							this.handleClose();
						});
				}
			}
		},
		// handleConfirmDialogConfirm() {
		//   const { id } = this.predefinedPositionPresetEditable;
		//   this.$store.dispatch('predefinedPositionPresets/delete', { id }).then(() => {
		//     this.handleClose();
		//   });
		// },
		// handleConfirmDialogClose() {
		//   this.isDeleteConfirmVisible = false;
		// },
		// hanleDeleteClick() {
		//   this.isDeleteConfirmVisible = true;
		// },
		handleClickOutside(evt) {
			evt.preventDefault();
		},
	},
	watch: {
		predefinedPositionPreset(newVal) {
			this.predefinedPositionPresetEditable = { ...newVal };
		},
	},
};
</script>
