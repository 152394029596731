import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify, {
	directives: {
		Ripple,
	},
});

export default new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
});
