<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			:max-width="700"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
		>
			<v-card>
				<v-card-text style="padding-top: 1rem">
					<ValidationObserver
						ref="form_question"
						tag="form"
						@submit="handleSubmit"
						id="form_question"
					>
						<v-container>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="itemEditable.type"
											:items="[
												{
													text: 'Text',
													value: 'text',
												},
												{
													text: 'Dropdown',
													value: 'dropdown',
												},
											]"
											outlined
											hide-details
											dense
											:label="$t('label.type')"
											:error-messages="errors"
											@change="
												newVal =>
													handleItemTypeChange({
														newVal,
													})
											"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="itemEditable.label"
											:label="`${$t('label.label')}*`"
											flat
											hide-details
											outlined
											:error-messages="errors"
											name="text"
											type="text"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.required') }}:
											{{
												itemEditable.required ? $t('label.yes') : $t('label.no')
											}}
										</legend>
										<v-switch
											v-model="itemEditable.required"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.sensitive-data') }}:
											{{
												itemEditable.sensitive
													? $t('label.yes')
													: $t('label.no')
											}}
										</legend>
										<v-switch
											v-model="itemEditable.sensitive"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>
							<v-row v-if="itemEditable.type === 'dropdown'">
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.options') }}
										</legend>
										<v-container>
											<Draggable
												v-model="itemEditable.options"
												@start="handleDragStart"
												@end="handleDragEnd"
											>
												<div
													v-for="(item, index) in itemEditable.options"
													:key="`option_${index}`"
												>
													<v-row>
														<v-col cols="auto" align-self="center">
															<span class="all-scroll">
																<v-icon>drag_indicator</v-icon>
															</span>
														</v-col>

														<v-col align-self="center">
															<ValidationProvider
																rules="required"
																v-slot="{ errors }"
															>
																<v-text-field
																	v-model="item.label"
																	:label="`${$t('label.label')}*`"
																	flat
																	hide-details
																	outlined
																	:error-messages="errors"
																	name="text"
																	type="text"
																	dense
																></v-text-field>
															</ValidationProvider>
														</v-col>

														<v-col align-self="center">
															<ValidationProvider rules="" v-slot="{ errors }">
																<v-autocomplete
																	v-model="item.tag_id"
																	:items="[
																		{
																			name: $t('label.add-new-tag'),
																			id: 0,
																		},
																		...tags,
																	]"
																	:label="`${$t('label.tag-candidate')}`"
																	item-text="name"
																	item-value="id"
																	flat
																	hide-details
																	outlined
																	:error-messages="errors"
																	name="tag_id"
																	dense
																	@change="
																		newVal =>
																			handleOptionTagIdChange({
																				newVal,
																				index,
																			})
																	"
																></v-autocomplete>
															</ValidationProvider>
														</v-col>

														<v-col
															class="disp-flex flex-end"
															cols="auto"
															align-self="center"
														>
															<v-btn
																text
																icon
																@click="
																	() =>
																		handleDeleteOption({
																			index,
																		})
																"
															>
																<v-icon>delete</v-icon>
															</v-btn>
														</v-col>
													</v-row>
													<v-divider
														style="margin-top: 1rem; margin-bottom: 1rem"
													></v-divider>
												</div>
											</Draggable>
											<v-row>
												<v-col>
													<v-btn @click="handleAddOption">
														<v-icon left>add</v-icon>
														{{ $t('button.add') }}
													</v-btn>
												</v-col>
											</v-row>
										</v-container>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="handleClose">{{ $t('button.cancel') }}</v-btn>
					<v-btn @click="handleSubmit" color="primary" dark>{{
						$t('button.apply')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<TagAttrs
			v-if="isTagsAttrsVisible"
			method="add"
			:onClose="handleTagAttrsClose"
		></TagAttrs>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Draggable from 'vuedraggable';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import TagAttrs from '../../../routes/settings/tags/tagsAttrs/TagsAttrs';

export default {
	name: 'AdditionalApplicationFormQuestion',
	components: {
		Draggable,
		ValidationObserver,
		ValidationProvider,
		TagAttrs,
	},
	props: {
		item: {
			type: Object,
			required: false,
			default() {
				return {
					type: 'text',
					label: '',
					required: false,
					sensitive: false,
					options: [],
					tag_id: null,
				};
			},
		},
		onClose: {
			type: Function,
			required: true,
		},
		onUpdate: {
			type: Function,
			required: true,
		},
	},
	computed: {
		...mapState({
			tags: state => state.tags.items,
		}),
	},
	data() {
		return {
			isVisible: true,
			itemEditable: {
				propName: null,
				type: 'text',
				label: '',
				options: [],
				sensitive: false,
				required: false,
			},
			drag: false,
			isTagsAttrsVisible: false,
			optionIndexToPutTagId: null,
		};
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleClose() {
			this.onClose();
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.form_question.validate();

			if (isValid) {
				this.onUpdate(this.itemEditable);
			}
		},
		handleItemTypeChange({ newVal }) {
			if (newVal === 'dropdown') {
				const rand = Math.floor(100000 + Math.random() * 9000000);

				this.itemEditable = {
					...this.itemEditable,
					options: [
						{
							label: '',
							value: `option_${rand}`,
							tag_id: null,
						},
					],
				};
			} else {
				this.itemEditable = {
					...this.itemEditable,
					options: [],
				};
			}
		},
		handleAddOption() {
			const rand = Math.floor(100000 + Math.random() * 9000000);

			this.itemEditable = {
				...this.itemEditable,
				options: [
					...this.itemEditable.options,
					{
						label: '',
						value: `option_${rand}`,
						tag_id: null,
					},
				],
			};
		},
		handleDeleteOption({ index }) {
			this.itemEditable = {
				...this.itemEditable,
				options: this.itemEditable.options.filter(
					(item, itemIndex) => itemIndex !== index,
				),
			};
		},
		handleDragStart() {
			this.drag = true;
		},
		handleDragEnd() {
			this.drag = false;
		},
		handleOptionTagIdChange({ newVal, index }) {
			if (newVal === 0) {
				setTimeout(
					function () {
						this.itemEditable = {
							...this.itemEditable,
							options: this.itemEditable.options.map((item, itemIndex) => {
								if (index === itemIndex) {
									return {
										...item,
										tag_id: null,
									};
								}
								return item;
							}),
						};
						this.optionIndexToPutTagId = index;
						this.isTagsAttrsVisible = true;
					}.bind(this),
					50,
				);
			} else {
				this.optionIndexToPutTagId = null;
			}
		},
		handleTagAttrsClose({ items }) {
			this.isTagsAttrsVisible = false;
			const { optionIndexToPutTagId } = this;

			if (items && items.length > 0 && optionIndexToPutTagId) {
				const { id } = items[0];
				this.itemEditable = {
					...this.itemEditable,
					options: this.itemEditable.options.map((item, itemIndex) => {
						if (optionIndexToPutTagId === itemIndex) {
							return {
								...item,
								tag_id: id,
							};
						}
						return item;
					}),
				};
			}
		},
	},
	created() {
		this.$store.dispatch('tags/get', { updateStore: true });
	},
	watch: {
		item: {
			handler(newVal) {
				if (newVal) {
					const { propName, type, label, options, sensitive, required } =
						JSON.parse(JSON.stringify(newVal));

					if (propName) {
						this.itemEditable = {
							...this.itemEditable,
							propName,
						};
					} else {
						const rand = Math.floor(100000 + Math.random() * 9000000);

						this.itemEditable = {
							...this.itemEditable,
							propName: `linkQuestion_${rand}`,
						};
					}

					if (type) {
						this.itemEditable = {
							...this.itemEditable,
							type,
						};
					} else {
						this.itemEditable = {
							...this.itemEditable,
							type: 'text',
						};
					}

					if (label) {
						this.itemEditable = {
							...this.itemEditable,
							label,
						};
					}

					if (required) {
						this.itemEditable = {
							...this.itemEditable,
							required,
						};
					} else {
						this.itemEditable = {
							...this.itemEditable,
							required: false,
						};
					}

					if (sensitive) {
						this.itemEditable = {
							...this.itemEditable,
							sensitive,
						};
					} else {
						this.itemEditable = {
							...this.itemEditable,
							sensitive: false,
						};
					}

					if (options) {
						this.itemEditable = {
							...this.itemEditable,
							options,
						};
					} else {
						this.itemEditable = {
							...this.itemEditable,
							options: [],
						};
					}
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
