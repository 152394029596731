<template>
	<v-menu
		offset-y
		bottom
		left
		:max-height="500"
		:max-width="500"
		v-model="menu"
	>
		<template v-slot:activator="{ on }">
			<span v-on="on">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
							<v-badge
								color="pink"
								overlap
								bottom
								:content="unseenNotificationsCounter"
								:value="unseenNotificationsCounter"
							>
								<v-icon>notifications</v-icon>
							</v-badge>
						</v-btn>
					</template>
					{{ !menu ? $t('tooltip.notifications') : '' }}
				</v-tooltip>
			</span>
		</template>

		<v-list>
			<v-list-item-group v-if="notifications.length > 0">
				<template v-for="(notification, index) in notifications">
					<v-list-item :key="index" @click="handleClick(notification)">
						<v-list-item-avatar>
							<Avatar :userId="notification.created_by_id"></Avatar>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title
								v-if="notification.type === 'new_feedback'"
								v-html="
									$t('notifications.has-new-feedback', {
										candidate: notification.candidate_name,
									})
								"
								style="white-space: normal"
							></v-list-item-title>
							<v-list-item-title
								v-if="notification.type === 'new_form'"
								v-html="
									$t('notifications.has-new-form', {
										candidate: notification.candidate_name,
									})
								"
								style="white-space: normal"
							></v-list-item-title>
							<v-list-item-subtitle
								v-html="
									formatDate({
										timestamp: notification.created,
									})
								"
							></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider
						:key="`divider_${index}`"
						v-if="index < notifications.length - 1"
					></v-divider>
				</template>
			</v-list-item-group>

			<v-list-item v-else disabled>
				<v-list-item-title>
					{{ $t('text.no-new-notifications') }}</v-list-item-title
				>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { db } from '../../../../../main';
import {
	collection,
	where,
	orderBy,
	limit,
	query,
	getDocs,
} from 'firebase/firestore';
import { format } from 'date-fns';
import { mapState } from 'vuex';
import Avatar from '@/components/shared/avatar/Avatar';
import api from '@/api';

export default {
	name: 'Notifications',
	components: {
		Avatar,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			currentLang: state => state.currentUser.lang,
		}),
		unseenNotificationsCounter() {
			return this.notifications.filter(item => item.seen === false).length;
		},
	},
	data() {
		return {
			format,
			notifications: [],
			menu: false,
		};
	},
	methods: {
		async getNotifications() {
			const { uuidv4, company_id } = this.currentUser;
			const that = this;

			const notificationsRef = collection(db, `users/${uuidv4}/notifications`);
			const notificationsQuery = query(
				notificationsRef,
				where('company_id', '==', company_id),
				orderBy('created', 'desc'),
				limit(20),
			);

			try {
				const querySnapshot = await getDocs(notificationsQuery);
				const notifications = [];

				querySnapshot.forEach(doc => {
					notifications.push({ ...doc.data(), id: doc.id });
				});

				that.$store.commit('notifications/setItems', {
					items: notifications,
				});
				that.notifications = notifications;
			} catch (error) {
				console.error('Error querying documents: ', error);
			}
		},
		handleClick({ type, candidate_id }) {
			if (type === 'new_feedback') {
				const { currentLang } = this;
				const { query } = this.$route;
				this.$router.push({
					path: `/${currentLang}/candidates/edit/${candidate_id}`,
					query: {
						...query,
						is_search: 1,
						tab: 'feedbacks',
					},
				});
			} else if (type === 'new_form') {
				const { currentLang } = this;
				const { query } = this.$route;
				this.$router.push({
					path: `/${currentLang}/candidates/edit/${candidate_id}`,
					query: {
						...query,
						is_search: 1,
						tab: 'forms',
					},
				});
			}
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleMarksAsSeen() {
			const unseenNotifications = this.notifications.filter(
				item => item.seen === false,
			);
			if (unseenNotifications.length > 0) {
				api.notifications.markAsSeen
					.post({
						ids: unseenNotifications.map(item => item.id),
					})
					.then(() => {
						this.getNotifications();
					});
			}
		},
	},
	watch: {
		currentUser: {
			handler: function (newVal) {
				if (newVal && newVal.uuidv4) {
					this.getNotifications();
				}
			},
			immediate: true,
			deep: true,
		},
		menu: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal && newVal === true) {
					this.handleMarksAsSeen();
				}
			},
			immediate: false,
			deep: true,
		},
	},
};
</script>
