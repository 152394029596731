<template>
	<v-container fluid fill-height style="padding: 0">
		<v-navigation-drawer
			:value="drawer"
			:clipped="$vuetify.breakpoint.lgAndUp"
			app
			:color="`${isDark ? '#121212' : 'white'}`"
		>
			<v-container>
				<v-row v-if="currentUser && currentUser.can_create_job_openings">
					<v-col>
						<v-btn rounded large @click="handleNewJobClick">
							<v-icon>add</v-icon>
							{{ $t('button.new-job-uppercase') }}
						</v-btn>
					</v-col>
				</v-row>
				<v-row
					v-if="
						company &&
						company.apply_approval_flow_on_new_job_openings &&
						currentUser &&
						currentUser.can_create_job_requisitions
					"
				>
					<v-col>
						<v-btn rounded @click="handleNewRequisitionClick">
							<v-icon>add</v-icon>
							{{ $t('button.new-job-requisition-uppercase') }}
						</v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-autocomplete
							v-model="filters.status"
							:items="statusItems"
							:item-text="item => $t(`label.${item.text}`)"
							item-value="value"
							hide-no-data
							hide-details
							outlined
							@change="handleFilterChange"
							:label="$t('label.status')"
							dense
						>
							<template v-slot:item="data">
								<template>
									<v-list-item-avatar>
										<v-icon>{{ data.item.icon }}</v-icon>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title
											v-html="$t(`label.${data.item.text}`)"
										></v-list-item-title>
									</v-list-item-content>
								</template>
							</template>
						</v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<UserPicker
							v-model="filters.owner"
							:users="users.filter(item => item.active)"
							:label="$t('label.owner')"
							multiple
							@input="handleFilterChange"
							dense
						></UserPicker>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<UserPicker
							v-model="filters.hiring_manager"
							:users="users.filter(item => item.active)"
							:label="$t('label.hiring-manager')"
							multiple
							@input="handleFilterChange"
							dense
						></UserPicker>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<TeamPicker
							v-model="filters.team_id"
							:label="$t('label.department-team')"
							multiple
							dense
							@input="handleFilterChange"
						></TeamPicker>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-autocomplete
							v-model="filters.location"
							:items="
								locations.map(item => ({
									value: item.id,
									text: item.name,
								}))
							"
							item-text="text"
							item-value="value"
							hide-no-data
							hide-details
							outlined
							@change="handleFilterChange"
							:label="$t('label.location')"
							multiple
							chips
							deletable-chips
							class="v-select-talentics-chips"
							dense
						></v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-autocomplete
							v-model="filters.work_type"
							:items="workTypes"
							item-text="name"
							item-value="id"
							hide-no-data
							hide-details
							outlined
							@change="handleFilterChange"
							:label="$t('label.work-type')"
							multiple
							chips
							deletable-chips
							class="v-select-talentics-chips"
							dense
						></v-autocomplete>
					</v-col>
				</v-row>
			</v-container>
		</v-navigation-drawer>
		<v-layout>
			<v-flex style="position: relative">
				<v-card
					tile
					elevation="0"
					style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
				>
					<div
						:style="`padding: .5rem; background: ${
							isDark ? '#121212' : '#ffffff'
						};`"
					>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" icon @click="handleRefresh">
									<v-icon>refresh</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.refresh') }}
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" icon @click="handleUnfoldAll">
									<v-icon v-if="allUnfolded">unfold_less</v-icon>
									<v-icon v-else>unfold_more</v-icon>
								</v-btn>
							</template>
							<span v-if="allUnfolded">{{ $t('tooltip.fold-up') }}</span>
							<span v-else>{{ $t('tooltip.unfold') }}</span>
						</v-tooltip>
					</div>
					<v-divider></v-divider>
					<div
						style="
							position: absolute;
							top: 53px;
							right: 0;
							bottom: 0;
							left: 0;
							overflow: auto;
						"
					>
						<div v-if="jobs.length === 0" style="padding: 1rem">
							{{ $t('err.no-jobs-found') }}
						</div>
						<div v-else>
							<JobsTree
								:jobs="jobs"
								:onArchiveJob="handleArchiveJob"
								:unfold="allUnfolded"
							></JobsTree>

							<Confirm
								v-if="isConfirmVisible"
								:onConfirm="handleConfirmDialogConfirm"
								:onClose="handleConfirmDialogClose"
								confirmText="archive"
								primaryActionColor="error"
								><div style="padding-top: 20px">
									{{
										$t('text.confirm-archive-job', {
											jobName: jobNameToArchive,
										})
									}}
								</div></Confirm
							>
						</div>
					</div>
					<router-view></router-view>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import TeamPicker from '@/components/shared/teamPicker/TeamPicker';
import JobsTree from './jobsTree/JobsTree';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';

export default {
	name: 'Jobs',
	components: {
		UserPicker,
		JobsTree,
		TeamPicker,
		Confirm,
	},
	created() {
		this.$store.commit('currentRoute/setRouteFirstLevel', {
			routeName: 'jobs',
		});
		this.$store.dispatch('organizations/get', { updateStore: true });
		this.$store.dispatch('departments/get', { updateStore: true });
		this.$store.dispatch('teams/get', { updateStore: true });
		this.$store.dispatch('users/get', { updateStore: true });
		this.$store.dispatch('locations/get', { updateStore: true });
		this.$store.dispatch('workTypes/get', { updateStore: true });
		this.$store.dispatch('ogPreferences/get', { updateStore: true });
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			users: state => state.users.items,
			locations: state => state.locations.items,
			workTypes: state => state.workTypes.items,
			itemsNeedToRefresh: state => state.jobs.itemsNeedToRefresh,
			drawer: state => state.currentRoute.drawer,
			isDark: state => state.currentUser.isDark,
			company: state => state.company.company,
			currentUser: state => state.currentUser.currentUser,
		}),
	},
	data() {
		const { status, owner, hiring_manager, team_id, location, work_type } =
			this.$route.query;
		return {
			isOpened: true,
			jobs: [],
			tab: 0,
			filters: {
				status: status ? status : 'open',
				owner: owner ? owner.split(',').map(item => parseInt(item, 10)) : [],
				hiring_manager: hiring_manager
					? hiring_manager.split(',').map(item => parseInt(item, 10))
					: [],
				team_id: team_id
					? team_id.split(',').map(item => parseInt(item, 10))
					: [],
				location: location
					? location.split(',').map(item => parseInt(item, 10))
					: [],
				work_type: work_type
					? work_type.split(',').map(item => parseInt(item, 10))
					: [],
			},
			statusItems: [
				{
					text: 'all-open-jobs',
					value: 'open',
					icon: 'business_center',
				},
				{
					text: 'pending',
					value: 'pending',
					icon: 'pending',
				},
				{
					text: 'drafts',
					value: 'draft',
					icon: 'edit',
				},
				{
					text: 'closed',
					value: 'closed',
					icon: 'indeterminate_check_box',
				},
				{
					text: 'archived',
					value: 'archived',
					icon: 'archive',
				},
			],
			isConfirmVisible: false,
			jobIdToArchive: null,
			jobNameToArchive: null,
			allUnfolded: true,
		};
	},
	methods: {
		handleFilterChange() {
			const { currentLang } = this;
			const { status, owner, hiring_manager, team_id, location, work_type } =
				this.filters;
			const query = {
				status: status !== 'open' ? status : undefined,
				owner: owner.length > 0 ? owner.join(',') : undefined,
				hiring_manager:
					hiring_manager.length > 0 ? hiring_manager.join(',') : undefined,
				team_id: team_id.length > 0 ? team_id.join(',') : undefined,
				location: location.length > 0 ? location.join(',') : undefined,
				work_type: work_type.length > 0 ? work_type.join(',') : undefined,
			};
			this.$router.push({
				path: `/${currentLang}/jobs`,
				query,
			});
		},
		getJobs({ query }) {
			this.$store
				.dispatch('jobs/get', {
					...query,
					status: query.status ? query.status : 'open',
					refresh: true,
				})
				.then(response => {
					const { items } = response.data;
					this.jobs = items;
				});
		},
		handleRefresh() {
			const { query } = this.$route;
			this.getJobs({ query });
		},
		handleNewJobClick() {
			const { currentLang } = this;
			const { query } = this.$route;

			this.$router.push({
				path: `/${currentLang}/jobs/new`,
				query,
			});
		},
		handleNewRequisitionClick() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/jobs/new-requisition`,
				query,
			});
		},
		handleArchiveJob({ id, name }) {
			this.jobIdToArchive = id;
			this.jobNameToArchive = name;
			this.isConfirmVisible = true;
		},
		handleConfirmDialogClose() {
			this.jobIdToArchive = null;
			this.jobNameToArchive = null;
			this.isConfirmVisible = false;
		},
		handleConfirmDialogConfirm() {
			const { jobIdToArchive } = this;
			this.$store
				.dispatch('jobs/put', {
					items: [
						{
							id: jobIdToArchive,
							status: 'archived',
						},
					],
				})
				.then(() => {
					this.handleRefresh();
					this.handleConfirmDialogClose();
				});
		},
		handleUnfoldAll() {
			this.allUnfolded = !this.allUnfolded;
		},
	},
	watch: {
		'$route.query': {
			handler: function (newVal, oldVal) {
				if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
					this.getJobs({ query: newVal });
				}
			},
			deep: true,
			immediate: true,
		},
		itemsNeedToRefresh() {
			const { query } = this.$route;
			this.getJobs({ query });
		},
	},
};
</script>
