<template>
	<JobDialog
		:jobId="jobId ? parseInt(jobId, 10) : -1"
		:onClose="handleClose"
	></JobDialog>
</template>

<script>
import { mapState } from 'vuex';
import JobDialog from '@/components/routes/jobs/job/jobDialog/JobDialog';

export default {
	name: 'Job',
	components: {
		JobDialog,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
		}),
	},
	data() {
		const { jobId } = this.$route.params;
		return {
			jobId,
		};
	},
	methods: {
		handleClose() {
			const { currentLang } = this;
			const { query } = this.$route;
			const { is_search } = query;
			if (is_search) {
				window.history.back();
			} else {
				this.$router.push({
					path: `/${currentLang}/jobs`,
					query,
				});
			}
		},
	},
	watch: {
		'$route.params.jobId': {
			handler: function (newVal) {
				this.jobId = newVal;
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
