<template>
	<div class="rich-editor-container">
		<div :ref="refName"></div>
	</div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

export default {
	name: 'RichEditor',
	props: {
		value: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		refName: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			editor: null,
			changed: 0,
		};
	},
	methods: {
		initQuill() {
			const { refName, disabled } = this;
			this.editor = new Quill(this.$refs[refName], {
				modules: {
					toolbar: [
						[{ header: [1, 2, false] }],
						['bold', 'underline', 'italic'],
						[
							{
								color: [
									'#000000',
									'#e60000',
									'#ff9900',
									'#ffff00',
									'#008a00',
									'#0066cc',
									'#9933ff',
									'#ffffff',
									'#facccc',
									'#ffebcc',
									'#ffffcc',
									'#cce8cc',
									'#cce0f5',
									'#ebd6ff',
									'#bbbbbb',
									'#f06666',
									'#ffc266',
									'#ffff66',
									'#66b966',
									'#66a3e0',
									'#c285ff',
									'#888888',
									'#a10000',
									'#b26b00',
									'#b2b200',
									'#006100',
									'#0047b2',
									'#6b24b2',
									'#444444',
									'#5c0000',
									'#663d00',
									'#666600',
									'#003700',
									'#002966',
									'#3d1466',
								],
							},
						],
						['link', 'blockquote'],
						[{ list: 'ordered' }, { list: 'bullet' }],
					],
				},
				theme: 'snow',
			});
			this.editor.root.innerHTML = this.value;
			this.editor.on('text-change', this.handleChange);
			if (disabled) {
				this.editor.enable(false);
			}
		},
		handleChange() {
			const now = new Date();
			this.changed = now.getTime();
			this.$emit(
				'input',
				this.editor.getText() ? this.editor.root.innerHTML : '',
			);
		},
	},
	mounted() {
		this.initQuill();
		this.editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
			let ops = [];

			delta.ops.forEach(op => {
				if (op.insert && typeof op.insert === 'string') {
					ops.push({
						insert: op.insert,
					});
				}
			});

			delta.ops = ops;
			return delta;
		});
	},
	watch: {
		value: {
			handler: function (newVal) {
				const now = new Date();
				const { changed } = this;
				if (now.getTime() - changed > 100) {
					this.editor.root.innerHTML = newVal;
				}
			},
			deep: true,
			immediate: false,
		},
		disabled: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal && this.editor) {
					this.editor.enable(!newVal);
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>

<style lang="scss">
.rich-editor-container {
	border: 1px solid #cccccc;
	border-radius: 4px;

	.ql-toolbar.ql-snow {
		border: 0;
		border-bottom: 1px solid #cccccc;
	}

	.ql-container.ql-snow {
		border: 0;
	}
}
</style>
