<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="680px"
			persistent
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>{{
							$t('text.add-new-candidate')
						}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn text small @click="handleBulkImportClick">
							{{ $t('button.bulk-import') }}
						</v-btn>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0 0.5rem" ref="newCandidateDialogContent">
					<v-container fluid>
						<v-row>
							<v-col>
								<p>
									<v-icon left>auto_fix_high</v-icon
									>{{ $t('text.please-upload-candidate-resume') }}
								</p>
								<Dropzone
									id="candidateFilesDropzone"
									:options="{
										maxFilesize: 20,
										maxFiles: 10,
										acceptedFiles:
											'image/png, image/jpeg, image/gif, .pdf, .doc, .docx, .txt, .html',
									}"
									:initialFiles="[]"
									:onSuccess="
										(file, response) => handleDropzoneSuccess({ response })
									"
								></Dropzone>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-divider></v-divider>
								<div style="text-align: center; margin-top: -10px">
									<span
										:style="`background: ${
											isDark ? '#1E1E1E' : '#ffffff'
										}; padding-left: 10px; padding-right: 10px;`"
										>OR</span
									>
								</div>
							</v-col>
						</v-row>

						<v-row>
							<v-col style="text-align: center">
								<v-btn small color="secondary" @click="handleSkipUploadingClick"
									><v-icon left>skip_next</v-icon
									>{{ $t('button.skip-uploading-resume') }}</v-btn
								>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="handleClose">{{ $t('button.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<Autofill
			v-if="isAutofillVisible"
			:items="autofillItems"
			:onClose="handleAutofillClose"
			requestMethod="post"
		></Autofill>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Dropzone from '@/components/shared/dropzone/Dropzone';
import Autofill from '../candidate/autofill/Autofill';

export default {
	name: 'NewCandidate',
	components: {
		Dropzone,
		Autofill,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			isVisible: false,
			isSkipResume: false,
			isAutofillVisible: false,
			autofillItems: [],
		};
	},
	methods: {
		handleClose() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates`,
				query: {
					...query,
				},
			});
		},
		handleDropzoneSuccess({ response }) {
			const { items } = response;
			const { id } = items[0];
			this.autofillItems = [...this.autofillItems, { fileId: id }];
			this.isAutofillVisible = true;
		},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleAutofillClose({ needsRefresh }) {
			this.isAutofillVisible = false;

			if (needsRefresh) {
				this.$store.commit('candidates/setItemsNeedToRefresh');
			}

			this.handleClose();
		},
		handleSkipUploadingClick() {
			this.autofillItems = [{ fileId: null }];

			setTimeout(
				function () {
					this.isAutofillVisible = true;
				}.bind(this),
				100,
			);
		},
		handleBulkImportClick() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/bulk-import`,
				query,
			});
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
};
</script>
