<template>
	<div class="editor"></div>
</template>

<script>
import ace from 'brace';
import html from 'brace/mode/html'; // eslint-disable-line no-unused-vars
import eclipse from 'brace/theme/eclipse'; // eslint-disable-line no-unused-vars

export default {
	props: {
		value: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		mode: {
			type: String,
			required: false,
			default() {
				return 'html';
			},
		},
		theme: {
			type: String,
			required: false,
			default() {
				return 'eclipse';
			},
		},
		debounce: {
			type: Number,
			required: false,
		},
		readOnly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	watch: {
		value(newValue) {
			const now = new Date().getTime();
			const { updated } = this;
			if (now - updated > 100) {
				// prevent ping-pong changes
				this.editor.setValue(newValue);
				this.editor.clearSelection();
			}
		},
		mode(mode) {
			this.editor.getSession().setMode(`ace/mode/${mode}`);
		},
	},
	data() {
		return {
			editor: null,
			updatedByProp: false,
			updated: 0,
			timer: null,
		};
	},
	mounted() {
		const { $el, mode, value, theme, readOnly } = this;
		this.editor = ace.edit($el);
		this.editor.$blockScrolling = Infinity;
		this.editor.getSession().setMode(`ace/mode/${mode}`);
		this.editor.setTheme(`ace/theme/${theme}`);
		this.editor.getSession().setTabSize(2);
		this.editor.setValue(value);
		this.editor.clearSelection();
		this.editor.setReadOnly(readOnly);
		this.editor.setFontSize('14px');

		this.editor.on('change', () => {
			if (this.editor.curOp && this.editor.curOp.command.name) {
				if (this.debounce) {
					if (this.timer) {
						clearTimeout(this.timer);
					}
					this.timer = setTimeout(
						function () {
							// eslint-disable-line
							this.$emit('input', this.editor.getValue());
							this.updated = new Date().getTime();
						}.bind(this),
						this.debounce,
					);
				} else {
					this.$emit('input', this.editor.getValue());
					this.updated = new Date().getTime();
				}
			}
		});
	},
	beforeDestroy() {
		this.editor.destroy();
		this.editor.container.remove();
	},
};
</script>

<style lang="scss">
.editor {
	min-height: 300px;
	word-spacing: 0 !important; // never ever remove this line
}
</style>
