<template>
	<div>
		<div v-if="teamJobs.length > 0" align-top dense>
			<JobsTreeJobItem
				v-for="(jobItem, jobIndex) in teamJobs"
				:key="`job_${jobIndex}`"
				:job="jobItem"
				:jobs="jobs"
				@newchild="handleNewChild"
				:organizationName="organizationName"
				:departmentName="departmentName"
				:teamName="teamName"
				:onArchiveJob="onArchiveJob"
			></JobsTreeJobItem>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import JobsTreeJobItem from '../jobsTreeJobItem/JobsTreeJobItem';

export default {
	name: 'JobsTreeDepartments',
	components: {
		JobsTreeJobItem,
	},
	computed: {
		...mapState({
			teams: state => state.teams.items,
		}),
		teamJobs() {
			const { teamId, jobs } = this;
			return jobs.filter(item => item.team_id === teamId);
		},
	},
	props: {
		teamId: {
			type: Number,
			required: true,
		},
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		organizationName: {
			type: String,
			required: true,
		},
		departmentName: {
			type: String,
			required: true,
		},
		teamName: {
			type: String,
			required: true,
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
	},
	data() {
		return {
			childrenCount: 0,
		};
	},
	methods: {
		handleNewChild() {
			this.childrenCount += 1;
			this.$emit('newchild');
		},
	},
	watch: {
		jobs: {
			handler: function () {
				this.childrenCount = 0;
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
