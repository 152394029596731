<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" md="4">
					<h5 class="text-center">
						{{ $t('tab.applicant') }} ({{ countersTopLevel.applieds }}) &amp;
						{{ $t('tab.lead') }} ({{ countersTopLevel.leads }})
					</h5>
					<ChartBar :chartdata="chartDataLeadAndApplicant"></ChartBar>
				</v-col>
				<v-col cols="12" md="4">
					<h5 class="text-center">
						{{ $t('tab.interview') }} ({{ countersTopLevel.interviews }})
					</h5>
					<ChartBar :chartdata="chartDataInterview"></ChartBar>
				</v-col>
				<v-col cols="12" md="4">
					<h5 class="text-center">
						{{ $t('tab.archive') }} ({{ countersTopLevel.archived }})
					</h5>
					<ChartBar :chartdata="chartDataArchive"></ChartBar>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ChartBar from '@/components/shared/chartBar/ChartBar';

export default {
	name: 'CurrentSituation',
	components: {
		ChartBar,
	},
	computed: {
		...mapState({
			pipelineInterviewItems: state => state.pipelineInterview.items,
			pipelineArchiveItems: state => state.pipelineArchive.items,
			pipelineLeadItems: state => state.pipelineLead.items,
		}),
		chartDataLeadAndApplicant() {
			const { applieds } = this.countersTopLevel;
			const { labels, datasets } = this.chartDataLead;

			return {
				labels,
				datasets: [
					...(datasets.length === 0
						? []
						: [
								{
									...datasets[0],
									data: [applieds || 0, ...datasets[0].data],
								},
						  ]),
				],
			};
		},
	},
	data() {
		return {
			chartDataLead: {
				labels: [],
				datasets: [],
			},
			chartDataApplicant: {
				labels: [],
				datasets: [],
			},
			chartDataInterview: {
				labels: [],
				datasets: [],
			},
			chartDataArchive: {
				labels: [],
				datasets: [],
			},
			countersTopLevel: {
				leads: 0,
				applieds: 0,
				interviews: 0,
				archived: 0,
			},
		};
	},
	methods: {
		handleGetChartDataLead() {
			const { pipelineLeadItems } = this;
			const { query } = this.$route;
			this.$store
				.dispatch('applicants/get_counters_lead', {
					...query,
					statusItems: pipelineLeadItems.join(','),
					refresh: true,
					updateStore: false,
				})
				.then(response => {
					const { counters } = response.data;
					const counterKeys = Object.keys(counters);
					const counterLabels = [
						this.$t(`pipeline.tab-steps.new-applicant`),
						...counterKeys.map(counterKey =>
							this.$t(`pipeline.tab-steps.${counterKey}`),
						),
					];
					const counterValues = counterKeys.map(
						counterKey => counters[counterKey] || 0,
					);

					this.chartDataLead = {
						labels: counterLabels,
						datasets: [
							{
								backgroundColor: [
									'#115f9a',
									'#1984c5',
									'#22a7f0',
									'#48b5c4',
									'#76c68f',
									'#a6d75b',
									'#c9e52f',
									'#d0ee11',
									'#d0f400',
								],
								data: counterValues,
							},
						],
					};
				});
		},
		handleGetChartDataApplicant() {
			const { query } = this.$route;
			this.$store
				.dispatch('applicants/get_counters', {
					...query,
					refresh: true,
					updateStore: false,
				})
				.then(response => {
					const { counters } = response.data;
					this.countersTopLevel = {
						...this.countersTopLevel,
						...counters,
					};
				});
		},
		handleGetChartDataArchive() {
			const { pipelineArchiveItems } = this;
			const { query } = this.$route;
			this.$store
				.dispatch('applicants/get_counters_archive', {
					...query,
					statusItems: pipelineArchiveItems.join(','),
					refresh: true,
					updateStore: false,
				})
				.then(response => {
					const { counters } = response.data;
					const counterKeys = Object.keys(counters);
					const counterLabels = counterKeys.map(counterKey =>
						this.$t(`pipeline.tab-steps.${counterKey}`),
					);
					const counterValues = counterKeys.map(
						counterKey => counters[counterKey] || 0,
					);

					this.chartDataArchive = {
						labels: counterLabels,
						datasets: [
							{
								backgroundColor: [
									'#115f9a',
									'#1984c5',
									'#22a7f0',
									'#48b5c4',
									'#76c68f',
									'#a6d75b',
									'#c9e52f',
									'#d0ee11',
									'#d0f400',
								],
								data: counterValues,
							},
						],
					};
				});
		},
		handleGetChartDataInterview() {
			const { pipelineInterviewItems } = this;
			const { query } = this.$route;
			this.$store
				.dispatch('applicants/get_counters_interview', {
					...query,
					statusItems: pipelineInterviewItems.join(','),
					refresh: true,
					updateStore: false,
				})
				.then(response => {
					const { counters } = response.data;
					const counterKeys = Object.keys(counters);
					const counterLabels = counterKeys.map(counterKey =>
						this.$t(`pipeline.tab-steps.${counterKey}`),
					);
					const counterValues = counterKeys.map(
						counterKey => counters[counterKey] || 0,
					);

					this.chartDataInterview = {
						labels: counterLabels,
						datasets: [
							{
								backgroundColor: [
									'#115f9a',
									'#1984c5',
									'#22a7f0',
									'#48b5c4',
									'#76c68f',
									'#a6d75b',
									'#c9e52f',
									'#d0ee11',
									'#d0f400',
								],
								data: counterValues,
							},
						],
					};
				});
		},
		handleRefresh() {
			this.handleGetChartDataApplicant();
			this.handleGetChartDataInterview();
			this.handleGetChartDataLead();
			this.handleGetChartDataArchive();
		},
	},
	mounted() {
		this.handleGetChartDataApplicant();
		this.handleGetChartDataInterview();
		this.handleGetChartDataLead();
		this.handleGetChartDataArchive();
	},
	watch: {
		pipelineInterviewItems: {
			handler: function (newVal) {
				if (newVal && newVal.length > 0) {
					this.handleGetChartDataInterview();
				}
			},
			deep: true,
			immediate: true,
		},
		pipelineLeadItems: {
			handler: function (newVal) {
				if (newVal && newVal.length > 0) {
					this.handleGetChartDataLead();
				}
			},
			deep: true,
			immediate: true,
		},
		pipelineArchiveItems: {
			handler: function (newVal) {
				if (newVal && newVal.length > 0) {
					this.handleGetChartDataArchive();
				}
			},
			deep: true,
			immediate: true,
		},
		'$route.query': {
			handler: function (newVal, oldVal) {
				if (
					oldVal === undefined ||
					newVal.job_id !== oldVal.job_id ||
					newVal.owner !== oldVal.owner
				) {
					this.handleGetChartDataApplicant();
					this.handleGetChartDataInterview();
					this.handleGetChartDataLead();
					this.handleGetChartDataArchive();
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>
