import Vue from 'vue';
import Vuex from 'vuex';
import apiTraffic from '@/store/modules/apiTraffic';
import currentRoute from '@/store/modules/currentRoute';
import currentUser from '@/store/modules/currentUser';
import users from '@/store/modules/users';
import assessments from '@/store/modules/assessments';
import events from '@/store/modules/events';
import company from '@/store/modules/company';
import jobs from '@/store/modules/jobs';
import candidates from '@/store/modules/candidates';
import groupMail from '@/store/modules/groupMail';
import locations from '@/store/modules/locations';
import workTypes from '@/store/modules/workTypes';
import departments from '@/store/modules/departments';
import teams from '@/store/modules/teams';
import files from '@/store/modules/files';
import applicants from '@/store/modules/applicants';
import pipelineInterview from '@/store/modules/pipelineInterview';
import pipelineArchive from '@/store/modules/pipelineArchive';
import pipelineLead from '@/store/modules/pipelineLead';
import emailTemplates from '@/store/modules/emailTemplates';
import feedbackTemplates from '@/store/modules/feedbackTemplates';
import emails from '@/store/modules/emails';
import feedbacks from '@/store/modules/feedbacks';
import activities from '@/store/modules/activities';
import scorecardTemplates from '@/store/modules/scorecardTemplates';
import scorecards from '@/store/modules/scorecards';
import eventTypes from '@/store/modules/eventTypes';
import mentions from '@/store/modules/mentions';
import notifications from '@/store/modules/notifications';
import timezones from '@/store/modules/timezones';
import approvalTemplates from '@/store/modules/approvalTemplates';
import tags from '@/store/modules/tags';
import formTemplates from '@/store/modules/formTemplates';
import forms from '@/store/modules/forms';
import userApprovalRequests from '@/store/modules/userApprovalRequests';
import drafts from '@/store/modules/drafts';
import organizations from '@/store/modules/organizations';
import knownHosts from '@/store/modules/knownHosts';
import requisitionFormTemplates from '@/store/modules/requisitionFormTemplates';
import requisitionApprovalFlowTemplates from '@/store/modules/requisitionApprovalFlowTemplates';
import cdnFiles from '@/store/modules/cdnFiles';
import ogPreferences from '@/store/modules/ogPreferences';
import offerTemplates from '@/store/modules/offerTemplates';
import formRulesets from '@/store/modules/formRulesets';
import emailLayouts from '@/store/modules/emailLayouts';
import predefinedPositions from '@/store/modules/predefinedPositions';
import predefinedPositionPresets from '@/store/modules/predefinedPositionPresets';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		apiTraffic,
		currentRoute,
		currentUser,
		assessments,
		users,
		events,
		company,
		jobs,
		candidates,
		locations,
		workTypes,
		groupMail,
		departments,
		teams,
		files,
		applicants,
		pipelineInterview,
		pipelineArchive,
		pipelineLead,
		emailTemplates,
		feedbackTemplates,
		emails,
		feedbacks,
		activities,
		scorecardTemplates,
		scorecards,
		eventTypes,
		mentions,
		notifications,
		timezones,
		approvalTemplates,
		tags,
		formTemplates,
		forms,
		userApprovalRequests,
		drafts,
		organizations,
		knownHosts,
		requisitionFormTemplates,
		requisitionApprovalFlowTemplates,
		cdnFiles,
		ogPreferences,
		offerTemplates,
		formRulesets,
		emailLayouts,
		predefinedPositions,
		predefinedPositionPresets,
	},
	debug: true,
});
