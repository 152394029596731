<template>
	<SettingsItemLayout>
		<template v-slot:bar>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						icon
						:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
						@click="handleRefresh"
					>
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</template>
		<template v-slot:navigation>
			<v-list dense>
				<v-list-item
					@click="handleOpenEmailTemplate({ id: null })"
					:class="emailTemplate.id === null ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-icon>add</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t('button.new-email-template')
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					@click="handleOpenEmailTemplate({ id: item.id })"
					v-for="(item, index) in emailTemplates"
					:key="index"
					:class="emailTemplate.id === item.id ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>folder_open</v-icon>
						</v-badge>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<template>
			<v-card v-if="showTemplateDetails" tile elevation="0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-toolbar-title>
						{{ emailTemplate.label }}
					</v-toolbar-title>

					<v-spacer></v-spacer>

					<v-btn
						@click="() => handleSubmit({ close: false, refresh: true })"
						color="primary"
					>
						{{ $t('button.save-changes') }}
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<ValidationObserver
						ref="email_template_form"
						tag="form"
						@submit="handleSubmit"
						id="email_template_form"
					>
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}:
											{{
												emailTemplate.active
													? $t('label.active')
													: $t('label.inactive')
											}}
										</legend>
										<v-switch
											v-model="emailTemplate.active"
											color="success"
											inset
											dense
											hide-details
											class="v-input--template-switch"
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="emailTemplate.label"
											:label="$t('label.label')"
											outlined
											hide-details
											dense
											:error-messages="errors"
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="emailTemplate.email_layout_id"
											:label="$t('label.layout')"
											outlined
											:items="emailLayouts"
											item-text="label"
											item-value="id"
											hide-details
											dense
											:error-messages="errors"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="emailTemplate.subject"
											outlined
											:label="$t('label.subject')"
											hide-details
											dense
											:error-messages="errors"
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row align="center">
								<v-col sm="6" cols="12">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="emailTemplate.sent_from"
											:label="$t('label.from')"
											outlined
											:items="emails"
											hide-details
											dense
											:error-messages="errors"
										></v-select>
									</ValidationProvider>
								</v-col>

								<v-col sm="6" cols="12">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="emailTemplate.default_schedule"
											:label="$t('label.schedule')"
											outlined
											:items="scheduleItems"
											hide-details
											prepend-inner-icon="schedule"
											dense
											:error-messages="errors"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row align="center">
								<v-col sm="6" cols="12">
									<v-autocomplete
										v-model="emailTemplate.cc"
										:items="
											users.map(item => ({
												value: item.email,
											}))
										"
										item-text="value"
										item-value="value"
										hide-no-data
										hide-details
										outlined
										:label="$t('label.cc-uppercase')"
										multiple
										chips
										deletable-chips
										class="v-select-talentics-chips"
										dense
									></v-autocomplete>
								</v-col>
								<v-col sm="6" cols="12">
									<v-autocomplete
										v-model="emailTemplate.bcc"
										:items="
											users.map(item => ({
												value: item.email,
											}))
										"
										item-text="value"
										item-value="value"
										hide-no-data
										hide-details
										outlined
										:label="$t('label.bcc-uppercase')"
										multiple
										chips
										deletable-chips
										class="v-select-talentics-chips"
										dense
									></v-autocomplete>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>{{ $t('label.body') }}</legend>
										<RichEditor
											v-model="emailTemplate.template"
											refName="emailBody"
										></RichEditor>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<Attachments
										:attachments="emailTemplate.attachment_files"
										:onNewAttachmentAdded="handleNewAttachmentAdded"
										:onAttachmentDeleteClick="handleAttachmentDeleteClick"
									></Attachments>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
			</v-card>
		</template>
	</SettingsItemLayout>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import api from '@/api';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import Attachments from '@/components/shared/attachments/Attachments';
import SettingsItemLayout from '../settingsItemLayout/SettingsItemLayout';

export default {
	name: 'EmailTemplates',
	components: {
		ValidationObserver,
		ValidationProvider,
		RichEditor,
		Attachments,
		SettingsItemLayout,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			emailTemplates: state => state.emailTemplates.items,
			emailLayouts: state => state.emailLayouts.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			emails: [
				{
					value: 'noreply@talentics.app',
					text: 'noreply@talentics.app',
				},
			],
			scheduleItems: [
				{
					value: '0d',
					text: 'Immediately',
				},
				{
					value: '1d',
					text: '1 Day',
				},
				{
					value: '2d',
					text: '2 Days',
				},
				{
					value: '3d',
					text: '3 Days',
				},
				{
					value: '4d',
					text: '4 Days',
				},
				{
					value: '5d',
					text: '5 Days',
				},
				{
					value: '6d',
					text: '6 Days',
				},
				{
					value: '1w',
					text: '1 Week',
				},
				{
					value: '2w',
					text: '2 Weeks',
				},
				{
					value: '3w',
					text: '3 Weeks',
				},
				{
					value: '4w',
					text: '4 Weeks',
				},
			],
			emailTemplate: {
				id: null,
				sent_from: 'noreply@talentics.app',
				label: '',
				subject: '',
				template: '',
				default_schedule: '2w',
				active: true,
				cc: [],
				bcc: [],
				attachments: [],
				attachment_files: [],
				email_layout_id: null,
			},
			showTemplateDetails: false,
		};
	},
	methods: {
		handleClose() {
			this.showTemplateDetails = false;
		},
		async handleSubmit({ close = false, refresh = false }) {
			const isValid = await this.$refs.email_template_form.validate();
			if (isValid) {
				const { emailTemplate } = this;
				const { id } = emailTemplate;
				if (id) {
					this.$store
						.dispatch('emailTemplates/put', {
							items: [
								{
									...emailTemplate,
								},
							],
						})
						.then(() => {
							if (close) {
								this.showTemplateDetails = false;
							}
							if (refresh) {
								this.handleOpenEmailTemplate({ id });
							}
						});
				} else {
					this.$store
						.dispatch('emailTemplates/post', {
							items: [
								{
									...emailTemplate,
								},
							],
						})
						.then(response => {
							if (close) {
								this.showTemplateDetails = false;
							}
							if (refresh) {
								const { items } = response.data;
								if (items.length > 0) {
									const { id } = items[0];
									this.handleOpenEmailTemplate({ id });
								}
							}
							this.$store.dispatch('emailTemplates/get', {
								updateStore: true,
								refresh: true,
							});
						});
				}
			}
		},
		handleOpenEmailTemplate({ id }) {
			if (id) {
				this.$store
					.dispatch('emailTemplates/get', { id, refresh: true })
					.then(response => {
						const { items } = response.data;
						if (items.length > 0) {
							this.emailTemplate = { ...items[0] };
							this.showTemplateDetails = true;
						}
					});
			} else {
				this.emailTemplate = {
					id: null,
					sent_from: 'noreply@talentics.app',
					label: '',
					template: '',
					default_schedule: '2w',
					active: true,
					cc: [],
					bcc: [],
					attachments: [],
					attachment_files: [],
				};
				this.showTemplateDetails = true;
			}
		},
		handleRefresh() {
			this.$store.dispatch('emailTemplates/get', {
				updateStore: true,
				refresh: true,
			});
			this.$store.dispatch('emailLayouts/get', {
				updateStore: true,
				refresh: true,
			});
			this.handleOpenEmailTemplate({ id: null });
		},
		handleNewAttachmentAdded({ newFiles = [] }) {
			this.emailTemplate = {
				...this.emailTemplate,
				attachments: [
					...(this.emailTemplate.attachments
						? this.emailTemplate.attachments
						: []),
					...newFiles.map(({ id }) => id),
				],
				attachment_files: [
					...(this.emailTemplate.attachment_files
						? this.emailTemplate.attachment_files
						: []),
					...newFiles,
				],
			};
		},
		handleAttachmentDeleteClick({ index }) {
			this.emailTemplate = {
				...this.emailTemplate,
				attachments: this.emailTemplate.attachments.filter(
					(item, itemIndex) => index !== itemIndex,
				),
			};
			this.handleSubmit({ refresh: true });
		},
	},
	created() {
		api.emailsConsented.get({ include: 'all' }).then(response => {
			const { items } = response.data;
			this.emails = [
				...this.emails,
				...items.map(item => ({ text: item.email, value: item.email })),
			];
		});
		this.$store.dispatch('emailTemplates/get', {
			updateStore: true,
			refresh: true,
		});
		this.$store.dispatch('emailLayouts/get', {
			updateStore: true,
			refresh: true,
		});
	},
	mounted() {
		this.handleOpenEmailTemplate({ id: null });
	},
};
</script>
