<template>
	<div class="user-chips-container">
		<v-chip
			v-for="(chip, index) in chips"
			:key="`chip_${index}`"
			pill
			:color="chipColor"
			:style="`cursor: ${cursor};`"
		>
			<v-avatar left>
				<v-img :src="chip.picture"></v-img>
			</v-avatar>
			{{ chip.name }}
		</v-chip>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'UserChips',
	props: {
		userIds: {
			type: Array,
			required: true,
		},
		chipColor: {
			type: String,
			required: false,
			default() {
				return 'default';
			},
		},
		cursor: {
			type: String,
			required: false,
			default() {
				return 'default';
			},
		},
	},
	computed: {
		...mapState({
			users: state => state.users.items,
		}),
		chips() {
			return this.users.filter(user => this.userIds.indexOf(user.id) > -1);
		},
	},
};
</script>

<style lang="scss">
.user-chips-container {
	& > .v-chip {
		margin-right: 0.5rem;

		&:last-child {
			margin-right: 0;
		}
	}
}
</style>
