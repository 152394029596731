<template>
  <div>
    <fieldset class="talentics-fieldset">
      <legend>
        Switch between accounts
      </legend>
      <ValidationObserver
        ref="switch_account_form"
        tag="form"
        @submit="handleSubmit"
        id="switch_account_form"
      >
        <v-container>
          <v-row>
            <v-col cols="9">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-autocomplete
                  v-model="autocompleteModel"
                  dense
                  item-text="name"
                  item-value="id"
                  outlined
                  :items="items"
                  label="Company"
                  :error-messages="errors"
                  hide-details
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col cols="3">
              <v-btn @click="handleSubmit">
                GO
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </ValidationObserver>
    </fieldset>

    <v-divider style="margin: 1rem 0;" dark></v-divider>

    <fieldset class="talentics-fieldset">
      <legend>
        Create new account
      </legend>
      <v-container>
        <v-row>
          <v-col>
            <v-btn @click="handleClickNewAccount">
              Go
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </fieldset>

    <v-dialog
      v-model="createAccountDialog"
      @click:outside="handleClickOutside"
      @keydown.esc="handleClickOutside"
      persistent
      width="500"
    >
      <v-card elevation="0">
        
        <v-card-title class="mb-3">
          <v-icon>support_agent</v-icon> Create new account
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="padding: 0;">
          <ValidationObserver
            ref="create_account_form"
            tag="form"
            @submit="handleSubmitForm"
            id="create_account_form"
          >
            <v-container>
              <v-row>
                <v-col>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.name"
                      label="Company Name"
                      placeholder="Example LLC"
                      name="name"
                      type="text"
                      outlined
                      flat
                      dense
                      hide-details
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.homepage"
                      label="Homepage"
                      placeholder="https://www.example.com"
                      name="homepage"
                      type="text"
                      outlined
                      flat
                      dense
                      hide-details
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.slug"
                      label="Career site URL"
                      placeholder="example"
                      name="slug"
                      type="text"
                      outlined
                      flat
                      dense
                      hide-details
                      :error-messages="errors"
                      prefix="https://"
                      suffix=".talentics.app"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <v-text-field
                      v-model="form.super_admin_email"
                      label="Super admin e-mail address"
                      placeholder="superadmin@example.com"
                      name="super_admin_email"
                      type="email"
                      outlined
                      flat
                      dense
                      hide-details
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <v-autocomplete
                      v-model="form.timezone"
                      label="Timezone"
                      hide-details
                      :items="timezoneItems"
                      :item-text="item => `${item.text} - ${item.value}`"
                      prepend-inner-icon="public"
                      outlined
                      :error-messages="errors"
                      dense
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>

            </v-container>

          </ValidationObserver>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="onClose">{{
            $t('button.cancel')
          }}</v-btn>
          <v-btn color="pink darken-2" dark @click="handleSubmitForm">{{
            $t('button.save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';

export default {
  name: 'AgentSwitchAccount',
  components: {
		ValidationObserver,
		ValidationProvider,
  },
	computed: {
		...mapState({
			timezones: state => state.timezones.items,
		}),
		timezoneItems() {
			const { timezones } = this;
			return timezones.reduce((acc, item) => {
				return [
					...acc,
					{ header: item.group },
					...item.zones.map(zone => ({
						text: zone.name,
						value: zone.value,
						group: item.group,
					})),
				];
			}, []);
		},
  },
  data() {
    return {
      autocompleteModel: null,
      items: [],
      form: {
        name: null,
        slug: null,
        homepage: null,
        hostname: null,
        timezone: 'Europe/Istanbul',
        super_admin_email: null,
      },
      createAccountDialog: false,
    };
  },
  props: {
    companyId: {
      required: false,
      default: null,
    },
  },
  methods: {
    handleGetCompanyList() {
      api.agent.company.get().then((response) => {
        const { items } = response.data;
        this.items = items;
      });
    },
    async handleSubmitForm(evt) {
			if (evt) {
				evt.preventDefault();
      }

      const isValid = await this.$refs.create_account_form.validate();

      if (isValid) {
        const {
          name,
          slug,
          homepage,
          timezone,
          super_admin_email,
        } = this.form;

        api.agent.company.post({
          name,
          slug,
          homepage,
          timezone,
          super_admin_email,
          hostname: `${slug}.talentics.app`,
        }).then((response) => {
          const { company_id } = response.data;
          api.switchAccount.post({ company_id, agent_mode: true }).then(() => {
            window.location.reload();
          });
        });
      }
    },
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

      const isValid = await this.$refs.switch_account_form.validate();

      if (isValid) {
        const { autocompleteModel } = this;
        api.switchAccount.post({ company_id: autocompleteModel, agent_mode: true }).then(() => {
				  window.location.reload();
			  });
      }
    },
		handleClickOutside(evt) {
			evt.preventDefault();
		},
    onClose() {
      this.createAccountDialog = false;
    },
    handleClickNewAccount() {
      this.createAccountDialog = true;
    },
  },
  mounted() {
    this.handleGetCompanyList();
    this.$store.dispatch('timezones/get', { updateStore: true });
  },
  watch: {
    companyId: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.autocompleteModel = newVal;
        }
      },
			deep: true,
			immediate: true,
    }
  }
};
</script>
