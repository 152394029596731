<template>
	<v-navigation-drawer
		:value="drawerCalendar"
		app
		right
		:width="300"
		class="calendar-drawer"
		disable-resize-watcher
	>
		<div style="position: relative">
			<div
				:style="`position: fixed; top: 0; left: 1px; right: 0; background: ${
					isDark ? 'rgb(18, 18, 18)' : 'white'
				}; z-index: 2;`"
			>
				<div style="padding: 0.5rem 0.5rem">
					<div style="display: flex">
						<DatePicker
							v-model="datePickerDate"
							@input="handleDatePickerInput"
							no-title
							reactive
							dense
							label="Calendar"
							:outlined="false"
							type="button"
						></DatePicker>
						<v-spacer></v-spacer>
						<v-btn icon @click="handleClickToggleDrawerCalendar">
							<v-icon>close</v-icon>
						</v-btn>
					</div>
				</div>
				<v-divider></v-divider>
				<div style="padding: 0.5rem 0.5rem">
					<div>
						<v-btn
							depressed
							tile
							color="transparent"
							@click="() => handleGoToDay({ day: 'today' })"
						>
							Today
						</v-btn>
						<v-btn icon @click="() => handleGoToDay({ day: 'previous' })">
							<v-icon>keyboard_arrow_left</v-icon>
						</v-btn>
						<v-btn icon @click="() => handleGoToDay({ day: 'next' })">
							<v-icon>keyboard_arrow_right</v-icon>
						</v-btn>
					</div>
				</div>
				<v-divider></v-divider>
			</div>
			<div style="margin-top: 105px">
				<v-calendar
					type="day"
					:value="calendarDate"
					:events="calendarEvents"
					hide-header
					@click:event="handleEventClick"
					ref="calendar"
				>
					<template v-slot:day-body="{ date, week }">
						<div
							v-if="todayDate === week[0].date"
							class="v-current-time"
							:class="{ first: date === week[0].date }"
							:style="{ top: nowY }"
						></div>
					</template>

					<template v-slot:event="{ event }">
						<div class="pl-1">
							<div v-if="event.isCandidateEvent">
								<v-icon small class="mr-1">person</v-icon>
								<strong>{{ event.name }}</strong>
							</div>
							<div v-else>
								<strong>{{ event.name }}</strong>
							</div>
						</div>
					</template>
				</v-calendar>
			</div>
		</div>
		<BottomSheetEvent
			:event="event"
			:visible="overlay"
			:onClickClose="handleBottomSheetEventCloseClick"
		></BottomSheetEvent>
	</v-navigation-drawer>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import api from '@/api';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import BottomSheetEvent from './bottomSheetEvent/BottomSheetEvent';

export default {
	name: 'DrawerCalendar',
	components: {
		DatePicker,
		BottomSheetEvent,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			drawerCalendar: state => state.currentRoute.drawerCalendar,
			isDark: state => state.currentUser.isDark,
		}),
		calendarEvents() {
			const { events } = this;
			return events.map(event => {
				return {
					name: event.summary,
					date: format(new Date(event.start.dateTime), 'yyyy-MM-dd'),
					start: format(new Date(event.start.dateTime), 'yyyy-MM-dd HH:mm:ss'),
					end: format(new Date(event.end.dateTime), 'yyyy-MM-dd HH:mm:ss'),
					id: event.id,
					color: event.isCandidateEvent ? 'warning' : 'primary',
					isCandidateEvent: event.isCandidateEvent,
				};
			});
		},
		cal() {
			return this.ready ? this.$refs.calendar : null;
		},
		nowY() {
			return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
		},
	},
	data() {
		const calendarDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
		const datePickerDate = format(new Date(), 'yyyy-MM-dd');
		const todayDate = format(new Date(), 'yyyy-MM-dd');
		return {
			events: [],
			event: {},
			calendarDate,
			datePickerDate,
			overlay: false,
			ready: false,
			todayDate,
		};
	},
	methods: {
		getDailyEvents() {
			const userId = this.currentUser.id;
			const { datePickerDate } = this;
			const timeMin = new Date(datePickerDate);
			const timeMax = new Date(timeMin);
			timeMax.setDate(timeMax.getDate() + 1);
			api.events.get({ timeMin, timeMax, userId }).then(response => {
				const { items } = response.data;
				this.events = items;
			});
		},
		handleDatePickerInput(newVal) {
			const date = new Date(newVal);
			this.calendarDate = format(date, 'yyyy-MM-dd HH:mm:ss');
			this.getDailyEvents();
		},
		handleClickToggleDrawerCalendar() {
			const { drawerCalendar } = this;
			this.$store.commit('currentRoute/setDrawerCalendar', {
				drawerCalendar: !drawerCalendar,
			});
		},
		handleGoToDay({ day }) {
			if (day === 'today') {
				this.calendarDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
				this.datePickerDate = format(new Date(), 'yyyy-MM-dd');
			} else if (day === 'previous') {
				const newDate = new Date(this.datePickerDate);
				newDate.setDate(newDate.getDate() - 1);
				this.calendarDate = format(newDate, 'yyyy-MM-dd');
				this.datePickerDate = format(newDate, 'yyyy-MM-dd');
			} else if (day === 'next') {
				const newDate = new Date(this.datePickerDate);
				newDate.setDate(newDate.getDate() + 1);
				this.calendarDate = format(newDate, 'yyyy-MM-dd');
				this.datePickerDate = format(newDate, 'yyyy-MM-dd');
			}
			this.getDailyEvents();
		},
		handleEventClick({ event }) {
			const { id } = event;
			this.event = this.events.find(item => item.id === id);
			this.overlay = true;
		},
		handleBottomSheetEventCloseClick() {
			this.overlay = false;
		},
		getCurrentTime() {
			return this.cal
				? this.cal.times.now.hour * 60 + this.cal.times.now.minute
				: 0;
		},
		scrollToTime() {
			const time = this.getCurrentTime();
			const first = Math.max(0, time - (time % 30) - 30);

			this.cal.scrollToTime(first);
		},
		updateTime() {
			setInterval(() => this.cal.updateTimes(), 60 * 1000);
		},
	},
	mounted() {
		this.ready = true;
		this.scrollToTime();
		this.updateTime();
	},
	watch: {
		drawerCalendar: {
			handler(newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.datePickerDate = format(new Date(), 'yyyy-MM-dd');
					this.getDailyEvents();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
.v-current-time {
	height: 2px;
	background-color: #ea4335;
	position: absolute;
	left: -1px;
	right: 0;
	pointer-events: none;

	&.first::before {
		content: '';
		position: absolute;
		background-color: #ea4335;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin-top: -5px;
		margin-left: -6.5px;
	}
}
</style>
