<template>
	<div>
		<div>
			<JobsTreeDepartmentItem
				v-for="(departmentItem, departmentIndex) in organizationDepartments"
				:key="`department_${departmentIndex}`"
				:department="departmentItem"
				:jobs="jobs"
				@newchild="handleNewChild"
				:organizationName="organizationName"
				:onArchiveJob="onArchiveJob"
				:unfold="unfold"
			></JobsTreeDepartmentItem>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import JobsTreeDepartmentItem from '../jobsTreeDepartmentItem/JobsTreeDepartmentItem';

export default {
	name: 'JobsTreeDepartments',
	components: {
		JobsTreeDepartmentItem,
	},
	computed: {
		...mapState({
			departments: state => state.departments.items,
		}),
		organizationDepartments() {
			const { organizationId, departments } = this;
			return departments.filter(
				item => item.organization_id === organizationId,
			);
		},
	},
	props: {
		organizationId: {
			type: Number,
			required: true,
		},
		organizationName: {
			type: String,
			required: true,
		},
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
		unfold: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			childrenCount: 0,
		};
	},
	methods: {
		handleNewChild() {
			this.childrenCount += 1;
			this.$emit('newchild');
		},
	},
	watch: {
		jobs: {
			handler: function () {
				this.childrenCount = 0;
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
