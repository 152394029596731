<template>
	<div>
		<v-chip
			v-for="(tagId, tagIndex) in chips"
			:key="`tag_${tagIndex}`"
			small
			class="mr-1"
		>
			{{ handleGetTagName({ tagId }) }}
		</v-chip>
		<v-menu
			v-model="menu"
			:close-on-content-click="false"
			offset-x
			:nudge-width="200"
			v-if="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" x-small icon>
					<v-icon>add</v-icon>
				</v-btn>
			</template>

			<v-card>
				<v-card-text style="padding: 0.25rem">
					<v-autocomplete
						v-model="tagModel"
						:items="tags.filter(item => item.active)"
						item-text="name"
						item-value="id"
						outlined
						dense
						:label="`${$t('label.tags')}`"
						hide-details
						@input="() => handleFilterChange({ goToFirstPage: true })"
						ref="tagModel"
					></v-autocomplete>
				</v-card-text>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'ChipEditor',
	props: {
		value: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
	},
	computed: {
		...mapState({
			tags: state => state.tags.items,
		}),
	},
	data() {
		return {
			chips: [],
			menu: false,
			tagModel: null,
		};
	},
	methods: {
		handleGetTagName({ tagId }) {
			const { tags } = this;
			const tag = tags.find(item => item.id === tagId);
			return tag ? tag.name : tagId;
		},
		handleFilterChange() {},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					this.chips = JSON.parse(JSON.stringify(newVal));
				} else {
					this.chips = [];
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
