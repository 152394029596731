<template>
	<div>
		<Draggable v-model="newEvents" @start="drag = true" @end="drag = false">
			<div v-for="(event, index) in newEvents" :key="`checkbox_${index}`">
				<NewEventRow
					:value="event"
					@input="newVal => handleNewEventRowInput({ newVal, index })"
					:job_id="job_id"
					:candidate="candidate"
					:onDeleteNewEvent="() => onDeleteNewEvent({ index })"
				></NewEventRow>

				<v-row style="margin-top: 0">
					<v-col>
						<v-divider></v-divider>
					</v-col>
				</v-row>
			</div>
		</Draggable>

		<!-- <v-row>
      <v-col>
        <v-btn>{{ $t('button.add-interview') }}</v-btn>
      </v-col>
    </v-row> -->
	</div>
</template>

<script>
import Draggable from 'vuedraggable';
import NewEventRow from './newEventRow/NewEventRow';

export default {
	name: 'NewEvents',
	components: {
		Draggable,
		NewEventRow,
	},
	props: {
		value: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		job_id: {
			required: true,
		},
		candidate: {
			required: true,
		},
		onDeleteNewEvent: {
			required: true,
		},
	},
	data() {
		return {
			newEvents: [],
		};
	},
	methods: {
		handleNewEventRowInput({ newVal, index }) {
			const newEvents = this.newEvents.map((newEvent, newEventIndex) => {
				if (index === newEventIndex) {
					return {
						...newVal,
					};
				}
				return {
					...newEvent,
				};
			});
			this.$emit('input', newEvents);
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				this.newEvents = JSON.parse(JSON.stringify(newVal));
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
