<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		offset-y
		:nudge-width="250"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" small>
				<v-icon left>add</v-icon>
				{{ $t('button.new-form') }}
			</v-btn>
		</template>

		<v-card>
			<ValidationObserver
				ref="form_new_form"
				tag="form"
				@submit="handleStart"
				id="form_new_form"
			>
				<v-container fluid>
					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="applicationId"
									:items="applicationsWithJobNames"
									:item-text="
										item =>
											`${item.job_name} (${handleFormatDate({
												timestamp: item.created,
											})})`
									"
									item-value="id"
									:label="$t('label.choose-job')"
									outlined
									hide-details
									dense
									:error-messages="errors"
									@change="
										newVal =>
											handleApplicationIdChange({
												applicationId: newVal,
											})
									"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="pipelineStatus"
									:items="statuses"
									:item-text="
										item => {
											if (item.text) {
												return $t(`pipeline.steps.${item.text}`);
											}
											return item;
										}
									"
									item-value="value"
									:label="$t('label.status')"
									outlined
									hide-details
									dense
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="formTemplateId"
									:items="formTemplates.filter(item => item.active)"
									item-text="label"
									item-value="id"
									:label="$t('label.choose-form-template')"
									outlined
									hide-details
									dense
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>
				</v-container>
			</ValidationObserver>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="menu = false">
					{{ $t('button.cancel') }}
				</v-btn>
				<v-btn color="primary" @click="handleStart">
					{{ $t('button.start') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { format } from 'date-fns';

export default {
	name: 'NewFormMenu',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		onStart: {
			type: Function,
			required: true,
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	computed: {
		...mapState({
			jobs: state => state.jobs.items,
			formTemplates: state => state.formTemplates.items,
			pipelineInterviewItems: state => state.pipelineInterview.items,
			pipelineLeadItems: state => state.pipelineLead.items,
			pipelineArchiveItems: state => state.pipelineArchive.items,
		}),
		applicationsWithJobNames() {
			const { applications } = this.candidate;
			const { jobs } = this;
			if (applications) {
				return applications.map(application => {
					const job = jobs.find(item => item.id === application.job_id);
					return {
						...application,
						job_name: job ? job.name : application.job_id,
					};
				});
			}
			return [];
		},
		statuses() {
			const {
				pipelineInterviewItems,
				pipelineLeadItems,
				pipelineArchiveItems,
			} = this;
			return [
				{ header: 'Lead' },
				...pipelineLeadItems.map(item => ({ text: item, value: item })),
				{ divider: true },
				{ header: 'Application' },
				{ text: 'new-applicant', value: 'new-applicant' },
				{ divider: true },
				{ header: 'Interview' },
				...pipelineInterviewItems.map(item => ({
					text: item,
					value: item,
				})),
				{ header: 'Archive' },
				...pipelineArchiveItems.map(item => ({
					text: item,
					value: item,
				})),
			];
		},
	},
	data() {
		return {
			format,
			formTemplateId: null,
			applicationId: null,
			pipelineStatus: null,
			jobId: null,
			menu: false,
		};
	},
	methods: {
		initForm() {
			const { applications } = this.candidate;
			if (applications.length > 0) {
				const { id } = applications[0];
				this.applicationId = id;
				this.handleApplicationIdChange({ applicationId: id });
			}
			const activeFormTemplates = this.formTemplates.filter(
				item => item.active,
			);
			if (activeFormTemplates.length > 0) {
				const { id } = activeFormTemplates[0];
				this.formTemplateId = id;
			}
		},
		handleApplicationIdChange({ applicationId }) {
			if (applicationId) {
				const application = this.candidate.applications.find(
					item => item.id === applicationId,
				);
				const getPipelineStatus = ({
					is_archived,
					archive_status,
					is_interview,
					interview_status,
					is_lead,
					lead_status,
				}) => {
					if (is_archived) {
						return archive_status ? archive_status : 'underqualified';
					} else if (is_interview) {
						return interview_status ? interview_status : 'phone';
					} else if (is_lead) {
						return lead_status ? lead_status : 'new-lead';
					}
					return 'new-applicant';
				};
				this.pipelineStatus = getPipelineStatus(application);
				this.jobId = application.job_id;
			}
		},
		async handleStart(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_new_form.validate();
			if (isValid) {
				const { formTemplateId, applicationId, pipelineStatus, jobId } = this;
				this.onStart({
					formTemplateId,
					applicationId,
					pipelineStatus,
					jobId,
				});
				this.menu = false;
			}
		},
		handleFormatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
	},
	watch: {
		menu: {
			handler: function (newVal) {
				if (newVal) {
					this.initForm();
				}
			},
		},
	},
};
</script>
