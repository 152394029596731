<template>
	<v-dialog
		v-model="dialog"
		style="background-color: white !important"
		width="750px"
		persistent
	>
		<v-card>
			<v-card-title>{{ $t('label.select-mail-template') }}</v-card-title>
			<ValidationObserver
				ref="form_schedule_email"
				tag="form"
				@submit="handleStart"
				id="form_schedule_email"
			>
				<v-container fluid>
					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="email.id"
									:items="emailTemplates.filter(item => item.active)"
									:item-text="item => item.label + ` (${item.sent_from})`"
									item-value="id"
									:label="$t('label.template')"
									outlined
									hide-details
									dense
									@change="id => handleEmailTemplateChange({ id })"
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>
				</v-container>
			</ValidationObserver>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="onCancel">
					{{ $t('button.prev') }}
				</v-btn>
				<v-btn color="primary" @click="handleStart">
					{{ $t('button.start') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: 'ScheduleEmailMenu',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		assessment: {
			type: Object,
			required: false,
		},
		onStart: {
			type: Function,
			required: true,
		},
		onCancel: {
			type: Function,
			required: true,
		},
		dialog: {
			type: Boolean,
			required: true,
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	computed: {
		...mapState({
			emailTemplates: state => state.emailTemplates.items,
		}),
	},
	data() {
		return {
			email: {
				id: null,
				label: null,
				subject: null,
				template: null,
				sent_from: null,
				send_at: null,
				send_time: null,
				cc: [],
				bcc: [],
				email_template_id: null,
			},
		};
	},
	methods: {
		initForm() {
			const activeEmailTemplates = this.emailTemplates.filter(
				item => item.active,
			);
			if (activeEmailTemplates.length > 0) {
				const { id } = activeEmailTemplates[0];
				this.handleEmailTemplateChange({ id });
			}
		},
		handleEmailTemplateChange({ id }) {
			this.$store
				.dispatch('emailTemplates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					const { candidate, applicationId } = this;
					if (items.length > 0) {
						this.email = {
							...items[0],
							candidate_id: candidate.id,
							application_id: applicationId,
							send_to: candidate.email[0],
							is_sent: false,
							is_cancelled: false,
							send_time: items[0].default_schedule,
							email_template_id: id,
						};
					}
				});
		},
		async handleStart(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_schedule_email.validate();
			if (isValid) {
				this.onStart(this.email);
			}
		},
	},
	mounted() {
		if (
			!this.emailTemplates ||
			(this.emailTemplates && this.emailTemplates.length === 0)
		) {
			this.$store
				.dispatch('emailTemplates/get', {
					updateStore: true,
					refresh: true,
				})
				.then(() => {
					this.initForm();
				});
		}
	},
};
</script>
