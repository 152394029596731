<template>
	<span>
		<span v-if="lastApplication">
			{{ lastApplication.job_name }}
			<span v-if="lastApplication.match_score">
				({{ parseInt(lastApplication.match_score * 100) }}%)
			</span>
		</span>
		<span v-if="applications.length > 1">
			<v-tooltip right>
				<template v-slot:activator="{ on }">
					<v-btn x-small color="primary" text v-on="on">
						{{
							$t('text.x-more', {
								count: applications.length - 1,
							})
						}}
					</v-btn>
				</template>
				<div>
					<v-simple-table dense>
						<tbody>
							<tr
								v-for="(application, index) in applications"
								:key="`application_${index}`"
							>
								<td>
									{{ application.job_name }}
									<span v-if="application.match_score">
										({{ parseInt(application.match_score * 100) }}%)
									</span>
								</td>
								<td>
									{{
										formatDate({
											timestamp: application.created,
										})
									}}
								</td>
								<td>
									{{
										$t(
											`pipeline.steps.${handleGetPipelineStatus({
												is_archived: application.is_archived,
												is_lead: application.is_lead,
												is_interview: application.is_interview,
												archive_status: application.archive_status,
												lead_status: application.lead_status,
												interview_status: application.interview_status,
											})}`,
										)
									}}
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</div>
			</v-tooltip>
		</span>
	</span>
</template>

<script>
import { format } from 'date-fns';

export default {
	name: 'ApplicationsCell',
	props: {
		applications: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
	},
	computed: {
		lastApplication() {
			const { applications } = this;
			return applications.length > 0 ? applications[0] : null;
		},
	},
	data() {
		return {
			format,
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleGetPipelineStatus({
			is_archived,
			is_lead,
			is_interview,
			archive_status,
			lead_status,
			interview_status,
		}) {
			if (is_archived) {
				return archive_status ? archive_status : 'underqualified';
			} else if (is_interview) {
				return interview_status ? interview_status : 'phone';
			} else if (is_lead) {
				return lead_status ? lead_status : 'new-lead';
			}
			return 'new-applicant';
		},
	},
};
</script>
