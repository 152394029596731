<template>
	<fieldset class="talentics-fieldset talentics-fieldset-paddings">
		<legend>{{ label }}</legend>
		<v-container fluid>
			<v-row>
				<v-col cols="11" sm="11">
					<div v-if="value">
						<div style="font-size: 0.7rem; margin-bottom: 0.5rem">
							{{ value }}
						</div>
						<div
							class="preview-image"
							:style="`background-image: url(${value});`"
						>
							<v-btn
								fab
								x-small
								elevation="1"
								style="position: absolute; right: -0.5rem; top: -0.5rem"
								@click="handleClickDelete"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
					</div>
					<div v-else>Please select</div>
				</v-col>
				<v-col cols="1" sm="1" class="text-right">
					<v-btn fab x-small elevation="0" @click="handleClickCollapse">
						<v-icon v-if="isCollapsed">keyboard_arrow_up</v-icon>
						<v-icon v-else>keyboard_arrow_down</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<div v-if="isCollapsed">
			<CdnFilePickerFiles
				:onClickSelect="handleClickSelect"
			></CdnFilePickerFiles>
		</div>
	</fieldset>
</template>

<script>
import CdnFilePickerFiles from './CdnFilePickerFiles';

export default {
	name: 'CdnFilePicker',
	components: {
		CdnFilePickerFiles,
	},
	data() {
		return {
			isCollapsed: false,
		};
	},
	methods: {
		handleClickCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		handleClickSelect({ file }) {
			if (file) {
				this.$emit('input', `https://cdn.talentics.app/${file}`);
			} else {
				this.$emit('input', null);
			}
			this.handleClickCollapse();
		},
		handleClickDelete() {
			this.$emit('input', null);
		},
	},
	props: {
		value: {
			required: false,
		},
		label: {
			required: false,
		},
	},
};
</script>

<style lang="scss">
.preview-image {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	height: 200px;
	width: 200px;
	border: 1px solid silver;
	background-color: #f9f9f9;
	position: relative;
}
</style>
