<template>
	<div>
		<v-dialog
			:value="true"
			max-width="400"
			@click:outside="() => handleClose({ items: [] })"
			@keydown.esc="() => handleClose({ items: [] })"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>{{ $t('label.add-new-tag') }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<ValidationObserver ref="form" tag="form" @submit="handleSubmit">
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}:
											{{
												tagEditable.active
													? $t('label.active')
													: $t('label.inactive')
											}}
										</legend>
										<v-switch
											v-model="tagEditable.active"
											color="success"
											inset
											hide-details
											class="v-input--template-switch"
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="tagEditable.name"
											outlined
											:label="$t('label.name')"
											:error-messages="errors"
											hide-details
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="() => handleClose({ items: [] })"
						>{{ $t('button.close') }}</v-btn
					>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';

export default {
	name: 'TagAttrs',
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	computed: {
		...mapState({
			tags: state => state.tags.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		method: {
			type: String,
			required: true,
		},
		tag: {
			type: Object,
			required: false,
			default() {
				return {
					active: true,
					name: '',
				};
			},
		},
		onClose: {
			type: Function,
			required: true,
		},
	},
	data() {
		const { tag } = this;
		return {
			tagEditable: JSON.parse(JSON.stringify(tag)),
		};
	},
	methods: {
		handleClose({ items = [] }) {
			this.onClose({ items });
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form.validate();
			if (isValid) {
				const { tagEditable, method } = this;
				if (method === 'edit') {
					this.$store
						.dispatch('tags/put', {
							items: [{ ...tagEditable }],
						})
						.then(response => {
							const { items } = response.data;
							this.handleClose({ items });
						});
				} else if (method === 'add') {
					this.$store
						.dispatch('tags/post', {
							items: [{ ...tagEditable }],
						})
						.then(response => {
							const { items } = response.data;
							this.handleClose({ items });
						});
				}
			}
		},
	},
	watch: {
		tag(newVal) {
			this.tagEditable = { ...newVal };
		},
	},
};
</script>
