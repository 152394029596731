var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"flex"}},[_c('v-select',{staticStyle:{"width":"250px"},attrs:{"items":_vm.statuses,"item-text":item => {
					if (item.text) {
						return _vm.$t(`pipeline.steps.${item.text}`);
					}
					return item;
				},"item-value":"value","label":_vm.$t('label.status'),"outlined":"","hide-details":"","dense":"","return-object":"","prepend-inner-icon":_vm.innerIcon},on:{"change":newVal => _vm.handleChange({ newVal })},model:{value:(_vm.currentStatus),callback:function ($$v) {_vm.currentStatus=$$v},expression:"currentStatus"}}),(!_vm.isArchived)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"0.5rem"},attrs:{"fab":"","small":"","tile":"","light":_vm.isDark,"dark":!_vm.isDark}},on),[_c('v-icon',[_vm._v("archive")])],1)]}}],null,false,1165668274)},[_c('v-list',_vm._l((_vm.pipelineArchiveItems.map(item => ({
						text: item,
						value: item,
						is_lead: undefined,
						is_interview: false,
						is_archived: true,
					}))),function(item,index){return _c('v-list-item',{key:index,on:{"click":() => _vm.handleChange({ newVal: item })}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(`pipeline.steps.${item.text}`)))])],1)}),1)],1):_vm._e()],1),(_vm.isConfirmVisible)?_c('Confirm',{attrs:{"onConfirm":_vm.handleConfirmDialogConfirm,"onClose":_vm.handleConfirmDialogClose}},[_c('div',{staticStyle:{"padding-top":"20px"},domProps:{"innerHTML":_vm._s(
				_vm.$t('text.edit-app-status', {
					initialStatus: `<strong>${_vm.$t(
						`pipeline.steps.${_vm.initialStatus}`,
					)}</strong>`,
					nextStatus: `<strong>${_vm.$t(
						`pipeline.steps.${_vm.nextStatus}`,
					)}</strong>`,
				})
			)}})]):_vm._e(),(_vm.isEditFormDialogVisible)?_c('EditFormDialog',{attrs:{"formId":_vm.formIdToEdit,"candidate":_vm.candidate,"onClose":_vm.handleEditFormDialogClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }