<template>
	<ValidationProvider :rules="rules" v-slot="{ errors }">
		<v-autocomplete
			v-model="itemModel"
			:items="deptsTeams"
			item-text="text"
			item-value="value"
			hide-no-data
			hide-details
			outlined
			@change="handleChange"
			:label="label"
			:multiple="multiple"
			:chips="multiple"
			:deletable-chips="multiple"
			class="v-select-talentics-chips"
			:dense="dense"
			:error-messages="errors"
			:return-object="returnObject"
			:readonly="readonly"
		></v-autocomplete>
	</ValidationProvider>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider } from 'vee-validate';

export default {
	name: 'TeamPicker',
	components: {
		ValidationProvider,
	},
	props: {
		value: {
			type: [Number, Array],
			required: false,
			default() {
				return null;
			},
		},
		multiple: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		rules: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		label: {
			type: String,
			required: false,
		},
		returnObject: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		readonly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	computed: {
		...mapState({
			organizations: state => state.organizations.items,
			departments: state => state.departments.items,
			teams: state => state.teams.items,
		}),
		deptsTeams() {
			const { departments, organizations } = this;
			return organizations
				.reduce((acc, organization) => {
					return [
						...acc,
						{
							header: organization.name,
							organization_id: organization.id,
							is_organization: true,
						},
						...departments
							.filter(item => item.organization_id === organization.id)
							.map(item => ({
								header: `${organization.name} / ${item.name}`,
								department_id: item.id,
								is_department: true,
							})),
					];
				}, [])
				.reduce((acc, item) => {
					const { teams } = this;

					if (item.is_department) {
						return [
							...acc,
							item,
							...teams
								.filter(team => item.department_id === team.department_id)
								.map(team => ({
									value: team.id,
									text: team.name?.toUpperCase(),
									disabled: team.active !== true,
									team_id: team.id,
									department_id: item.department_id,
								})),
						];
					}

					return [...acc, item];
				}, []);
		},
	},
	data() {
		return {
			itemModel: null,
		};
	},
	methods: {
		handleChange(newVal) {
			this.$emit('input', newVal);
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					this.itemModel = newVal;
				} else {
					this.itemModel = null;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
