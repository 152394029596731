<template>
	<div>
		<v-dialog
			:value="true"
			max-width="800"
			@click:outside="onClose"
			@keydown.esc="onClose"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat>
						<v-toolbar-title>{{ $t('text.add-file') }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<Dropzone
					id="candidateFilesDropzone"
					:options="{
						maxFilesize: 20,
						maxFiles: 10,
						addRemoveLinks: false,
					}"
					:onSuccess="(file, response) => handleDropzoneSuccess({ response })"
				></Dropzone>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="onClose">{{
						$t('button.close')
					}}</v-btn>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Dropzone from '@/components/shared/dropzone/Dropzone';

export default {
	name: 'AddCandidateFile',
	components: {
		Dropzone,
	},
	computed: {
		...mapState({
			files: state => state.files.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		onSave: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			candidateFileIds: [],
		};
	},
	methods: {
		handleDropzoneSuccess({ response }) {
			const { items } = response;
			this.candidateFileIds = [
				...this.candidateFileIds,
				...items.map(({ id }) => id),
			];
		},
		handleSubmit() {
			this.onSave({ addedFiles: this.candidateFileIds });
		},
	},
};
</script>
