<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="600"
			@click:outside="onClose"
			@keydown.esc="onClose"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat>
						<v-toolbar-title>
							{{ $t('text.change-stage') }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-card-text style="padding: 0">
					<ValidationObserver
						ref="form_edit_app_status"
						tag="form"
						@submit="handleApplicationStatus"
						id="form_edit_app_status"
					>
						<v-container fluid>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="jobIds"
											:items="jobsWithNames"
											:label="$t('label.choose-job')"
											outlined
											hide-details
											dense
											multiple
											:error-messages="errors"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="currentStatus"
											:items="statuses"
											:item-text="
												item => {
													if (item.text) {
														return $t(`pipeline.steps.${item.text}`);
													}
													return item;
												}
											"
											item-value="value"
											:label="$t('label.choose-status')"
											outlined
											hide-details
											dense
											:error-messages="errors"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="onClose">{{ $t('button.cancel') }}</v-btn>
					<v-btn @click="handleApplicationStatus" color="primary">
						{{ $t('button.apply') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: 'EditApplicationStatusDialog',
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	computed: {
		...mapState({
			jobs: state => state.jobs.items,
			pipelineInterviewItems: state => state.pipelineInterview.items,
			pipelineLeadItems: state => state.pipelineLead.items,
			isDark: state => state.currentUser.isDark,
		}),
		jobsWithNames() {
			const { candidates, jobs } = this;
			return candidates
				.reduce((acc, candidate) => {
					const { job_id } = candidate;
					return [...acc, ...job_id.filter(item => acc.indexOf(item) === -1)];
				}, [])
				.map(item => {
					const appliedJob = jobs.find(job => job.id === item);
					return appliedJob
						? {
								text: appliedJob.name,
								value: appliedJob.id,
						  }
						: {
								text: item,
								value: item,
						  };
				});
		},
		statuses() {
			const { pipelineInterviewItems, pipelineLeadItems } = this;
			return [
				{ header: 'Lead' },
				...pipelineLeadItems.map(item => ({
					text: item,
					value: item,
					is_lead: true,
					is_interview: false,
					is_archived: false,
				})),
				{ divider: true },
				{ header: 'Application' },
				{
					text: 'new-applicant',
					value: 'new-applicant',
					is_lead: false,
					is_interview: false,
					is_archived: false,
				},
				{ divider: true },
				{ header: 'Interview' },
				...pipelineInterviewItems.map(item => ({
					text: item,
					value: item,
					is_lead: undefined,
					is_interview: true,
					is_archived: false,
				})),
			];
		},
	},
	data() {
		const { statusInitial } = this;
		return {
			currentStatus: statusInitial ? statusInitial : null,
			isVisible: false,
			jobIds: [],
		};
	},
	props: {
		candidates: {
			type: Array,
			required: true,
		},
		statusInitial: {
			type: String,
			required: false,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
	methods: {
		async handleApplicationStatus(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_edit_app_status.validate();
			if (isValid) {
				const candidate_ids = this.candidates.map(candidate => candidate.id);
				const { query } = this.$route;
				const candidateApplications = await this.$store.dispatch(
					'candidates/get_applications',
					{
						candidate_ids: candidate_ids.join(','),
						job_ids: this.jobIds.join(','),
						refresh: true,
						...query,
					},
				);
				const candidateApplicationsFlat =
					candidateApplications.data.items.reduce((acc, item) => [
						...acc,
						...item,
					]);
				const currentStatusObject = this.statuses.find(
					({ value }) => value === this.currentStatus,
				);
				const { is_archived, is_interview, is_lead, value } =
					currentStatusObject;
				const interview_status = is_interview ? value : null;
				const lead_status = is_lead ? value : null;
				const archive_status = is_archived ? value : null;

				this.$store
					.dispatch('applicants/put', {
						items: candidateApplicationsFlat.map(({ id }) => ({
							id,
							is_interview,
							interview_status,
							is_archived,
							archive_status,
							is_lead,
							lead_status,
							updateStore: false,
						})),
					})
					.then(() => {
						this.$store.commit('candidates/setItemsNeedToRefresh');
						this.onClose();
					});
			}
		},
	},
	watch: {
		jobsWithNames: {
			handler: function (newVal) {
				if (newVal) {
					this.jobIds = newVal.reduce((acc, item) => {
						if (acc.indexOf(item.value) === -1) {
							return [...acc, item.value];
						}
						return [...acc];
					}, []);
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
