<template>
	<div>
		<div>
			<JobsTreeOrganizationItem
				v-for="(organizationItem, organizationIndex) in organizations"
				:key="`organization_${organizationIndex}`"
				:organization="organizationItem"
				:jobs="jobs"
				:onArchiveJob="onArchiveJob"
				:unfold="unfold"
			></JobsTreeOrganizationItem>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import JobsTreeOrganizationItem from '../jobsTreeOrganizationItem/JobsTreeOrganizationItem';

export default {
	name: 'JobsTreeOrganizations',
	components: {
		JobsTreeOrganizationItem,
	},
	computed: {
		...mapState({
			organizations: state => state.organizations.items,
		}),
	},
	props: {
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
		unfold: {
			type: Boolean,
			required: true,
		},
	},
};
</script>
