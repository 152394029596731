<template>
	<div id="wtf">
		<vue-dropzone
			:id="id"
			:ref="id"
			:options="dropzoneOptions"
			@vdropzone-success="onSuccess"
			@vdropzone-removed-file="handleRemovedFile"
			@vdropzone-files-added="handleFilesAdded"
			@vdropzone-error="handleDropzoneError"
			:class="`
		  ${isError ? 'err-border-red ' : ''}
		  ${isDark ? 'dropzone-dark ' : ''}
		  ${customClassNames}
		`"
			:useCustomSlot="useCustomSlot"
			><slot></slot
		></vue-dropzone>
		<p v-if="invalidFileError" style="color: red">File type is not allowed.</p>
	</div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import api from '@/api';

export const allowedMimeTypes = [
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'image/jpeg',
	'image/png',
	'application/msword',
	'text/plain',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'video/mp4',
	'application/vnd.ms-excel',
	'application/vnd.oasis.opendocument.text',
	'image/svg+xml',
	'image/webp',
	'image/heic',
	'text/xml',
	'text/rtf',
	'text/csv',
];

export const allowedExtensions = [
	'.pdf',
	'.docx',
	'.jpeg',
	'.jpg',
	'.png',
	'.doc',
	'.txt',
	'.xlsx',
	'.pptx',
	'.mp4',
	'.xls',
	'.odt',
	'.svg',
	'.webp',
	'.heic',
	'.xml',
	'.rtf',
	'.csv',
];

export default {
	name: 'Dropzone',
	components: {
		vueDropzone: vue2Dropzone,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		options: {
			type: Object,
			required: true,
		},
		onSuccess: {
			type: Function,
			default() {
				return false;
			},
		},
		onRemove: {
			type: Function,
			default() {
				return false;
			},
		},
		initialFiles: {
			type: Array,
			default() {
				return [];
			},
		},
		isError: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		useCustomSlot: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		customClassNames: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		const { options } = this;
		const baseUrl = process.env.VUE_APP_HOST_API_KEY;
		const url = `${baseUrl}${
			baseUrl?.slice(-1) !== '/' ? '/' : ''
		}v1/hire-app/files`;
		return {
			updated: 0,
			dropzoneOptions: {
				addRemoveLinks: true,
				acceptedFiles: allowedMimeTypes.join(','),
				...options,
				url,
				autoProcessQueue: false,
			},
			invalidFileError: false,
		};
	},
	methods: {
		handleDropzoneError($event) {
			this.invalidFileError = $event.status === 'error';
		},
		setIdToken({ id_token }) {
			if (this.$refs[this.id]) {
				this.$refs[this.id]?.setOption('headers', {
					'X-Auth-Token': id_token,
				});
			} else {
				setTimeout(
					function () {
						this.setIdToken({ id_token });
					}.bind(this),
					100,
				);
			}
		},
		handleManuallyAddFiles({ files = [] }) {
			if (this.$refs[this.id]) {
				const { id } = this;
				const now = new Date();
				this.updated = now.getTime();
				this.$refs[id]?.removeAllFiles(true);
				setTimeout(
					function () {
						for (let i = 0; i < files.length; i++) {
							const file = {
								size: files[i].size,
								name: files[i].original_file_name,
								type: files[i].content_type,
								url: files[i].signed_url || '',
							};
							this.$refs[id].manuallyAddFile(file, files[i].signed_url || '');
						}
					}.bind(this),
					100,
				);
			} else {
				setTimeout(
					function () {
						this.handleManuallyAddFiles({ files });
					}.bind(this),
					100,
				);
			}
		},
		handleRemovedFile(file, error, xhr) {
			const { updated } = this;
			const now = new Date();
			this.invalidFileError = false;

			if (now.getTime() - updated > 100) {
				this.onRemove(file, error, xhr);
			}
		},
		async handleFilesAdded() {
			await api.checkToken.get();
			const id_token = axios.defaults.headers.common['X-Auth-Token'];
			this.setIdToken({ id_token });

			setTimeout(
				function () {
					this.$refs[this.id].processQueue();
				}.bind(this),
				50,
			);
		},
	},
	watch: {
		initialFiles: {
			handler: function (newVal, oldVal) {
				const newValStringified = JSON.stringify(newVal);
				const oldValStringified = JSON.stringify(oldVal);
				if (newValStringified !== oldValStringified) {
					this.handleManuallyAddFiles({ files: newVal });
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
.err-border-red {
	border-color: red;
}
.dropzone-dark {
	background-color: grey;
	color: white;
}
.dropzone {
	&.dropzone-button {
		padding: 0;
		border: 0;
		min-height: 0;
		background: transparent;
		display: inline-block;

		&:hover {
			background: transparent;
		}

		.dz-message {
			margin: 0;
			text-align: left;
		}
	}

	.dz-error-message {
		top: 5px !important;
		right: 5px !important;
		left: 5px !important;
		width: auto !important;
	}
}
</style>
