<template>
	<div id="app">
		<v-app>
			<Header :onClickToggleDrawer="handleClickToggleDrawer"></Header>
			<DrawerCalendar></DrawerCalendar>
			<v-content>
				<slot></slot>
			</v-content>
		</v-app>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Header from './header/Header';
import DrawerCalendar from './drawerCalendar/DrawerCalendar';

export default {
	components: {
		Header,
		DrawerCalendar,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			drawer: state => state.currentRoute.drawer,
		}),
	},
	methods: {
		handleClickToggleDrawer() {
			const { drawer } = this;
			this.$store.commit('currentRoute/setDrawer', { drawer: !drawer });
		},
	},
};
</script>
