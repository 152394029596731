<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="800px"
			persistent
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
		>
			<v-card>
				<v-card-title style="display: block; padding: 0; font-size: 1rem">
					<v-stepper alt-labels v-model="stepperStep" flat>
						<v-stepper-header>
							<v-stepper-step :step="1" :complete="stepperStep > 1">
								{{ $t('label.select-job') }}
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step :step="2" :complete="stepperStep > 2">
								{{ $t('label.upload-file') }}
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step :step="3" :complete="stepperStep > 2">
								{{ $t('label.success') }}
							</v-stepper-step>
						</v-stepper-header>
					</v-stepper>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text style="padding: 0 0.5rem">
					<v-stepper v-model="stepperStep" flat>
						<v-stepper-items>
							<v-stepper-content :step="1">
								<ValidationObserver
									ref="bulk_import_form_step_1"
									tag="form"
									@submit="handleGoToSecondStep"
									id="bulk_import_form_step_1"
								>
									<JobPicker
										v-model="jobId"
										:jobs="jobs"
										:label="$t('label.jobs')"
										hide-inactive-teams
										hide-inactive-departments
										hide-draft-jobs
										hide-pending-jobs
										:hide-closed-jobs="false"
										dense
										rules="required"
									></JobPicker>
								</ValidationObserver>
							</v-stepper-content>

							<v-stepper-content :step="2">
								<Dropzone
									id="bulkCandidateImportDropzone"
									:options="{
										maxFilesize: 5,
										maxFiles: 1,
										acceptedFiles: '.xlsx',
									}"
									:initialFiles="[]"
									:onSuccess="
										(file, response) => handleDropzoneSuccess({ response })
									"
								></Dropzone>
							</v-stepper-content>

							<v-stepper-content :step="3">
								<v-alert outlined type="success" text>
									{{ $t('text.operation-successful') }}
								</v-alert>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-card-text>

				<v-divider></v-divider>
				<v-card-actions>
					<v-btn
						href="https://cdn.talentics.app/assets/bulk-import-template/bulk-import-template-20231227.xlsx"
						target="_blank"
						v-if="stepperStep === 1"
						small
					>
						{{ $t('button.download-excel-template') }}
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn text @click="handleClose" v-if="stepperStep === 1">{{
						$t('button.close')
					}}</v-btn>
					<v-btn
						text
						@click="handleCloseAfterSuccess"
						v-if="stepperStep === 3"
						>{{ $t('button.close') }}</v-btn
					>
					<v-btn
						color="primary"
						@click="handleGoToSecondStep"
						v-if="stepperStep === 1"
					>
						{{ $t('button.next') }}
					</v-btn>
					<v-btn
						color="secondary"
						@click="stepperStep = 1"
						v-if="stepperStep === 2"
					>
						<v-icon left>arrow_back</v-icon>
						{{ $t('button.back') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Dropzone from '@/components/shared/dropzone/Dropzone';
import JobPicker from '@/components/shared/jobPicker/JobPicker';
import { ValidationObserver } from 'vee-validate';
import api from '@/api';

export default {
	name: 'BulkCandidateImport',
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			isDark: state => state.currentUser.isDark,
			jobs: state => state.jobs.items,
		}),
	},
	components: {
		Dropzone,
		JobPicker,
		ValidationObserver,
	},
	data() {
		return {
			isVisible: false,
			items: [],
			fileId: null,
			jobId: null,
			stepperStep: 1,
		};
	},
	methods: {
		handleClickOutside(evt) {
			evt.preventDefault();
		},
		handleClose() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates`,
				query: {
					...query,
				},
			});
		},
		handleCloseAfterSuccess() {
			const { currentLang } = this;
			this.$router.push({
				path: `/${currentLang}/candidates`,
				query: {
					is_lead: 1,
				},
			});
		},
		handleDropzoneSuccess({ response }) {
			const { jobId } = this;
			const { items } = response;
			const { id } = items[0];
			api.bulkImport.post({ fileId: id, jobId }).then(() => {
				this.stepperStep = 3;
			});
		},
		async handleGoToSecondStep(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.bulk_import_form_step_1.validate();

			if (isValid) {
				this.stepperStep = 2;
			}
		},
	},
	created() {
		this.$store.dispatch('jobs/get', { updateStore: true });
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
};
</script>
