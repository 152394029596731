<script>
import { Doughnut } from 'vue-chartjs';

export default {
	name: 'ChartDoughnut',
	extends: Doughnut,
	props: {
		chartdata: {
			type: Object,
			default: null,
		},
		options: {
			type: Object,
			default: null,
		},
	},
	methods: {
		handleRenderChart(chartData) {
			this.renderChart(chartData, {
				responsive: true,
				maintainAspectRatio: false,
				aspectRatio: 5,
				legend: {
					display: true,
					position: 'bottom',
				},
				width: null,
				height: null,
			});
		},
	},
	mounted() {
		this.handleRenderChart(this.chartdata);
	},
	watch: {
		chartdata: {
			handler: function (newVal) {
				this.handleRenderChart(newVal);
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>
