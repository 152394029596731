<template>
	<v-menu
		ref="menu"
		:close-on-content-click="false"
		:return-value.sync="time"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="290px"
	>
		<template v-slot:activator="{ on }">
			<v-text-field
				v-model="time"
				:prepend-inner-icon="prependInnerIcon"
				readonly
				v-on="on"
				:outlined="outlined"
				:background-color="`${isDark ? '' : '#ffffff'}`"
				hide-details
				no-title
				:error-messages="errors"
				:label="label"
				:dense="dense"
				:disabled="disabled"
			></v-text-field>
		</template>
		<v-card elevation="1">
			<v-card-text>
				<v-row>
					<v-col>
						<v-select
							v-model="hour"
							:items="hours"
							dense
							outlined
							hide-details
							@input="handleHoursMinutesChange"
						></v-select>
					</v-col>
					<v-col>
						<v-select
							v-model="minute"
							:items="minutes"
							dense
							outlined
							hide-details
							@input="handleHoursMinutesChange"
						></v-select>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';

export default {
	name: 'TimePicker',
	data() {
		return {
			format,
			time: null,
			hour: null,
			minute: null,
			menu: false,
			hours: [
				{ text: '00', value: 0 },
				{ text: '01', value: 1 },
				{ text: '02', value: 2 },
				{ text: '03', value: 3 },
				{ text: '04', value: 4 },
				{ text: '05', value: 5 },
				{ text: '06', value: 6 },
				{ text: '07', value: 7 },
				{ text: '08', value: 8 },
				{ text: '09', value: 9 },
				{ text: '10', value: 10 },
				{ text: '11', value: 11 },
				{ text: '12', value: 12 },
				{ text: '13', value: 13 },
				{ text: '14', value: 14 },
				{ text: '15', value: 15 },
				{ text: '16', value: 16 },
				{ text: '17', value: 17 },
				{ text: '18', value: 18 },
				{ text: '19', value: 19 },
				{ text: '20', value: 20 },
				{ text: '21', value: 21 },
				{ text: '22', value: 22 },
				{ text: '23', value: 23 },
			],
			minutes: [
				{ text: '00', value: 0 },
				{ text: '01', value: 1 },
				{ text: '02', value: 2 },
				{ text: '03', value: 3 },
				{ text: '04', value: 4 },
				{ text: '05', value: 5 },
				{ text: '06', value: 6 },
				{ text: '07', value: 7 },
				{ text: '08', value: 8 },
				{ text: '09', value: 9 },
				{ text: '10', value: 10 },
				{ text: '11', value: 11 },
				{ text: '12', value: 12 },
				{ text: '13', value: 13 },
				{ text: '14', value: 14 },
				{ text: '15', value: 15 },
				{ text: '16', value: 16 },
				{ text: '17', value: 17 },
				{ text: '18', value: 18 },
				{ text: '19', value: 19 },
				{ text: '20', value: 20 },
				{ text: '21', value: 21 },
				{ text: '22', value: 22 },
				{ text: '23', value: 23 },
				{ text: '24', value: 24 },
				{ text: '25', value: 25 },
				{ text: '26', value: 26 },
				{ text: '27', value: 27 },
				{ text: '28', value: 28 },
				{ text: '29', value: 29 },
				{ text: '30', value: 30 },
				{ text: '31', value: 31 },
				{ text: '32', value: 32 },
				{ text: '33', value: 33 },
				{ text: '34', value: 34 },
				{ text: '35', value: 35 },
				{ text: '36', value: 36 },
				{ text: '37', value: 37 },
				{ text: '38', value: 38 },
				{ text: '39', value: 39 },
				{ text: '40', value: 40 },
				{ text: '41', value: 41 },
				{ text: '42', value: 42 },
				{ text: '43', value: 43 },
				{ text: '44', value: 44 },
				{ text: '45', value: 45 },
				{ text: '46', value: 46 },
				{ text: '47', value: 47 },
				{ text: '48', value: 48 },
				{ text: '49', value: 49 },
				{ text: '50', value: 50 },
				{ text: '51', value: 51 },
				{ text: '52', value: 52 },
				{ text: '53', value: 53 },
				{ text: '54', value: 54 },
				{ text: '55', value: 55 },
				{ text: '56', value: 56 },
				{ text: '57', value: 57 },
				{ text: '58', value: 58 },
				{ text: '59', value: 59 },
			],
		};
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		value: {
			type: String,
			required: false,
		},
		label: {
			type: String,
			required: false,
			default() {
				return 'Time';
			},
		},
		errors: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		outlined: {
			type: Boolean,
			required: false,
			default() {
				return true;
			},
		},
		prependInnerIcon: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	methods: {
		handleMenuSave() {
			const { time } = this;
			if (time) {
				const formattedDate = new Date(time);
				const formattedDateToISOString = formattedDate.toISOString();
				this.$emit('input', formattedDateToISOString);
			}
			this.$refs.menu.save(time);
		},
		formatDate({ timestamp }) {
			const newDate = new Date(timestamp);
			return format(newDate, 'E, MMM d');
		},
		handleDatePickerMenuInput() {
			this.handleMenuSave();
		},
		handleHoursMinutesChange() {
			const { hour, minute } = this;
			const date = new Date(this.value);
			date.setHours(hour);
			date.setMinutes(minute);
			const newVal = format(date, 'yyyy-MM-dd HH:mm:ss');
			this.$emit('input', newVal);
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					const date = new Date(newVal);
					this.hour = date.getHours();
					this.minute = date.getMinutes();
					this.time = format(date, 'HH:mm');
				} else {
					this.time = null;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
