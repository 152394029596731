<template>
	<v-menu
		offset-y
		bottom
		left
		:max-height="500"
		:max-width="500"
		v-model="menu"
	>
		<template v-slot:activator="{ on }">
			<span v-on="on">
				<v-tooltip bottom>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
							<v-badge
								color="pink"
								overlap
								bottom
								:content="unseenMentionsCounter"
								:value="unseenMentionsCounter"
							>
								<v-icon>speaker_notes</v-icon>
							</v-badge>
						</v-btn>
					</template>
					{{ !menu ? $t('tooltip.mentions') : '' }}
				</v-tooltip>
			</span>
		</template>

		<v-list>
			<v-list-item-group v-if="mentions.length > 0">
				<template v-for="(mention, index) in mentions">
					<v-list-item :key="index" @click="handleClick(mention)">
						<v-list-item-avatar>
							<Avatar :userId="mention.created_by_id"></Avatar>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title
								v-html="mention.note"
								style="white-space: normal"
							></v-list-item-title>
							<v-list-item-subtitle
								v-html="formatDate({ timestamp: mention.created })"
							></v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider
						:key="`divider_${index}`"
						v-if="index < mentions.length - 1"
					></v-divider>
				</template>
			</v-list-item-group>

			<v-list-item v-else disabled>
				<v-list-item-title> {{ $t('text.no-new-mentions') }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { db } from '../../../../../main';
import {
	collection,
	where,
	orderBy,
	limit,
	query,
	getDocs,
} from 'firebase/firestore';

import { format } from 'date-fns';
import { mapState } from 'vuex';
import Avatar from '@/components/shared/avatar/Avatar';
import api from '@/api';

export default {
	name: 'Mentions',
	components: {
		Avatar,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			currentLang: state => state.currentUser.lang,
		}),
		unseenMentionsCounter() {
			return this.mentions.filter(item => item.seen === false).length;
		},
	},
	data() {
		return {
			format,
			mentions: [],
			menu: false,
		};
	},
	methods: {
		async getMentions() {
			const { uuidv4, company_id } = this.currentUser;
			const that = this;
			const mentionsRef = collection(db, `users/${uuidv4}/mentions`);
			const mentionsQuery = query(
				mentionsRef,
				where('company_id', '==', company_id),
				orderBy('created', 'desc'),
				limit(20),
			);

			try {
				const querySnapshot = await getDocs(mentionsQuery);
				const mentions = [];

				querySnapshot.forEach(doc => {
					mentions.push({ ...doc.data(), id: doc.id });
				});

				that.$store.commit('mentions/setItems', {
					items: mentions,
				});

				that.mentions = mentions;
			} catch (error) {
				console.error('Error querying documents: ', error);
			}
		},
		handleClick({ candidate_id }) {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${candidate_id}`,
				query: {
					...query,
					is_search: 1,
					tab: 'comments',
				},
			});
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleMarksAsSeen() {
			const unseenMentions = this.mentions.filter(item => item.seen === false);
			if (unseenMentions.length > 0) {
				api.mentions.markAsSeen
					.post({
						ids: unseenMentions.map(item => item.id),
					})
					.then(() => {
						this.getMentions();
					});
			}
		},
	},
	watch: {
		currentUser: {
			handler: function (newVal) {
				if (newVal && newVal.uuidv4) {
					this.getMentions();
				}
			},
			immediate: true,
			deep: true,
		},
		menu: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal && newVal === true) {
					this.handleMarksAsSeen();
				}
			},
			immediate: false,
			deep: true,
		},
	},
};
</script>
