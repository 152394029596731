<template>
	<span style="white-space: nowrap">
		<span
			v-for="(feedback, index) in feedbacks"
			:key="`feedback_${index}`"
			:style="`margin-left: ${index === 0 ? '0' : '8px'};`"
			@click="() => onClickItem({ tab: 2 })"
		>
			<CandidateFeedbackIcon
				:score="feedback.overall_score"
				:userId="feedback.created_by"
			></CandidateFeedbackIcon>
		</span>
		<span
			v-for="(scorecard, index) in scorecards"
			:key="`scorecard_${index}`"
			:style="`margin-left: ${
				index === 0 && feedbacks.length === 0 ? '0' : '8px'
			};`"
			@click="() => onClickItem({ tab: 3 })"
		>
			<CandidateScorecardIcon
				:score="scorecard.overall_score"
				:userId="scorecard.created_by"
			></CandidateScorecardIcon>
		</span>
	</span>
</template>

<script>
import CandidateFeedbackIcon from './candidateFeedbackIcon/CandidateFeedbackIcon';
import CandidateScorecardIcon from './candidateScorecardIcon/CandidateScorecardIcon';

export default {
	name: 'CandidateFeedbackIcons',
	props: {
		feedbacks: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		scorecards: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		avatarSize: {
			type: Number,
			required: false,
			default() {
				return 30;
			},
		},
		onClickItem: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		CandidateFeedbackIcon,
		CandidateScorecardIcon,
	},
};
</script>
