<template>
	<div style="max-width: 960px">
		<v-container fluid>
			<v-row>
				<v-col>
					<p>{{ $t('text.company-logo') }}</p>
					<Dropzone
						id="logoDropzone"
						:options="{
							maxFilesize: 2,
							maxFiles: 1,
							acceptedFiles: 'image/png, image/jpeg, image/gif',
						}"
						:initialFiles="logoImage ? [logoImage] : []"
						:onSuccess="(file, response) => handleDropzoneSuccess({ response })"
					></Dropzone>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div style="max-width: 200px">
						<ColorPicker
							:label="$t('label.primary-color')"
							v-model="primaryColor"
						></ColorPicker>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<fieldset class="talentics-fieldset talentics-fieldset-paddings">
						<legend>{{ $t('text.button-text-color') }}</legend>
						<v-radio-group
							v-model="isDark"
							column
							hide-details
							dense
							class="text-color-selection-controls"
						>
							<v-radio
								:label="$t('label.light-text')"
								color="primary"
								:value="true"
							></v-radio>
							<v-radio
								:label="$t('label.dark-text')"
								color="primary"
								:value="false"
							></v-radio>
						</v-radio-group>
					</fieldset>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<fieldset class="talentics-fieldset talentics-fieldset-paddings">
						<legend>{{ $t('text.button-preview') }}</legend>
						<v-card light elevation="0">
							<v-card-text>
								<v-btn :color="primaryColor" :dark="isDark">
									{{ $t('button.sample-action') }}
								</v-btn>
							</v-card-text>
						</v-card>
					</fieldset>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';
import ColorPicker from '@/components/shared/colorPicker/ColorPicker';
import Dropzone from '@/components/shared/dropzone/Dropzone';

export default {
	name: 'Styling',
	components: {
		ColorPicker,
		Dropzone,
	},
	computed: {
		...mapState({
			company: state => state.company.company,
		}),
	},
	data() {
		return {
			primaryColor: '#1976D2FF',
			isDark: true,
			logo: null,
			logoImage: undefined,
		};
	},
	methods: {
		handleDropzoneSuccess({ response }) {
			const { items } = response;
			this.logo = items[0].id;
		},
		handleSubmit() {
			const { primaryColor, isDark, logo } = this;
			this.$store.dispatch('company/put', {
				primary_color: primaryColor,
				is_dark: isDark,
				logo,
			});
		},
		getLogoImage({ id }) {
			api.files.get({ id }).then(response => {
				this.logoImage = response.data;
			});
		},
	},
	watch: {
		company: {
			handler: function (newVal) {
				const { is_dark, logo, primary_color } = newVal;
				if (primary_color) {
					this.primaryColor = primary_color;
				}
				if (logo) {
					this.logo = logo;
					this.getLogoImage({ id: logo });
				}
				if (is_dark) {
					this.isDark = is_dark;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
.v-input--selection-controls {
	&.text-color-selection-controls {
		margin-top: 0;
	}
}
</style>
