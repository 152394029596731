import api from '@/api';

const state = {
	items: [],
	applications: [],
	updated: 0,
	itemsNeedToRefresh: 0,
	itemNeedsToRefresh: 0,
	loading: false,
};

const getters = {};

const actions = {
	get_applications: (
		{ commit },
		{ refresh = false, updateStore = false, ...params },
	) => {
		return new Promise((resolve, reject) => {
			const { updated } = state;
			if (!refresh && updated > 0) {
				resolve({ data: { items: state.applications } });
			}
			api.candidates.applications
				.get(params)
				.then(response => {
					if (updateStore) {
						const { items } = response.data;
						commit('setApplications', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	post_applications: (_, params) => {
		// eslint-disable-line
		return new Promise((resolve, reject) => {
			api.candidates.applications
				.post(params)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	put_applications: (_, params) => {
		// eslint-disable-line
		return new Promise((resolve, reject) => {
			api.candidates.applications
				.put(params)
				.then(response => {
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	get: ({ commit }, { refresh = false, updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			const { updated } = state;
			if (!refresh && updated > 0) {
				resolve({ data: { items: state.items } });
			}
			commit('setLoading', { loading: true });
			api.candidates
				.get(params)
				.then(response => {
					if (updateStore) {
						const { items } = response.data;
						commit('setItems', { items });
					}
					commit('setLoading', { loading: false });
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	post: ({ commit }, { updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			api.candidates
				.post(params)
				.then(response => {
					if (updateStore) {
						const { items } = response.data;
						commit('addItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	put: ({ commit }, { updateStore = false, ...params }) => {
		return new Promise((resolve, reject) => {
			api.candidates
				.put(params)
				.then(response => {
					if (updateStore) {
						const { items } = response.data;
						commit('updateItems', { items });
					}
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	pinCandidate: ({ commit }, { candidateId }) => {
		return new Promise((resolve, reject) => {
			api.candidates
				.pinCandidate(candidateId)
				.then(response => {
					commit('updateCandidatesIsPinned', {
						candidateId,
						isPinned: true,
					});
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
	unpinCandidate: ({ commit }, { candidateId }) => {
		return new Promise((resolve, reject) => {
			api.candidates
				.unpinCandidate(candidateId)
				.then(response => {
					commit('updateCandidatesIsPinned', {
						candidateId,
						isPinned: false,
					});
					resolve(response);
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

const mutations = {
	setItems: (state, { jobs }) => {
		const now = new Date();
		state.items = jobs;
		state.updated = now.getTime();
	},
	setApplications: (state, { applications }) => {
		const now = new Date();
		state.applications = applications;
		state.updated = now.getTime();
	},
	setItemsNeedToRefresh: state => {
		const now = new Date();
		state.itemsNeedToRefresh = now.getTime();
		state.updated = now.getTime();
	},
	setItemNeedsToRefresh: state => {
		const now = new Date();
		state.itemNeedsToRefresh = now.getTime();
		state.updated = now.getTime();
	},
	updateItems: (state, { items }) => {
		const now = new Date();
		state.items = state.items.map(stateItem => {
			const itemMatches = items.find(({ id }) => stateItem.id === id);
			return itemMatches ? itemMatches : stateItem;
		});
		state.updated = now.getTime();
	},
	updateCandidatesIsPinned: (state, { candidateId, isPinned }) => {
		const now = new Date();
		if (state.items && state.items.length > 0) {
			state.items = state.items.map(stateItem => {
				if (stateItem.id === candidateId) {
					stateItem.is_pinned = isPinned;
				}

				return stateItem;
			});
			state.updated = now.getTime();
		}
	},
	setLoading(state, { loading }) {
		state.loading = loading;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
