import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/lang/en.json';
import de from '@/lang/de.json';
import tr from '@/lang/tr.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: ['en', 'de', 'tr'],
	messages: { en, de, tr },
});
