<template>
	<v-card>
		<v-card-title class="pa-0">
			<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
				<v-toolbar-title v-if="bulkAction">
					{{ $t('text.archive') }}
				</v-toolbar-title>
				<v-toolbar-title v-else>
					{{ $t('text.archive') }} - {{ candidate.name }}
				</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
		</v-card-title>
		<v-divider></v-divider>
		<v-card-text style="padding: 0">
			<ValidationObserver
				ref="form_step_1"
				tag="form"
				@submit="handleArchive"
				id="form_step_1"
			>
				<v-container fluid>
					<v-row v-if="bulkAction">
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="editableArchiveForm.jobIds"
									:items="jobsWithNames"
									:label="$t('label.choose-job-archive')"
									outlined
									hide-details
									dense
									multiple
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row v-else>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="editableArchiveForm.applicationIds"
									:items="applicationsWithJobNames"
									item-text="job_name"
									item-value="id"
									:label="$t('label.choose-job-archive')"
									outlined
									hide-details
									dense
									multiple
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="editableArchiveForm.archiveReason"
									:items="archiveReasons"
									:item-text="status => $t(`pipeline.steps.${status.text}`)"
									item-value="value"
									:label="$t('label.choose-archive-reason')"
									outlined
									hide-details
									dense
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<div style="margin-top: 8px">
									<v-checkbox
										v-model="sendEmailToCandidate"
										hide-details
										dense
										style="margin: auto; padding: 0"
										:label="$t('label.send-email-candidate')"
										prepend-icon="email"
									></v-checkbox>
								</div>
							</fieldset>
						</v-col>
					</v-row>
				</v-container>
			</ValidationObserver>
		</v-card-text>
		<v-divider></v-divider>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn text @click="handleClose">{{ $t('button.cancel') }}</v-btn>
			<v-btn
				v-if="sendEmailToCandidate"
				@click="handleGoToNextStep"
				color="primary"
			>
				{{ $t('button.next-step') }}
			</v-btn>
			<v-btn v-else @click="handleArchive" color="primary">
				{{ $t('button.archive') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';

export default {
	name: 'ArchiveCandidate',
	props: {
		archiveForm: {
			type: Object,
			required: true,
		},
		candidate: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
		candidates: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		bulkAction: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		onArchive: {
			type: Function,
			required: true,
		},
		onNextStep: {
			type: Function,
			required: true,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	computed: {
		...mapState({
			jobs: state => state.jobs.items,
			pipelineArchiveItems: state => state.pipelineArchive.items,
			isDark: state => state.currentUser.isDark,
		}),
		jobsWithNames() {
			const { candidates, jobs } = this;
			return candidates
				.reduce((acc, candidate) => {
					const { job_id } = candidate;
					return [...acc, ...job_id.filter(item => acc.indexOf(item) === -1)];
				}, [])
				.map(item => {
					const appliedJob = jobs.find(job => job.id === item);
					return appliedJob
						? {
								text: appliedJob.name,
								value: appliedJob.id,
						  }
						: {
								text: item,
								value: item,
						  };
				});
		},
		applicationsWithJobNames() {
			const { applications } = this.candidate;
			const { jobs } = this;
			if (applications) {
				return applications.map(application => {
					const job = jobs.find(item => item.id === application.job_id);
					return {
						...application,
						job_name: job ? job.name : application.job_id,
					};
				});
			}
			return [];
		},
		archiveReasons() {
			const { pipelineArchiveItems } = this;
			return [
				...pipelineArchiveItems.map(item => ({
					text: item,
					value: item,
				})),
			];
		},
	},
	data() {
		return {
			sendEmailToCandidate: false,
			editableArchiveForm: {},
		};
	},
	methods: {
		async handleArchive(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_step_1.validate();
			if (isValid) {
				this.handleEmitChangeForm();
				this.onArchive({ getApplications: this.bulkAction });
			}
		},
		async handleGoToNextStep(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_step_1.validate();
			if (isValid) {
				this.handleEmitChangeForm();
				this.onNextStep();
			}
		},
		handleEmitChangeForm() {
			this.$emit('change-form', {
				...this.editableArchiveForm,
				archiveReasonName: this.sendEmailToCandidate
					? this.archiveReasons.find(
							i => i.value === this.editableArchiveForm.archiveReason,
					  ).text
					: null,
			});
		},
		handleClose() {
			this.onClose();
		},
	},
	watch: {
		archiveForm: {
			handler: function (newVal) {
				if (newVal) {
					this.editableArchiveForm = JSON.parse(JSON.stringify(newVal));
				}
			},
			immediate: true,
			deep: true,
		},
		jobsWithNames: {
			handler: function (newVal) {
				if (newVal) {
					this.editableArchiveForm = {
						...this.editableArchiveForm,
						jobIds: newVal.reduce((acc, item) => {
							if (acc.indexOf(item.value) === -1) {
								return [...acc, item.value];
							}
							return [...acc];
						}, []),
					};
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
