<template>
	<div>
		<RequisitionDialog
			:onClose="handleClose"
			requisitionType="new_job"
			:requisitionId="requisitionId"
			:actionType="actionType"
		></RequisitionDialog>
	</div>
</template>

<script>
import RequisitionDialog from '@/components/shared/requisitionDialog/RequisitionDialog';
import { mapState } from 'vuex';

export default {
	name: 'JobRequisition',
	components: {
		RequisitionDialog,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
		}),
	},
	data() {
		const { requisitionId } = this.$route.params;
		return {
			requisitionId,
			actionType: requisitionId ? 'edit' : 'new',
		};
	},
	methods: {
		handleClose() {
			const { currentLang } = this;
			const { query } = this.$route;
			const { jobId } = this.$route.params;
			if (jobId) {
				this.$router.push({
					path: `/${currentLang}/jobs/edit/${jobId}`,
					query,
				});
			} else {
				this.$router.push({
					path: `/${currentLang}/jobs`,
					query,
				});
			}
		},
	},
};
</script>
