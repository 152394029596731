<template>
	<div>
		<v-card flat>
			<v-card-text>
				<v-container fluid>
					<v-row>
						<v-col>
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<ValidationProvider rules="" v-slot="{ errors }">
									<v-checkbox
										v-model="item.hide_header"
										:error-messages="errors"
										:label="$t('label.hide-header')"
										hide-details
										class="header-checkbox"
										color="secondary"
									></v-checkbox>
								</ValidationProvider>
							</fieldset>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<ValidationProvider rules="" v-slot="{ errors }">
									<v-checkbox
										v-model="item.hide_footer"
										:error-messages="errors"
										:label="$t('label.hide-footer')"
										hide-details
										class="header-checkbox"
										color="secondary"
									></v-checkbox>
								</ValidationProvider>
							</fieldset>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<fieldset class="talentics-fieldset talentics-fieldset-paddings">
								<legend>{{ $t('label.html') }}</legend>
								<div class="brace-editor-container">
									<BraceEditor v-model="item.custom_html"></BraceEditor>
								</div>
							</fieldset>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-text-field
									v-model="item.og_title"
									:label="$t('label.og_title')"
									outlined
									dense
									hide-details
									:error-messages="errors"
								></v-text-field>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-text-field
									v-model="item.og_description"
									:label="$t('label.og_description')"
									outlined
									dense
									hide-details
									:error-messages="errors"
								></v-text-field>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<CdnFilePicker
								v-model="item.og_image"
								:label="$t('label.og_image')"
							></CdnFilePicker>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import api from '@/api';
import { ValidationProvider } from 'vee-validate';
import BraceEditor from '@/components/shared/braceEditor/BraceEditor';
import CdnFilePicker from '@/components/shared/cdnFilePicker/CdnFilePicker';

export default {
	name: 'CustomCareerPage',
	props: {
		itemId: {
			type: [Number, String],
			required: true,
		},
	},
	components: {
		ValidationProvider,
		BraceEditor,
		CdnFilePicker,
	},
	data() {
		return {
			item: {
				hide_header: false,
				hider_footer: false,
				custom_html: '',
				og_title: '',
				og_description: '',
				og_image: null,
			},
		};
	},
	methods: {
		exportItem() {
			return this.item;
		},
	},
	created() {
		api.customCareerPages.get({ id: this.itemId }).then(response => {
			const { items } = response.data;

			if (items.length > 0) {
				this.item = items[0];
			}
		});
	},
};
</script>
