<template>
	<v-container>
		<div id="content">
			<img src="/talentics.png" style="max-width: 200px; margin-bottom: 32px" />
			<div id="box">
				<h4>Talentics is currently down for maintenance.</h4>
				<p>We will be back on air soon. Thanks for your patience.</p>
				<div id="airplane-icon">
					<img src="/maintenance.png" style="max-width: 48px" />
				</div>
				<p>Sincerely 👋</p>
				<p><strong>Kolay İK</strong> Team</p>
			</div>
		</div>
	</v-container>
</template>

<script>
export default {
	name: 'Maintenance',
};
</script>

<style lang="scss">
#content {
	width: 100%;
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	#box {
		width: 560px;
		margin: 0 auto;
		padding: 40px 32px;
		background-color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		#airplane-icon {
			width: 96px;
			height: 96px;
			background: #f0f2f5;
			border-radius: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 38px auto 48px auto;
		}

		p {
			margin: 0;
		}
	}
}
</style>
