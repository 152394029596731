var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","transition":"dialog-bottom-transition","value":_vm.isVisible,"max-width":"800"},on:{"click:outside":_vm.onClose,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onClose.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":`${_vm.isDark ? '' : 'grey lighten-3'}`,"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t(`tab.${_vm.candidateStatus}`))+" - "+_vm._s(_vm.$t(`pipeline.tab-steps.${_vm.pipelineStatusValue}`))+" ("+_vm._s(_vm.formatDate({ timestamp: _vm.date }))+")")]),_c('v-spacer'),(_vm.candidates.length > 0)?_c('div',{staticStyle:{"font-size":"0.75rem","color":"#5f6368","white-space":"nowrap","line-height":"36px"}},[_vm._v(" "+_vm._s(_vm.$t('text.candidates-length', { total: _vm.total, startIndex: _vm.startIndex, endIndex: _vm.endIndex, }))+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.page === 1},on:{"click":() =>
											_vm.handlePageChange({
												page: _vm.page - 1,
											})}},[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltip.newer'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":_vm.total <= _vm.page * 50},on:{"click":() =>
											_vm.handlePageChange({
												page: _vm.page + 1,
											})}},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1)],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltip.older'))+" ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"padding":"0"}},[(_vm.candidates.length === 0 && _vm.loading === true)?_c('div',[_c('v-simple-table',{staticClass:"candidates-table"},[_c('tbody',_vm._l(((0,
								1,
								2,
								3,
								4,
								5,
								6,
								7,
								8,
								9,
								10,
								11,
								12,
								13,
								14,
								15,
								16,
								17,
								18,
								19)),function(item,key){return _c('tr',{key:`placeholder_${key}`},[_c('td',[_c('v-progress-linear',{attrs:{"color":"grey lighten-2","height":"6","indeterminate":"","reverse":""}})],1)])}),0)])],1):(_vm.candidates.length === 0 && _vm.loading === false)?_c('div',{staticStyle:{"padding":"1rem"}},[_vm._v(" "+_vm._s(_vm.$t('err.no-candidates-found'))+" ")]):_c('div',[_c('v-simple-table',{staticClass:"candidates-table"},[_c('tbody',_vm._l((_vm.candidates),function(candidate,index){return _c('tr',{key:index,staticStyle:{"cursor":"pointer"}},[_c('td',{on:{"click":() =>
											_vm.handleCandidateClick({
												id: candidate.id,
											})}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(candidate.name))])])])}),0)])],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.$t('button.close')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }