<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex>
				<div style="padding: 0.5rem">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								icon
								:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
								@click="handleRefresh"
							>
								<v-icon>refresh</v-icon>
							</v-btn>
						</template>
						{{ $t('tooltip.refresh') }}
					</v-tooltip>
				</div>
				<v-divider></v-divider>
				<div>
					<div v-if="tags.length === 0" style="padding: 1rem">
						{{ $t('err.no-tags-found') }}
					</div>
					<v-list>
						<template v-for="(item, index) in tags">
							<v-list-item
								:key="item.created"
								@click="
									() =>
										addOrEditItem({
											id: item.id,
											operation: 'edit',
										})
								"
							>
								<v-list-item-avatar>
									<v-badge
										v-if="!item.addButton"
										bottom
										:color="item.active ? 'green' : 'grey'"
										dot
										offset-x="10"
										offset-y="10"
									>
										<v-icon>folder_open</v-icon>
									</v-badge>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title v-html="item.name"></v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-divider
								:key="index"
								v-if="index < tags.length - 1"
								inset
							></v-divider>
						</template>
					</v-list>
					<div style="padding: 1rem">
						<v-btn
							small
							@click="
								() =>
									addOrEditItem({
										operation: 'add',
									})
							"
						>
							<v-icon left>add</v-icon> {{ $t('button.add-tag') }}
						</v-btn>
					</div>
					<TagAttrs
						v-if="isTagsAttrsVisible"
						:method="tagActionMethod"
						:tag="tagToEdit"
						:onClose="handleTagAttrsClose"
					></TagAttrs>
				</div>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import TagAttrs from './tagsAttrs/TagsAttrs';

export default {
	name: 'Tags',
	components: {
		TagAttrs,
	},
	computed: {
		...mapState({
			tags: state => state.tags.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			isTagsAttrsVisible: false,
			tagActionMethod: 'edit',
			tagToEdit: {},
		};
	},
	methods: {
		addOrEditItem({ id, operation }) {
			if (operation === 'edit') {
				this.tagActionMethod = 'edit';
				this.tagToEdit = this.tags.find(item => item.id === id);
			} else if (operation === 'add') {
				this.tagActionMethod = 'add';
				this.tagToEdit = {
					active: true,
					name: '',
				};
			}
			this.isTagsAttrsVisible = true;
		},
		handleTagAttrsClose() {
			this.isTagsAttrsVisible = false;
		},
		handleRefresh() {
			this.$store.dispatch('tags/get', {
				updateStore: true,
				refresh: true,
			});
		},
	},
	created() {
		this.$store.dispatch('tags/get', { updateStore: true });
	},
};
</script>
