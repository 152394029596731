<template>
	<v-chip
		pill
		:color="chipColor"
		:style="`cursor: ${cursor}; margin-right: .5rem;`"
		@click="onClick({ approval_request_id: approvalRequestId })"
	>
		<v-avatar left v-if="chip && chip.picture">
			<v-img :src="chip.picture"></v-img>
		</v-avatar>
		<span v-if="chip && chip.name">{{ chip.name }}</span>
		<span v-if="approvalRequest" style="margin-left: 0.25rem"
			>({{ $t(`status.${approvalRequest.status}`) }})</span
		>
	</v-chip>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'ApprovalFlowUserChip',
	computed: {
		...mapState({
			users: state => state.users.items,
		}),
		chip() {
			return this.users.find(
				user => parseInt(this.userId, 10) === parseInt(user.id, 10),
			);
		},
		cursor() {
			return this.approvalRequest ? 'pointer' : 'default';
		},
		chipColor() {
			const { approvalRequest } = this;
			if (approvalRequest) {
				const { status } = approvalRequest;
				if (status === 'approved') {
					return 'success';
				} else if (status === 'rejected') {
					return 'error';
				} else if (status === 'pending') {
					return 'warning';
				}
				return 'default';
			}
			return 'default';
		},
	},
	props: {
		userId: {
			type: [Number, String, Object],
			required: false,
		},
		status: {
			type: String,
			required: false,
			default() {
				return null;
			},
		},
		approvalRequestId: {
			type: [Number, String],
			required: false,
			default() {
				return null;
			},
		},
		onClick: {
			type: Function,
			required: false,
			default() {
				return () => {};
			},
		},
		approvalRequest: {
			type: Object,
			required: false,
		},
	},
};
</script>
