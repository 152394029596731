<template>
	<div>
		<v-dialog
			:value="true"
			max-width="400"
			@click:outside="onClose"
			@keydown.esc="onClose"
		>
			<v-card>
				<v-card-title class="pa-0" v-if="title">
					<v-toolbar color="primary" dark flat>
						<v-toolbar-title>{{ title }}</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-card-text>
					<slot></slot>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="onClose">{{
						$t(`button.${closeText}`)
					}}</v-btn>
					<v-btn :color="primaryActionColor" @click="onConfirm">{{
						$t(`button.${confirmText}`)
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'Confirm',
	props: {
		onConfirm: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		closeText: {
			type: String,
			required: false,
			default() {
				return 'close';
			},
		},
		confirmText: {
			type: String,
			required: false,
			default() {
				return 'confirm';
			},
		},
		title: {
			type: String,
			required: false,
		},
		primaryActionColor: {
			type: String,
			required: false,
			default() {
				return 'primary';
			},
		},
	},
};
</script>
