const state = {
	items: [],
};

const getters = {};

const actions = {};

const mutations = {
	setItems: (state, { items }) => {
		state.items = items;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
