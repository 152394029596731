<template>
	<fieldset class="talentics-fieldset">
		<legend>{{ label }}</legend>
		<v-container style="padding-top: 0">
			<Draggable
				v-model="linkQuestions"
				@start="handleDragStart"
				@end="handleDragEnd"
			>
				<div
					v-for="(item, index) in linkQuestions"
					:key="`linkQuestion_${index}`"
				>
					<v-row>
						<v-col cols="auto" align-self="center">
							<span class="all-scroll">
								<v-icon>drag_indicator</v-icon>
							</span>
						</v-col>
						<v-col align-self="center">
							{{ item.label }}
						</v-col>

						<v-col class="disp-flex flex-end" cols="auto" align-self="center">
							<v-btn
								text
								icon
								:disabled="userRole < 666"
								@click="() => handleEditClick({ index })"
							>
								<v-icon>edit</v-icon>
							</v-btn>
							<v-btn
								text
								icon
								:disabled="userRole < 666"
								@click="
									() =>
										handleEditLinkQuestion({
											index,
											field: 'required',
										})
								"
							>
								<v-icon>{{ item.required ? 'star' : 'star_outline' }}</v-icon>
							</v-btn>
							<v-btn
								text
								icon
								:disabled="userRole < 666"
								@click="
									() =>
										handleEditLinkQuestion({
											index,
											field: 'sensitive',
										})
								"
							>
								<v-icon>{{
									item.sensitive
										? 'mdi-shield-check'
										: 'mdi-shield-check-outline'
								}}</v-icon>
							</v-btn>
							<v-btn
								text
								icon
								:disabled="userRole < 666"
								@click="() => handleDeleteLinkQuestion({ index })"
							>
								<v-icon>delete</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<v-divider></v-divider>
				</div>
			</Draggable>
			<v-row
				:style="`margin-top: ${linkQuestions.length > 0 ? '.25rem' : '0'};`"
			>
				<v-col>
					<v-btn @click="handleAddNewLinkQuestion">
						<v-icon left>add</v-icon>
						{{ $t('button.add') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
		<AdditionalApplicationFormQuestion
			v-if="isAdditionalApplicationFormQuestionVisible"
			:item="questionToEdit"
			:onClose="handleAdditionalApplicationFormQuestionClose"
			:onUpdate="handleAdditionalApplicationFormQuestionUpdate"
		></AdditionalApplicationFormQuestion>
	</fieldset>
</template>

<script>
import { mapState } from 'vuex';
import Draggable from 'vuedraggable';
import AdditionalApplicationFormQuestion from './additionalApplicationFormQuestion/AdditionalApplicationFormQuestion';

export default {
	name: 'AdditionalApplicationFormQuestions',
	components: {
		Draggable,
		AdditionalApplicationFormQuestion,
	},
	props: {
		value: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		label: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
	},
	computed: {
		...mapState({
			userRole: state => state.currentUser.userRole,
		}),
	},
	data() {
		return {
			newLinkQuestion: null,
			linkQuestions: [],
			drag: false,
			isAdditionalApplicationFormQuestionVisible: false,
			questionToEdit: null,
			questionIndexToEdit: null,
			questionMethod: 'post',
		};
	},
	methods: {
		handleAddNewLinkQuestion() {
			this.questionMethod = 'post';
			this.questionToEdit = {
				propName: null,
				type: 'text',
				label: '',
				options: [],
				required: false,
				sensitive: false,
			};
			this.questionIndexToEdit = null;
			this.isAdditionalApplicationFormQuestionVisible = true;
		},
		handleDeleteLinkQuestion({ index }) {
			const linkQuestions = this.linkQuestions.filter(
				(item, itemIndex) => itemIndex !== index,
			);
			this.$emit('input', linkQuestions);
		},
		handleEditLinkQuestion({ index, field }) {
			this.linkQuestions[index][field] = !this.linkQuestions[index][field];
			this.$emit('input', [...this.linkQuestions]);
		},
		handleDragStart() {
			this.drag = true;
		},
		handleDragEnd() {
			this.drag = false;
			this.$emit('input', this.linkQuestions);
		},
		handleEditClick({ index }) {
			this.questionMethod = 'put';
			this.questionToEdit = JSON.parse(
				JSON.stringify(this.linkQuestions[index]),
			);
			this.questionIndexToEdit = index;
			this.isAdditionalApplicationFormQuestionVisible = true;
		},
		handleAdditionalApplicationFormQuestionClose() {
			this.isAdditionalApplicationFormQuestionVisible = false;
		},
		handleAdditionalApplicationFormQuestionUpdate(newVal) {
			const { questionMethod } = this;

			if (questionMethod === 'put') {
				const { questionIndexToEdit } = this;
				this.linkQuestions = this.linkQuestions.map((item, index) => {
					return index === questionIndexToEdit ? newVal : item;
				});
				this.isAdditionalApplicationFormQuestionVisible = false;
			} else if (questionMethod === 'post') {
				this.linkQuestions = [...this.linkQuestions, newVal];
				this.isAdditionalApplicationFormQuestionVisible = false;
			}

			this.$emit('input', this.linkQuestions);
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					this.linkQuestions = JSON.parse(JSON.stringify(newVal));
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
