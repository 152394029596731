<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="600"
			@click:outside="onClose"
			@keydown.esc="onClose"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>
							<span>{{ $t('text.send-email') }}</span>
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<ValidationObserver
						ref="form_send_bulk_emails"
						tag="form"
						@submit="handleSendBulkEmails"
						id="form_send_bulk_emails"
					>
						<v-container fluid>
							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="emailTemplateId"
											:items="emailTemplates.filter(item => item.active)"
											:item-text="item => item.label"
											item-value="id"
											:label="$t('label.template')"
											outlined
											hide-details
											dense
											:disabled="previewMode"
											@change="
												id =>
													handleEmailTemplateChange({
														id,
													})
											"
											:error-messages="errors"
										></v-select>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row v-if="emails.length > 0 && emailTemplateId">
								<v-col class="d-flex" align-self="center">
									<v-spacer></v-spacer>
									<span class="mx-3">{{
										$t('text.email-counts', {
											index: emailIndex + 1,
											counts: emails.length,
										})
									}}</span>
								</v-col>
								<v-col cols="auto">
									<v-btn
										@click="emailIndex -= 1"
										:disabled="emailIndex === 0"
										small
									>
										<v-icon left>keyboard_arrow_left</v-icon>
										{{ $t('button.prev') }}
									</v-btn>
								</v-col>
								<v-col cols="auto">
									<v-btn
										@click="emailIndex += 1"
										:disabled="emailIndex === emails.length - 1"
										small
									>
										{{ $t('button.next') }}
										<v-icon right>keyboard_arrow_right</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-container>

						<v-divider></v-divider>

						<ScheduleEmailForm
							v-if="emails.length > 0 && emailTemplateId"
							:emailForm="emails[emailIndex]"
							:completedEmailForm="{
								template: emails[emailIndex].templateReplaced,
								subject: emails[emailIndex].subjectReplaced,
							}"
							:candidateEmails="emails[emailIndex].send_to_array"
							:previewMode="previewMode"
							@change-form="handleChangeScheduleEmailForm"
							:onNewAttachmentAdded="handleNewAttachmentAdded"
							:onAttachmentDeleteClick="handleAttachmentDeleteClick"
						></ScheduleEmailForm>
					</ValidationObserver>
				</v-card-text>

				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						style="margin-right: 0.5rem"
						text
						v-if="previewMode"
						@click="goToEditingMode"
					>
						{{ $t('button.go-back') }}
					</v-btn>

					<v-btn
						v-if="emailTemplateId && !previewMode"
						@click="goToPreviewMode"
						color="primary"
					>
						<v-icon style="margin-right: 0.25rem">visibility</v-icon>
						{{ $t('button.preview') }}
					</v-btn>

					<v-btn
						v-if="previewMode"
						@click="handleSendBulkEmails"
						color="primary"
					>
						<v-icon left>send</v-icon>
						{{ $t('button.send') }} {{ `(${emails.length})` }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';
import ScheduleEmailForm from '@/components/shared/scheduleEmailForm/ScheduleEmailForm';
import { getCandidateLastName, getCandidateFirstName } from '../../../../utils/helpers';

export default {
	name: 'ScheduleBulkEmail',
	components: {
		ValidationObserver,
		ValidationProvider,
		ScheduleEmailForm,
	},
	props: {
		candidates: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	computed: {
		...mapState({
			emailTemplates: state => state.emailTemplates.items,
			sendTimes: state => state.emailTemplates.sendTimes,
			company: state => state.company.company,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			isVisible: true,
			previewMode: false,
			emailIndex: 0,
			emailTemplateId: null,
			emails: [],
		};
	},
	methods: {
		handleSendBulkEmails() {
			const { emailTemplateId } = this;
			const items = this.emails.map(
				({
					candidate_id,
					subjectReplaced,
					templateReplaced,
					sent_from,
					send_to,
					cc,
					bcc,
					attachments,
					send_at,
					is_sent,
					is_cancelled,
				}) => ({
					application_id: null,
					candidate_id,
					subject: subjectReplaced,
					template: templateReplaced,
					sent_from,
					send_to: [send_to],
					cc,
					bcc,
					attachments,
					send_at,
					is_sent,
					is_cancelled,
					email_template_id: emailTemplateId,
				}),
			);

			this.$store.dispatch('emails/post', { items }).then(() => {
				this.onClose();
			});
		},
		handleGetSendAt(newVal) {
			if (newVal) {
				const { sendTimes } = this;
				const sendTime = sendTimes.find(item => item.value === newVal);
				const { days } = sendTime;
				const send_at = new Date();
				send_at.setDate(send_at.getDate() + days);
				return send_at;
			}
		},
		handleEmailTemplateChange({ id }) {
			this.$store
				.dispatch('emailTemplates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						const {
							sent_from,
							cc,
							bcc,
							default_schedule,
							subject,
							template,
							attachments,
							attachment_files,
						} = items[0];
						const { candidates } = this;
						this.emails = candidates.map(candidate => {
							const send_at = this.handleGetSendAt(default_schedule);
							return {
								id: candidate.id,
								sent_from,
								cc,
								bcc,
								send_time: default_schedule,
								send_at,
								send_to:
									candidate.email && candidate.email.length > 0
										? candidate.email[0]
										: null,
								send_to_array:
									candidate.email && candidate.email.length > 0
										? candidate.email
										: [],
								subject,
								template,
								attachments,
								attachment_files,
								is_sent: false,
								is_cancelled: false,
								candidate_id: candidate.id,
							};
						});
					}
				});
		},
		goToEditingMode() {
			this.previewMode = false;
		},
		goToPreviewMode(evt) {
			const isValid = this.getFormIsValid(evt);
			if (isValid) {
				const candidateRegex = /({{\s+|{{)(candidate)(\s+}}|}})/gi;
				const candidateFirstNameRegex =
					/({{\s+|{{)(candidateFirstName)(\s+}}|}})/gi;
				const candidateLastNameRegex = 
					/({{\s+|{{)(candidateLastName)(\s+}}|}})/gi;
				const companyRegex = /({{\s+|{{)(company)(\s+}}|}})/gi;
				
				const { company } = this;
				
				this.emails = this.emails.map(email => {
					const { subject, template, candidate_id } = email;
					const candidate = this.candidates.find(
						item => item.id === candidate_id,
					);
					return {
						...email,
						subjectReplaced: subject
							.replace(candidateRegex, candidate.name)
							.replace(
								candidateFirstNameRegex,
								getCandidateFirstName({ name: candidate.name }),
							)
							.replace(
								candidateLastNameRegex, 
								getCandidateLastName({ name: candidate.name }),
							)
							.replace(companyRegex, company.name),
						templateReplaced: template
							.replace(candidateRegex, candidate.name)
							.replace(
								candidateFirstNameRegex,
								getCandidateFirstName({ name: candidate.name }),
							)
							.replace(
								candidateLastNameRegex, 
								getCandidateLastName({ name: candidate.name }),
							)
							.replace(companyRegex, company.name),
					};
				});

				this.previewMode = true;
			}
		},
		async handleNewAttachmentAdded({ newFiles }) {
			const { emails, emailIndex } = this;
			this.emails = emails.map((emailItem, emailItemIndex) =>
				emailItemIndex === emailIndex
					? {
							...emailItem,
							attachments: [
								...emailItem.attachments,
								...newFiles.map(({ id }) => id),
							],
							attachment_files: [...emailItem.attachment_files, ...newFiles],
					  }
					: emailItem,
			);
		},
		handleAttachmentDeleteClick({ index }) {
			const { emails, emailIndex } = this;
			this.emails = emails.map((emailItem, emailItemIndex) =>
				emailItemIndex === emailIndex
					? {
							...emailItem,
							attachments: emailItem.attachments.filter(
								(item, itemIndex) => index !== itemIndex,
							),
							attachment_files: emailItem.attachment_files.filter(
								(item, itemIndex) => index !== itemIndex,
							),
					  }
					: emailItem,
			);
		},
		handleChangeScheduleEmailForm(newVal) {
			const { emails, emailIndex } = this;
			this.emails = emails.map((item, index) =>
				index === emailIndex ? { ...item, ...newVal } : item,
			);
		},
		handleGoToPreviousStep() {
			this.previewMode = false;
		},
		async getFormIsValid(evt) {
			if (evt) {
				evt.preventDefault();
			}
			return await this.$refs.form_send_bulk_emails.validate();
		},
	},
	created() {
		this.$store.dispatch('emailTemplates/get', { updateStore: true });
	},
};
</script>
