<template>
	<v-menu
		v-model="menu"
		:close-on-content-click="false"
		:close-on-click="closeOnClick"
		offset-y
		:nudge-width="300"
		:max-height="500"
		:max-width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				v-bind="attrs"
				v-on="on"
				small
				style="margin-left: 0.5rem"
				:disabled="!feedbacks.length"
			>
				<v-icon left>email</v-icon>
				{{ $t('button.share-feedbacks-via-email') }}
			</v-btn>
		</template>

		<v-card>
			<ValidationObserver
				ref="form_share_feedback"
				tag="form"
				@submit="handleShare"
				id="form_share_feedback"
			>
				<v-container fluid>
					<v-row
						v-for="(feedback, index) in feedbacksEditable"
						:key="`feedback_${index}`"
					>
						<v-col>
							<div style="display: flex; align-items: center">
								<v-checkbox v-model="feedback.checked" dense></v-checkbox>
								<Avatar :userId="feedback.created_by"></Avatar>
								<div style="padding-left: 1rem">
									<h4 v-if="feedback.pipeline_status || feedback.job_id">
										<span v-if="feedback.pipeline_status"
											>{{ $t(`pipeline.steps.${feedback.pipeline_status}`) }}
											-
										</span>
										<span v-if="feedback.job_id">{{
											handleGetJobName({
												jobId: feedback.job_id,
											})
										}}</span>
									</h4>
									<strong
										v-if="feedback.overall_score === 4"
										style="color: #2e7d32"
										><v-icon style="color: #2e7d32">thumb_up</v-icon>
										{{ $t('text.strong-yes') }}</strong
									>
									<strong
										v-else-if="feedback.overall_score === 3"
										style="color: #66bb6a"
										><v-icon style="color: #66bb6a">thumb_up</v-icon>
										{{ $t('text.yes') }}</strong
									>
									<strong
										v-else-if="feedback.overall_score === 2"
										style="color: #f44336"
										><v-icon style="color: #f44336">thumb_down</v-icon>
										{{ $t('text.no') }}</strong
									>
									<strong
										v-else-if="feedback.overall_score === 1"
										style="color: #b71c1c"
										><v-icon style="color: #b71c1c">thumb_down</v-icon>
										{{ $t('text.strong-no') }}</strong
									>
									<div
										v-html="
											formatDate({
												timestamp: feedback.created,
											})
										"
									></div>
								</div>
							</div>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<UserPicker
								v-model="user_ids"
								:users="users.filter(item => item.active)"
								:label="$t('label.to')"
								multiple
								dense
								rules="required"
							></UserPicker>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<ValidationProvider rules="" v-slot="{ errors }">
								<v-textarea
									v-model="optional_message"
									:label="$t('label.optional-message')"
									outlined
									hide-details
									dense
									auto-grow
									:rows="2"
									:error-messages="errors"
								></v-textarea>
							</ValidationProvider>
						</v-col>
					</v-row>
				</v-container>
			</ValidationObserver>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn text @click="menu = false">
					{{ $t('button.cancel') }}
				</v-btn>
				<v-btn color="primary" @click="handleShare">
					{{ $t('button.share') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Avatar from '@/components/shared/avatar/Avatar';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import api from '@/api';

export default {
	name: 'ShareFeedbacksMenu',
	props: {
		feedbacks: {
			type: Array,
			required: true,
		},
		candidateName: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		candidateId: {
			type: [Number, String],
			required: false,
			default() {
				return null;
			},
		},
		onShare: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		ValidationProvider,
		ValidationObserver,
		Avatar,
		UserPicker,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			jobs: state => state.jobs.items,
			currentLang: state => state.currentUser.lang,
		}),
	},
	data() {
		return {
			format,
			menu: false,
			feedbacksEditable: [],
			send_to: [],
			optional_message: null,
			closeOnClick: true,
			user_ids: [],
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		async handleShare(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_share_feedback.validate();
			if (isValid) {
				const { user_ids, optional_message } = this;
				const feedback_ids = this.feedbacksEditable
					.filter(item => item.checked)
					.map(item => item.id);
				api.feedbacks.shareViaEmail
					.post({
						feedback_ids,
						user_ids,
						candidate_id: this.candidateId,
						optional_message,
					})
					.then(() => {
						this.menu = false;
					});
			}
		},
		handleGetJobName({ jobId }) {
			const { jobs } = this;
			const job = jobs.find(item => item.id === jobId);
			return job ? job.name : jobId;
		},
	},
	watch: {
		feedbacks: {
			handler: function (newVal) {
				if (newVal) {
					this.feedbacksEditable = newVal.map(item => ({
						...item,
						checked: true,
					}));
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
