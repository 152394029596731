var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-width":250},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('button.new-feedback'))+" ")],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('ValidationObserver',{ref:"form_new_feedback",attrs:{"tag":"form","id":"form_new_feedback"},on:{"submit":_vm.handleStart}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.applicationsWithJobNames,"item-text":item =>
										`${item.job_name} (${_vm.handleFormatDate({
											timestamp: item.created,
										})})`,"item-value":"id","label":_vm.$t('label.choose-job'),"outlined":"","hide-details":"","dense":"","error-messages":errors},on:{"change":newVal =>
										_vm.handleApplicationIdChange({
											applicationId: newVal,
										})},model:{value:(_vm.applicationId),callback:function ($$v) {_vm.applicationId=$$v},expression:"applicationId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.statuses,"item-text":item => {
										if (item.text) {
											return _vm.$t(`pipeline.steps.${item.text}`);
										}
										return item;
									},"item-value":"value","label":_vm.$t('label.status'),"outlined":"","hide-details":"","dense":"","error-messages":errors},model:{value:(_vm.pipelineStatus),callback:function ($$v) {_vm.pipelineStatus=$$v},expression:"pipelineStatus"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.feedbackTemplates.filter(item => item.active),"item-text":"label","item-value":"id","label":_vm.$t('label.choose-feedback-template'),"outlined":"","hide-details":"","dense":"","error-messages":errors},model:{value:(_vm.feedbackTemplateId),callback:function ($$v) {_vm.feedbackTemplateId=$$v},expression:"feedbackTemplateId"}})]}}])})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleStart}},[_vm._v(" "+_vm._s(_vm.$t('button.start'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }