var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldset',{class:`talentics-fieldset talentics-fieldset-paddings ${
			_vm.readOnly ? 'talentics-fieldset-disabled' : ''
		}`},[_c('legend',[_vm._v(_vm._s(_vm.$t('text.attachments')))]),(_vm.attachments.length > 0)?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.attachments),function(item,index){return [_c('v-list-item',{key:`attachment_${index}`},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("attachment")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.original_file_name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.content_type)+" - "+_vm._s(item.size)+" bytes")])],1),(!_vm.readOnly)?_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":() =>
											_vm.handleDownloadFile({
												id: item.id,
											})}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("download")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('button.download')))])],1),_c('v-list-item',{on:{"click":() =>
											_vm.handleDeleteFile({
												fileIndex: index,
											})}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('button.delete')))])],1)],1)],1)],1):_vm._e()],1),(index < _vm.attachments.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2):_vm._e(),(!_vm.readOnly)?_c('div',[_c('Dropzone',{ref:"attachmentsDropzoneContainer",attrs:{"id":"attachmentsDropzone","options":{
					maxFilesize: 20,
					maxFiles: 1,
				},"initialFiles":[],"onSuccess":(file, response) => _vm.handleDropzoneSuccess({ response }),"onRemove":file => _vm.handleDropzoneRemove({ file }),"use-custom-slot":"","customClassNames":"dropzone-button"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('v-btn',{on:{"click":_vm.handleClickAttachFiles}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("attach_email")]),_vm._v(" "+_vm._s(_vm.$t('button.attach-files'))+" ")],1)],1)])],1):_vm._e(),(_vm.readOnly && _vm.attachments.length === 0)?_c('div',[_vm._v("-")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }