<template>
	<v-list>
		<v-subheader>
			{{ $t('subheader.approvals') }}
			<v-spacer></v-spacer>
			<v-btn icon small @click="handleRefresh">
				<v-icon>refresh</v-icon>
			</v-btn>
			<RequestApprovalMenu
				:candidate="candidate"
				:onRequest="handleRequest"
			></RequestApprovalMenu>
		</v-subheader>
		<v-list-item v-for="(item, i) in approvals" :key="i" two-line>
			<v-list-item-icon>
				<v-tooltip top v-if="item.status === 'requested'">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on">pending</v-icon>
					</template>
					{{ $t('tooltip.pending') }}
				</v-tooltip>
				<v-tooltip top v-else-if="item.status === 'approved'">
					<template v-slot:activator="{ on }">
						<v-icon v-on="on">grading</v-icon>
					</template>
					{{ $t('tooltip.approved') }}
				</v-tooltip>
			</v-list-item-icon>
			<v-list-item-content>
				<v-list-item-title>{{
					getApprovalName({ template_id: item.template_id })
				}}</v-list-item-title>
				<v-list-item-subtitle>{{
					formatDate({ timestamp: item.created })
				}}</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-list-item disabled v-if="approvals.length === 0">
			<v-list-item-icon>
				<v-icon>not_interested</v-icon>
			</v-list-item-icon>
			<v-list-item-content>
				{{ $t('text.no-approval-requests-found') }}
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>
import api from '@/api';
import { mapState } from 'vuex';
import { format } from 'date-fns';
import RequestApprovalMenu from './requestApprovalMenu/RequestApprovalMenu';

export default {
	name: 'Approvals',
	props: {
		candidate: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
	},
	components: {
		RequestApprovalMenu,
	},
	computed: {
		...mapState({
			approvalTemplates: state => state.approvalTemplates.items,
		}),
	},
	data() {
		return {
			format,
			approvals: [],
		};
	},
	methods: {
		handleRequest({ template_id, status, candidate_id, email }) {
			api.approvals
				.post({
					items: [
						{
							template_id,
							status,
							candidate_id,
							email,
						},
					],
				})
				.then(() => {
					this.handleGetApprovals({ candidate_id });
				});
		},
		handleGetApprovals({ candidate_id }) {
			api.approvals.get({ candidate_id }).then(response => {
				const { items } = response.data;
				this.approvals = items;
			});
		},
		getApprovalName({ template_id }) {
			const { approvalTemplates } = this;
			const approvalTemplate = approvalTemplates.find(
				item => item.id === template_id,
			);
			return approvalTemplate ? approvalTemplate.label : template_id;
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleRefresh() {
			const { id } = this.candidate;
			this.handleGetApprovals({ candidate_id: id });
		},
	},
	created() {
		this.$store.dispatch('approvalTemplates/get', {
			updateStore: true,
			refresh: true,
		});
	},
	watch: {
		candidate: {
			handler: function (newVal, oldVal) {
				if (
					newVal &&
					newVal.id &&
					((oldVal && !oldVal.id) ||
						(oldVal && oldVal.id && newVal.id !== oldVal.id))
				) {
					this.handleGetApprovals({ candidate_id: newVal.id });
				}
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
