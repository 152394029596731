<template>
	<div style="max-width: 960px">
		<ValidationObserver
			ref="form_confirm_email"
			tag="form"
			@submit="handleSubmit"
			id="form_confirm_email"
		>
			<v-container fluid>
				<v-row>
					<v-col>
						<p>
							{{ $t('text.confirmation-email-description') }}
						</p>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>
								{{ $t('label.send-automatic-confirmation-email') }}
							</legend>
							<ValidationProvider rules="" v-slot="{ errors }">
								<v-switch
									v-model="email.confirmation_email_active"
									hide-details
									color="success"
									:error-messages="errors"
									class="v-input--template-switch"
								></v-switch>
							</ValidationProvider>
						</fieldset>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-select
								v-model="email.confirmation_email_layout_id"
								:label="$t('label.layout')"
								outlined
								:items="emailLayouts"
								item-text="label"
								item-value="id"
								hide-details
								dense
								:error-messages="errors"
							></v-select>
						</ValidationProvider>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<v-text-field
								v-if="viewMode === 'preview'"
								v-model="completedEmail.confirmation_email_subject"
								:label="$t('label.subject')"
								outlined
								dense
								hide-details
								:disabled="true"
								:error-messages="errors"
							></v-text-field>
							<v-text-field
								v-else
								v-model="email.confirmation_email_subject"
								:label="$t('label.subject')"
								outlined
								dense
								hide-details
								:error-messages="errors"
							></v-text-field>
						</ValidationProvider>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<RichEditor
							v-if="viewMode === 'preview'"
							v-model="completedEmail.confirmation_email_template"
							refName="completed_email_template"
							:disabled="true"
						></RichEditor>
						<RichEditor
							v-else
							v-model="email.confirmation_email_template"
							refName="email_template"
						></RichEditor>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<Attachments
							:attachments="email.confirmation_email_attachment_files"
							:onNewAttachmentAdded="handleNewAttachmentAdded"
							:onAttachmentDeleteClick="handleAttachmentDeleteClick"
						></Attachments>
					</v-col>
				</v-row>
				<v-row class="mt-4">
					<v-col>
						<v-btn color="primary" @click="handleSubmit">
							{{ $t('button.save') }}
						</v-btn>
						<span class="caption ml-2">
							{{ $t('text.affect-all-job-postings') }}
						</span>
					</v-col>

					<v-spacer></v-spacer>

					<v-col cols="3">
						<v-btn-toggle
							rounded
							dense
							v-model="viewMode"
							style="margin-right: 0.5rem"
						>
							<v-btn value="edit">
								<v-icon left>edit</v-icon>
								{{ $t('button.edit') }}
							</v-btn>
							<v-btn value="preview">
								<v-icon left>visibility</v-icon>
								{{ $t('button.preview') }}
							</v-btn>
						</v-btn-toggle>
					</v-col>
				</v-row>
			</v-container>
		</ValidationObserver>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RichEditor from '@/components/shared/richEditor/RichEditor';
import api from '@/api';
import Attachments from '@/components/shared/attachments/Attachments';

export default {
	name: 'ConfirmationEmail',
	computed: {
		...mapState({
			company: state => state.company.company,
			emailLayouts: state => state.emailLayouts.items,
		}),
	},
	components: {
		ValidationObserver,
		ValidationProvider,
		RichEditor,
		Attachments,
	},
	data() {
		return {
			viewMode: 'edit',
			email: {
				confirmation_email_subject: '',
				confirmation_email_template: '',
				confirmation_email_active: false,
				confirmation_email_layout_id: null,
				confirmation_email_attachments: [],
				confirmation_email_attachment_files: [],
			},
			candidateRegex: /({{\s+|{{)(candidate)(\s+}}|}})/gi,
			candidateFirstNameRegex: /({{\s+|{{)(candidateFirstName)(\s+}}|}})/gi,
			candidateLastNameRegex: /({{\s+|{{)(candidateLastName)(\s+}}|}})/gi,
			companyRegex: /({{\s+|{{)(company)(\s+}}|}})/gi,
			jobRegex: /({{\s+|{{)(job)(\s+}}|}})/gi,
			completedEmail: {
				confirmation_email_subject: '',
				confirmation_email_template: '',
			},
		};
	},
	methods: {
		handleConvertCompletedEmail() {
			const {
				company,
				candidateRegex,
				candidateFirstNameRegex,
				candidateLastNameRegex,
				companyRegex,
				jobRegex,
			} = this;
			const { confirmation_email_subject, confirmation_email_template } =
				this.email;

			this.completedEmail = {
				...this.email,
				confirmation_email_subject: confirmation_email_subject
					.replace(candidateRegex, 'John Smith')
					.replace(candidateFirstNameRegex, 'John')
					.replace(candidateLastNameRegex, 'Smith')
					.replace(companyRegex, company.name)
					.replace(jobRegex, 'Software Engineer'),
				confirmation_email_template: confirmation_email_template
					.replace(candidateRegex, 'John Smith')
					.replace(candidateFirstNameRegex, 'John')
					.replace(candidateLastNameRegex, 'Smith')
					.replace(companyRegex, company.name)
					.replace(jobRegex, 'Software Engineer'),
			};
		},
		handleGetConfirmationEmail() {
			api.company.confirmationEmail.get().then(response => {
				const { items } = response.data;
				if (items.length > 0) {
					const {
						confirmation_email_active,
						confirmation_email_subject,
						confirmation_email_template,
						confirmation_email_layout_id,
						confirmation_email_attachments,
						confirmation_email_attachment_files,
					} = items[0];

					this.email = {
						...this.email,
						confirmation_email_subject,
						confirmation_email_template,
						confirmation_email_active,
						confirmation_email_layout_id,
						confirmation_email_attachments,
						confirmation_email_attachment_files,
					};
				}
			});
		},
		async handleSubmit() {
			const isValid = await this.$refs.form_confirm_email.validate();
			if (isValid) {
				const { email } = this;
				api.company.confirmationEmail.put(email);
			}
		},
		handleNewAttachmentAdded({ newFiles = [] }) {
			this.email = {
				...this.email,
				confirmation_email_attachment_files: [
					...(this.email.confirmation_email_attachment_files || []),
					...newFiles,
				],
				confirmation_email_attachments: [
					...(this.email.confirmation_email_attachments || []),
					...newFiles.map(({ id }) => id),
				],
			};
		},
		handleAttachmentDeleteClick({ index }) {
			this.email.confirmation_email_attachments =
				this.email.confirmation_email_attachments.filter(
					(item, itemIndex) => index !== itemIndex,
				);
			this.email.confirmation_email_attachment_files =
				this.email.confirmation_email_attachment_files.filter(
					(item, itemIndex) => index !== itemIndex,
				);
			this.handleSubmit({ refresh: true });
		},
	},
	created() {
		this.$store.dispatch('emailLayouts/get', {
			updateStore: true,
			refresh: true,
		});
	},
	mounted() {
		this.handleGetConfirmationEmail();
	},
	watch: {
		viewMode: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal && newVal === 'preview') {
					this.handleConvertCompletedEmail();
				}
			},
			immediate: false,
		},
	},
};
</script>
