<template>
	<ValidationProvider :rules="rules" v-slot="{ errors }">
		<v-autocomplete
			:value="value"
			:items="users"
			item-text="name"
			item-value="id"
			hide-no-data
			hide-details
			outlined
			:label="label"
			@change="handleChange"
			:multiple="multiple"
			:chips="multiple"
			class="v-select-talentics-chips"
			:error-messages="errors"
			:dense="dense"
			:disabled="disabled"
			:menu-props="{
				closeOnClick: true,
				closeOnContentClick: true,
			}"
			:readonly="readonly"
		>
			<template v-slot:selection="data">
				<v-chip
					v-bind="data.attrs"
					:input-value="data.selected"
					:close="!data.item.disabled"
					@click:close="!readonly ? remove(data.item) : null"
					pill
				>
					<Avatar :userId="data.item.id" :left="true"></Avatar>
					<span
						v-html="
							`${data.item.name ? data.item.name : data.item.email}${
								data.item.active
									? ''
									: ` <span style='color: red;'>(${$t(
											'label.inactive-user',
									  )})</span>`
							}`
						"
					></span>
				</v-chip>
			</template>
			<template v-slot:item="data">
				<template>
					<v-list-item-avatar>
						<Avatar :userId="data.item.id" :left="true"></Avatar>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title
							v-html="
								`${data.item.name ? data.item.name : data.item.email}${
									data.item.active
										? ''
										: ` <span style='color: red;'>(${$t(
												'label.inactive-user',
										  )})</span>`
								}`
							"
						></v-list-item-title>
					</v-list-item-content>
				</template>
			</template>
		</v-autocomplete>
	</ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'UserPicker',
	props: {
		users: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		value: {
			type: [Number, Array],
			required: false,
			default() {
				return null;
			},
		},
		label: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		multiple: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		rules: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		readonly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		ValidationProvider,
		Avatar,
	},
	methods: {
		remove({ id }) {
			const { value, multiple } = this;
			const newVal = multiple ? value.filter(item => item !== id) : null;
			this.handleChange(newVal);
		},
		handleChange(newVal) {
			this.$emit('input', newVal);
		},
	},
};
</script>
