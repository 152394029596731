<template>
	<div>
		<v-list>
			<v-list-item>
				<v-btn
					@click="() => handleAddEditGroupMailClick('add')"
					class="mr-2 ml-2"
					v-if="userRole >= 666"
				>
					{{ $t('button.add-group-mail') }}
				</v-btn>
				<span class="ml-auto">{{ $t('label.consent') }}</span>
			</v-list-item>
			<template v-for="(item, index) in groupMail">
				<v-list-item
					:key="item.email_address"
					@click="() => handleAddEditGroupMailClick('edit', item.id)"
				>
					<v-list-item-avatar>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.is_active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>email</v-icon>
						</v-badge>
					</v-list-item-avatar>
					<v-list-item-content class="d-flex flex-row align-items-center">
						<v-list-item-title v-html="item.email_address"></v-list-item-title>
					</v-list-item-content>
					<v-list-item-action>
						<v-switch
							:disabled="!item.is_active"
							@click="
								$event => {
									$event.stopPropagation();
								}
							"
							@change="$event => onConsentSwitchboxChange($event, item)"
							:input-value="consentSwitchMap[item.id]"
							color="success"
							class="v-input--template-switch"
							hide-details
							inset
							dense
						></v-switch>
					</v-list-item-action>
				</v-list-item>

				<v-divider
					:key="index"
					v-if="index < groupMail.length - 1"
					inset
				></v-divider>
			</template>
		</v-list>
		<AddEditGroupMail
			v-if="isAddEditGroupMailModalVisible"
			:onClose="handleAddEditGroupMailModalClose"
			:method="groupMailMethod"
			:groupMailData="groupMailToEdit"
		></AddEditGroupMail>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import AddEditGroupMail from './AddEditGroupMail';

export default {
	name: 'groupMail',
	components: {
		AddEditGroupMail,
	},
	computed: {
		...mapState({
			groupMail: state => state.groupMail.items,
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
		}),
		calculateIsChecked() {
			return users => {
				const { email } = this.currentUser;
				const user = users.find(userEmail => userEmail === email);

				return user ? true : false;
			};
		},
	},
	data() {
		return {
			isAddEditGroupMailModalVisible: false,
			groupMailToEdit: {},
			groupMailMethod: 'add',
			consentSwitchMap: {},
		};
	},
	methods: {
		onConsentSwitchboxChange(checked, groupMailItem) {
			if (checked) {
				this.handleGetConsent(checked, groupMailItem);
			} else {
				this.handleRevokeConsent(checked, groupMailItem);
			}
		},
		handleGetConsent(_, groupMailItem) {
			const { email_address } = groupMailItem;
			const params = `service=shared_inbox&shared_inbox=${email_address}&id_token=${this.currentUser.id_token}`;

			window.location.replace(
				`${process.env.VUE_APP_HOST_API_KEY}v1/hire-app/ms-auth?${params}`,
			);
		},
		handleRevokeConsent(_, groupMailItem) {
			const { email_address } = groupMailItem;
			const { id: currentUserID } = this.currentUser;

			this.$store
				.dispatch('groupMail/revokeConsent', {
					email_address,
					currentUserID,
				})
				.then(() => {
					this.consentSwitchMap[groupMailItem.id] = false;
				})
				.catch(err => {
					console.error(err);
					this.$store.dispatch('groupMail/get', {
						updateStore: true,
					});
				});
		},
		handleAddEditGroupMailModalClose() {
			this.isAddEditGroupMailModalVisible = false;
		},
		handleAddEditGroupMailClick(method, id) {
			if (this.userRole < 666) return;

			this.groupMailMethod = method;
			this.isAddEditGroupMailModalVisible = true;

			if (method === 'add') {
				this.groupMailToEdit = {};
			} else if (method === 'edit') {
				this.groupMailToEdit = this.groupMail.find(item => item.id === id);
			}
		},
	},
	watch: {
		groupMail: {
			handler: function (val) {
				if (val && val.length > 0) {
					val.forEach(item => {
						this.$set(
							this.consentSwitchMap,
							item.id,
							this.calculateIsChecked(item.users),
						);
					});
				}
			},
			deep: true,
			immediate: true,
		},
	},
	created() {
		this.$store.dispatch('groupMail/get', { updateStore: true });
	},
};
</script>
