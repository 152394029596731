<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" sm="12" md="12">
					<h5 class="text-center">
						{{ $t('text.interviews-grouped-by-job') }} ({{
							chartDataJobCounts.total_count
						}})
					</h5>
					<ChartDoughnut :chartdata="chartDataJobCounts"></ChartDoughnut>
				</v-col>
			</v-row>
			<v-row>
				<v-col><v-divider></v-divider></v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12" md="12">
					<h5 class="text-center">
						{{ $t('text.interviews-grouped-by-interviewer') }} ({{
							chartDataInterviewerCounts.total_count
						}})
					</h5>
					<ChartDoughnut
						:chartdata="chartDataInterviewerCounts"
					></ChartDoughnut>
				</v-col>
			</v-row>
			<v-row>
				<v-col><v-divider></v-divider></v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12" md="12">
					<h5 class="text-center">
						{{ $t('text.interviews-grouped-by-score') }} ({{
							chartDataScoreCounts.total_count
						}})
					</h5>
					<ChartDoughnut :chartdata="chartDataScoreCounts"></ChartDoughnut>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
// import ChartBar from '@/components/shared/chartBar/ChartBar';
import ChartDoughnut from '@/components/shared/chartDoughnut/ChartDoughnut';
import api from '@/api';

export default {
	name: 'Interviewers',
	components: {
		ChartDoughnut,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
		}),
	},
	props: {
		filters: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			chartDataJobCounts: {
				labels: [],
				datasets: [],
				total_count: 0,
			},
			chartDataScoreCounts: {
				labels: [],
				datasets: [],
				total_count: 0,
			},
			chartDataInterviewerCounts: {
				labels: [],
				datasets: [],
				total_count: 0,
			},
		};
	},
	methods: {
		handleGetChartDataJobCounts() {
			const { query } = this.$route;
			api.reports.interviewers.jobCounts.get({ ...query }).then(response => {
				const { labels, datasets, total } = response.data;
				this.chartDataJobCounts = {
					labels: labels,
					datasets,
					total_count: total,
				};
			});
		},
		handleGetChartDataScoreCounts() {
			const { query } = this.$route;
			api.reports.interviewers.scoreCounts.get({ ...query }).then(response => {
				const { labels, datasets, total } = response.data;
				this.chartDataScoreCounts = {
					labels: labels.map(label => this.$t(`text.${label}`)),
					datasets,
					total_count: total,
				};
			});
		},
		handleGetChartDataInterviewerCounts() {
			const { query } = this.$route;
			api.reports.interviewers.interviewerCounts
				.get({ ...query })
				.then(response => {
					const { labels, datasets, total } = response.data;
					this.chartDataInterviewerCounts = {
						labels,
						datasets,
						total_count: total,
					};
				});
		},
		handleRefresh() {
			this.handleGetChartDataJobCounts();
			this.handleGetChartDataScoreCounts();
			this.handleGetChartDataInterviewerCounts();
		},
	},
	watch: {
		'$route.query': {
			handler: function (newVal, oldVal) {
				if (
					oldVal === undefined ||
					newVal.job_id !== oldVal.job_id ||
					newVal.interviewer !== oldVal.interviewer ||
					newVal.min_date !== oldVal.min_date ||
					newVal.max_date !== oldVal.max_date
				) {
					const { min_date, max_date } = newVal;
					if (!min_date || !max_date) {
						const { query } = this.$route;
						const { currentLang } = this;
						this.$router.push({
							path: `/${currentLang}/reports/at-a-glance`,
							query: {
								...query,
								min_date: this.filters.min_date,
								max_date: this.filters.max_date,
							},
						});
					} else {
						this.handleGetChartDataJobCounts();
						this.handleGetChartDataScoreCounts();
						this.handleGetChartDataInterviewerCounts();
					}
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
