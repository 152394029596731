<template>
	<v-navigation-drawer
		:value="drawer"
		:clipped="$vuetify.breakpoint.lgAndUp"
		app
		:color="`${isDark ? '#121212' : 'white'}`"
	>
		<v-list dense>
			<v-list-item
				v-for="(item, index) in items.filter(item => {
					// Check user role first
					if (userRole < item.minRole) {
						return false;
					}

					// Check additional permissions if the role is not sufficient
					if (item.additionalPermissions.length > 0) {
						const permissions = item.additionalPermissions
							.map(permission => currentUser[permission])
							.filter(permission => permission === true);

						if (permissions.length > 0) {
							return true;
						}
					}

					return true;
				})"
				v-bind:key="index"
				:to="`/${lang}/${item.route}`"
			>
				<v-list-item-content>
					<v-list-item-title>{{
						$t(`sidenav-menu.${item.name}`)
					}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		isOpened: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	computed: {
		...mapState({
			lang: state => state.currentUser.lang,
			drawer: state => state.currentRoute.drawer,
			isDark: state => state.currentUser.isDark,
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
		}),
	},
	data() {
		return {
			items: [
				{
					name: 'my-account',
					route: 'settings/my-account',
					minRole: 333,
					additionalPermissions: [],
				},
				{
					name: 'users',
					route: 'settings/users',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'company',
					route: 'settings/company',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'e-mail-layouts',
					route: 'settings/email-layouts',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'e-mail-templates',
					route: 'settings/email-templates',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'instant-booking',
					route: 'settings/instant-booking',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'feedback-templates',
					route: 'settings/feedback-templates',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'scorecard-templates',
					route: 'settings/scorecard-templates',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'requisition-form-templates',
					route: 'settings/requisition-form-templates',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'requisition-approval-flow-templates',
					route: 'settings/requisition-approval-flow-templates',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'form-templates',
					route: 'settings/form-templates',
					minRole: 666,
					additionalPermissions: [],
				},
				{
					name: 'approval-templates',
					route: 'settings/approval-templates',
					minRole: 666,
					additionalPermissions: [],
				},
				// {
				//   name: 'offer-templates',
				//   route: 'settings/offer-templates',
				//   minRole: 777,
				//   additionalPermissions: [],
				// },
				{
					name: 'rulesets',
					route: 'settings/rulesets',
					minRole: 777,
					additionalPermissions: [],
				},
				{
					name: 'tags',
					route: 'settings/tags',
					minRole: 666,
					additionalPermissions: [],
				},
				// {
				//   name: 'Forms',
				//   route: 'settings',
				//   minRole: 555,
				// },
				{
					name: 'job-site',
					route: 'settings/job-site',
					minRole: 666,
					additionalPermissions: ['can_edit_career_pages'],
				},
				{
					name: 'resource-usage',
					route: 'settings/resource-usage',
					minRole: 777,
					additionalPermissions: [],
				},
				// {
				//   name: 'Sources and Tags',
				//   route: 'settings',
				//   minRole: 555,
				// },
				// {
				//   name: 'Integrations',
				//   route: 'settings',
				//   minRole: 555,
				// },
				// {
				//   name: 'Offers',
				//   route: 'settings',
				//   minRole: 555,
				// },
				// {
				//   name: 'Requisitions',
				//   route: 'settings',
				//   minRole: 555,
				// },
				// {
				//   name: 'Approvals',
				//   route: 'settings',
				//   minRole: 555,
				// },
			],
		};
	},
};
</script>
