<template>
	<div>
		<div class="sector-item-wrapper">
			<v-alert
				style="cursor: pointer; margin-bottom: 0; padding-left: 60px"
				@click="() => handleJobClick({ id: job.id })"
				@mouseenter="handleMouseEnter"
				@mouseleave="handleMouseLeave"
				:color="hover ? 'primary' : ''"
				:text="hover"
				tile
				dense
			>
				<template v-slot:prepend>
					<v-icon :color="job.status === 'open' ? 'green' : 'grey'"
						>folder</v-icon
					>
				</template>
				<v-container>
					<v-row>
						<v-col align-self="center">
							<div>{{ job.name }}</div>
							<div style="color: grey; font-size: 12px">
								{{
									getLocationName({
										locationId: job.location,
									})
								}}
								-
								{{
									getWorkTypeName({
										workTypeId: job.work_type,
									})
								}}<br />
								{{ organizationName }} / {{ departmentName }} /
								{{ teamName }}
							</div>
						</v-col>
						<v-col
							cols="auto"
							align-self="center"
							v-if="hover && job.status !== 'archived'"
						>
							<v-btn small @click="handleArchiveJob">
								<v-icon left>archive</v-icon>
								{{ $t('button.archive') }}
							</v-btn>
						</v-col>
						<v-col cols="auto" align-self="center">
							<span style="margin-right: 0.5rem"
								><Avatar
									:userId="job.hiring_manager"
									tooltipAppend="Hiring Manager: "
								></Avatar
							></span>
							<span
								><Avatar
									:userId="job.owner"
									tooltipAppend="Posting Owner: "
								></Avatar
							></span>
						</v-col>
					</v-row>
				</v-container>
			</v-alert>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'JobsTreeTeamItem',
	props: {
		job: {
			type: Object,
		},
		jobs: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		organizationName: {
			type: String,
			required: true,
		},
		departmentName: {
			type: String,
			required: true,
		},
		teamName: {
			type: String,
			required: true,
		},
		onArchiveJob: {
			type: Function,
			required: true,
		},
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			locations: state => state.locations.items,
			workTypes: state => state.workTypes.items,
		}),
	},
	components: {
		Avatar,
	},
	data() {
		return {
			hover: false,
		};
	},
	methods: {
		getLocationName({ locationId }) {
			const { locations } = this;
			const location = locations.find(item => item.id === locationId);
			return location ? location.name : '';
		},
		getWorkTypeName({ workTypeId }) {
			const { workTypes } = this;
			const workType = workTypes.find(item => item.id === workTypeId);
			return workType ? workType.name : '';
		},
		handleJobClick({ id }) {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/jobs/edit/${id}`,
				query,
			});
		},
		handleMouseEnter() {
			this.hover = true;
		},
		handleMouseLeave() {
			this.hover = false;
		},
		handleArchiveJob(evt) {
			evt.preventDefault();
			evt.stopPropagation();
			const { job } = this;
			this.onArchiveJob({ id: job.id, name: job.name });
		},
	},
	watch: {
		job: {
			handler() {
				this.$emit('newchild');
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
