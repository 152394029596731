<template>
	<v-card elevation="0" style="border: 1px solid #9e9e9e; border-radius: 4px">
		<v-card-title class="pa-0">
			<v-toolbar
				:color="`${isDark ? 'grey darken-3' : 'grey'}`"
				dark
				flat
				dense
				@click="() => handleToggleUnfold()"
				style="cursor: pointer"
			>
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" icon>
							<v-icon v-if="!unfolded">unfold_more</v-icon>
							<v-icon v-else>unfold_less</v-icon>
						</v-btn>
					</template>
					<span v-if="!unfolded">{{ $t('button.unfold') }}</span>
					<span v-else>{{ $t('button.fold-up') }}</span>
				</v-tooltip>

				<v-toolbar-title>
					{{ label }}
				</v-toolbar-title>
			</v-toolbar>
		</v-card-title>
		<v-card-text style="padding: 0" v-if="unfolded">
			<slot></slot>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'CollapsibleCard',
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		label: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			unfolded: false,
		};
	},
	methods: {
		handleToggleUnfold() {
			this.unfolded = !this.unfolded;
		},
	},
};
</script>
