<template>
	<div>
		<v-list two-line v-if="comments.length > 0">
			<template v-for="(item, index) in comments">
				<template v-if="item.id === commentIdToEdit">
					<v-list-item :key="`edit_item_${item.created}`">
						<v-list-item-avatar>
							<Avatar :userId="item.sender_id"></Avatar>
						</v-list-item-avatar>
						<v-list-item-content>
							<div style="border: 1px solid grey; border-radius: 4px">
								<OneBox
									v-model="editComment"
									id="edit_candidate_comment"
									:users="users.filter(item => item.active)"
								></OneBox>
							</div>
							<div>
								<v-btn
									color="secondary"
									small
									@click="handleClickUpdateEditComment"
								>
									{{ $t('button.update') }}
								</v-btn>
								<v-btn text small @click="handleClickCancelEditComment">
									{{ $t('button.cancel') }}
								</v-btn>
							</div>
						</v-list-item-content>
					</v-list-item>
				</template>
				<template v-else>
					<v-list-item :key="`item_${item.created}`" class="comment-item">
						<v-list-item-avatar>
							<Avatar :userId="item.sender_id"></Avatar>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title
								v-html="item.message_text"
								style="white-space: normal"
							></v-list-item-title>
							<v-list-item-subtitle>
								<span>
									{{ formatDate({ timestamp: item.created }) }}
								</span>
								<span v-if="item.created !== item.updated"> - </span>
								<span v-if="item.created !== item.updated">
									{{ formatDate({ timestamp: item.updated }) }}
									{{ $t('text.edited-with-parentheses') }}
								</span>
							</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action
							v-if="item.sender_id === currentUser.id"
							class="comment-action-item"
						>
							<v-menu bottom left>
								<template v-slot:activator="{ on }">
									<v-btn icon small v-on="on">
										<v-icon>more_vert</v-icon>
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item
										@click="
											() =>
												handleEditComment({
													id: item.id,
												})
										"
									>
										<v-list-item-icon>
											<v-icon>edit</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{ $t('text.edit') }}</v-list-item-title>
									</v-list-item>
									<v-divider></v-divider>
									<v-list-item
										@click="
											() =>
												handleDeleteComment({
													id: item.id,
												})
										"
									>
										<v-list-item-icon>
											<v-icon>delete</v-icon>
										</v-list-item-icon>
										<v-list-item-title>{{
											$t('text.delete')
										}}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-list-item-action>
					</v-list-item>
				</template>

				<v-divider
					:key="index"
					v-if="index < comments.length - 1"
					inset
				></v-divider>
			</template>
		</v-list>
		<v-card elevation="0">
			<v-card-text style="padding: 0.5rem">
				<v-divider style="margin-bottom: 1rem"></v-divider>
				<div style="border: 1px solid grey; border-radius: 4px">
					<OneBox
						v-model="newComment"
						id="candidate_notes"
						:users="users.filter(item => item.active)"
					></OneBox>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="handleAddNote">
					{{ $t('button.add-comment') }}
				</v-btn>
			</v-card-actions>
		</v-card>
		<Confirm
			v-if="isDeleteCommentConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
			><div style="padding-top: 20px">
				{{ $t('text.confirm-deleting-comment') }}
			</div></Confirm
		>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import OneBox from '@/components/shared/oneBox/OneBox';
import Avatar from '@/components/shared/avatar/Avatar';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';
import api from '@/api';

export default {
	name: 'Notes',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		onSendNotesCount: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		OneBox,
		Avatar,
		Confirm,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			currentUser: state => state.currentUser.currentUser,
		}),
	},
	data() {
		return {
			format,
			editComment: '',
			newComment: '',
			comments: [],
			isDeleteCommentConfirmVisible: false,
			commentIdToDelete: null,
			commentIdToEdit: null,
		};
	},
	methods: {
		handleAddNote() {
			const { id, company_id } = this.candidate;
			const { newComment } = this;
			api.comments
				.post({
					items: [
						{
							candidate_id: id,
							company_id,
							message_text: newComment,
						},
					],
				})
				.then(() => {
					this.newComment = '';
					this.handleGetComments({ candidate_id: id });
				});
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleGetComments({ candidate_id, emptyArray = false }) {
			if (emptyArray) {
				this.comments = [];
				this.onSendNotesCount({ count: 0 });
			}
			api.comments.get({ candidate_id }).then(response => {
				const { items } = response.data;
				this.comments = items;
				this.onSendNotesCount({ count: items.length });
			});
		},
		handleEditComment({ id }) {
			const { comments } = this;
			const comment = comments.find(item => item.id === id);
			if (comment) {
				const { message_text } = comment;
				this.editComment = message_text;
				this.commentIdToEdit = id;
			}
		},
		handleDeleteComment({ id }) {
			this.commentIdToDelete = id;
			this.isDeleteCommentConfirmVisible = true;
		},
		handleConfirmDialogConfirm() {
			const { commentIdToDelete } = this;
			const { id } = this.candidate;
			api.comments.delete({ id: commentIdToDelete }).then(() => {
				this.handleGetComments({ candidate_id: id });
				this.handleConfirmDialogClose();
			});
		},
		handleConfirmDialogClose() {
			this.commentIdToDelete = null;
			this.isDeleteCommentConfirmVisible = false;
		},
		handleClickCancelEditComment() {
			this.editComment = '';
			this.commentIdToEdit = null;
		},
		handleClickUpdateEditComment() {
			const { id } = this.candidate;
			const { editComment, commentIdToEdit } = this;
			api.comments
				.put({
					items: [
						{
							id: commentIdToEdit,
							candidate_id: id,
							message_text: editComment,
						},
					],
				})
				.then(() => {
					this.editComment = '';
					this.commentIdToEdit = null;
					this.handleGetComments({ candidate_id: id });
				});
		},
	},
	watch: {
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.handleGetComments({
						candidate_id: newVal,
						emptyArray: true,
					});
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
span.user-mention {
	font-weight: bold;
}

.comment-action-item {
	display: none;
}

.comment-item:hover {
	.comment-action-item {
		display: block;
	}
}
</style>
