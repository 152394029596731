<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" md="8">
					<ChartBar :chartdata="chartDataGetHiredDurations"></ChartBar>
				</v-col>
				<v-col cols="12" md="4" align-self="center">
					<v-alert border="left" colored-border type="info" elevation="2">
						A hiree's average duration (In days) spent from the candidate's job
						application to marked as hired, grouped by jobs. Your company's
						general average is
						<strong>{{ averageGetHiredDays }} days</strong> (With selected
						filters).
					</v-alert>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="4" align-self="center">
					<v-alert border="left" colored-border type="info" elevation="2">
						A candidate's average duration (In days) spent on each interview
						pipeline stage.
					</v-alert>
				</v-col>
				<v-col cols="12" md="8">
					<ChartBar :chartdata="chartDataInterviewDurations"></ChartBar>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="8">
					<ChartBar :chartdata="chartDataLeadDurations"></ChartBar>
				</v-col>
				<v-col cols="12" md="4" align-self="center">
					<v-alert border="left" colored-border type="info" elevation="2">
						A candidate's average duration (In days) spent on each lead pipeline
						stage.
					</v-alert>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import api from '@/api';
import ChartBar from '@/components/shared/chartBar/ChartBar';

export default {
	name: 'AverageDurations',
	components: {
		ChartBar,
	},
	data() {
		return {
			chartDataGetHiredDurations: {
				labels: [],
				datasets: [],
			},
			chartDataInterviewDurations: {
				labels: [],
				datasets: [],
			},
			chartDataLeadDurations: {
				labels: [],
				datasets: [],
			},
			averageGetHiredDays: 0,
		};
	},
	methods: {
		handleGetChartDataGetHiredDurations() {
			const { query } = this.$route;
			api.reports.durations.getHired.get({ ...query }).then(response => {
				const { labels, datasets, average_duration } = response.data;
				this.chartDataGetHiredDurations = {
					labels,
					datasets,
				};
				this.averageGetHiredDays = average_duration;
			});
		},
		handleGetChartDataInterviewDurations() {
			const { query } = this.$route;
			api.reports.durations.interview.get({ ...query }).then(response => {
				const { labels, datasets } = response.data;
				this.chartDataInterviewDurations = {
					labels: labels.map(label => this.$t(`pipeline.steps.${label}`)),
					datasets,
				};
			});
		},
		handleGetChartDataLeadDurations() {
			const { query } = this.$route;
			api.reports.durations.lead.get({ ...query }).then(response => {
				const { labels, datasets } = response.data;
				this.chartDataLeadDurations = {
					labels: labels.map(label => this.$t(`pipeline.steps.${label}`)),
					datasets,
				};
			});
		},
		handleRefresh() {
			this.handleGetChartDataGetHiredDurations();
			this.handleGetChartDataInterviewDurations();
			this.handleGetChartDataLeadDurations();
		},
	},
	mounted() {
		this.handleGetChartDataGetHiredDurations();
		this.handleGetChartDataInterviewDurations();
		this.handleGetChartDataLeadDurations();
	},
	watch: {
		'$route.query': {
			handler: function (newVal, oldVal) {
				if (
					oldVal === undefined ||
					newVal.job_id !== oldVal.job_id ||
					newVal.owner !== oldVal.owner
				) {
					this.handleGetChartDataGetHiredDurations();
					this.handleGetChartDataInterviewDurations();
					this.handleGetChartDataLeadDurations();
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>
