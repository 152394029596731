<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="680"
			@click:outside="() => onClose({ needsRefresh: false })"
			@keydown.esc="() => onClose({ needsRefresh: false })"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat>
						<v-toolbar-title>
							{{ $t('text.view-feedback') }}
						</v-toolbar-title>
						<v-spacer></v-spacer>
						<Avatar :userId="feedback.created_by"></Avatar>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 1rem">
					<DynamicForm
						:jsonSchema="feedback.json_schema"
						:contextSchema="feedback.context_schema"
						read-only
					></DynamicForm>
					<div>
						<v-radio-group v-model="feedback.overall_score" readonly>
							<template v-slot:label>
								<div>
									<strong>{{ $t('text.overall-score-for-candidate') }}</strong>
								</div>
							</template>
							<v-radio :value="4">
								<template v-slot:label>
									<div>
										<strong style="color: #2e7d32"
											><v-icon style="color: #2e7d32">thumb_up</v-icon>
											{{ $t('text.strong-yes') }}</strong
										>
									</div>
								</template>
							</v-radio>
							<v-radio :value="3">
								<template v-slot:label>
									<div>
										<strong style="color: #66bb6a"
											><v-icon style="color: #66bb6a">thumb_up</v-icon>
											{{ $t('text.yes') }}</strong
										>
									</div>
								</template>
							</v-radio>
							<v-radio :value="2">
								<template v-slot:label>
									<div>
										<strong style="color: #f44336"
											><v-icon style="color: #f44336">thumb_down</v-icon>
											{{ $t('text.no') }}</strong
										>
									</div>
								</template>
							</v-radio>
							<v-radio :value="1">
								<template v-slot:label>
									<div>
										<strong style="color: #b71c1c"
											><v-icon style="color: #b71c1c">thumb_down</v-icon>
											{{ $t('text.strong-no') }}</strong
										>
									</div>
								</template>
							</v-radio>
						</v-radio-group>
					</div>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="() => onClose()">{{ $t('button.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import DynamicForm from '@/components/shared/dynamicForm/DynamicForm';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'ViewFeedbackDialog',
	components: {
		DynamicForm,
		Avatar,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		feedbackId: {
			type: [Number, String],
			required: true,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			isVisible: false,
			feedback: {
				json_schema: [],
				context_schema: {},
				overall_score: null,
			},
		};
	},
	methods: {
		async handleGetFeedback({ id }) {
			this.$store
				.dispatch('feedbacks/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.feedback = items[0];
					}
				});
		},
	},
	mounted() {
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
	watch: {
		feedbackId: {
			handler: function (newVal) {
				this.handleGetFeedback({ id: newVal });
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
