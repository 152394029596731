<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-col>
					<v-select
						v-model="templateId"
						:items="[
							{
								template_name: 'Please select',
								id: null,
							},
							...knownHosts,
						]"
						item-text="template_name"
						item-value="id"
						hide-details
						outlined
						dense
						@change="handleTemplateIdChange"
					></v-select>
				</v-col>
			</v-row>

			<div
				v-if="templateId"
				style="
					border: 1px solid #9e9e9e;
					border-radius: 4px;
					margin-top: 1rem;
					padding: 1rem;
				"
			>
				<ValidationObserver
					ref="form_known_host_template"
					tag="form"
					@submit="handleSubmit"
					id="form_known_host_template"
				>
					<v-row>
						<v-col>
							<CollapsibleCard :label="$t('label.main-settings')">
								<v-container fluid>
									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="form.template_name"
													:label="$t('label.name')"
													outlined
													dense
													hide-details
													:error-messages="errors"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<fieldset
												class="talentics-fieldset talentics-fieldset-paddings"
											>
												<ValidationProvider rules="" v-slot="{ errors }">
													<v-checkbox
														v-model="form.active"
														:error-messages="errors"
														:label="`${$t('label.status')}: ${
															form.active
																? $t('label.active')
																: $t('label.inactive')
														}`"
														hide-details
														class="header-checkbox"
														color="secondary"
													></v-checkbox>
												</ValidationProvider>
											</fieldset>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<fieldset
												class="talentics-fieldset talentics-fieldset-paddings"
											>
												<legend>
													{{ $t('label.hostnames') }}
												</legend>
												<div>
													<v-chip
														class="mr-1"
														v-for="(hostname, hostnameIndex) in form.hostnames"
														:key="`hostname_${hostnameIndex}`"
													>
														{{ hostname }}
													</v-chip>
												</div>
											</fieldset>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="form.base_url"
													:label="$t('label.base-url')"
													outlined
													dense
													hide-details
													:error-messages="errors"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="form.homepage"
													:label="$t('label.homepage')"
													outlined
													dense
													hide-details
													:error-messages="errors"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<CdnFilePicker
												v-model="form.favicon"
												:label="$t('label.favicon')"
											></CdnFilePicker>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="" v-slot="{ errors }">
												<v-select
													v-model="form.lang"
													:items="[
														{
															text: 'English',
															value: 'en',
														},
														{
															text: 'Türkçe',
															value: 'tr',
														},
													]"
													item-text="text"
													item-value="value"
													:label="$t('label.language')"
													outlined
													hide-details
													dense
													:error-messages="errors"
												></v-select>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="" v-slot="{ errors }">
												<v-select
													:label="$t('label.organizations')"
													v-model="form.organizations"
													:items="organizations"
													item-text="name"
													item-value="id"
													outlined
													dense
													:error-messages="errors"
													multiple
													hide-details
												></v-select>
												<span
													style="font-size: 0.75rem; margin-left: 0.75rem"
													>{{ $t('text.please-leave-this-field-empty') }}</span
												>
											</ValidationProvider>
										</v-col>
									</v-row>
								</v-container>
							</CollapsibleCard>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<CollapsibleCard :label="$t('label.open-graph-meta-tags')">
								<v-container fluid>
									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="form.og_title"
													:label="$t('label.og_title')"
													outlined
													dense
													hide-details
													:error-messages="errors"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="form.og_description"
													:label="$t('label.og_description')"
													outlined
													dense
													hide-details
													:error-messages="errors"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<ValidationProvider rules="required" v-slot="{ errors }">
												<v-text-field
													v-model="form.og_url"
													:label="$t('label.og_url')"
													outlined
													dense
													hide-details
													:error-messages="errors"
												></v-text-field>
											</ValidationProvider>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<CdnFilePicker
												v-model="form.og_image"
												:label="$t('label.og_image')"
											></CdnFilePicker>
										</v-col>
									</v-row>
								</v-container>
							</CollapsibleCard>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<CollapsibleCard :label="$t('label.career-site')">
								<v-container fluid>
									<v-row>
										<v-col>
											<v-alert
												border="top"
												colored-border
												type="info"
												elevation="2"
												hide
												style="margin-bottom: 0"
											>
												Predefined libraries:
												<ul>
													<li>Bootstrap 5</li>
													<li>Font Awesome Pro Icons</li>
													<li>AOS - Animate On Scroll Library</li>
												</ul>
											</v-alert>
										</v-col>
									</v-row>

									<v-row>
										<v-col>
											<v-card
												elevation="0"
												style="border: 1px solid #9e9e9e; border-radius: 4px"
											>
												<v-tabs vertical>
													<v-tab>
														<v-icon left> home </v-icon>
														{{ $t('label.homepage') }}
													</v-tab>
													<v-tab>
														<v-icon left> work </v-icon>
														{{ $t('label.job-details') }}
													</v-tab>
													<v-tab>
														<v-icon left> share </v-icon>
														{{ $t('label.shared') }}
													</v-tab>

													<v-tab
														v-for="(
															customCareerPageItem, customCareerPageItemIndex
														) in customCareerPages"
														:key="`customCareerPageItem_${customCareerPageItemIndex}`"
													>
														{{ customCareerPageItem.slug }}
													</v-tab>

													<v-tab-item eager>
														<v-card flat>
															<v-card-text>
																<v-container fluid>
																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<legend>
																					{{ $t('label.view-type') }}
																				</legend>
																				<ValidationProvider
																					rules="required"
																					v-slot="{ errors }"
																				>
																					<v-radio-group
																						v-model="form.view_type"
																						:error-messages="errors"
																						hide-details
																						class="overall-score-selection-controls"
																					>
																						<v-radio value="list">
																							<template v-slot:label>
																								<div>
																									{{ $t('label.list-view') }}
																								</div>
																							</template>
																						</v-radio>
																						<v-radio value="card">
																							<template v-slot:label>
																								<div>
																									{{ $t('label.card-view') }}
																								</div>
																							</template>
																						</v-radio>
																					</v-radio-group>
																				</ValidationProvider>
																			</fieldset>
																		</v-col>
																	</v-row>

																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<ValidationProvider
																					rules=""
																					v-slot="{ errors }"
																				>
																					<v-checkbox
																						v-model="form.hide_header"
																						:error-messages="errors"
																						:label="$t('label.hide-header')"
																						hide-details
																						class="header-checkbox"
																						color="secondary"
																					></v-checkbox>
																				</ValidationProvider>
																			</fieldset>
																		</v-col>
																	</v-row>

																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<ValidationProvider
																					rules=""
																					v-slot="{ errors }"
																				>
																					<v-checkbox
																						v-model="form.hide_footer"
																						:error-messages="errors"
																						:label="$t('label.hide-footer')"
																						hide-details
																						class="header-checkbox"
																						color="secondary"
																					></v-checkbox>
																				</ValidationProvider>
																			</fieldset>
																		</v-col>
																	</v-row>

																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<legend>
																					{{ $t('label.header-html') }}
																				</legend>
																				<div class="brace-editor-container">
																					<BraceEditor
																						v-model="form.custom_html"
																					></BraceEditor>
																				</div>
																			</fieldset>
																		</v-col>
																	</v-row>

																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<legend>
																					{{ $t('label.footer-html') }}
																				</legend>
																				<div class="brace-editor-container">
																					<BraceEditor
																						v-model="form.footer_html"
																					></BraceEditor>
																				</div>
																			</fieldset>
																		</v-col>
																	</v-row>
																</v-container>
															</v-card-text>
														</v-card>
													</v-tab-item>

													<v-tab-item eager>
														<v-card flat>
															<v-card-text>
																<v-container fluid>
																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<ValidationProvider
																					rules=""
																					v-slot="{ errors }"
																				>
																					<v-checkbox
																						v-model="form.hide_job_header"
																						:error-messages="errors"
																						:label="$t('label.hide-header')"
																						hide-details
																						class="header-checkbox"
																						color="secondary"
																					></v-checkbox>
																				</ValidationProvider>
																			</fieldset>
																		</v-col>
																	</v-row>

																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<ValidationProvider
																					rules=""
																					v-slot="{ errors }"
																				>
																					<v-checkbox
																						v-model="form.hide_job_footer"
																						:error-messages="errors"
																						:label="$t('label.hide-footer')"
																						hide-details
																						class="header-checkbox"
																						color="secondary"
																					></v-checkbox>
																				</ValidationProvider>
																			</fieldset>
																		</v-col>
																	</v-row>

																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<legend>
																					{{ $t('label.header-html') }}
																				</legend>
																				<div class="brace-editor-container">
																					<BraceEditor
																						v-model="form.custom_job_html"
																					></BraceEditor>
																				</div>
																			</fieldset>
																		</v-col>
																	</v-row>

																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<legend>
																					{{ $t('label.footer-html') }}
																				</legend>
																				<div class="brace-editor-container">
																					<BraceEditor
																						v-model="form.footer_job_html"
																					></BraceEditor>
																				</div>
																			</fieldset>
																		</v-col>
																	</v-row>
																</v-container>
															</v-card-text>
														</v-card>
													</v-tab-item>

													<v-tab-item eager>
														<v-card flat>
															<v-card-text>
																<v-container fluid>
																	<v-row>
																		<v-col>
																			<fieldset
																				class="talentics-fieldset talentics-fieldset-paddings"
																			>
																				<legend>
																					{{ $t('label.shared-html') }}
																				</legend>
																				<div class="brace-editor-container">
																					<BraceEditor
																						v-model="form.shared_html"
																					></BraceEditor>
																				</div>
																			</fieldset>
																		</v-col>
																	</v-row>
																</v-container>
															</v-card-text>
														</v-card>
													</v-tab-item>

													<v-tab-item
														eager
														v-for="(
															customCareerPageItem, customCareerPageItemIndex
														) in customCareerPages"
														:key="`customCareerPageTabItem_${customCareerPageItemIndex}`"
													>
														<CustomCareerPage
															:itemId="customCareerPageItem.id"
															:ref="`customCareerPageItem_${customCareerPageItem.id}`"
														></CustomCareerPage>
													</v-tab-item>
												</v-tabs>
												<!-- <div style="padding: 1rem;" v-if="currentUser.id === 21" @click="handleAddCustomCareerPage">
                        <v-btn>{{ $t('button.add') }}</v-btn>
                      </div> -->
											</v-card>
										</v-col>
									</v-row>
								</v-container>
							</CollapsibleCard>
						</v-col>
					</v-row>

					<v-row class="mt-4">
						<v-col>
							<v-btn color="primary" @click="handleSubmit">
								{{ $t('button.update') }}
							</v-btn>
							<v-btn text @click="handleClose">
								{{ $t('button.close') }}
							</v-btn>
						</v-col>
					</v-row>
				</ValidationObserver>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import BraceEditor from '@/components/shared/braceEditor/BraceEditor';
import CdnFilePicker from '@/components/shared/cdnFilePicker/CdnFilePicker';
import api from '@/api';
import CollapsibleCard from '@/components/shared/collapsibleCard/CollapsibleCard';
import CustomCareerPage from './customCareerPage/CustomCareerPage';

export default {
	name: 'ConfirmationEmail',
	computed: {
		...mapState({
			company: state => state.company.company,
			knownHosts: state => state.knownHosts.items,
			organizations: state => state.organizations.items,
			currentUser: state => state.currentUser.currentUser,
		}),
	},
	components: {
		ValidationObserver,
		ValidationProvider,
		BraceEditor,
		CdnFilePicker,
		CollapsibleCard,
		CustomCareerPage,
	},
	data() {
		return {
			templateId: null,
			form: {
				id: null,
				template_name: '',
				custom_html: '',
				footer_html: '',
				homepage: '',
				base_url: '/',
				hide_header: false,
				hide_footer: false,
				organizations: [],
				hostnames: [],
				active: true,
				og_title: '',
				og_description: '',
				og_url: '',
				og_image: null,
				lang: null,
				favicon: null,
				custom_job_html: '',
				footer_job_html: '',
				hide_job_header: false,
				hide_job_footer: false,
				shared_html: '',
				view_type: null,
			},
			customCareerPages: [],
		};
	},
	methods: {
		handleGetKnownHost({ id }) {
			api.knownHosts.get({ id }).then(response => {
				const { items } = response.data;

				if (items.length > 0) {
					const {
						id,
						template_name,
						custom_html,
						footer_html,
						homepage,
						base_url,
						hide_header,
						hide_footer,
						organizations,
						hostnames,
						active,
						og_title,
						og_description,
						og_url,
						og_image,
						lang,
						favicon,
						custom_job_html,
						footer_job_html,
						hide_job_header,
						hide_job_footer,
						shared_html,
						view_type,
					} = items[0];

					this.form = {
						...this.form,
						id,
						template_name,
						custom_html,
						footer_html,
						homepage,
						base_url,
						hide_header,
						hide_footer,
						organizations,
						hostnames,
						active,
						og_title,
						og_description,
						og_url,
						og_image,
						lang,
						favicon,
						custom_job_html,
						footer_job_html,
						hide_job_header,
						hide_job_footer,
						shared_html,
						view_type,
					};

					api.customCareerPages.get({ known_host_id: id }).then(response => {
						const { items } = response.data;
						if (items.length > 0) {
							this.customCareerPages = items;
						}
					});
				}
			});
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_known_host_template.validate();
			if (isValid) {
				const { form } = this;
				api.knownHosts.put({ items: [form] }).then(() => {
					this.$store.dispatch('knownHosts/get', {
						updateStore: true,
					});
					this.templateId = null;
				});

				const customCareerPagesPayload = this.customCareerPages
					.map(({ id }) => {
						if (this.$refs[`customCareerPageItem_${id}`]) {
							return this.$refs[`customCareerPageItem_${id}`][0]?.exportItem();
						}
						return null;
					})
					.filter(item => Boolean(item));

				if (customCareerPagesPayload.length > 0) {
					api.customCareerPages.put({
						items: customCareerPagesPayload,
					});
				}
			}
		},
		handleTemplateIdChange(newVal) {
			this.handleGetKnownHost({ id: newVal });
		},
		handleClose() {
			this.templateId = null;
		},
		handleAddCustomCareerPage() {
			api.customCareerPages.post({
				items: [{ known_host_id: this.templateId, slug: 'foo' }],
			});
		},
	},
	mounted() {
		this.$store.dispatch('knownHosts/get', { updateStore: true });
		this.$store.dispatch('organizations/get', { updateStore: true });
	},
};
</script>

<style lang="scss">
.brace-editor-container {
	border: 1px solid rgba(0, 0, 0, 0.12);
}

.v-input--selection-controls {
	&.header-checkbox {
		margin-top: 0;
	}
}
</style>
