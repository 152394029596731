<template>
	<div class="one-box-content">
		<div
			:class="`one-box-placeholder one-box-placeholder-${view}`"
			v-if="isEmpty"
			v-html="placeholder"
		></div>
		<div
			:id="id"
			:class="`one-box one-box-${view}`"
			@focus="onFocus"
			@blur="onBlur"
			@input="handleInput"
		>
			<div><br /></div>
		</div>
	</div>
</template>

<script>
import Tribute from 'tributejs';

export default {
	props: {
		id: {
			type: String,
			required: true,
		},
		users: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		onFocus: {
			type: Function,
			required: false,
			default() {},
		},
		onBlur: {
			type: Function,
			required: false,
			default() {},
		},
		onInput: {
			type: Function,
			required: false,
			default() {},
		},
		value: {
			type: String,
			required: false,
		},
		resetTime: {
			type: Number,
			required: false,
			default() {
				return 0;
			},
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		view: {
			type: String,
			required: false,
			default() {
				return 'default';
			},
		},
		placeholder: {
			type: String,
			required: false,
			default() {
				return this.$t('label.one-box-default-placeholder');
			},
		},
	},
	watch: {
		users(newVal) {
			this.setTributeUsers(newVal);
		},
		value() {
			const now = new Date();
			const { changed } = this;
			if (now.getTime() - changed > 100) {
				this.setInitialValue();
			}
		},
		disabled(newVal) {
			if (newVal) {
				this.attachTribute();
			} else {
				this.detachTribute();
			}
		},
	},
	data() {
		return {
			isEditing: false,
			tribute: null,
			isEmpty: false,
			changed: 0,
		};
	},
	methods: {
		handleFocus() {
			this.isEditing = true;
		},
		handleBlur() {
			this.isEditing = false;
		},
		handleInput() {
			const now = new Date();
			this.changed = now.getTime();
			const oneBox = document.getElementById(this.id);
			this.$emit('input', oneBox.innerHTML);
			this.setIsEmpty();
		},
		setInitialValue() {
			if (this.value && this.value !== '') {
				this.fillOneBox(this.value);
			} else {
				this.fillOneBox('<div><br></div>');
			}
			this.setIsEmpty();
		},
		attachTribute() {
			const { users, disabled } = this;

			if (!disabled) {
				this.tribute = new Tribute({
					collection: [
						{
							trigger: '@',
							values: [
								...users.map(user => ({
									key: user.name ? user.name : user.email,
									value: user.id,
								})),
							],
							selectTemplate: function (item) {
								return `<span contenteditable="false" class="user-mention" data-user-id="${item.original.value}">@${item.original.key}</span>`;
							},
							menuItemTemplate: function (item) {
								return item.original.key;
							},
						},
					],
				});

				setTimeout(
					function () {
						this.tribute.attach(document.getElementById(this.id));

						document.getElementById(this.id).addEventListener('paste', evt => {
							evt.preventDefault();
							const text = evt.clipboardData.getData('text/plain');
							document.execCommand('insertHTML', false, text);
						});
					}.bind(this),
					100,
				);
			}

			setTimeout(
				function () {
					this.setInitialValue();
				}.bind(this),
				100,
			);
		},
		detachTribute() {
			const oneBox = document.getElementById(this.id);

			if (oneBox && oneBox.hasAttribute('data-tribute')) {
				this.tribute.detach(document.getElementById(this.id));
			}
		},
		setTributeUsers(users) {
			if (this.tribute) {
				this.tribute.append(
					0,
					[
						...users.map(user => ({
							key: user.name ? user.name : user.email,
							value: user.id,
						})),
					],
					true,
				);
			}
		},
		resetOneBox() {
			this.fillOneBox('<div><br></div>');
			this.handleInput();
		},
		fillOneBox(oneBoxHTML) {
			const oneBox = document.getElementById(this.id);
			if (oneBox) {
				oneBox.innerHTML = oneBoxHTML;
			}
		},
		setIsEmpty() {
			const oneBox = document.getElementById(this.id);
			this.isEmpty = oneBox?.innerText.trim().length === 0;
		},
	},
	created() {
		document.execCommand('DefaultParagraphSeparator', false, 'div');
	},
	mounted() {
		this.attachTribute();
	},
	beforeDestroy() {
		this.detachTribute();
	},
};
</script>

<style lang="scss">
.one-box-content {
	position: relative;

	.one-box-placeholder {
		position: absolute;
		top: 2px;
		left: 4px;
		right: 0;
		z-index: 0;
		font-style: italic;
		color: #a6aeb3;

		&.one-box-placeholder-basic {
			left: 0;
		}
	}
}

.one-box {
	min-height: 50px;
	position: relative;
	z-index: 1;
	padding: 2px 4px;

	// &:focus {
	//   outline: none !important;
	// }

	.user-mention {
		font-weight: bold;
	}

	.tag-mention {
		font-weight: bold;
	}
}

.tribute-container {
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	z-index: 250;

	ul {
		background: white !important;
		margin-top: 0 !important;
		list-style-type: none;
		font-family: 'roboto';

		li {
			padding: 0.5rem 1rem;

			&.highlight {
				background: #f8f8f8;
			}

			.avatar-img {
				width: 32px;
				height: 32px;
				margin-right: 8px;
				border: 1px solid #f8f8f8;
				border-radius: 16px;
			}
		}
	}
}
</style>
