<template>
	<div>
		<div v-if="activities.length > 0">
			<v-timeline dense>
				<v-timeline-item
					v-for="(activity, index) in activities"
					:key="index"
					small
				>
					<template v-slot:icon v-id="activity.user_id">
						<Avatar :userId="activity.user_id"></Avatar>
					</template>
					<v-card class="elevation-1">
						<v-card-title class="headline">
							{{ formatDate({ timestamp: activity.occured }) }}
						</v-card-title>
						<v-card-text
							v-if="activity.activity_code === 'new_application_by_candidate'"
							v-html="
								$t('text.new-job-application', {
									candidate: candidate.name,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'new_application_by_user'"
							v-html="
								$t('text.added-as-lead-candidate', {
									candidate: candidate.name,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="
								activity.activity_code === 'application_status_change_by_user'
							"
							v-html="
								$t('text.application-status-changed', {
									candidate: candidate.name,
									activity: $t(
										`pipeline.steps.${
											activity.new_val ? activity.new_val : 'new-applicant'
										}`,
									),
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'email_scheduled_by_user'"
							v-html="
								$t('text.new-scheduled-email', {
									candidate: candidate.name,
									activity: activity.new_val,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="
								activity.activity_code === 'scheduled_email_cancelled_by_user'
							"
							v-html="
								$t('text.scheduled-email-cancelled', {
									candidate: candidate.name,
									activity: activity.new_val,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'user_viewed_candidate'"
							v-html="
								$t('text.user-viewed-candidate', {
									candidate: candidate.name,
									user: activity.user_name,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'feedback_given_by_user'"
						>
							<span
								v-html="
									$t('text.has-new-feedback', {
										candidate: candidate.name,
									})
								"
							></span>
							<strong v-if="activity.new_val_int === 4" style="color: #2e7d32"
								><v-icon style="color: #2e7d32">thumb_up</v-icon>
								{{ $t('text.strong-yes') }}
							</strong>
							<strong
								v-else-if="activity.new_val_int === 3"
								style="color: #66bb6a"
								><v-icon style="color: #66bb6a">thumb_up</v-icon>
								{{ $t('text.yes') }}
							</strong>
							<strong
								v-else-if="activity.new_val_int === 2"
								style="color: #f44336"
								><v-icon style="color: #f44336">thumb_down</v-icon>
								{{ $t('text.no') }}
							</strong>
							<strong
								v-else-if="activity.new_val_int === 1"
								style="color: #b71c1c"
								><v-icon style="color: #b71c1c">thumb_down</v-icon>
								{{ $t('text.strong-no') }}
							</strong>
						</v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'feedback_updated_by_user'"
						>
							<span
								v-html="
									$t('text.existing-feedback-updated', {
										candidate: candidate.name,
									})
								"
							></span>
							<strong v-if="activity.new_val_int === 4" style="color: #2e7d32"
								><v-icon style="color: #2e7d32">thumb_up</v-icon>
								{{ $t('text.strong-yes') }}
							</strong>
							<strong
								v-else-if="activity.new_val_int === 3"
								style="color: #66bb6a"
								><v-icon style="color: #66bb6a">thumb_up</v-icon>
								{{ $t('text.yes') }}
							</strong>
							<strong
								v-else-if="activity.new_val_int === 2"
								style="color: #f44336"
								><v-icon style="color: #f44336">thumb_down</v-icon>
								{{ $t('text.no') }}
							</strong>
							<strong
								v-else-if="activity.new_val_int === 1"
								style="color: #b71c1c"
								><v-icon style="color: #b71c1c">thumb_down</v-icon>
								{{ $t('text.strong-no') }}
							</strong>
						</v-card-text>
						<v-card-text
							v-else-if="
								activity.activity_code === 'application_job_changed_by_user'
							"
							v-html="
								$t('text.application-job-changed-by-user', {
									candidate: candidate.name,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'form_filled_by_user'"
							v-html="
								$t('text.has-new-form', {
									candidate: candidate.name,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'user_pinned_candidate'"
							v-html="
								$t('text.user-starred-candidate', {
									candidate: candidate.name,
									user: activity.user_name,
								})
							"
						></v-card-text>
						<v-card-text
							v-else-if="activity.activity_code === 'user_unpinned_candidate'"
							v-html="
								$t('text.user-unstarred-candidate', {
									candidate: candidate.name,
									user: activity.user_name,
								})
							"
						></v-card-text>
						<v-card-text v-else>
							{{ activity.activity_code }}
						</v-card-text>
					</v-card>
				</v-timeline-item>
			</v-timeline>
		</div>
		<div v-else style="margin-top: 1rem">
			{{ $t('err.no-activities-found') }}
		</div>
	</div>
</template>

<script>
import { format } from 'date-fns';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'Activities',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
	},
	components: {
		Avatar,
	},
	data() {
		return {
			format,
			activities: [],
		};
	},
	methods: {
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		getCandidateActivities() {
			const { candidateId } = this.$route.params;
			this.$store
				.dispatch('activities/get', {
					candidate_id: candidateId,
					refresh: true,
				})
				.then(response => {
					this.activities = response.data.items;
				});
		},
	},
	watch: {
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.getCandidateActivities();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
