<template>
	<SettingsItemLayout>
		<template v-slot:bar>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						icon
						:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
						@click="handleRefresh"
					>
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</template>
		<template v-slot:navigation>
			<v-list dense>
				<v-list-item
					@click="handleOpenRequisitionApprovalFlowTemplate({ id: null })"
					:class="
						requisitionApprovalFlowTemplate.id === null
							? 'v-list-item--active'
							: ''
					"
				>
					<v-list-item-icon>
						<v-icon>add</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t('button.new-requisition-approval-flow-template')
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					@click="
						handleOpenRequisitionApprovalFlowTemplate({
							id: item.id,
						})
					"
					v-for="(item, index) in requisitionApprovalFlowTemplates"
					:key="index"
					:class="
						requisitionApprovalFlowTemplate.id === item.id
							? 'v-list-item--active'
							: ''
					"
				>
					<v-list-item-icon>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>folder_open</v-icon>
						</v-badge>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<template>
			<v-card tile v-if="showTemplateDetails" elevation="0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-toolbar-title>
						{{ requisitionApprovalFlowTemplate.label }}
					</v-toolbar-title>

					<v-spacer></v-spacer>

					<v-btn @click="handleSubmit" color="primary">
						{{ $t('button.save-changes') }}
					</v-btn>
				</v-toolbar>
				<v-divider></v-divider>
				<v-card-text>
					<ValidationObserver
						ref="dynamic_form_build"
						tag="form"
						@submit="handleSubmit"
						id="dynamic_form_build"
					>
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}:
											{{
												requisitionApprovalFlowTemplate.active
													? $t('label.active')
													: $t('label.inactive')
											}}
										</legend>
										<v-switch
											v-model="requisitionApprovalFlowTemplate.active"
											color="success"
											class="v-input--template-switch"
											hide-details
											inset
											dense
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="requisitionApprovalFlowTemplate.label"
											:label="$t('label.label')"
											outlined
											hide-details
											:error-messages="errors"
											dense
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset class="talentics-fieldset">
										<legend>
											{{ $t('label.valid-for') }}
										</legend>
										<v-container fluid>
											<v-row>
												<v-col>
													<v-checkbox
														v-model="
															requisitionApprovalFlowTemplate.is_valid_job_new_position
														"
														dense
														hide-details
														class="candidate-checkbox"
														:color="isDark ? 'white' : '#121212'"
														:label="$t('label.new-job-new-position')"
													></v-checkbox>
												</v-col>
											</v-row>
											<v-row>
												<v-col>
													<v-checkbox
														v-model="
															requisitionApprovalFlowTemplate.is_valid_job_replacement
														"
														dense
														hide-details
														class="candidate-checkbox"
														:color="isDark ? 'white' : '#121212'"
														:label="$t('label.new-job-replacement')"
													></v-checkbox>
												</v-col>
											</v-row>
											<v-row>
												<v-col>
													<v-checkbox
														v-model="
															requisitionApprovalFlowTemplate.is_valid_offer
														"
														dense
														hide-details
														class="candidate-checkbox"
														:color="isDark ? 'white' : '#121212'"
														:label="$t('label.new-offer')"
													></v-checkbox>
												</v-col>
											</v-row>
										</v-container>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset class="talentics-fieldset">
										<legend>
											{{ $t('label.settings') }}
										</legend>
										<v-container fluid>
											<v-row>
												<v-col>
													<v-checkbox
														v-model="
															requisitionApprovalFlowTemplate.publish_job_on_completion
														"
														dense
														hide-details
														class="candidate-checkbox"
														:color="isDark ? 'white' : '#121212'"
														:label="$t('label.publish_job_on_completion')"
													></v-checkbox>
												</v-col>
											</v-row>
										</v-container>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<TeamPicker
										v-model="requisitionApprovalFlowTemplate.team_ids"
										:label="$t('label.department-team')"
										multiple
										dense
									></TeamPicker>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ApprovalFlow
										v-model="requisitionApprovalFlowTemplate.json_schema"
										consistent-editing
									></ApprovalFlow>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
			</v-card>
		</template>
	</SettingsItemLayout>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import SettingsItemLayout from '../settingsItemLayout/SettingsItemLayout';
import ApprovalFlow from '@/components/shared/approvalFlow/ApprovalFlow';
import TeamPicker from '@/components/shared/teamPicker/TeamPicker';

export default {
	name: 'RequisitionApprovalFlowTemplates',
	components: {
		ValidationObserver,
		ValidationProvider,
		SettingsItemLayout,
		ApprovalFlow,
		TeamPicker,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			requisitionApprovalFlowTemplates: state =>
				state.requisitionApprovalFlowTemplates.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			showTemplateDetails: false,
			requisitionApprovalFlowTemplate: {
				id: null,
				label: '',
				json_schema: [],
				active: true,
				team_ids: [],
				is_valid_job_new_position: true,
				is_valid_job_replacement: true,
				is_valid_offer: true,
				publish_job_on_completion: true,
			},
			newFormItem: null,
		};
	},
	methods: {
		handleClose() {
			this.showTemplateDetails = false;
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.dynamic_form_build.validate();
			if (isValid) {
				const { requisitionApprovalFlowTemplate } = this;
				const { id } = requisitionApprovalFlowTemplate;
				if (id) {
					this.$store.dispatch('requisitionApprovalFlowTemplates/put', {
						items: [
							{
								...requisitionApprovalFlowTemplate,
							},
						],
					});
				} else {
					this.$store
						.dispatch('requisitionApprovalFlowTemplates/post', {
							items: [
								{
									...requisitionApprovalFlowTemplate,
								},
							],
						})
						.then(response => {
							const { items } = response.data;
							if (items.length > 0) {
								const { id } = items[0];
								this.handleOpenRequisitionApprovalFlowTemplate({
									id,
								});
							}
						});
				}
			}
		},
		handleOpenRequisitionApprovalFlowTemplate({ id }) {
			if (id) {
				this.$store
					.dispatch('requisitionApprovalFlowTemplates/get', {
						id,
						refresh: true,
					})
					.then(response => {
						const { items } = response.data;
						if (items.length > 0) {
							this.requisitionApprovalFlowTemplate = {
								...items[0],
							};
							this.showTemplateDetails = true;
						}
					});
			} else {
				this.requisitionApprovalFlowTemplate = {
					id: null,
					label: '',
					json_schema: [
						{
							approvers: [{ user_id: null, is_mandatory: true }],
						},
					],
					active: true,
					is_valid_job_new_position: true,
					is_valid_job_replacement: true,
					is_valid_offer: true,
					publish_job_on_completion: true,
				};
				this.showTemplateDetails = true;
			}
		},
		handleRefresh() {
			this.$store.dispatch('requisitionApprovalFlowTemplates/get', {
				updateStore: true,
				refresh: true,
			});
			this.handleOpenRequisitionApprovalFlowTemplate({ id: null });
		},
	},
	created() {
		this.$store.dispatch('organizations/get', { updateStore: true });
		this.$store.dispatch('departments/get', { updateStore: true });
		this.$store.dispatch('teams/get', { updateStore: true });
		this.$store.dispatch('requisitionApprovalFlowTemplates/get', {
			updateStore: true,
		});
		this.showTemplateDetails = false;
	},
	mounted() {
		this.handleOpenRequisitionApprovalFlowTemplate({ id: null });
	},
};
</script>

<style lang="scss">
.v-input--template-switch {
	&.v-input--selection-controls {
		margin-top: 0;
		margin-left: 4px;
	}
}
</style>
