<template>
	<v-card>
		<v-card-title class="pa-0">
			<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat>
				<v-toolbar-title>
					<span v-if="bulkAction">{{
						$t('text.archive-job(s)-bulk-title', {
							reason: archiveReasonName,
						})
					}}</span>
					<span v-else>{{
						$t('text.archive-job-title', {
							candidate: candidate.name,
							reason: archiveReasonName,
						})
					}}</span>
				</v-toolbar-title>
				<v-spacer></v-spacer>
			</v-toolbar>
		</v-card-title>

		<v-divider></v-divider>

		<v-card-text style="padding: 0">
			<ValidationObserver
				ref="form_archive_with_emails"
				tag="form"
				@submit="handleArchiveAndSend"
				id="form_archive_with_emails"
			>
				<v-container fluid>
					<v-row>
						<v-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<v-select
									v-model="emailTemplateId"
									:items="emailTemplates.filter(item => item.active)"
									:item-text="item => item.label"
									item-value="id"
									:label="$t('label.template')"
									outlined
									hide-details
									dense
									:disabled="previewMode"
									@change="id => handleEmailTemplateChange({ id })"
									:error-messages="errors"
								></v-select>
							</ValidationProvider>
						</v-col>
					</v-row>

					<v-row v-if="applications.length > 1 && emailTemplateId">
						<v-col class="d-flex" align-self="center">
							<span class="ml-1">{{
								applications[applicationIndex].job_name
							}}</span>
							<v-spacer></v-spacer>
							<span class="mx-3">{{
								$t('text.email-counts', {
									index: applicationIndex + 1,
									counts: applications.length,
								})
							}}</span>
						</v-col>
						<v-col cols="auto">
							<v-btn
								@click="applicationIndex -= 1"
								:disabled="applicationIndex === 0"
								small
							>
								<v-icon left>keyboard_arrow_left</v-icon>
								{{ $t('button.prev') }}
							</v-btn>
						</v-col>
						<v-col cols="auto">
							<v-btn
								@click="applicationIndex += 1"
								:disabled="applicationIndex === applications.length - 1"
								small
							>
								{{ $t('button.next') }}
								<v-icon right>keyboard_arrow_right</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-container>

				<v-divider></v-divider>

				<ScheduleEmailForm
					v-if="applications.length > 0 && emailTemplateId"
					:emailForm="applications[applicationIndex]"
					:completedEmailForm="{
						template: applications[applicationIndex].templateReplaced,
						subject: applications[applicationIndex].subjectReplaced,
					}"
					:candidateEmails="applications[applicationIndex].send_to_array"
					:previewMode="previewMode"
					@change-form="handleChangeScheduleEmailForm"
					:onNewAttachmentAdded="handleNewAttachmentAdded"
					:onAttachmentDeleteClick="handleAttachmentDeleteClick"
				></ScheduleEmailForm>
			</ValidationObserver>
		</v-card-text>
		<v-divider></v-divider>
		<v-card-actions>
			<v-btn v-if="!previewMode" @click="handleGoToPreviousStep">
				<v-icon left>chevron_left</v-icon>
				<span>{{ $t('tooltip.back') }}</span>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn
				style="margin-right: 0.5rem"
				text
				v-if="previewMode"
				@click="goToEditingMode"
			>
				{{ $t('button.go-back') }}
			</v-btn>

			<v-btn
				v-if="emailTemplateId && !previewMode"
				@click="goToPreviewMode"
				color="primary"
			>
				<v-icon style="margin-right: 0.25rem">visibility</v-icon>
				{{ $t('button.preview') }}
			</v-btn>

			<v-btn v-if="previewMode" @click="handleArchiveAndSend" color="primary">
				{{ $t('button.archive-and-send') }}
				{{ bulkAction ? `(${applications.length})` : '' }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';
import ScheduleEmailForm from '@/components/shared/scheduleEmailForm/ScheduleEmailForm';
import { getCandidateLastName, getCandidateFirstName } from '../../../../../../utils/helpers';

export default {
	name: 'ArchiveCandidateWithEmails',
	props: {
		applicationsFlat: {
			type: Array,
			required: true,
		},
		candidate: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
		candidates: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		bulkAction: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		archiveReasonName: {
			type: String,
			required: true,
		},
		onArchiveAndSend: {
			type: Function,
			required: true,
		},
		onPreviousStep: {
			type: Function,
			required: true,
		},
		onEmailTemplateIdChange: {
			type: Function,
			required: true,
		},
	},
	components: {
		ValidationObserver,
		ValidationProvider,
		ScheduleEmailForm,
	},
	computed: {
		...mapState({
			emailTemplates: state => state.emailTemplates.items,
			sendTimes: state => state.emailTemplates.sendTimes,
			company: state => state.company.company,
			isDark: state => state.currentUser.isDark,
			jobs: state => state.jobs.items,
		}),
	},
	data() {
		return {
			previewMode: false,
			application: {},
			applicationIndex: 0,
			emailTemplateId: null,
			applications: [],
		};
	},
	created() {
		this.$store.dispatch('emailTemplates/get', { updateStore: true });
	},
	methods: {
		handleArchiveAndSend(evt) {
			const isValid = this.getFormIsValid(evt);
			if (isValid) {
				this.handleEmitChangeApplications(this.applications);
				this.onArchiveAndSend();
			}
		},
		handleGoToPreviousStep() {
			this.emailTemplateId = null;
			this.onPreviousStep();
		},
		handleEmailTemplateChange({ id }) {
			this.$store
				.dispatch('emailTemplates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						const {
							sent_from,
							cc,
							bcc,
							default_schedule,
							subject,
							template,
							attachments,
							attachment_files,
						} = items[0];
						const { bulkAction } = this;
						const applicationsFlat = this.applications.map(application => {
							const send_at = this.handleGetSendAt(default_schedule);
							let candidate = {};

							if (bulkAction) {
								const { candidate_id } = application;
								candidate = this.candidates.find(
									candidateItem => candidateItem.id === candidate_id,
								);
							} else {
								candidate = this.candidate;
							}
							return {
								...application,
								sent_from,
								cc,
								bcc,
								send_time: default_schedule,
								send_at,
								send_to: candidate.email[0],
								send_to_array: candidate.email,
								subject,
								template,
								attachments,
								attachment_files,
								is_sent: false,
								is_cancelled: false,
								job_name: this.jobs.find(job => job.id === application.job_id)
									?.name,
							};
						});
						this.handleEmitChangeApplications(applicationsFlat);
					}
					this.onEmailTemplateIdChange({ email_template_id: id });
				});
		},
		goToEditingMode() {
			this.previewMode = false;
		},
		goToPreviewMode(evt) {
			const isValid = this.getFormIsValid(evt);
			if (isValid) {
				const candidateRegex = /({{\s+|{{)(candidate)(\s+}}|}})/gi;
				const candidateFirstNameRegex =
					/({{\s+|{{)(candidateFirstName)(\s+}}|}})/gi;
				const candidateLastNameRegex = 
					/({{\s+|{{)(candidateLastName)(\s+}}|}})/gi;
				const companyRegex = /({{\s+|{{)(company)(\s+}}|}})/gi;
				const jobRegex = /({{\s+|{{)(job)(\s+}}|}})/gi;

				const { company } = this;

				const applicationsFlat = this.applications.map(application => {
					const { subject, template, candidate_id } = application;
					let candidate = {};

					if (this.bulkAction) {
						candidate = this.candidates.find(item => item.id === candidate_id);
					} else {
						candidate = this.candidate;
					}

					return {
						...application,
						subjectReplaced: subject
							.replace(candidateRegex, candidate.name)
							.replace(
								candidateFirstNameRegex,
								getCandidateFirstName({ name: candidate.name }),
							)
							.replace(
								candidateLastNameRegex, 
								getCandidateLastName({ name: candidate.name }),
							)
							.replace(companyRegex, company.name)
							.replace(jobRegex, application.job_name),
						templateReplaced: template
							.replace(candidateRegex, candidate.name)
							.replace(
								candidateFirstNameRegex,
								getCandidateFirstName({ name: candidate.name }),
							)
							.replace(
								candidateLastNameRegex, 
								getCandidateLastName({ name: candidate.name }),
							)
							.replace(companyRegex, company.name)
							.replace(jobRegex, application.job_name),
					};
				});
				this.handleEmitChangeApplications(applicationsFlat);
				this.previewMode = true;
			}
		},
		handleEmitChangeApplications(newVal) {
			this.$emit('change-applications', newVal);
		},
		handleChangeScheduleEmailForm(newVal) {
			const { applications, applicationIndex } = this;
			let updatedApplications = applications.map((item, index) =>
				index === applicationIndex ? { ...item, ...newVal } : item,
			);
			this.handleEmitChangeApplications(updatedApplications);
		},
		async getFormIsValid(evt) {
			if (evt) {
				evt.preventDefault();
			}
			return await this.$refs.form_archive_with_emails.validate();
		},
		handleGetSendAt(newVal) {
			if (newVal) {
				const { sendTimes } = this;
				const sendTime = sendTimes.find(item => item.value === newVal);
				const { days } = sendTime;
				const send_at = new Date();
				send_at.setDate(send_at.getDate() + days);
				return send_at;
			}
		},
		async handleNewAttachmentAdded({ newFiles }) {
			const { applications, applicationIndex } = this;
			let updatedApplications = applications.map((item, appIndex) =>
				appIndex === applicationIndex
					? {
							...item,
							attachments: [
								...item.attachments,
								...newFiles.map(({ id }) => id),
							],
							attachment_files: [...item.attachment_files, ...newFiles],
					  }
					: item,
			);
			this.handleEmitChangeApplications(updatedApplications);
		},
		handleAttachmentDeleteClick({ index }) {
			const { applications, applicationIndex } = this;
			let updatedApplications = applications.map((item, appIndex) =>
				appIndex === applicationIndex
					? {
							...item,
							attachments: item.attachments.filter(
								(item, itemIndex) => index !== itemIndex,
							),
							attachment_files: item.attachment_files.filter(
								(item, itemIndex) => index !== itemIndex,
							),
					  }
					: item,
			);
			this.handleEmitChangeApplications(updatedApplications);
		},
	},
	watch: {
		applicationsFlat: {
			handler: function (newVal) {
				this.applications = JSON.parse(JSON.stringify(newVal));
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
