<template>
	<v-menu
		ref="menu"
		:close-on-content-click="false"
		:return-value.sync="date"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="290px"
	>
		<template v-slot:activator="{ on }">
			<div v-if="readonly">
				<fieldset class="talentics-fieldset talentics-fieldset-paddings">
					<legend>{{ label }}</legend>
					<div v-html="date ? date : '&nbsp;'"></div>
				</fieldset>
			</div>
			<div v-else>
				<v-btn
					v-if="type === 'button'"
					v-on="on"
					depressed
					color="transparent"
					tile
				>
					{{ formatDate({ timestamp: date }) }}
					<v-icon right>arrow_drop_down</v-icon>
				</v-btn>
				<v-text-field
					v-else
					:value="date"
					:prepend-inner-icon="prependInnerIcon"
					readonly
					v-on="on"
					:outlined="outlined"
					:background-color="`${isDark ? '' : '#ffffff'}`"
					hide-details
					no-title
					:error-messages="errors"
					:label="label"
					:dense="dense"
					:disabled="disabled"
				></v-text-field>
			</div>
		</template>
		<div>
			<v-date-picker
				v-model="date"
				:allowed-dates="allowedDates"
				no-title
				scrollable
				@input="handleDatePickerMenuInput"
				:first-day-of-week="1"
				:range="range"
			></v-date-picker>
			<div v-if="range" style="margin-top: -5px">
				<v-card elevation="0">
					<v-card-text>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.quick-selections') }}</legend>
							<div>
								<v-btn
									x-small
									style="margin: 0.25rem"
									@click="
										handleQuickSelection({
											preset: 'this-week',
										})
									"
									>{{ $t('button.this-week') }}</v-btn
								>
								<v-btn
									x-small
									style="margin: 0.25rem"
									@click="
										handleQuickSelection({
											preset: 'this-month',
										})
									"
									>{{ $t('button.this-month') }}</v-btn
								>
								<v-btn
									x-small
									style="margin: 0.25rem"
									@click="
										handleQuickSelection({
											preset: 'last-month',
										})
									"
									>{{ $t('button.last-month') }}</v-btn
								>
								<v-btn
									x-small
									style="margin: 0.25rem"
									@click="
										handleQuickSelection({
											preset: 'this-year',
										})
									"
									>{{ $t('button.this-year') }}</v-btn
								>
							</div>
						</fieldset>
					</v-card-text>
				</v-card>
			</div>
		</div>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';

export default {
	name: 'DatePicker',
	data() {
		return {
			format,
			date: null,
			menu: false,
		};
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	props: {
		value: {
			type: [String, Array],
			required: false,
		},
		label: {
			type: String,
			required: false,
			default() {
				return 'Date';
			},
		},
		errors: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		firstDayOfWeek: {
			type: Number,
			required: false,
			default() {
				return 1;
			},
		},
		outlined: {
			type: Boolean,
			required: false,
			default() {
				return true;
			},
		},
		prependInnerIcon: {
			type: String,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		range: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		readonly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	methods: {
		handleMenuSave() {
			const { date, range } = this;
			if (date) {
				if (range) {
					if (date.length === 2) {
						const start_date = new Date(date[0]);
						const end_date = new Date(date[1]);
						const switch_min_max_values = start_date > end_date;
						const min_date = switch_min_max_values ? date[1] : date[0];
						const max_date = switch_min_max_values ? date[0] : date[1];
						const dates = [min_date, max_date];
						this.$emit('input', dates);
						this.$refs.menu.save(dates);
					}
				} else {
					const formattedDate = new Date(date);
					const formattedDateToISOString = formattedDate.toISOString();
					this.$emit('input', formattedDateToISOString);
					this.$refs.menu.save(date);
				}
			}
		},
		allowedDates() {
			// const date = new Date(val);
			// const dateDay = date.getDay();
			// return (dateDay !== 0 && dateDay !== 6);
			return true;
		},
		formatDate({ timestamp, dateFormat = 'E, MMM d' }) {
			const newDate = new Date(timestamp);
			return format(newDate, dateFormat);
		},
		handleDatePickerMenuInput() {
			this.handleMenuSave();
		},
		handleQuickSelection({ preset }) {
			const today = new Date();

			if (preset === 'this-week') {
				// ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
				const dayOfWeekUncorrected = today.getUTCDay();
				const daysOfWeek = {
					0: 6,
					1: 0,
					2: 1,
					3: 2,
					4: 3,
					5: 4,
					6: 5,
				};
				const dayOfWeek = daysOfWeek[dayOfWeekUncorrected];
				const startOfWeek = new Date(today);
				startOfWeek.setDate(today.getDate() - dayOfWeek);
				const dates = [
					this.format(startOfWeek, 'yyyy-MM-dd'),
					this.format(today, 'yyyy-MM-dd'),
				];
				this.$emit('input', dates);
				this.$refs.menu.save(dates);
			} else if (preset === 'this-month') {
				const startOfMonth = new Date(today);
				startOfMonth.setDate(1);
				const dates = [
					this.format(startOfMonth, 'yyyy-MM-dd'),
					this.format(today, 'yyyy-MM-dd'),
				];
				this.$emit('input', dates);
				this.$refs.menu.save(dates);
			} else if (preset === 'last-month') {
				const startOfMonth = new Date(today);
				startOfMonth.setDate(1);
				const endOfLastMonth = new Date(startOfMonth);
				endOfLastMonth.setDate(startOfMonth.getDate() - 1);
				const startOfLastMonth = new Date(endOfLastMonth);
				startOfLastMonth.setDate(1);
				const dates = [
					this.format(startOfLastMonth, 'yyyy-MM-dd'),
					this.format(endOfLastMonth, 'yyyy-MM-dd'),
				];
				this.$emit('input', dates);
				this.$refs.menu.save(dates);
			} else if (preset === 'this-year') {
				const startOfYear = new Date(today);
				startOfYear.setMonth(0);
				startOfYear.setDate(1);
				const dates = [
					this.format(startOfYear, 'yyyy-MM-dd'),
					this.format(today, 'yyyy-MM-dd'),
				];
				this.$emit('input', dates);
				this.$refs.menu.save(dates);
			}
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					if (this.range) {
						this.date = newVal;
					} else {
						const date = new Date(newVal);
						this.date = date.toISOString().substr(0, 10);
					}
				} else {
					this.date = null;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
