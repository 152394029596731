<template>
	<div>
		<div style="display: flex">
			<v-select
				v-model="currentStatus"
				:items="statuses"
				:item-text="
					item => {
						if (item.text) {
							return $t(`pipeline.steps.${item.text}`);
						}
						return item;
					}
				"
				item-value="value"
				:label="$t('label.status')"
				outlined
				hide-details
				dense
				style="width: 250px"
				@change="newVal => handleChange({ newVal })"
				return-object
				:prepend-inner-icon="innerIcon"
			></v-select>
			<v-menu v-if="!isArchived" bottom left>
				<template v-slot:activator="{ on }">
					<v-btn
						fab
						small
						tile
						:light="isDark"
						:dark="!isDark"
						v-on="on"
						style="margin-left: 0.5rem"
					>
						<v-icon>archive</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in pipelineArchiveItems.map(item => ({
							text: item,
							value: item,
							is_lead: undefined,
							is_interview: false,
							is_archived: true,
						}))"
						:key="index"
						@click="() => handleChange({ newVal: item })"
					>
						<v-list-item-title>{{
							$t(`pipeline.steps.${item.text}`)
						}}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<Confirm
			v-if="isConfirmVisible"
			:onConfirm="handleConfirmDialogConfirm"
			:onClose="handleConfirmDialogClose"
		>
			<div
				v-html="
					$t('text.edit-app-status', {
						initialStatus: `<strong>${$t(
							`pipeline.steps.${initialStatus}`,
						)}</strong>`,
						nextStatus: `<strong>${$t(
							`pipeline.steps.${nextStatus}`,
						)}</strong>`,
					})
				"
				style="padding-top: 20px"
			></div>
		</Confirm>
		<EditFormDialog
			v-if="isEditFormDialogVisible"
			:formId="formIdToEdit"
			:candidate="candidate"
			:onClose="handleEditFormDialogClose"
		></EditFormDialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Confirm from '@/components/shared/dialogs/confirm/Confirm';
import EditFormDialog from '../forms/editFormDialog/EditFormDialog';

export default {
	name: 'EditApplicationStatus',
	components: {
		Confirm,
		EditFormDialog,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			pipelineInterviewItems: state => state.pipelineInterview.items,
			pipelineLeadItems: state => state.pipelineLead.items,
			pipelineArchiveItems: state => state.pipelineArchive.items,
			isDark: state => state.currentUser.isDark,
			formRulesets: state => state.formRulesets.items,
		}),
		statuses() {
			const {
				pipelineInterviewItems,
				pipelineLeadItems,
				pipelineArchiveItems,
				isArchived,
			} = this;
			return [
				{ header: 'Lead' },
				...pipelineLeadItems.map(item => ({
					text: item,
					value: item,
					is_lead: true,
					is_interview: false,
					is_archived: false,
				})),
				{ divider: true },
				{ header: 'Application' },
				{
					text: 'new-applicant',
					value: 'new-applicant',
					is_lead: false,
					is_interview: false,
					is_archived: false,
				},
				{ divider: true },
				{ header: 'Interview' },
				...pipelineInterviewItems.map(item => ({
					text: item,
					value: item,
					is_lead: undefined,
					is_interview: true,
					is_archived: false,
				})),
				...(!isArchived
					? [
							{
								text: 'hired',
								value: 'archive-hired',
								is_lead: undefined,
								is_interview: false,
								is_archived: true,
							},
					  ]
					: []),
				...(isArchived ? [{ header: 'Archive' }] : []),
				...(isArchived
					? pipelineArchiveItems.map(item => ({
							text: item,
							value: item,
							is_lead: undefined,
							is_interview: false,
							is_archived: true,
					  }))
					: []),
			];
		},
		innerIcon() {
			const { isArchived, isInterview, isLead } = this;
			if (isArchived) {
				return 'archive';
			}
			if (isInterview) {
				return 'person_search';
			}
			if (isLead) {
				return 'verified';
			}
			return 'person_pin';
		},
	},
	data() {
		return {
			isConfirmVisible: false,
			currentStatus: null,
			initialStatus: null,
			nextStatus: null,
			nextStatusObject: {},
			isEditFormDialogVisible: false,
			formIdToEdit: null,
		};
	},
	props: {
		value: {
			type: String,
			required: true,
		},
		applicationId: {
			type: [Number, String],
			required: true,
		},
		candidateId: {
			type: [Number, String],
			required: true,
		},
		jobId: {
			type: [Number, String],
			required: true,
		},
		isLead: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		isInterview: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		isArchived: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		onArchive: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		candidate: {
			type: Object,
			required: false,
		},
	},
	methods: {
		handleChange({ newVal }) {
			const { is_archived, value } = newVal;
			if (is_archived) {
				const { jobId, applicationId } = this;
				this.onArchive({
					job_id: jobId,
					application_id: applicationId,
					prev_status: this.value,
					archive_reason: value,
				});
				setTimeout(
					function () {
						this.currentStatus = this.value;
					}.bind(this),
					250,
				);
			} else {
				const { value } = this;
				this.initialStatus = value;
				this.nextStatus = newVal.value;
				this.nextStatusObject = { ...newVal };
				this.isConfirmVisible = true;
			}
		},
		handleConfirmDialogConfirm() {
			this.isConfirmVisible = false;
			const { applicationId } = this;
			const { is_archived, is_interview, is_lead, value } =
				this.nextStatusObject;
			const interview_status = is_interview ? value : null;
			const lead_status = is_lead ? value : null;
			const archive_status = is_archived ? value : null;

			this.$store
				.dispatch('applicants/put', {
					items: [
						{
							id: applicationId,
							is_interview,
							interview_status,
							is_archived,
							archive_status,
							is_lead,
							lead_status,
							updateStore: false,
						},
					],
				})
				.then(() => {
					this.$emit('change', value);
					this.handleCheckStatusChangeRuleset();
				});
		},
		handleConfirmDialogClose() {
			this.isConfirmVisible = false;
			const { value } = this;
			this.currentStatus = value;
		},
		async handleCheckStatusChangeRuleset() {
			const { initialStatus, nextStatus } = this;
			const formRulesetsQuery = await this.$store.dispatch('formRulesets/get', {
				updateStore: true,
				refresh: true,
			});
			const { items } = formRulesetsQuery.data;
			const rulesetItem = items.find(
				item =>
					(item.prev_status === initialStatus || item.prev_status === '*') &&
					(item.next_status === nextStatus || item.next_status === '*'),
			);
			if (!rulesetItem) {
				return false;
			}
			const { form_id } = rulesetItem;
			const { candidateId, applicationId, jobId } = this;
			this.handleAddNewForm({
				candidateId,
				formTemplateId: form_id,
				applicationId,
				jobId,
				pipelineStatus: nextStatus,
			});
		},
		async handleAddNewForm({
			candidateId,
			formTemplateId,
			applicationId,
			pipelineStatus,
			jobId,
		}) {
			const formTemplateResponse = await this.$store.dispatch(
				'formTemplates/get',
				{ id: formTemplateId, refresh: true },
			);
			const { created_json_schema, predefined_type } =
				formTemplateResponse.data.items[0];
			const draftFormResponse = await this.$store.dispatch('forms/post', {
				items: [
					{
						json_schema: created_json_schema,
						context_schema: {},
						candidate_id: candidateId,
						application_id: applicationId,
						pipeline_status: pipelineStatus,
						pipeline_status_translation: this.$t(
							`pipeline.steps.${pipelineStatus}`,
						),
						job_id: jobId,
						status: 'draft',
						form_template_predefined_type: predefined_type,
					},
				],
			});
			this.$store.dispatch('drafts/get_forms', {});
			const { id } = draftFormResponse.data.items[0];
			this.handleEditForm({ id });
		},
		handleEditForm({ id }) {
			this.formIdToEdit = id;
			this.isEditFormDialogVisible = true;
		},
		handleEditFormDialogClose() {
			this.isEditFormDialogVisible = false;
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				this.currentStatus = newVal;
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
