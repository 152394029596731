<template>
	<v-menu
		v-model="isMenuActive"
		:close-on-content-click="false"
		:nudge-right="40"
		max-width="300"
		transition="scale-transition"
		offset-y
	>
		<template v-slot:activator="{ on }">
			<v-text-field
				v-model="colorModel"
				:label="label"
				prepend-inner-icon="colorize"
				readonly
				v-on="on"
				hide-details
				outlined
				:dense="dense"
			>
			</v-text-field>
		</template>
		<v-card>
			<v-color-picker
				v-model="colorModel"
				mode="hexa"
				@update:color="
					selectedColor =>
						handleColorPickerChange({ newValue: selectedColor.hex })
				"
				class="viv-color-picker"
			></v-color-picker>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="isMenuActive = false">{{ $t('button.close') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		label: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		dense: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	data() {
		const { value } = this;
		return {
			isMenuActive: false,
			colorModel: value,
		};
	},
	watch: {
		value(newValue) {
			this.colorModel = newValue;
		},
	},
	methods: {
		handleColorPickerChange({ newValue }) {
			this.$emit('input', newValue);
		},
	},
};
</script>

<style lang="scss">
.v-color-picker {
	&.viv-color-picker {
		box-shadow: none !important;
	}
}
</style>
