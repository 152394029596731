<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex>
				<v-card tile elevation="0">
					<div style="padding: 0.5rem">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									icon
									:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
									@click="handleRefresh"
								>
									<v-icon>refresh</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.refresh') }}
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									v-on="on"
									:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
									icon
									@click="handleAddUser"
								>
									<v-icon>add</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.add') }}
						</v-tooltip>
						<v-btn small @click="handleToggleInactiveUsers">
							<span v-if="filterInactiveUsers">{{
								$t('button.show-inactive-users')
							}}</span>
							<span v-else>{{ $t('button.hide-inactive-users') }}</span>
							<span style="margin-left: 0.25rem"
								>({{
									users.filter(({ active }) => active !== true).length
								}})</span
							>
						</v-btn>
					</div>
					<v-divider></v-divider>
					<v-simple-table class="users-table">
						<thead>
							<tr>
								<th class="text-left" style="width: 1%"></th>
								<th class="text-left">
									{{ $t('label.name') }}
								</th>
								<th class="text-left">
									{{ $t('label.email') }}
								</th>
								<th
									class="text-left"
									v-if="company.user_has_display_email_prop"
								>
									{{ $t('label.display-email') }}
								</th>
								<th class="text-left" v-if="company.user_has_reports_to_prop">
									{{ $t('label.reports-to') }}
								</th>
								<th class="text-left">
									{{ $t('label.role') }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(user, index) in users.filter(({ active }) =>
									filterInactiveUsers ? active : true,
								)"
								:key="index"
								@click="() => handleUserClick({ id: user.id })"
								:style="`cursor: pointer; opacity: ${
									user.active ? '1' : '0.5'
								};`"
							>
								<td>
									<v-badge
										bottom
										:color="user.active ? 'green' : 'grey'"
										dot
										offset-x="10"
										offset-y="10"
									>
										<Avatar :userId="user.id"></Avatar>
									</v-badge>
								</td>
								<td>{{ user.name ? user.name : '-' }}</td>
								<td>{{ user.email }}</td>
								<td v-if="company.user_has_display_email_prop">
									{{ user.display_email ? user.display_email : '-' }}
								</td>
								<td v-if="company.user_has_reports_to_prop">
									{{
										user.reports_to_user_id
											? getUserDisplayName({
													id: user.reports_to_user_id,
											  })
											: '-'
									}}
								</td>
								<td>{{ getRoleName({ code: user.role }) }}</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card>
				<router-view></router-view>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import Avatar from '@/components/shared/avatar/Avatar';

export default {
	name: 'Users',
	components: {
		Avatar,
	},
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
			users: state => state.users.items,
			itemsNeedToRefresh: state => state.users.itemsNeedToRefresh,
			isDark: state => state.currentUser.isDark,
			company: state => state.company.company,
		}),
	},
	data() {
		return {
			tab: 0,
			filterInactiveUsers: true,
		};
	},
	methods: {
		handleRefresh() {
			this.$store.dispatch('users/get', {
				refresh: true,
				updateStore: true,
			});
		},
		handleUserClick({ id }) {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/settings/users/edit/${id}`,
				query,
			});
		},
		handleAddUser() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/settings/users/new`,
				query,
			});
		},
		getRoleName({ code }) {
			const roleNames = {
				999: 'Super Admin',
				777: 'Super Admin',
				666: 'Admin',
				555: 'Team Member',
				444: 'Limited Team Member',
				333: 'Interviewer',
			};
			return roleNames[code] || code;
		},
		getUserDisplayName({ id }) {
			const { users } = this;
			const user = users.find(item => item.id === id);
			if (user) {
				const { name, email } = user;
				if (name) {
					return name;
				} else if (email) {
					return email;
				}
				return '-';
			}
			return '-';
		},
		handleToggleInactiveUsers() {
			this.filterInactiveUsers = !this.filterInactiveUsers;
		},
	},
	created() {
		this.$store.dispatch('timezones/get', { updateStore: true });
	},
	watch: {
		itemsNeedToRefresh() {
			this.handleRefresh();
		},
	},
};
</script>

<style lang="scss">
.v-data-table {
	&.users-table {
		border-radius: 0;
		border-top: thin solid rgba(0, 0, 0, 0.12);
		border-bottom: thin solid rgba(0, 0, 0, 0.12);

		&.theme--light {
			background-color: rgba(242, 245, 245, 0.8);
		}
	}
}
</style>
