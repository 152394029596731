<template>
	<v-container fluid fill-height class="settings-item-container">
		<v-layout>
			<v-flex>
				<v-card
					tile
					elevation="0"
					:color="`${isDark ? '#121212' : 'white'}`"
					class="settings-item-card"
				>
					<div class="settings-item-bar">
						<slot name="bar"></slot>
					</div>
					<v-divider></v-divider>
					<div class="settings-item-content-container">
						<v-container fluid fill-height style="padding: 0">
							<v-navigation-drawer
								:value="true"
								:clipped="$vuetify.breakpoint.lgAndUp"
								:color="`${isDark ? '#121212' : 'white'}`"
							>
								<div><slot name="navigation"></slot></div>
							</v-navigation-drawer>
							<v-layout>
								<v-flex class="position-relative">
									<div class="settings-item-content-area">
										<slot></slot>
									</div>
								</v-flex>
							</v-layout>
						</v-container>
					</div>
				</v-card>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'SettingsItemLayout',
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
};
</script>

<style lang="scss">
.settings-item-container {
	padding: 0 !important;

	.settings-item-card {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		.settings-item-bar {
			padding: 0.5rem;
		}

		.settings-item-content-container {
			position: absolute;
			top: 53px;
			right: 0;
			bottom: 0;
			left: 0;

			.settings-item-content-area {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				overflow-y: auto;
			}
		}
	}

	.position-relative {
		position: relative;
	}
}
</style>
