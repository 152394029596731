<template>
	<SettingsItemLayout>
		<template v-slot:bar>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						v-on="on"
						icon
						:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
						@click="handleRefresh"
					>
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</template>
		<template v-slot:navigation>
			<v-list dense>
				<v-list-item
					@click="handleOpenEmailLayout({ id: null })"
					:class="emailLayout.id === null ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-icon>add</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{
							$t('button.new-email-layout')
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item
					@click="handleOpenEmailLayout({ id: item.id })"
					v-for="(item, index) in emailLayouts"
					:key="index"
					:class="emailLayout.id === item.id ? 'v-list-item--active' : ''"
				>
					<v-list-item-icon>
						<v-badge
							v-if="!item.addButton"
							bottom
							:color="item.active ? 'green' : 'grey'"
							dot
							offset-x="10"
							offset-y="10"
						>
							<v-icon>folder_open</v-icon>
						</v-badge>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ item.label }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>
		<template>
			<v-card v-if="showLayoutDetails" tile elevation="0">
				<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
					<v-toolbar-title>
						{{ emailLayout.label }}
					</v-toolbar-title>

					<v-spacer></v-spacer>

					<v-btn
						@click="() => handleSubmit({ close: false, refresh: true })"
						color="primary"
					>
						{{ $t('button.save-changes') }}
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<ValidationObserver
						ref="email_layout_form"
						tag="form"
						@submit="handleSubmit"
						id="email_layout_form"
					>
						<v-container>
							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.status') }}:
											{{
												emailLayout.active
													? $t('label.active')
													: $t('label.inactive')
											}}
										</legend>
										<v-switch
											v-model="emailLayout.active"
											color="success"
											inset
											dense
											hide-details
											class="v-input--template-switch"
										></v-switch>
									</fieldset>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-text-field
											v-model="emailLayout.label"
											:label="$t('label.label')"
											outlined
											hide-details
											dense
											:error-messages="errors"
										></v-text-field>
									</ValidationProvider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<v-row>
								<v-col>
									<fieldset
										class="talentics-fieldset talentics-fieldset-paddings"
									>
										<legend>
											{{ $t('label.layout-html') }}
										</legend>
										<div class="brace-editor-container">
											<BraceEditor
												v-model="emailLayout.layout_html"
											></BraceEditor>
										</div>
									</fieldset>
								</v-col>
							</v-row>
						</v-container>
					</ValidationObserver>
				</v-card-text>
			</v-card>
		</template>
	</SettingsItemLayout>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
// import api from '@/api';
import SettingsItemLayout from '../settingsItemLayout/SettingsItemLayout';
import BraceEditor from '@/components/shared/braceEditor/BraceEditor';

export default {
	name: 'EmailLayouts',
	components: {
		ValidationObserver,
		ValidationProvider,
		SettingsItemLayout,
		BraceEditor,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			emailLayouts: state => state.emailLayouts.items,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		return {
			emailLayout: {
				id: null,
				label: '',
				layout_html: '',
				active: true,
			},
			showLayoutDetails: false,
		};
	},
	methods: {
		handleClose() {
			this.showLayoutDetails = false;
		},
		async handleSubmit({ close = false, refresh = false }) {
			const isValid = await this.$refs.email_layout_form.validate();
			if (isValid) {
				const { emailLayout } = this;
				const { id } = emailLayout;
				if (id) {
					this.$store
						.dispatch('emailLayouts/put', {
							items: [
								{
									...emailLayout,
								},
							],
						})
						.then(() => {
							if (close) {
								this.showLayoutDetails = false;
							}
							if (refresh) {
								this.handleOpenEmailLayout({ id });
							}
						});
				} else {
					this.$store
						.dispatch('emailLayouts/post', {
							items: [
								{
									...emailLayout,
								},
							],
						})
						.then(response => {
							if (close) {
								this.showLayoutDetails = false;
							}
							if (refresh) {
								const { items } = response.data;
								if (items.length > 0) {
									const { id } = items[0];
									this.handleOpenEmailLayout({ id });
								}
							}
							this.$store.dispatch('emailLayout/get', {
								updateStore: true,
								refresh: true,
							});
						});
				}
			}
		},
		handleOpenEmailLayout({ id }) {
			if (id) {
				this.$store
					.dispatch('emailLayouts/get', { id, refresh: true })
					.then(response => {
						const { items } = response.data;
						if (items.length > 0) {
							this.emailLayout = { ...items[0] };
							this.showLayoutDetails = true;
						}
					});
			} else {
				this.emailLayout = {
					id: null,
					label: '',
					layout_html: '',
					active: true,
				};
				this.showLayoutDetails = true;
			}
		},
		handleRefresh() {
			this.$store.dispatch('emailLayouts/get', {
				updateStore: true,
				refresh: true,
			});
			this.handleOpenEmailLayout({ id: null });
		},
	},
	created() {
		this.$store.dispatch('emailLayouts/get', {
			updateStore: true,
			refresh: true,
		});
	},
	mounted() {
		this.handleOpenEmailLayout({ id: null });
	},
};
</script>
