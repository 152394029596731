var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',[_vm._l((_vm.locations),function(item,index){return [_c('v-list-item',{key:item.created,on:{"click":() =>
						_vm.addOrEditItem({
							id: item.id,
							operation: 'edit',
						})}},[_c('v-list-item-avatar',[(!item.addButton)?_c('v-badge',{attrs:{"bottom":"","color":item.active ? 'green' : 'grey',"dot":"","offset-x":"10","offset-y":"10"}},[_c('v-icon',[_vm._v("folder_open")])],1):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.name)}})],1),_c('v-list-item-action',[(!item.addButton)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":() =>
										_vm.addOrEditItem({
											id: item.id,
											operation: 'edit',
										})}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('text.edit-location')))])],1)],1)],1):_vm._e()],1)],1),(index < _vm.locations.length - 1)?_c('v-divider',{key:index,attrs:{"inset":""}}):_vm._e()]})],2),_c('div',{staticStyle:{"padding":"1rem"}},[_c('v-btn',{attrs:{"small":""},on:{"click":() =>
					_vm.addOrEditItem({
						operation: 'add',
					})}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t('button.add-location'))+" ")],1)],1),(_vm.isLocationAttrsVisible)?_c('LocationAttrs',{attrs:{"method":_vm.locationActionMethod,"location":_vm.locationToEdit,"onClose":_vm.handleLocationAttrsClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }