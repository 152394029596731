export const dynamicFormMixin = {
	props: {
		label: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		description: {
			type: String,
			required: false,
			default() {
				return '';
			},
		},
		required: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		onChange: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		options: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		readOnly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		multiple: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			itemModel: this.value,
		};
	},
	watch: {
		value(newVal) {
			this.itemModel = newVal;
		},
	},
};
