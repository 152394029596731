<template>
	<div>
		<v-dialog
			scrollable
			transition="dialog-bottom-transition"
			:value="isVisible"
			max-width="600"
			@click:outside="() => onClose({ needsRefresh: false })"
			@keydown.esc="() => onClose({ needsRefresh: false })"
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
						<v-toolbar-title>
							{{
								$t('text.schedule-mail-title', {
									candidate: candidate.name,
								})
							}}
						</v-toolbar-title>
						<v-spacer></v-spacer>
					</v-toolbar>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text style="padding: 0">
					<ValidationObserver
						ref="form_schedule_email"
						tag="form"
						@submit="handleSchedule"
						id="form_schedule_email"
					>
						<ScheduleEmailForm
							:emailForm="emailForm"
							:completedEmailForm="completedEmail"
							:previewMode="previewMode"
							:candidateEmails="candidate.email"
							@change-form="handleChangeEmailForm"
							:onNewAttachmentAdded="handleNewAttachmentAdded"
							:onAttachmentDeleteClick="handleAttachmentDeleteClick"
						></ScheduleEmailForm>
					</ValidationObserver>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						style="margin-right: 0.5rem"
						text
						v-if="previewMode"
						@click="goToEditingMode"
					>
						{{ $t('button.go-back') }}
					</v-btn>

					<v-btn v-if="!previewMode" @click="goToPreviewMode" color="primary">
						<v-icon style="margin-right: 0.25rem">visibility</v-icon>
						{{ $t('button.preview') }}
					</v-btn>

					<v-btn v-if="previewMode" @click="handleSchedule" color="primary">
						{{ $t('button.send') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapState } from 'vuex';
import ScheduleEmailForm from '@/components/shared/scheduleEmailForm/ScheduleEmailForm';
import { getCandidateLastName, getCandidateFirstName } from '../../../../../../utils/helpers';

export default {
	name: 'ScheduleEmailDialog',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		email: {
			type: Object,
			required: true,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		assessment: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
		onScheduleEmailComplete: {
			type: Function,
			required: false,
			default() {
				return null;
			},
		},
	},
	components: {
		ValidationObserver,
		ScheduleEmailForm,
	},
	computed: {
		...mapState({
			company: state => state.company.company,
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		const { email } = this;
		return {
			previewMode: false,
			emailForm: JSON.parse(JSON.stringify(email)),
			completedEmail: {},
			isVisible: false,
		};
	},
	methods: {
		async handleSchedule() {
			const { completedEmail } = this;
			this.$store
				.dispatch('emails/post', {
					items: [
						{
							...completedEmail,
							spica_link_id: this.assessment?.spica_link_id,
						},
					],
				})
				.then(() => {
					if (this.onScheduleEmailComplete) {
						this.onScheduleEmailComplete();
					}

					this.onClose({ needsRefresh: true });
				});
		},
		handleChangeEmailForm(newFormValue) {
			this.emailForm = { ...this.emailForm, ...newFormValue };
		},
		async goToPreviewMode(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.form_schedule_email.validate();
			if (isValid) {
				const { candidate, company } = this;

				const { subject, template, send_to } = this.emailForm;
				const candidateRegex = /({{\s+|{{)(candidate)(\s+}}|}})/gi;
				const candidateFirstNameRegex =
					/({{\s+|{{)(candidateFirstName)(\s+}}|}})/gi;
				const candidateLastNameRegex = 
					/({{\s+|{{)(candidateLastName)(\s+}}|}})/gi;
				const companyRegex = /({{\s+|{{)(company)(\s+}}|}})/gi;
				const assessmentLinkUrlRegex =
					/({{\s+|{{)(assessmentLinkUrl)(\s+}}|}})/gi;

				this.completedEmail = {
					...this.emailForm,
					subject: subject
						.replace(candidateRegex, candidate.name)
						.replace(
							candidateFirstNameRegex,
							getCandidateFirstName({ name: candidate.name }),
						)
						.replace(
							candidateLastNameRegex, 
							getCandidateLastName({ name: candidate.name }),
						)
						.replace(companyRegex, company.name),
					template: template
						? template
								.replace(candidateRegex, candidate.name)
								.replace(
									candidateFirstNameRegex,
									getCandidateFirstName({ name: candidate.name }),
								)
								.replace(
									candidateLastNameRegex, 
									getCandidateLastName({ name: candidate.name }),
								)
								.replace(companyRegex, company.name)
								.replace(assessmentLinkUrlRegex, this.assessment.spica_link_url)
						: '',
					send_to: [send_to],
				};

				this.previewMode = true;
			}
		},
		goToEditingMode() {
			this.previewMode = false;
		},
		async handleNewAttachmentAdded({ newFiles = [] }) {
			this.emailForm = {
				...this.emailForm,
				attachments: [
					...this.emailForm.attachments,
					...newFiles.map(({ id }) => id),
				],
				attachment_files: [...this.emailForm.attachment_files, ...newFiles],
			};
		},
		handleAttachmentDeleteClick({ index }) {
			this.emailForm = {
				...this.emailForm,
				attachments: this.emailForm.attachments.filter(
					(item, itemIndex) => index !== itemIndex,
				),
				attachment_files: this.emailForm.attachment_files.filter(
					(item, itemIndex) => index !== itemIndex,
				),
			};
		},
	},
	mounted() {
		this.isVisible = true;
	},
};
</script>
