<template>
	<v-dialog
		:value="true"
		@click:outside="handleClickOutside"
		@keydown.esc="handleClickOutside"
		persistent
		:fullscreen="true"
	>
		<v-card elevation="0">
			<v-card-text v-if="isSignInLinkEmailSent">
				<v-container style="max-width: 400px; padding-top: 60px">
					<v-row>
						<v-col>
							<p style="text-align: center">
								<v-icon style="font-size: 3rem">check_circle</v-icon>
							</p>
							<p>Email link for sign-in is sent.</p>
							<p>Now please close this tab and check your email box.</p>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-text style="padding: 0" v-else>
				<ValidationObserver
					ref="email_link_form"
					tag="form"
					@submit="handleSubmit"
					id="email_link_form"
				>
					<v-container style="max-width: 400px; padding-top: 60px">
						<v-row>
							<v-col>
								<ValidationProvider rules="required|email" v-slot="{ errors }">
									<v-text-field
										v-model="form.useremail"
										:label="`${$t('label.email')}*`"
										flat
										hide-details
										outlined
										:error-messages="errors"
										name="email"
										type="email"
										dense
									></v-text-field>
								</ValidationProvider>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-btn color="primary" block @click="handleSubmit">
									<v-icon left>send</v-icon>
									{{ $t('button.send-sign-in-link') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState } from 'vuex';
import api from '@/api';

export default {
	name: 'EmailLink',
	computed: {
		...mapState({
			currentLang: state => state.currentUser.lang,
		}),
	},
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			isSignInLinkEmailSent: false,
			form: {
				useremail: null,
			},
		};
	},
	methods: {
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.email_link_form.validate();

			if (isValid) {
				const { form } = this;
				api.authUser.sendSignInEmail.post(form).then(() => {
					this.isSignInLinkEmailSent = true;
					window.localStorage.setItem(
						'emailForSignIn',
						form.useremail.trim().toLowerCase(),
					);
				});
			}
		},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
	},
};
</script>
