<template>
	<v-menu offset-y>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				v-bind="attrs"
				outlined
				text
				class="icons-with-menu-button"
				style="margin-left: 0.5rem"
			>
				<span v-on="on" class="icons-with-menu-icon-container">
					<v-icon v-if="listViewModel === 'basic'">view_headline</v-icon>
					<v-icon v-else-if="listViewModel === 'detailed'">view_agenda</v-icon>
					<v-icon>arrow_drop_down</v-icon>
				</span>
			</v-btn>
		</template>
		<v-list>
			<v-list-item
				@click="() => handleChangeListViewMode({ listViewMode: 'basic' })"
			>
				<v-list-item-icon><v-icon>view_headline</v-icon></v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('text.simple-view') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				@click="() => handleChangeListViewMode({ listViewMode: 'detailed' })"
			>
				<v-list-item-icon><v-icon>view_agenda</v-icon></v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('text.detailed-view') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
	name: 'PanePicker',
	computed: {
		...mapState({
			listViewMode: state => state.currentUser.listViewMode,
		}),
	},
	data() {
		return {
			listViewModel: 'basic',
		};
	},
	methods: {
		handleChangeListViewMode({ listViewMode }) {
			api.authUser.put({ list_view_mode: listViewMode }).then(() => {
				this.$store.commit('currentUser/setListViewMode', {
					listViewMode,
				});
			});
		},
	},
	watch: {
		listViewMode: {
			handler(newValue) {
				this.listViewModel = newValue ? newValue : 'basic';
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.v-btn {
	&.icons-with-menu-button {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
.icons-with-menu-icon-container {
	margin-left: -0.5rem;
}
</style>
